import { createSlice, PayloadAction } from "@reduxjs/toolkit"
// import { WritableDraft } from "@reduxjs/toolkit/node_modules/immer/dist/internal"

interface Field<Type> {
    value: Type
    error: string
}

interface CreditCardForm {
    cardholderName: Field<string>,
    number: Field<string>,
    expirationDate: Field<string>,
    cvn: Field<string>,
    paymentAmount: Field<string>,
    patientIsPaying: Field<boolean>
}

interface PaymentState {
    isOpen: boolean,
    step: number,
    error: string,
    loading: boolean,
    emailReceipt: boolean,
    printReceipt: boolean,
    emailAddress: string,
    creditCard: CreditCardForm
}

const emptyCreditCard: CreditCardForm = {
    cardholderName: {value: "", error: ""},
    number: {value: "", error: ""},
    expirationDate: {value: "", error: ""},
    cvn: {value: "", error: ""},
    paymentAmount: {value: "", error: ""},
    patientIsPaying: {value: false, error: ""}
}

const initialState: PaymentState = {
    isOpen: false,
    step: 0,
    error: "",
    loading: false,
    emailReceipt: true,
    printReceipt: false,
    emailAddress: "",
    creditCard: emptyCreditCard
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        toggleOpen: (state) => {
            state.isOpen = !state.isOpen
        },
        closeWizard: (state) => {
            state.isOpen = false
        },
        advanceStep: (state) => {
            state.step += 1
            state.error = ""
            state.loading = false
        },
        reverseStep: (state) => {
            state.step -= 1
            state.error = ""
            state.loading = false
        },
        startLoading: (state) => {
            state.loading = true
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
            state.loading = false
        },
        toggleEmailReceipt: (state) => {
            state.emailReceipt = !state.emailReceipt
        },
        togglePrintReceipt: (state) => {
            state.printReceipt = !state.printReceipt
        },
        setEmailAddress: (state, action: PayloadAction<string>) => {
            state.emailAddress = action.payload
        },
        setCardholderName: (state, action: PayloadAction<string>) => {
            state.creditCard.cardholderName.value = action.payload
        },
        setFieldError: (state, action: PayloadAction<{fieldName: keyof CreditCardForm, error: string}>) => {
            state.creditCard[action.payload.fieldName].error = action.payload.error
        },
        // setFieldValue: <Key extends keyof CreditCardForm>(state: WritableDraft<PaymentState>, action: PayloadAction<{fieldName: Key, val: CreditCardForm[Key]["value"] }>) => {
        //     state.creditCard[action.payload.fieldName].value = action.payload.val
        // },
        resetError: (state) => {
            state.error = ""
        },
        resetWizard: (state) => {
            state.step = 0
            state.error = ""
            state.loading = false
            state.emailReceipt = true
            state.printReceipt = false
            state.emailAddress = ""
            state.creditCard = emptyCreditCard
        }
    }
})

export const { 
    toggleOpen, 
    advanceStep,
    reverseStep, 
    setError,
    startLoading,
    toggleEmailReceipt,
    togglePrintReceipt,
    setEmailAddress,
    setFieldError,
    // setFieldValue,
    resetError, 
    resetWizard, 
    closeWizard 
} = paymentSlice.actions

export default paymentSlice.reducer