import React, { useEffect, useRef } from 'react'
import { InputWrapper, PasscodeInput } from './multiFactorStyles'
import { useAppSelector } from '../../redux/store'

const PasscodeInputs = ({
  passcode,
  updatePasscode,
  passcodeLength,
  elevated
}) => {
  const InputsRef = useRef([])
  const mobile = useAppSelector(state => state.nav.mobile)
  const theme = useAppSelector(state => state.users.ThemeName)

  useEffect(() => {
    updatePasscode(new Array(passcodeLength).fill(''))
    if (!mobile) {
      InputsRef.current[0].focus()
    }
  }, [])

  const handleInputKeyDown = (i, key, e) => {
    const lastInputIndex = passcodeLength - 1

    if (key === 'Backspace') {
      if (i > 0) { // backspace in inputs that are not the first input
        let newPasscode = [...passcode]
        newPasscode[i] = '';
        updatePasscode(newPasscode)
        InputsRef.current[i - 1].focus()
        e.preventDefault()
      } else { // backspace in first input
        if (passcode[i] !== '') {
          let newPasscode = [...passcode]
          newPasscode[i] = '';
          updatePasscode(newPasscode)
        }
      }
    } else if (key === 'ArrowLeft' && i > 0) {
      InputsRef.current[i - 1].focus()
    } else if (key === 'ArrowRight' && i !== lastInputIndex) {
      InputsRef.current[i + 1].focus()
    }
  }

  const handleInputChange = (i, val) => {
    let newPasscode = [...passcode]
    newPasscode[i] = val;
    updatePasscode(newPasscode)

    // If inputted valid character and not the last input, move to next input
    if (val.trim() && i !== (passcodeLength - 1)) {
      InputsRef.current[i + 1].focus()
    }
  }

  const handlePaste = (event) => {
    const text = event.clipboardData.getData('Text')
    const code = text.split("").map((char) => {
      if (Number.isInteger(Number(char))) {
        return Number(char);
      }
      return "";
    });
    updatePasscode(code);
  };

  const generateInputs = () => {
    let display = []
    for (let i = 0; i < passcodeLength; i++) {
      display.push(
        <PasscodeInput
          mobile={mobile}
          autoComplete="off"
          aria-label={'PasscodeInput' + i}
          id={'PasscodeInput' + i}
          key={'PasscodeInput' + i}
          data-test={'passcode-input-' + i}
          value={passcode[i]}
          inputMode="numeric"
          maxLength="1"
          ref={input => InputsRef.current[i] = input}
          onChange={(e) => handleInputChange(i, e.target.value)}
          onKeyDown={(e) => handleInputKeyDown(i, e.key, e)}
          elevated={elevated}
          lightTheme={theme === "Light"}
        />
      )
    }
    return display;
  }

  return (
    <InputWrapper data-test="passcode-inputs-wrapper" onPaste={handlePaste}>
      {generateInputs()}
    </InputWrapper>  
  )
}

export default PasscodeInputs