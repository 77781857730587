import { ChargeData, CollectionData, GCRData, KPIData, ProcessedKPI } from "../../../type"
export const addKPIData = (data:KPIData[]) =>{
    const KPIObj = []
    const CollectionsToday:ProcessedKPI = {posted: data[data.length-1].CollectionAmountPosted, pending: data[data.length-1].CollectionAmountPending}
    KPIObj.push({CollectionsToday: CollectionsToday})
    const BillingToday:ProcessedKPI = {posted: data[data.length-1].BillingAmountPosted, pending: data[data.length-1].BillingAmountPending}
    KPIObj.push({BillingToday: BillingToday})
    const SpecimenCountToday = data[data.length-1].SpecimenAmount
    KPIObj.push({SpecimenCountToday: SpecimenCountToday})
    const CollectionsMonthToDate:ProcessedKPI = data.reduce((a,b) => {
        if(a.posted){
            a.posted += b.CollectionAmountPosted
        }else{
            a.posted = b.CollectionAmountPosted
        }
        if(a.pending){
            a.pending += b.CollectionAmountPending
        }else{
            a.pending = b.CollectionAmountPending
        }
        return a
    }, {} as ProcessedKPI)
    KPIObj.push({CollectionsMonthToDate: CollectionsMonthToDate})
    const BillingMonthToDate = data.reduce((a,b) => {
        if(a.posted){
            a.posted += b.BillingAmountPosted
        }else{
            a.posted = b.BillingAmountPosted
        }
        if(a.pending){
            a.pending += b.BillingAmountPending
        }else{
            a.pending = b.BillingAmountPending
        }
        return a
    }, {} as ProcessedKPI)
    KPIObj.push({BillingMonthToDate: BillingMonthToDate})
    const SpecimenCountMonthToDate = data.map(el => {return {ReportPeriodDate: el.ReportPeriodDate, SpecimenAmount: el.SpecimenAmount}})
    KPIObj.push({SpecimenCountMonthToDate: SpecimenCountMonthToDate})
    return  KPIObj
}

export const buildGCRData = (charges:ChargeData[], collections:CollectionData[]):GCRData[] => {
        let combined = []
            if(charges?.length > 0 && collections?.length > 0){
                // debugger
              combined = [...collections].map((collection: CollectionData, i) => { 
                let percentage = charges[i] ? (collection.PaymentAmount/charges[i].Amount)*100 : (collection.PaymentAmount/0.01)*100
                const gcr = {
                        FourDigitYear: collection.FourDigitYear,
                        MonthNumber: collection.MonthNumber,
                        PeriodAbbreviation: collection.PeriodAbbreviation,
                        GCR: percentage
                    }
                return gcr
                })
                return combined
            }
            return []
}