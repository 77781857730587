import styled, { css } from 'styled-components'
import { UnstyledButton } from '../../styles/buttonStyles'

export const ModalContainer = styled.div`
  min-height: ${props => (props.minHeight ? props.minHeight : 'fit-content')};
  width: 50%;
  z-index: 500;
  border: 1px solid ${props => props.theme.modalBorder};
  background-color: ${props => props.theme.viewer.background};
  color: ${props => props.theme.restyle.primary};
  padding: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 1000ms linear;
  @media screen and (max-width: 768px) {
    width: 100%;
    top: 160px;
  }
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;

  ${props =>
    props.mobile &&
    css`
      height: 80%;
    `}
`

export const ModalContent = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  margin: 0 auto;
`

export const ModalForm = styled.form`
  width: 100%;
  margin: 0 auto;
  color: ${props => props.theme.shades.text};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    text-align: left;
    width: 90%;
    margin: 0 auto;
    justify-content: left;
  }
`

export const ModalFormLabel = styled.label`
  font-size: 1.2em;
  font-weight: 500;
  margin-top: 2rem;
  align-self: left;
`

export const ModalActionBar = styled.div`
  width: 100%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 3000;
  overflow: hidden;
  background-color: rgba(90, 90, 90, 0.5);
  backdrop-filter: blur(3px);
`

export const ModalBlur = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 400;
  background-color: transparent;
  -webkit-backdrop-filter: blur(25px);
  -moz-backdrop-filter: blur(25px);
  -o-backdrop-filter: blur(25px);
  -ms-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
`

export const TestCardModalBackground = styled.div`
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: white;
`

export const ModalTitle = styled.h2`
  font-size: 1.5em;
  font-weight: 500;

  @media screen and (min-width: 1024px) {
    margin: 20px;
    font-size: 1.5em;
  }
`

export const ThemeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    height: 260px;
    justify-content: space-between;
  }
`

export const Theme = styled.div`
  width: 150px;
  height: 100px;
  border: 10px solid ${props => (props.name === 'Light' ? '#E2EAEF' : '#4f586c')};
  display: flex;
  position: relative;
  cursor: pointer;
  box-shadow: ${props => (props.name === props.currentTheme ? `0 0 5pt 2pt ${props.name === 'Light' ? '#B65EC7' : '#7ED1E7'}` : 'none')};
  @media screen and (min-width: 600px) {
    &:first-child {
      margin-right: 5%;
    }
  }

  @media screen and (min-width: 1024px) {
    &:first-child {
      margin-right: 2.5%;
    }
    &:last-child {
      margin-left: 2.5%;
    }
  }
`

export const ErrorMessage = styled.label`
  width: 100%;
  color: var(--color-accent-red);
  font-weight: bold;
  font-size: 0.9em;
  padding: 5px 0;
`

export const ThemeBoxColor = styled.div`
  background-color: ${props => props.color};
  width: 25%;
`

export const ThemeName = styled(UnstyledButton)`
  width: 100px;
  height: 20px;
  background-color: #e2eaef;
  font-weight: bold;
  position: absolute;
  top: 40px;
  left: 25px;
  border: 1px solid ${props => props.theme.shades.shadow};
  color: #000000;
`
