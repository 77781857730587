import React from 'react'
import { PositionIndicatorDiv } from './carouselStyles'


const CarouselPositionIndicator = ({selected})=> {

    return(
        <PositionIndicatorDiv selected={selected} />
    )
    
}

export default CarouselPositionIndicator