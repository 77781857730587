import * as wjChart from '@grapecity/wijmo.react.chart'
import { convertNumToCurrency } from '../../../utils/helperMethods'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { DashCardHeader } from '../dashboardStyles'
import { UnstyledButton } from '../../../styles/buttonStyles'
import CustomTooltip from '../../misc/CustomTooltip'

const CollectionsChartMobile = ({ collections, formatYLabels, goToReport, reportName }) => {
  // round largest amount to nearest place value
  const calculateYAxisMax = () => {
    // get largest VoucherBalance from array of collection objects
    const largestNum = Math.ceil(
      collections
        .map(data => data.PaymentAmount)
        .sort((a, b) => a - b)
        .pop()
    )
    // get the place value of left most num
    const splitNums = largestNum.toString().split('')
    let newTens = parseInt(
      splitNums
        .map((num, i) => {
          let numVal
          if (i === 0) {
            splitNums[1] > 5 ? (numVal = (parseInt(num) + 1).toString()) : (numVal = num)
          } else if (i === 1) {
            num > 5 ? (numVal = '0') : (numVal = (parseInt(num) + 1).toString())
          } else {
            numVal = '0'
          }
          return numVal
        })
        .join('')
    )
    return newTens
  }

  const formatXLabels = (eng, label) => {
    label.cls = 'mobile-label'
    return label
  }

  return (
    <>
      <DashCardHeader>
        <h4>Total Collections by Month</h4>
      </DashCardHeader>
      <wjChart.FlexChart itemsSource={collections} bindingX="PeriodAbbreviation" cssClass="dark" chartType="Bar" rendered={e => e.hostElement.classList.add('chart')}>
        <wjChart.FlexChartAxis itemFormatter={(engine, label) => formatYLabels(engine, label)} binding="PaymentAmount" wjProperty="axisX" max={calculateYAxisMax()} />
        <wjChart.FlexChartAxis wjProperty="axisY" binding="PeriodAbbreviation" itemFormatter={(eng, label) => formatXLabels(eng, label)}></wjChart.FlexChartAxis>
        <wjChart.FlexChartSeries
          style={{ fill: 'var(--color-primary-blue)' }}
          binding="PaymentAmount"
          cssClass="bar"
          tooltipContent=''
        />
        <wjChart.FlexChartDataLabel content={ht => `${convertNumToCurrency(ht.item.PaymentAmount)}`} position={1}></wjChart.FlexChartDataLabel>
        <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </>
  )
}

export default CollectionsChartMobile
