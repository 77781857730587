import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FeaturePermission,
  UserFeaturePermission,
  UserPermission,
} from "../../../type";
import { useOutsideClick } from "../../utils/hooks";
import { updateUsersActiveClient, } from "./permissionsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { findPermission } from "../../utils/helperMethods";
import {
  PermissionCard,
  PermissionCardBadges,
  PermissionCardClient,
  PermissionCardContent,
  PermissionCardName,
  PermissionsInit,
  PermissionsClientAbbreviation,
} from "./permissionStyles";
import PermissionBadgeComponent from "./PermissionsBadgeComponent";
import UserPermissionForm from "./UserPermissionForm";

interface Props {
  user: UserPermission;
  width: any;
}

const UserPermissionRow = (props: Props) => {
  const [expandedForm, setExpandedForm] = useState(false);
  const [currentClient, setCurrentClient] = useState<ClientPermission | null>(
    null
  );
  const formRef = useRef(null)

  useEffect(() => {
    if (props.user.Clients) {
      const client = props.user.Clients.find((client: any) => client.selected);
      if (client) {
        setCurrentClient(client);
      }
    }

    return () => {};
  }, [props.user.Clients]);

  const loggedInClient = useAppSelector((state) => state.users.Client);
  const loggedInUserID = useAppSelector((state) => state.users.UserID);
  const touchedPermissions = useAppSelector((state) => state.permissions.touchedPermissions);
  const AdminPermissions = useAppSelector((state) => state.users.UserPermissions.Admin);
  const CurrentClientAdminPermissions = useAppSelector((state) => state.users.ClientAdminPrivileges);

  const dispatch = useAppDispatch();

  useOutsideClick(formRef, () => setExpandedForm(false))

  const handleClientChange = useCallback(
    (payload) => {
      dispatch(updateUsersActiveClient(payload));
    },
    [dispatch]
  );

  // const handleCheckboxClick = (payload: any) => {
  //   dispatch(handlePermissionChange(payload));
  // };

  // const handleFormSubmit = (e: any, payload: any) => {
  //   e.preventDefault();
  //   setExpandedForm(!expandedForm);
  //   dispatch(
  //     setPermissions({
  //       ...payload,
  //       controller: new AbortController(),
  //       updateState: false,
  //     })
  //   );
  // };

  interface ClientPermission {
    ClientID: string;
    ClientAbbreviation: string;
    ClientName: string;
    Features: FeaturePermission;
    selected: boolean;
  }

  const filterClientsByUserPermission = (clients: ClientPermission[]) => {
    let processedClients = clients;
    if (
      findPermission("9", AdminPermissions) ||
      findPermission("10", AdminPermissions) ||
      findPermission("12", AdminPermissions) ||
      findPermission("13", AdminPermissions) ||
      findPermission("11", AdminPermissions)
    ) {
      if (
        findPermission("9", AdminPermissions) &&
        findPermission("10", AdminPermissions)
      ) {
        return processedClients;
      } else if (
        findPermission("10", AdminPermissions) &&
        (!findPermission("9", AdminPermissions) ||
          findPermission("13", AdminPermissions))
      ) {
        return processedClients.filter(
          (client) => client.ClientID === loggedInClient.ClientID
        );
      } else if (
        findPermission("9", AdminPermissions) &&
        !findPermission("10", AdminPermissions)
      ) {
        return processedClients.filter(
          (client) => client.ClientID !== loggedInClient.ClientID
        );
      }
    }
    return [];
  };

  const buildClientSelecter = () => {
    if (props.user.Clients.length > 1) {
      const unselectedClients = filterClientsByUserPermission(
        props.user.Clients
      ).map((client: ClientPermission) => {
        return (
          <option
            key={`${props.user.UserID}:${client.ClientID}`}
            value={client.ClientID}
          >
            {client.ClientAbbreviation}
          </option>
        );
      });
      if (unselectedClients) {
        return (
          // @ts-ignore
          <select
            value={currentClient?.ClientID}
            onChange={(e) =>
              handleClientChange({
                clientID: e.target.value,
                userID: props.user.UserID,
              })
            }
          >
            {unselectedClients}
          </select>
        );
      }
    } else {
      return <PermissionsClientAbbreviation>{props.user.Clients[0].ClientAbbreviation}</PermissionsClientAbbreviation>
    }
  };

  const buildBadges = () => {
    const permissionArray: any = [];
    let canView = true;
    if (
      currentClient &&
      currentClient.ClientID === loggedInClient.ClientID &&
      !findPermission("9", AdminPermissions) && !findPermission("11", AdminPermissions)
    ) {
      canView = false;
    }
    if (currentClient && canView) {
      for (const [key, val] of Object.entries(currentClient.Features)) {
        permissionArray.push({ category: key, subPermissions: val });
      }
    }
    return (
      permissionArray.length > 0 &&
      permissionArray.map(
        (permission: {
          category: string;
          subPermissions: UserFeaturePermission[];
        }) => {
          if (
            permission.subPermissions.some(
              (subPermission: UserFeaturePermission) =>
                subPermission.UserHasPermission
            )
          ) {
            const forClientAdminCheck = CurrentClientAdminPermissions.find(
              (client: any) => {
                // @ts-ignore
                return client.clientID === currentClient.ClientID;
              }
            );
            let canEdit = findPermission("12", forClientAdminCheck?.Admin) || findPermission("13", forClientAdminCheck?.Admin);
            if (props.user.UserID === loggedInUserID) {
              canEdit = findPermission("11", forClientAdminCheck?.Admin);
            }
            return (
              <PermissionBadgeComponent
                Client={currentClient ? currentClient : null}
                userID={props.user.UserID}
                key={`${props.user.UserID}:${permission.category}`}
                permissionCategory={permission.category}
                permissions={permission.subPermissions}
                canEdit={canEdit}
              />
            );
          }
        }
      )
    );
  };

  return (
    <PermissionCard>
      <PermissionCardContent>
        <PermissionCardName>
          <div id="name-init">
            <PermissionsInit>{props.user.UserInitials}</PermissionsInit>
            <p>{props.user.UserName}</p>
          </div>
          {props.width > 600 ? <PermissionCardClient>{buildClientSelecter()}</PermissionCardClient> : null}
        </PermissionCardName>
        {props.width <= 600 ? <PermissionCardClient>{buildClientSelecter()}</PermissionCardClient> : null}
        <PermissionCardBadges>
          <div className="addBadge">
            <PermissionBadgeComponent
              // @ts-ignore
              permissions={currentClient && currentClient.Features}
              userID={props.user.UserID}
              setExpandedForm={setExpandedForm}
              expandedForm={expandedForm}
              add
            />
          </div>
          <div className="Badges">{buildBadges()}</div>
        </PermissionCardBadges>
        {expandedForm ? 
          <UserPermissionForm
          currentClient={currentClient}
          CurrentClientAdminPermissions={CurrentClientAdminPermissions}
          loggedInUserID={loggedInUserID}
          loggedInClient={loggedInClient}
          touchedPermissions={touchedPermissions}
          user={props.user}
          setExpandedForm={setExpandedForm}
          expandedForm={expandedForm}
          AdminPermissions={AdminPermissions}
          /> : null}
      </PermissionCardContent>
    </PermissionCard>
  );
};

export default UserPermissionRow;
