import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../type'
import { AppDispatch } from '../../redux/store'
import { api } from '../../utils/api'
import { addBannerMessages } from '../alerts/alertSlice'

interface HealthCheckState {
  HealthCheckPassed: boolean
  healthCheckStatus: RequestStatus
  architectServiceOnline: boolean
  databasesOnline: boolean
  dualRemitServiceOnline: boolean
  labRcmServiceOnline: boolean
  pfrServiceOnline: boolean
  reportingServiceOnline: boolean
}

const initialHealthCheckState = {
  HealthCheckPassed: true,
  healthCheckStatus: 'idle',
  architectServiceOnline: false,
  databasesOnline: false,
  dualRemitServiceOnline: false,
  labRcmServiceOnline: false,
  pfrServiceOnline: false,
  reportingServiceOnline: false,
}

const initialState = initialHealthCheckState as HealthCheckState

export const callHealthCheck = createAsyncThunk<any, any, { dispatch: AppDispatch }>('healthCheck/callHealthCheck', async (payload, thunkAPI) => {
  const handleHealthCheckError = () => {
    const error = {
      MessageID: `healthcheck-fetch-critical`,
      MessageTypeID: '1',
      MessageSummary: 'A network issue has occurred.',
      FullMessage: 'A network issue has occurred. Please contact the help desk at (877) 846-2953.',
      MessageTypeDescription: 'Alert',
      read: false,
    }
    thunkAPI.dispatch(addBannerMessages([error]))
  }
  try {
    const handleHealthCheckReturn = (data: any) => {
      let criticalErrors = data.critical.map((msg: string, i: number) => {
        return {
          MessageID: `${i}critical`,
          MessageTypeID: '1',
          MessageSummary: msg,
          FullMessage: msg,
          MessageTypeDescription: 'Alert',
          read: false,
        }
      })
      let errors = data.nonCritical.map((msg: string, i: number) => {
        return {
          MessageID: `${i}noncritical`,
          MessageTypeID: '2',
          MessageSummary: msg,
          FullMessage: msg,
          MessageTypeDescription: 'Warning',
          read: false,
        }
      })
      thunkAPI.dispatch(addBannerMessages([...criticalErrors, ...errors]))
      return data
    }

    return api
      .post('/global/serviceHealthCheck')
      .then(res => res.data)
      .then(data => {
        return handleHealthCheckReturn(data)
      })
      .catch(error => {
        return handleHealthCheckError()
      })
  } catch (err) {
    return handleHealthCheckError()
  }
})

const healthCheckSlice = createSlice({
  name: 'healthCheck',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(callHealthCheck.pending, state => {
      state.healthCheckStatus = 'pending'
    })
    builder.addCase(callHealthCheck.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.HealthCheckPassed = action.payload.critical.length === 0
        state.architectServiceOnline = action.payload.architectServiceOnline || false
        state.databasesOnline = action.payload.databasesOnline || false
        state.dualRemitServiceOnline = action.payload.dualRemitServiceOnline || false
        state.labRcmServiceOnline = action.payload.labRcmServiceOnline || false
        state.pfrServiceOnline = action.payload.pfrServiceOnline || false
        state.reportingServiceOnline = action.payload.reportingServiceOnline || false
        state.healthCheckStatus = 'succeeded'
      } else {
        state.healthCheckStatus = 'rejected'
      }
    })
    builder.addCase(callHealthCheck.rejected, state => {
      state.healthCheckStatus = 'rejected'
    })
  },
})

// export const {} = healthCheckSlice.actions
export default healthCheckSlice.reducer
