import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RequestStatus, PatientCharge, PatientDemographic, BuildCSVPayload } from '../../../type'
import { AppDispatch, RootState } from '../../redux/store'
import { protectedApi } from '../../utils/api'
import { getCookie } from '../../utils/security'
import { fetchRefreshToken, logout } from '../login/userSlice'

export const buildDisplayLabel = (label: string) => {
  if (label === 'PFR') {
    return label
  } else if (label.includes('DXCode')) {
    const split = label.split('DXCode')
    return 'DX Code ' + split[1]
  } else if (label === 'TaxID') {
    return 'Tax ID'
  } else {
    let newLabel = label
      .split(/(?=[A-Z])/)
      .map(word => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
      .join(' ')

    if (label.includes('ClaimRemark')) {
      newLabel = newLabel.replace('Remark', 'Remark ')
    }

    if (label.includes('ReasonCode')) {
      newLabel = newLabel.replace('Code', 'Code ')
    }

    return newLabel
  }
}

export const buildCurrency = (val: any): string | undefined => {
  if (val !== null) {
    const sign = Math.sign(val)
    const options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }
    if (sign >= 0) {
      return new Intl.NumberFormat('en-US', options).format(val)
    } else if (sign < 0) {
      return '(' + new Intl.NumberFormat('en-US', options).format(Math.abs(val)) + ')'
    }
  } else {
    return '--'
  }
}

interface PatientState {
  currentPatient: {
    demographic: PatientDemographic | {}
    demographicLoaded: RequestStatus
    patientAR: any
    patientARLoaded: RequestStatus
    claims: PatientCharge[]
    filteredClaims: PatientCharge[]
    selectedClaimIndex: number
    mobileClaimMode: boolean
    claimsLoaded: RequestStatus
    exportMode: boolean
  }
  modalOpen: boolean
  modalLevel: '' | 'remarks' | 'accession-reasons' | 'service-reasons' | 'remarks-reasons'
  modalContent: {
    ClaimNumber?: string
    CPTCode?: string
    CPTDescription?: string
    content?: any[]
    specimens?: any[]
  }
  demographicsExpanded: boolean
  searchTerm: string
  CSVData: any
  CSVHeaders: any
  csvBuilding: RequestStatus
  errors: {
    getPatientDemographics: string
    getPatientFinances: string
    getPatientClaims: string
  }
}

const initialPatientState = {
  currentPatient: {
    demographic: {},
    demographicLoaded: 'idle',
    patientAR: {},
    patientARLoaded: 'idle',
    claims: [],
    filteredClaims: [],
    selectedClaimIndex: 0,
    mobileClaimMode: false,
    claimsLoaded: 'idle',
    exportMode: false,
  },
  modalOpen: false,
  modalLevel: '',
  modalContent: {},
  demographicsExpanded: false,
  searchTerm: '',
  CSVData: [],
  CSVHeaders: [],
  csvBuilding: 'idle',
  errors: {
    getPatientDemographics: '',
    getPatientFinances: '',
    getPatientClaims: '',
  },
}

const initialState = initialPatientState as PatientState

export const getPatientDemographics = createAsyncThunk<any, { PatientID: number; controller: AbortController }, { dispatch: AppDispatch; state: RootState }>(
  'patient/getPatientDemographics',
  async (payload, thunkAPI) => {
    const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN)
    const isLoggedIn = thunkAPI.getState().login.isLoggedIn
    if (token || isLoggedIn) {
      return protectedApi(payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        .post('/rcm/patients/getDemographicOverview', payload)
        .then(res => res.data)
        .then(data => {
          if (data.success) {
            return data
          } else {
            throw new Error()
          }
        })
        .catch(error => {
          if (error.response.data.tokenExpired) {
            thunkAPI.dispatch(fetchRefreshToken(() => thunkAPI.dispatch(getPatientDemographics(payload))))
          }
          return error.response.data
        })
    } else {
      thunkAPI.dispatch(
        logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' })
      )
    }
  }
)

export const getPatientFinances = createAsyncThunk<any, { PatientID: number; controller: AbortController }, { dispatch: AppDispatch }>(
  'patient/getPatientFinances',
  async (payload, thunkAPI) => {
    const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN)
    if (token) {
      return protectedApi(payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        .post('/rcm/patients/getFinances', payload)
        .then(res => res.data)
        .then(data => {
          if (data.success) {
            return data
          } else {
            throw new Error()
          }
        })
        .catch(error => {
          if (error.response.data.tokenExpired) {
            thunkAPI.dispatch(fetchRefreshToken(() => thunkAPI.dispatch(getPatientFinances(payload))))
          }
          return error.response.data
        })
    } else {
      thunkAPI.dispatch(
        logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' })
      )
    }
  }
)

export const getPatientClaims = createAsyncThunk<any, { PatientID: number; controller: AbortController }, { dispatch: AppDispatch }>(
  'patient/getPatientClaims',
  async (payload, thunkAPI) => {
    const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN)
    if (token) {
      return protectedApi(payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        .post('/rcm/patients/getCharges', payload)
        .then(res => res.data)
        .then(data => {
          if (data.success) {
            return data
          } else {
            throw {
              data: data,
              thrownError: true,
              error: new Error(),
            }
          }
        })
        .catch(error => {
          if (error.thrownError) {
            error = { response: error }
          }
          if (error.response.data.tokenExpired) {
            thunkAPI.dispatch(fetchRefreshToken(() => thunkAPI.dispatch(getPatientClaims(payload))))
            return error.response.data
          }
          return error.response.data
        })
    } else {
      thunkAPI.dispatch(
        logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' })
      )
    }
  }
)

export const buildCSVData = createAsyncThunk<any, BuildCSVPayload, { dispatch: AppDispatch }>('patient/buildCSVData', async (payload, thunkAPI) => {
  const claimRemarksHeaders = () => {
    // Finding the largest number of remarks to know how many sets of columns to generate
    //@ts-ignore
    const remarksLength: number = Math.max(...payload.files.map(file => file.remarks?.length))
    const remarksHeaders: string[] = Array(remarksLength)
      .fill(null)
      .map((remark, i) => [`ClaimRemark${i + 1}Category`, `ClaimRemark${i + 1}Code`, `ClaimRemark${i + 1}Description`])
      .flat()

    return remarksHeaders
  }

  const reasonCodesHeaders = () => {
    // Finding the largest number of reason codes for a service to know how many sets of columns to generate
    //@ts-ignore
    const reasonCodesLengths: number[] = payload.files
      .map(file => {
        return file.specimens.map((specimen: any) => {
          return specimen.services.map((service: any) => service.denials?.length)
        })
      })
      .flat(2)

    //@ts-ignore
    const reasonHeaders: string[] = Array(Math.max(...reasonCodesLengths))
      .fill(null)
      .map((reason, i) => [`ServiceReasonCode${i + 1}Category`, `ServiceReasonCode${i + 1}Abbreviation`, `ServiceReasonCode${i + 1}Description`])
      .flat()
    return reasonHeaders
  }

  const headers = [
    'ClaimNumber',
    'ServiceDate',
    'BillingDate',
    ...claimRemarksHeaders(),
    'SpecimenNumber',
    'ClinicName',
    'BillableProvider',
    'ReferringProvider',
    'TaxID',
    'PlaceOfService',
    'TestDescription',
    'CptDescription',
    'CptCode',
    'UnitCount',
    'DXCode1',
    'DXCode2',
    'DXCode3',
    'DXCode4',
    'DXCode5',
    'DXCode6',
    'DXCode7',
    'Modifiers',
    'PayorName',
    'BilledAmount',
    'DatePaid',
    'PaidAmount',
    'PaymentAmount',
    'AdjustmentDate',
    'AdjustmentDate',
    'AdjustmentAmount',
    'Balance',
    ...reasonCodesHeaders(),
  ]

  const csvHeaders = headers.map(objKey => Object.assign({}, { label: buildDisplayLabel(objKey), key: objKey }))
  // @ts-ignore
  const buildCSVRow = (obj, objArr = []) => {
    const buildObject = (obj: any, hash: { [index: string]: any } = {}) => {
      const firstTier: any = {}
      // top-level
      // Claim must have specimens as the last entry or will push the row early
      const level1Entries = Object.entries(obj)
      const specimensIndex = level1Entries.findIndex(arr => arr[0] === 'specimens')
      level1Entries.push(...level1Entries.splice(specimensIndex, 1))
      for (const [key, val] of level1Entries) {
        //Claim level
        if (key === 'remarks') {
          //@ts-ignore
          val.forEach((remark, i) => {
            firstTier[`ClaimRemark${i + 1}Category`] = remark.RemarkCategoryAbbreviation.trim() + ' - ' + remark.RemarkCategoryDescription
            firstTier[`ClaimRemark${i + 1}Code`] = remark.RemarkCode
            firstTier[`ClaimRemark${i + 1}Description`] = remark.RemarkDescription
          })
        }
        if (headers.includes(key)) {
          firstTier[key] = val
        }
        if (Array.isArray(val)) {
          val.forEach(item => {
            // Specimen / Accession level
            const secondTier: any = {}
            if (typeof item === 'object') {
              const nonArray = Object.entries(item).filter(el => !Array.isArray(el[1]))
              nonArray.forEach(el => (headers.includes(el[0]) ? (secondTier[el[0]] = el[1]) : null))

              // Specimens must have services as the last entry or will push the row early
              const level2Entries = Object.entries(item)
              const servicesIndex = level2Entries.findIndex(arr => arr[0] === 'services')
              level2Entries.push(...level2Entries.splice(servicesIndex, 1))
              for (const [key2, val2] of level2Entries) {
                if (Array.isArray(val2)) {
                  val2.forEach(subItem => {
                    // Charge level
                    const thirdTier: any = {}
                    if (typeof subItem === 'object') {
                      const nonArray2 = Object.entries(subItem).filter(el => !Array.isArray(el[1]))
                      // Key/value pairs at charge level (e.g., 'CptCode')
                      nonArray2.forEach(pair => {
                        if (headers.includes(pair[0])) {
                          thirdTier[pair[0]] = pair[1]
                        }
                      })
                      // Arrays at charge level (i.e., 'Payors' and 'DXCodes')
                      // Must have payors as the last entry or will push the row early
                      const level3Entries = Object.entries(subItem)
                      const payorIndex = level3Entries.findIndex(arr => arr[0] === 'payors')
                      level3Entries.push(...level3Entries.splice(payorIndex, 1))

                      for (const [key3, val3] of level3Entries) {
                        if (Array.isArray(val3)) {
                          // DX Codes
                          if (key3 === 'DXCodes') {
                            val3.forEach((dxCode, i) => {
                              thirdTier['DXCode' + (i + 1)] = dxCode
                            })
                          }

                          // Denials/Reason codes
                          if (key3 === 'denials') {
                            val3.forEach((denial, i) => {
                              thirdTier[`ServiceReasonCode${i + 1}Category`] = denial.DenialCategoryAbbreviation.trim() + ' - ' + denial.DenialCategoryDescription
                              thirdTier[`ServiceReasonCode${i + 1}Abbreviation`] = denial.DenialAbbreviation
                              thirdTier[`ServiceReasonCode${i + 1}Description`] = denial.DenialCommentDescription
                            })
                          }

                          // Payors
                          val3.forEach(subItem2 => {
                            const fourthTier: any = {}
                            if (typeof subItem2 === 'object') {
                              for (const [key4, val4] of Object.entries(subItem2)) {
                                if (headers.includes(key4)) {
                                  fourthTier[key4] = val4
                                }
                              }
                            }
                            // Nesting the push() within the payor loop is what generates a row for each payor
                            if (key3 === 'payors') {
                              // @ts-ignore
                              objArr.push({
                                ...firstTier,
                                ...secondTier,
                                ...thirdTier,
                                ...fourthTier,
                              })
                            }
                          })
                        }
                      }
                    }
                  })
                }
              }
            }
          })
        }
      }
    }
    buildObject(obj)
    return objArr
  }

  const buildData = () => {
    const files = payload.files.map(file => buildCSVRow(file, []))
    const merged = [].concat.apply([], files)
    return merged
  }

  return await { headers: csvHeaders, data: buildData() }
})

export const patientSlice = createSlice({
  name: 'patient',
  initialState: initialState,
  reducers: {
    resetCSV(state) {
      state.csvBuilding = 'idle'
      state.currentPatient.exportMode = false
    },
    toggleDemographicsExpanded(state, action) {
      state.demographicsExpanded = action.payload
    },
    toggleSelectClaim(state, action) {
      state.currentPatient.filteredClaims[action.payload.index].selected = action.payload.newVal
    },
    toggleSelectAllClaims(state, action) {
      state.currentPatient.filteredClaims = state.currentPatient.filteredClaims.map(claim => {
        claim.selected = action.payload
        return claim
      })
    },
    setClaimIndex(state, action) {
      state.currentPatient.selectedClaimIndex = action.payload
    },
    setMobileClaimMode(state, action) {
      state.currentPatient.mobileClaimMode = action.payload
    },
    setExportMode(state, action) {
      state.currentPatient.exportMode = action.payload
    },
    resetPatient(state) {
      state.currentPatient = {
        demographic: {},
        demographicLoaded: 'idle',
        patientAR: {},
        patientARLoaded: 'idle',
        claims: [],
        filteredClaims: [],
        selectedClaimIndex: 0,
        mobileClaimMode: false,
        claimsLoaded: 'idle',
        exportMode: false,
      }
      state.modalOpen = false
      state.modalLevel = ''
      state.modalContent = {}
      state.demographicsExpanded = false
      state.CSVData = []
      state.CSVHeaders = []
      state.csvBuilding = 'idle'
      state.errors = {
        getPatientDemographics: '',
        getPatientFinances: '',
        getPatientClaims: '',
      }
    },
    setFilteredClaims(state, action) {
      state.currentPatient.filteredClaims = action.payload
    },
    updatePatientModal(state, action) {
      if (action.payload.modalOpen) {
        state.modalOpen = true
        state.modalLevel = action.payload.modalLevel
        state.modalContent = action.payload.modalContent
        return
      }

      state.modalOpen = false
      state.modalLevel = ''
      state.modalContent = {}
    },
  },
  extraReducers: builder => {
    builder.addCase(getPatientDemographics.pending, state => {
      state.currentPatient.demographicLoaded = 'pending'
      state.errors.getPatientDemographics = ''
    })
    builder.addCase(getPatientDemographics.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.currentPatient.demographic = action.payload.results[0]
        state.currentPatient.demographicLoaded = 'succeeded'
      } else {
        state.currentPatient.demographicLoaded = 'rejected'
        state.errors.getPatientDemographics = action.payload.message
      }
    })
    builder.addCase(getPatientDemographics.rejected, (state, action: any) => {
      state.currentPatient.demographicLoaded = 'rejected'
      state.errors.getPatientDemographics = action.payload.message
    })
    builder.addCase(getPatientFinances.pending, state => {
      state.currentPatient.patientARLoaded = 'pending'
      state.errors.getPatientFinances = ''
    })
    builder.addCase(getPatientFinances.fulfilled, (state, action) => {
      if (action.payload.success) {
        const hash: { [index: string]: any } = {}
        action.payload.results.reduce((map: any, obj: any) => {
          return hash[obj.BalanceType] ? (hash[obj.BalanceType] = [...hash[obj.BalanceType], obj]) : (hash[obj.BalanceType] = [obj])
        }, hash)
        state.currentPatient.patientAR = hash
        state.currentPatient.patientARLoaded = 'succeeded'
      } else {
        state.currentPatient.patientARLoaded = 'rejected'
        state.errors.getPatientFinances = action.payload.message
      }
    })
    builder.addCase(getPatientFinances.rejected, (state, action: any) => {
      state.currentPatient.patientARLoaded = 'rejected'
      state.errors.getPatientFinances = action.payload.message
    })
    builder.addCase(getPatientClaims.pending, state => {
      state.currentPatient.claimsLoaded = 'pending'
      state.errors.getPatientClaims = ''
    })
    builder.addCase(getPatientClaims.fulfilled, (state, action) => {
      if (action.payload.success) {
        const processedData = action.payload.results.map((claim: any) => {
          claim.selected = false
          return claim
        })
        state.currentPatient.claims = processedData
        state.currentPatient.filteredClaims = processedData
        state.currentPatient.claimsLoaded = 'succeeded'
      } else {
        state.currentPatient.claimsLoaded = 'rejected'
        state.errors.getPatientClaims = action.payload.message
      }
    })
    builder.addCase(getPatientClaims.rejected, (state, action: any) => {
      state.currentPatient.claimsLoaded = 'rejected'
      state.errors.getPatientClaims = action.payload.message
    })
    builder.addCase(buildCSVData.pending, state => {
      state.csvBuilding = 'pending'
    })
    builder.addCase(buildCSVData.fulfilled, (state, action) => {
      if (action.payload) {
        state.CSVData = action.payload.data
        state.CSVHeaders = action.payload.headers
        state.csvBuilding = 'succeeded'
      }
    })
  },
})

export const {
  resetCSV,
  toggleDemographicsExpanded,
  toggleSelectClaim,
  toggleSelectAllClaims,
  resetPatient,
  setClaimIndex,
  setMobileClaimMode,
  setExportMode,
  setFilteredClaims,
  updatePatientModal,
} = patientSlice.actions
export default patientSlice.reducer
