import React from 'react'
import Theme from '../../styles/theme';
import PhyBG from '../../images/phytest-bg2.svg'
import { SplashPageDiv } from '../login/splashStyle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { AccountIssueText, HelpStatement, LoginButton } from '../../components/login/loginStyles';
import PhoneNumber from '../misc/PhoneNumber';
import { logout } from '../login/userSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const AccountIssuePage = () => {
  const dispatch = useAppDispatch()

  const width = useAppSelector(state => state.nav.width)

  return (
    <Theme>
      <SplashPageDiv bg={PhyBG}>
        <FontAwesomeIcon icon={faQuestionCircle} size="10x" color="#692c87" style={{ marginBottom: '20px' }} />
        <AccountIssueText>There appears to be an issue with your account.</AccountIssueText>
        <AccountIssueText>Please contact your administrator or call us at
          {width > 1024
            ? [" ", <a key={'link'} type='tel' href="tel:+18778462953" style={{ color: '#FFFFFF', textDecoration: 'none' }}>(877) 846-2953</a>]
            : [" ", <PhoneNumber number="8778462953" />]}
        </AccountIssueText>
        <HelpStatement extraSpace>
          <LoginButton onClick={() => dispatch(logout({controller: new AbortController()}))} login>
            BACK TO LOGIN
          </LoginButton>
        </HelpStatement>
      </SplashPageDiv>
    </Theme>
  )
}

export default AccountIssuePage