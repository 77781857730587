import React from 'react'
import { useAppSelector } from '../../redux/store';
import DashboardGrid from "./dashboardGrid";
import DotLoader from '../misc/dotLoader';
import { ViewerContainer } from '../../styles/viewerStyles';

const DashboardContainer = () => {

  const mobile = useAppSelector(state => state.nav.mobile)
  const userFetched = useAppSelector(state => state.users.fetchingUser)

    return (
      <ViewerContainer dashboard mobile={mobile}>
        {userFetched === 'succeeded' ?
          <DashboardGrid />
          :
          <DotLoader isLoading={true} />
        }
      </ViewerContainer>
    )
}


export default DashboardContainer