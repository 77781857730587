import { faHandPointer, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FaveReportPHExample, FavoriteReportPlaceholderDiv } from '../reportStyles'

const FavoriteReportPlaceholder = () =>{

    return(
        <FavoriteReportPlaceholderDiv>
            <div style={{ display: 'hidden', height: '150px'}}></div>
            {/* <FaveReportPHExample>
                <FontAwesomeIcon icon={faHandPointer} style={{transform: 'translate(125%, 60%)'}} size="3x"/>
                <FontAwesomeIcon icon={faCircle} color="#CCCCCC" size="3x"/>
                <h2>Aged Trial Balance</h2>
            </FaveReportPHExample> */}
        </FavoriteReportPlaceholderDiv>
    )
}

export default FavoriteReportPlaceholder