import styled from 'styled-components';

export const TermsTitle = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.login ? '#FFFFFF' : props.theme.inputs.text};
    padding: ${props => props.mobile ? '0px' : '0 15px'};
    font-size: ${props => props.mobile ? '1.1em' : '1.4em'};
    margin-bottom: 1em;
    box-sizing: border-box;
    text-align: center;
`;

export const TermsCard = styled.div`
    height: ${props => props.mobile ? (props.login ? '80%' : '90%') : '60%'};
    max-height: ${props => props.login ? '300px' : (props.mobile ? '90%' : '60%')};
    width: ${props => props.login ? '70%' : '90%'};
    padding: 0 30px 0 30px;
    margin: 10px;
    font-size: 1em;
    color: ${props => props.login ? '#000000' : props.theme.inputs.text};
    line-height: 1.6;
    border-radius: 15px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: ${props => props.login ? '#FFFFFF' : 'transparent'};
    border: ${props => props.mobile ? '10px' : '30px'} ${props => props.login ? '#FFFFFF' : props.theme.viewer.background};
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: none;
    border-right-style: none;
    overflow-y: auto;
`;

export const TermsContainer = styled.div`
    width: ${props => props.login ? '80%' : '100%'};
    height: ${props => props.mobile && props.login ? '500px' : '100%'};
    max-height: ${props => props.mobile || props.login? 'auto' : '700px'};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    form{
      margin: 10px 0;
      display: flex;
      align-self: flex-end;
      align-items: center;
      justify-content: space-between;
      label{
        margin-right: 10px;
      }
    }
    overflow-x: hidden;
`;

export const TermsFlexWrapper = styled.div`
  display: flex;
  flex-flow: ${props => props.flexFlow};
  font-size: ${props => props.mobile ? '' : '1em'};
  justify-content: ${props => props.justify ? props.justify : 'center'};
  align-items: ${props => props.align};
`;

export const TermsActionBar = styled.div`
    color: #ffffff;
    font-size: 1.4em;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TermsCheckbox = styled.input`
  width: 22px;
  height: 22px;
  padding: 2px 2px 2px 2px;
  margin-right: 15px;
  box-sizing: border-box;
  vertical-align: middle;
  border: 1px solid ${props => props.error ? '#e05d62' : '#373d4d'};
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  :hover {
    border: 3px solid ${props => props.error ? '#e05d62' : '#1A5192'};
  }

  :checked {
    width: 23px;
    height: 20px;
    appearance: auto;
    font-family: "MaterialIcons-Regular", "Material Icons", sans-serif;
    accent-color: #1A5192;
    line-height: 20px;
  }
`;