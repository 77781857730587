import styled from 'styled-components'

export const UserMenuContainer = styled.div`
  width: 350px;
  padding: 2px 2px 2px 2px;
  background-color: ${props => props.theme.viewer.background};
  color: ${props => props.theme.restyle.primary};
  top: ${props => (props.mobile ? '119px' : '89px')};
  right: 20px;
  z-index: 1000;
  position: absolute;
  border: 1px solid #a0a6b3;
  transition: transform 0.75s, background-color 0.5s, color 0.5s;
`

export const UserMenuHeader = styled.div`
  min-height: 150px;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #bfcdd8;
`

export const UserMenuName = styled.div`
  font-size: 24px;
  box-sizing: border-box;
  font-family: 'WorkSans-Regular', 'Work Sans', sans-serif;
  padding-top: 10px;
  text-align: center;
  line-height: 52px;
`

export const UserMenuList = styled.ul`
  font-size: 18px;
  align-items: center;
  margin: 0;
  padding: 0px 20px;
`

export const UserMenuListItem = styled.li`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 35px;
  border-bottom: 1px solid #bfcdd8;

  cursor: pointer;

  svg {
    color: ${props => props.theme.restyle.primary};
    margin: 20px;
    transition: color 0.5s;

    &:hover {
      color: var(--color-primary-lightBlue);
    }
  }
`

export const UserMenuListItemLink = styled.p`
  height: 24px;
  text-align: center;
  line-height: normal;
  cursor: pointer;
`
