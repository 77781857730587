import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import * as wijmo from '@grapecity/wijmo'
import * as wjChart from '@grapecity/wijmo.react.chart'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import { DashCard, DashCardHeader } from '../dashboardStyles'
import { ThemeContext } from 'styled-components'
import PieChartPlaceholder from '../placeholders/PieChartPlaceholder'
import { convertNumToCurrency } from '../../../utils/helperMethods'
import CustomTooltip from '../../misc/CustomTooltip'
import BrokenChart from '../placeholders/BrokenChart'
import { faArrowCircleLeft, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UnstyledButton } from '../../../styles/buttonStyles'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { fetchChartData } from '../dashboardSlice'
import { useViewport } from '../../../utils/hooks'
import { Info } from '@mui/icons-material'

const DenialBreakdownChart = ({ goToReport, mobile, visible }) => {
  const [showData, setShowData] = useState([])
  const [overview, setOverview] = useState(true)
  const [selection, setSelection] = useState('')

  const { width } = useViewport()

  const dispatch = useAppDispatch()

  const promiseRef = useRef()

  const clientSwitching = useAppSelector(state => state.users.switchingClients)
  const { data, reportName, error, loaded } = useAppSelector(state => state.dashboard.denials)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)
  const themeName = useAppSelector(state => state.users.ThemeName)

  const fetchDenials = useCallback(() => {
    const promise = dispatch(fetchChartData({ controller: new AbortController(), chart: 'denials' }))
    promiseRef.current = promise
  }, [dispatch])

  useEffect(() => {
    if (visible && ClientConnectionID) {
      fetchDenials()
      handleBack()
    }
    return () => {
      if (promiseRef.current) {
        promiseRef.current.abort()
      }
    }
  }, [ClientConnectionID])

  const theme = useContext(ThemeContext)
  const palette = [
    'var(--color-accent-darkpurple)',
    'var(--color-primary-purple)',
    'rgba(144, 69, 167, 1)',
    'var(--color-accent-purple)',
    'rgba(168, 123, 207, 1)',
    'rgba(140, 180, 223, 1)',
    'var(--color-primary-lightBlue)',
    'rgba(76, 145, 189, 1)',
    'var(--color-primary-blue)',
    'rgba(18, 56, 102, 1)',
  ]

  const aggregateByReason = data => {
    if (data) {
      return data.reduce((arr, current) => {
        let dataPoint = arr.find(el => el.ReimbursementCategoryDescription === current.ReimbursementCategoryDescription)
        if (dataPoint) {
          dataPoint.DenialAmount += current.DenialAmount
        } else {
          let newObj = {
            ReimbursementCategoryDescription: current.ReimbursementCategoryDescription,
          }
          newObj.DenialAmount = current.DenialAmount
          arr.push(newObj)
        }
        return arr.sort((a, b) => b.DenialAmount - a.DenialAmount)
      }, [])
    }
  }

  const aggregateByPayor = useCallback(
    ReimbursementCategoryDescription => {
      return [...data].filter(datapoint => datapoint.ReimbursementCategoryDescription === ReimbursementCategoryDescription)
    },
    [data]
  )

  useEffect(() => {
    if (loaded === 'succeeded') {
      setShowData(aggregateByReason(data))
    }
    return () => {}
  }, [loaded, data])

  useEffect(() => {
    if (selection !== '') {
      setShowData(aggregateByPayor(selection))
    }
    return () => setOverview(!overview)
  }, [selection, aggregateByPayor, setOverview, overview])

  const getSum = () => {
    return showData.reduce((acc, val) => {
      return acc + val.DenialAmount
    }, 0)
  }

  const getLabelContent = ht => {
    if (ht.value / getSum() > 0.01) {
      return ((ht.value / getSum()) * 100).toFixed() + '%'
    }
  }

  const handlePieClick = e => {
    if (e.selectedIndex >= 0) {
      setSelection(e._labels[e.selectedIndex])
    }
  }

  const initPie = sender => {
    sender.selectedIndex = -1
  }

  const buildTooltip = (tooltipHeader, tooltipContent) => {
    return `<span class='chart-tooltip'><strong>${tooltipHeader || ''}</strong><br/>${tooltipContent}</span>`
  }

  const formatXLabels = (eng, label) => {
    if (label.text === 'Blue Cross Blue Shield') {
      label.text = 'BCBS'
    }
    if (label.text === 'United Healthcare') {
      label.text = 'UHC'
    }
    if (label.text === 'Commercial Insurance') {
      label.text = 'Commercial'
    }
    return label
  }

  const formatYLabels = (engine, label, type) => {
    if (themeName === 'Dark') label.cls = label.cls + ' wj-label-dark'

    if (type === 'amount') {
      let splitText, mil, k, g
      const tens = label.text.match(/,/g)
      if (tens) {
        switch (tens.length) {
          case 1:
            splitText = label.text.split(',')
            // numbers in thousands position
            k = splitText[0]
            // first number in hundreds position
            if (k.length < 2) {
              g = splitText[1][0]
              g === '0' ? (label.text = `$${k}K`) : (label.text = `$${k}.${g}K`)
            } else {
              label.text = `$${k}K`
            }
            return label
          case 2:
            splitText = label.text.split(',')
            // numbers in million position
            mil = splitText[0]
            // first number in thousands position
            k = splitText[1][0]
            label.text = `$${mil}.${k}M`
            return label
          default:
            label.text = `${label.text}`
            return label
        }
      }
      label.text = `$${label.text}`
    }
    return label
  }

  const calculateYAxisMax = () => {
    if (showData.length > 0) {
      // get largest VoucherBalance from array of collection objects
      const largestNum = Math.ceil(
        showData
          .map(data => data.DenialCount)
          .sort((a, b) => a - b)
          .pop()
      )
      // get the place value of left most num
      const splitNums = largestNum.toString().split('')
      let newTens = parseInt(
        splitNums
          .map((num, i) => {
            let numVal
            if (i === 0) {
              splitNums[1] > 5 ? (numVal = (parseInt(num) + 1).toString()) : (numVal = num)
            } else if (i === 1) {
              num > 5 ? (numVal = '0') : (numVal = (parseInt(num) + 1).toString())
            } else {
              numVal = '0'
            }
            return numVal
          })
          .join('')
      )
      return newTens
    }
  }

  const buildPieChart = () => {
    return (
      <>
        <DashCardHeader>
          <h4>Open Denials</h4>
          {/* {mobile ? null : (
            <CustomTooltip title="Go to report" enterDelay={500} enterNextDelay={500}>
              <UnstyledButton onClick={() => goToReport(reportName)}>
                <FontAwesomeIcon style={{ marginLeft: mobile ? '10px' : '15px' }} icon={faFileAlt} />
              </UnstyledButton>
            </CustomTooltip>
          )} */}
        </DashCardHeader>
        <wjChart.FlexPie
          bindingName="ReimbursementCategoryDescription"
          binding="DenialAmount"
          selectionChanged={e => handlePieClick(e)}
          selectionMode="Point"
          selectedItemPosition="Top"
          isAnimated={true}
          itemsSource={showData}
          palette={palette}
          rendered={initPie}
          innerRadius={0.5}
          tooltipContent={ht => (mobile ? '' : buildTooltip(ht.name, convertNumToCurrency(ht.value)))}
        >
          <wjChart.FlexPieDataLabel content={ht => getLabelContent(ht)} position={3} offset={20} connectingLine={true}></wjChart.FlexPieDataLabel>
          <wjChart.FlexChartLegend position={mobile ? 'Bottom' : 'Right'} maxSize="100%"></wjChart.FlexChartLegend>
          <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
        </wjChart.FlexPie>
      </>
    )
  }

  const handleBack = () => {
    setSelection('')
    setShowData(aggregateByReason(data))
  }

  // const palette = showData.map(el=> theme.providers[el.PayorDescription.toLowerCase()])
  const formatBars = (engine, ht, defaultFormat, level) => {
    if (ht.item.PayorDescription) {
      let payer = ht.item.PayorDescription
      if (payer === 'Blue Cross Blue Shield') {
        payer = 'bcbs'
      }
      if (payer === 'United Healthcare') {
        payer = 'uhc'
      }
      if (payer === 'Collection') {
        payer = 'misc'
      }
      if (payer === 'Commercial Insurance') {
        payer = 'commercial'
      }
      if (level === 'main') {
        engine.fill = theme.providers[payer.toLowerCase()]?.main
      } else if (payer === 'commercial') {
        engine.fill = theme.providers[payer.toLowerCase()]?.['030']
      } else {
        engine.fill = theme.providers[payer.toLowerCase()]?.['6190']
      }
      engine.stroke = 'none'
    } else {
      engine.fill = '#858585'
    }
    defaultFormat()
  }

  const buildBarChart = () => {
    return (
      <>
        <DashCardHeader>
          <FontAwesomeIcon icon={faArrowCircleLeft} style={{ position: 'absolute', left: '10px', cursor: 'pointer' }} size="lg" onClick={() => handleBack()} />
          <h4 title="Go to report" style={{ cursor: 'pointer' }} onClick={() => goToReport(reportName)}>
            {`${selection}`}
            {/* <FontAwesomeIcon style={{ marginLeft: mobile ? '10px' : '15px' }} icon={faFileAlt} /> */}
          </h4>
        </DashCardHeader>
        <wjChart.FlexChart
          bindingX="PayorDescription"
          itemsSource={showData}
          chartType="Bar"
          selectionMode="Point"
          selectionChanged={() => handleBack()}
          plotMargin="60 40 70 110"
        >
          <wjChart.FlexChartAxis
            wjProperty="axisX"
            binding="DenialAmount"
            title="Denial Amount ($)"
            position="Bottom"
            axisLine={true}
            itemFormatter={(eng, label) => formatYLabels(eng, label, 'amount')}
          ></wjChart.FlexChartAxis>
          <wjChart.FlexChartAxis
            wjProperty="axisY"
            binding="PayorDescription"
            itemFormatter={(eng, label) => formatXLabels(eng, label, 'count')}
            reversed={true}
          ></wjChart.FlexChartAxis>
          <wjChart.FlexChartSeries
            name="Denial Amount"
            cssClass="bar"
            binding="DenialAmount"
            itemFormatter={(engine, ht, defaultFormat) => formatBars(engine, ht, defaultFormat, 'main')}
            tooltipContent={ht => (mobile ? '' : buildTooltip('Denial Amount - ' + ht.item.PayorDescription, convertNumToCurrency(ht.item.DenialAmount)))}
          ></wjChart.FlexChartSeries>
          <wjChart.FlexChartSeries
            cssClass="bar"
            binding="DenialCount"
            itemFormatter={(engine, ht, defaultFormat) => formatBars(engine, ht, defaultFormat, 'second')}
            tooltipContent={ht => (mobile ? '' : buildTooltip('Denial Count - ' + ht.item.PayorDescription, ht.item.DenialCount))}
          >
            <wjChart.FlexChartAxis
              wjProperty="axisX"
              binding="DenialCount"
              title="Denial Count"
              position="Top"
              axisLine={true}
              itemFormatter={(eng, label) => formatYLabels(eng, label)}
              min={0}
              max={calculateYAxisMax()}
            ></wjChart.FlexChartAxis>
          </wjChart.FlexChartSeries>
          <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
          <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        </wjChart.FlexChart>
      </>
    )
  }

  const renderChart = () => {
    if (loaded === 'succeeded') {
      if (error) {
        return <BrokenChart refreshFn={fetchDenials} header="Open Denials" chartType={0} />
      }
      if (showData && showData.length > 0) {
        if (!Object.keys(showData[0]).includes('PayorDescription')) {
          return buildPieChart()
        } else {
          return buildBarChart()
        }
      }
    }
  }

  return (
    <>
      {visible ? (
        <DashCard loaded={loaded !== 'succeeded'} cardWidth={width > 1220 ? 3 : 6} error={error}>
          {loaded === 'pending' || clientSwitching === 'pending' ? (
            <PieChartPlaceholder title="Open Denials" />
          ) : data?.length ? (
            <>
              <CustomTooltip title="Snapshot of active reason codes. Click for count and charge amount by payer.">
                <Info className="tooltip-icon" />
              </CustomTooltip>
              {renderChart()}
            </>
          ) : (
            <PieChartPlaceholder loaded title="Open Denials" />
          )}
        </DashCard>
      ) : null}
    </>
  )
}

export default DenialBreakdownChart
