import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ChartData } from "../../../type";
import { AppDispatch } from "../../redux/store";
import { protectedApi } from "../../utils/api";
import { getCookie } from "../../utils/security";
import { fetchRefreshToken, logout } from "../login/userSlice";
import { addKPIData } from "./dashboardHelpers";

interface DashboardState {
  collections: ChartData;
  charges: ChartData;
  gcr: ChartData;
  denials: ChartData;
  ar: ChartData;
  kpis: ChartData;
  salesRepData: any[];
}

const initialDashboardState = {
  collections: {
    reportName: "",
    data: [],
    error: false,
    loaded: "idle",
  },
  charges: {
    reportName: "",
    data: [],
    displayData: [],
    error: false,
  },
  gcr: {
    reportName: "",
    data: [],
    error: false,
    loaded: "idle"
  },
  denials: {
    reportName: "",
    data: [],
    error: false,
    loaded: "idle",
  },
  ar: {
    reportName: "",
    data: [],
    error: false,
    loaded: "idle",
  },
  kpis: {
    data: [],
    error: false,
    loaded: "idle",
  },
  salesRepData: [],
};

const initialState = initialDashboardState as DashboardState;

export const fetchChartData = createAsyncThunk<
  { reportName: string; data: []; chart: string; error: boolean },
  { chart: string; controller: AbortController },
  { dispatch: AppDispatch }
>(
  "dashboard/fetchChartData",
  // @ts-ignore
  async (payload, thunkAPI) => {
    if (!!getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
      switch (payload.chart) {
        case "kpis":
          return protectedApi(
            payload.controller,
            getCookie(process.env.REACT_APP_ACCESS_TOKEN)
          )
            .get(`/rcm/dashboard/getKPIData`)
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                return { data: data.results, chart: "kpis", error: false };
              } else {
                throw new Error()
              }
            })
            .catch((error) => {
              if (error.response.data.tokenExpired) {
                thunkAPI.dispatch(
                  fetchRefreshToken(() =>
                    thunkAPI.dispatch(fetchChartData(payload))
                  )
                );
              }
              return error.response.data
            });
        case "collections":
          return protectedApi(
            payload.controller,
            getCookie(process.env.REACT_APP_ACCESS_TOKEN)
          )
            .get("/rcm/dashboard/getCollections")
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                // return {reportName:data.reportName, data:[], chart: 'collections', error: false}
                return {
                  reportName: data.reportName,
                  data: data.results,
                  chart: "collections",
                  error: false,
                };
              } else {
                throw new Error()
              }
            })
            .catch((error) => {
              if (error.response.data.tokenExpired) {
                thunkAPI.dispatch(
                  fetchRefreshToken(() =>
                    thunkAPI.dispatch(fetchChartData(payload))
                  )
                );
              }
              return error.response.data
            });
        case "gcr":
          return protectedApi(payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN)).get("/rcm/dashboard/getGcrTotals")
            .then(res => res.data)
            .then(data => {
              if (data.success) {
                return {
                  reportName: data.reportName,
                  data: data.results,
                  chart: "gcr",
                  error: false,
                }
              } else if (!data.success && data.message) {
                return {
                  chart: 'gcr',
                  error: true
                }
              }
            })
            .catch((error) => {
              if (error.response.data.tokenExpired) {
                thunkAPI.dispatch(
                  fetchRefreshToken(() =>
                    thunkAPI.dispatch(fetchChartData(payload))
                  )
                );
              }
              return error.response.data
            });
        case "charges":
          return protectedApi(
            payload.controller,
            getCookie(process.env.REACT_APP_ACCESS_TOKEN)
          )
            .get("/rcm/dashboard/getCharges")
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                return {
                  reportName: data.reportName,
                  data: data.results,
                  chart: "charges",
                  error: false,
                };
                // return {reportName:data.reportName, data:[], chart: 'charges', error: false}
              } else {
                throw new Error()
              }
            })
            .catch((error) => {
              if (error.response.data.tokenExpired) {
                thunkAPI.dispatch(
                  fetchRefreshToken(() =>
                    thunkAPI.dispatch(fetchChartData(payload))
                  )
                );
              }
              return error.response.data
            });
        case "ar":
          return protectedApi(
            payload.controller,
            getCookie(process.env.REACT_APP_ACCESS_TOKEN)
          )
            .get("/rcm/dashboard/getAccountsReceivable")
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                // return {reportName:data.reportName, data: [], chart: 'ar', error: false}
                return {
                  reportName: data.reportName,
                  data: data.results,
                  chart: "ar",
                  error: false,
                };
              }  else {
                throw new Error()
              }
            })
            .catch((error) => {
              if (error.response.data.tokenExpired) {
                thunkAPI.dispatch(
                  fetchRefreshToken(() =>
                    thunkAPI.dispatch(fetchChartData(payload))
                  )
                );
              }
              return error.response.data
            });
        case "denials":
          return protectedApi(
            payload.controller,
            getCookie(process.env.REACT_APP_ACCESS_TOKEN)
          )
            .get("/rcm/dashboard/getDenialCategories")
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                // return {reportName:data.reportName, data: [], chart: 'denials', error: false}
                return {
                  reportName: data.reportName,
                  data: data.results,
                  chart: "denials",
                  error: false,
                };
              } else {
                throw new Error()
              }
            })
            .catch((error) => {
              if (error.response.data.tokenExpired) {
                thunkAPI.dispatch(
                  fetchRefreshToken(() =>
                    thunkAPI.dispatch(fetchChartData(payload))
                  )
                );
              }
              return error.response.data
            });
        default:
          return { data: [], chart: "", error: false };
      }
    } else {
      thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    resetDashboard(state) {
      Object.assign(state, initialDashboardState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChartData.pending, (state, action) => {
      if (action.meta.arg && typeof action.meta.arg.chart === "string") {
        switch (action.meta.arg.chart) {
          case "kpis":
            state.kpis.loaded = "pending";
            break;
          case "collections":
            state.collections.loaded = "pending";
            break;
          case "charges":
            state.charges.loaded = "pending";
            break;
          case "gcr":
            state.gcr.loaded = "pending";
            break;
          case "ar":
            state.ar.loaded = "pending";
            break;
          case "denials":
            state.denials.loaded = "pending";
            break;
          default:
            break;
        }
      }
    });
    builder.addCase(fetchChartData.fulfilled, (state, action) => {
      if (
        action.payload &&
        action.payload.chart &&
        typeof action.payload.chart === "string"
      ) {
        switch (action.payload.chart) {
          case "kpis":
            state.kpis.loaded = "succeeded";
            state.kpis.data = addKPIData(action.payload.data);
            state.kpis.error = false;
            break;
          case "collections":
            state.collections.loaded = "succeeded";
            state.collections.data = action.payload.data;
            state.collections.error = false;
            state.collections.reportName = action.payload.reportName;
            break;
          case "gcr":
            state.gcr.loaded = "succeeded"
            state.gcr.data = action.payload.data
            state.gcr.error = action.payload.error
            state.gcr.reportName = action.payload.reportName
            break
          case "charges":
            state.charges.loaded = "succeeded";
            state.charges.data = action.payload.data;
            state.charges.error = false;
            state.charges.reportName = action.payload.reportName;
            break;
          case "ar":
            state.ar.loaded = "succeeded";
            state.ar.data = action.payload.data;
            state.ar.error = false;
            state.ar.reportName = action.payload.reportName;
            break;
          case "denials":
            state.denials.loaded = "succeeded";
            state.denials.data = action.payload.data;
            state.denials.error = false;
            state.denials.reportName = action.payload.reportName;
            break;
          default:
            break;
        }
      }
    });
  },
});

export const { resetDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
