import { useNavigate } from 'react-router-dom'
import { QuickSearchResult } from '../../../type'
import { getPatientClaims, getPatientDemographics, getPatientFinances } from '../patientInfo/patientSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { UnstyledButton } from '../../styles/buttonStyles'
import { QuickSearchli } from '../patientInfo/search/searchStyles'
import { findPermission } from '../../utils/helperMethods'

type Props = {
  result: QuickSearchResult
  clearResults: () => any
}

const QuickSearchLineResult = ({ result, clearResults }: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const patientPermissions = useAppSelector(state => state.users.UserPermissions.Patients)

  const handleClick = (ID: string) => {
    const PatientID = parseInt(ID)
    if (findPermission('44', patientPermissions)) {
      dispatch(getPatientDemographics({ PatientID, controller: new AbortController() }))
    }
    if (findPermission('42', patientPermissions)) {
      dispatch(getPatientClaims({ PatientID, controller: new AbortController() }))
    }
    if (findPermission('43', patientPermissions)) {
      dispatch(getPatientFinances({ PatientID, controller: new AbortController() }))
    }
    clearResults()
    navigate(`/patientManagement/${ID}`)
  }

  return (
    <QuickSearchli key={`${result.PatientID}`} onClick={() => handleClick(result.PatientID)}>
      <UnstyledButton style={{ display: 'flex', justifyContent: 'flex-start' }}>{result.PatientString}</UnstyledButton>
    </QuickSearchli>
  )
}

export default QuickSearchLineResult
