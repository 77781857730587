import { useAppSelector } from "../../../redux/store";
import SupportMessage from "../loginPage/supportMessage";
import { LoginFooter } from "../../login/loginStyles";
import { SplashPageDiv, SplashpageWelcome } from "../splashStyle";


const LoginWelcomePage = () => {
  const preferredName = useAppSelector(state => state.users.PreferredName)
  const firstName = useAppSelector(state => state.users.FirstName)

  return (
    <SplashPageDiv>
      <SplashpageWelcome>
        Welcome{preferredName ? (' ' + preferredName) : (firstName ? (' ' + firstName) : null)}!
      </SplashpageWelcome>
      <LoginFooter>
        <SupportMessage/>
      </LoginFooter>
    </SplashPageDiv>
  );
};

export default LoginWelcomePage;
