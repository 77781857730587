import styled from "styled-components";

export const BlueRoundedButton = styled.button`
    margin: 1rem;
    padding: .5rem 1rem .5rem ${props => props.icon ? '.5rem' : '1rem'};
    border-radius: 1.5rem;
    border: none;
    background-color: ${props => props.theme.restyle.darkAccent};
    font-weight: 600;
    color: #FFF;
    text-align: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

export const SearchInput = styled.input`
    max-width: ${props => props.mobile ? '100px' : '200px'};
    outline: none;
    border: none;
    font-size: .9rem;
    color: ${props => props.theme.restyle.primary};
    background-color: transparent;
    padding-left: 5px;
    transition: color .5s;
    
    &::placeholder{
        color: ${props => props.darkMode || props.mobile ? 'var(--color-accent-grey)' : 'var(--color-accent-alsogrey)'}
    }
`

export const WizardTip = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    min-width: 200px;
    border-radius: 40px;
    width: ${props => props.mobile ? '90%' : (props.settings ? '50%' : '30%')};
    color: #FFFFFF;
    padding-top: ${props => props.settings ? '1em' : '0'};
    padding-bottom: 1em;
    text-align: left;
    margin-top: 1em;
    background-color: ${props => props.settings ? '#5E5E5E' : 'rgba(55, 61, 77, 0.6)'};
    box-sizing: border-box;
    h3 {
        text-align: center;
    }
    p {
        margin: 0px;
    }
`