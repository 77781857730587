import styled, { css, keyframes } from 'styled-components'

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
`

export const LogoBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #ffffff;
  border: solid white 2px;
`

export const LogoImageContainer = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoImage = styled.img`
  max-height: 45px;
  max-width: 45px;
`

export const LogoTitle = styled.div`
  display: block;
  min-width: 150px;
  text-align: left;
`

export const SiteTitle = styled.h3`
  /* position: absolute; */
  /* width: calc(100% - 80px); */
  /* right: 0px; */
  margin: 0;
`

export const UserMenuButton = styled.button`
  background-color: #738693;
  height: ${props => (props.menuActive ? '64px' : '50px')};
  min-height: ${props => (props.menuActive ? '64px' : '50px')};
  width: ${props => (props.menuActive ? '64px' : '50px')};
  min-width: ${props => (props.menuActive ? '64px' : '50px')};
  outline: none;
  border: #738693 1px;
  border-radius: 100%;
  color: #ffffff;
  float: right;
  font-size: 1.25em;
  font-weight: 650;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :active {
    border: #738693 1px;
  }
`

export const SiteHeaderSearch = styled.input`
  max-width: ${props => (props.mobile ? '50px' : '200px')};
  outline: none;
  border: none;
  font-size: 14px;
  color: ${props => (props.theme === 'Dark' || props.mobile ? '#FFF' : '#03263c')};
  background-color: rgba(255, 255, 255, 0);
  padding-left: 5px;
  &::placeholder {
    color: ${props => (props.theme === 'Dark' || props.mobile ? 'var(--color-accent-grey)' : 'var(--color-accent-alsogrey)')};
  }
`

export const SiteHeaderSearchWrapper = styled.div`
  display: flex;
  min-width: 140px;
  position: ${props => (props.mobile ? 'absolute' : 'relative')};
  top: ${props => (props.mobile ? '25px' : 0)};
  right: ${props => (props.mobile ? '70px' : 0)};
  justify-content: flex-end;
  align-items: center;
  margin-right: ${props => (props.mobile ? 0 : '10px')};
  font-size: min(max(16px, 1.1vw), 20px);
`

const slideIn = keyframes`
    0%{
        top:-100px;
    }
    100%{
        top:80px;
    }
`
const slideOut = keyframes`
    0%{
        top:80px;
    }
    100%{
        top:-100%;
    }
`

export const SiteHeaderAppNav = styled.div`
  position: absolute;
  display: ${props => (props.shown ? 'flex' : 'none')};
  visibility: ${props => (props.shown ? null : 'hidden')};
  right: 7%;
  top: 80px;
  background: ${props => props.theme.viewer.background};
  border: 1px solid ${props => props.theme.launchPad.cards.border};
  z-index: 100;
  border-radius: 5px;
  box-shadow: 5px 2px 15px ${props => props.theme.shades.shadow};
  padding: 1em;
`

export const MobileSiteHeaderAppNav = styled(SiteHeaderAppNav)`
  width: 100%;
  height: 80px;
  display: flex;
  visibility: visible;
  position: absolute;
  left: 0;
  top: ${props => (props.visible ? '80px' : '-80px')};
  z-index: 1;
  flex-flow: row wrap;
  animation: 1s ${props => (props.visible ? slideIn : slideOut)} ease-out;
`

export const AppIconLink = styled.a`
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 70px;
  text-decoration: none;
  label {
    color: ${props => props.theme.shades.text};
  }
  margin: 5px;
`

export const AppIconImg = styled.img`
  width: ${props => (props.alt === 'DualRemit' ? '40px' : '50px')};
  margin: ${props => (props.alt === 'DualRemit' ? '6px 0 4px 0' : '0px')};
  height: ${props => (props.alt === 'DualRemit' ? '40px' : '50px')};
`

/* SITE HEADER STYLES */
export const SiteHeaderContainer = styled.div`
  height: 90px;
  width: calc(100% - 200px);
  z-index: 2002;
  color: ${props => (props.theme === 'Dark' ? '#ffffff' : '#373d4d')};
  background-color: ${props => (props.theme === 'Dark' ? '#444' : '#FFF')};
  border-bottom: ${props => (props.theme === 'Dark' ? '1px solid #FFF' : '1px solid #bfcdd8')};
  box-sizing: border-box;
  position: fixed;
  top: 0;
  margin-left: 200px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  grid-area: header;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
`

export const MobileSiteHeaderContainer = styled.div`
  height: 70px;
  width: 100%;
  z-index: 2002;
  color: #fff;
  background-color: #373d4d;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  grid-area: header;
`

export const ClientTitle = styled.h3`
  font-size: 24px;
  font-weight: 400;
  box-sizing: border-box;
  text-align: left;
  line-height: 24px;
`

export const MobileClientTitle = styled.h3`
  height: 100%;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  box-sizing: border-box;
  color: ${props => props.theme.restyle.primary};
  text-align: center;
  display: flex;
  align-items: center;
`

export const MobileConnectionSwitcherContainer = styled.div`
  height: 50px;
  width: 100%;
  margin-top: 70px;
  z-index: ${props => (props.QuickSearchResults ? 0 : '2002')};
  position: fixed;
  color: ${props => props.theme.restyle.primary};
  background-color: ${props => props.theme.viewer.background};
  border-bottom: 1px solid ${props => props.theme.restyle.primary};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
  transition: background-color 0.5s, color 0.5s;
`

export const ConnectionSwitcherContainer = styled.div`
  width: 100%;
  margin-top: ${props => (props.mobile ? '120px' : '90px')}; //120px/90px for header
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: var(--font-branding);
  background-color: ${props => props.theme.viewer.background};
  color: ${props => props.theme.restyle.primary};
`

export const ConnectionSwitcherWrapper = styled.div`
  width: 100%;
  margin-left: 30px;
  display: flex;
  align-items: center;
`

export const AppCirclesContainer = styled.div`
  width: 100%;
  padding: ${props => (props.mobile ? '0 1.5rem 1.5rem' : '30px 2rem')};
  background: #738693;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`

export const ConnectionSwitcherHeader = styled.h2`
  color: ${props => (props.applications ? '#FFF' : props.theme.restyle.primary)};
  font-weight: 400;
  font-size: 18px;
  margin: 1rem 0;
  padding: ${props => (props.applications ? 0 : props.mobile ? '0 1.5rem' : '0 2rem')};
  transition: color 0.5s;

  ${props =>
    props.applications &&
    css`
      margin: 0 0 20px 5px;
      margin-top: ${props => (props.mobile ? '20px' : 0)};
    `}
`

export const ClientCirclesContainer = styled.div`
  margin-left: ${props => (props.mobile ? 0 : '2rem')};
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: ${props => (props.mobile ? 'space-evenly' : 'flex-start')};
  box-sizing: border-box;
`

export const ClientCircleWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

export const ConnectionSwitcherCircle = styled.div`
  width: ${props => (props.mobile ? '90px' : '119px')};
  height: ${props => (props.mobile ? '90px' : '119px')};
  margin: 0 8px;
  padding: 5px;
  border-radius: 100%;
  border: 3px solid ${props => props.theme.restyle.primary};
  background-color: ${props => (props.current ? props.theme.restyle.connectionSwitcher : 'transparent')};
  box-sizing: border-box;
  font-size: ${props => (props.mobile ? '22px' : '24px')};
  font-weight: ${props => (props.current ? 500 : 400)};
  color: ${props => (props.current ? props.theme.restyle.primaryInverse : props.theme.restyle.primary)};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.current ? 'transparent' : props.theme.restyle.connectionSwitcher)};
    color: ${props => (props.current ? props.theme.restyle.primary : props.theme.restyle.primaryInverse)};
  }
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
`

export const ConnectionSwitcherAppContainer = styled.div`
  width: ${props => (props.mobile ? '90px' : '119px')};
  height: ${props => (props.mobile ? '90px' : '119px')};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

export const ConnectionSwitcherAppButton = styled.button`
  width: 100%;
  margin: 10px 0;
  padding: 7px;
  border-radius: 20px;
  border: 3px solid ${props => props.theme.restyle.primary};
  background-color: #fff;
  font-size: 16px;
  color: var(--color-primary-charcoal);
  text-align: center;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
  cursor: pointer;
  :hover {
    background-color: #373d4d;
    color: #fff;
    border: 3px solid #373d4d;
  }
`

export const ConnectionSwitcherLabel = styled.p`
  margin: 8px 0 25px 0;
  width: 119px;
  font-size: ${props => (props.mobile ? '14px' : '12px')};
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  transition: color 0.5s;
`

export const UserMenuWrapper = styled.div`
  margin: 0px 30px 0px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
