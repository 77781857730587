import { BrokenChartDiv, BrokenKPIDiv } from '../dashboardStyles'
import { LoginButton } from '../../login/loginStyles'
import { DataUsage, Leaderboard } from '@mui/icons-material'

const BrokenChart = ({ refreshFn, header, chartType }) => {
  const renderIcon = () => {
    if (chartType === 0) {
      return <DataUsage className="chartSVG" />
    } else if (chartType === 1) {
      return <Leaderboard className="chartSVG" />
    } else if (chartType === 2) {
      // For KPIs
      return null
    } else {
      return <Leaderboard className="chartSVG" />
    }
  }

  const renderBrokenChart = () => {
    return (
      <BrokenChartDiv>
        <h1>Unable to fetch {header} chart.</h1>
        {renderIcon()}
        <LoginButton
          onClick={() => {
            refreshFn()
          }}
          inverse
        >
          Refresh
        </LoginButton>
      </BrokenChartDiv>
    )
  }

  const renderBrokenKpi = () => {
    return (
      <BrokenKPIDiv>
        <h5>Unable to fetch KPI</h5>
        <LoginButton
          onClick={() => {
            refreshFn()
          }}
          inverse
        >
          Refresh
        </LoginButton>
      </BrokenKPIDiv>
    )
  }

  return <>{chartType === 2 ? renderBrokenKpi() : renderBrokenChart()}</>
}

export default BrokenChart
