import { useCallback } from 'react'
import SiteHeaderSearchContainer from './SiteHeaderSearchContainer'

//Redux
import { useAppSelector, useAppDispatch } from '../../../redux/store'
import { setMenu } from '../navSlice'
import { toggleConnectionSwitcher } from '../../login/userSlice'

// Styling
import { SiteHeaderContainer, ClientTitle, ConnectionSwitcherWrapper, UserMenuWrapper } from './SiteHeaderStyles'
import { UserMenuButton } from './SiteHeaderStyles'
import MenuIcon from '@mui/icons-material/Menu'

const SiteHeader = props => {
  const dispatch = useAppDispatch()

  const fetchingUser = useAppSelector(state => state.users.fetchingUser)
  const UserInitials = useAppSelector(state => state.users.UserInitials)
  const Client = useAppSelector(state => state.users.Client)
  const { menuActive, mobileSidebar } = useAppSelector(state => state.nav)
  const theme = useAppSelector(state => state.users.ThemeName)
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn)
  const availableClients = useAppSelector(state => state.users.connectionSwitcher.clients)

  const toggleMenu = useCallback(
    payload => {
      dispatch(setMenu(payload))
    },
    [dispatch]
  )

  const handleMenuClick = e => {
    if (mobileSidebar) return
    e.stopPropagation()
    toggleMenu(!menuActive)
  }

  const buildMenuInit = () => {
    if (!menuActive) {
      return (
        <UserMenuButton key="user-menu-button-closed" style={{ margin: 0 }} onClick={handleMenuClick} data-testid="user-initials-menu-button">
          <p>{UserInitials}</p>
        </UserMenuButton>
      )
    } else {
      return (
        <UserMenuButton as="div" key="user-menu-button-open" onClick={handleMenuClick}>
          <p>{UserInitials}</p>
        </UserMenuButton>
      )
    }
  }

  return (
    <SiteHeaderContainer theme={theme}>
      <ConnectionSwitcherWrapper>
        {Client.ClientApps.length === 1 && availableClients.length === 1 ? null : (
          <MenuIcon style={{ fontSize: '30px', marginLeft: '10px', marginRight: '20px', cursor: 'pointer' }} onClick={() => dispatch(toggleConnectionSwitcher())} />
        )}
        <ClientTitle theme={theme} data-test="header-clientName">
          {fetchingUser === 'pending' ? '' : Client.ClientName}
        </ClientTitle>
      </ConnectionSwitcherWrapper>
      {isLoggedIn && props.quickSearchAllowed ? <SiteHeaderSearchContainer /> : null}
      <UserMenuWrapper>{buildMenuInit()}</UserMenuWrapper>
    </SiteHeaderContainer>
  )
}

export default SiteHeader
