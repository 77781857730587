import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import {
  ClaimKPISContainer,
  ClaimKPI,
  SpecimenContainer,
  SpecimenGrid,
  BoldSpan,
  AccessionHeader,
  ClaimDetails,
  MobileClaimInfoContainer,
  ServiceContainer,
  SpecimenPayorGrid,
  DenialCount,
  MobileClaimHeader,
} from './claimStyles'
import { convertNumToCurrency, formatID } from '../../../utils/helperMethods'
import { buildDate } from '../../../utils/helperMethods'
import { ArrowBack } from '@mui/icons-material'
import { setClaimIndex, setMobileClaimMode, updatePatientModal } from '../patientSlice'

const Claim = () => {
  const [claim, setClaim] = useState({})
  const claimTop = useRef(null)

  const dispatch = useAppDispatch()

  const filteredClaims = useAppSelector(state => state.patients.currentPatient.filteredClaims)
  const selectedClaimIndex = useAppSelector(state => state.patients.currentPatient.selectedClaimIndex)
  const theme = useAppSelector(state => state.users.ThemeName)
  const { mobile, tablet, portableDevice } = useAppSelector(state => state.nav)

  useEffect(() => {
    setClaim(filteredClaims[selectedClaimIndex] || {})
  }, [filteredClaims, selectedClaimIndex])

  useEffect(() => {
    claimTop.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [selectedClaimIndex])

  const openModal = (e, payload) => {
    e.stopPropagation()
    dispatch(
      updatePatientModal({
        modalOpen: true,
        ...payload,
      })
    )
  }

  const renderKPIs = () => {
    return (
      <ClaimKPISContainer portableDevice={portableDevice}>
        {Object.keys(claim.totals).map(key => {
          let newKey = key
          if (key === 'BilledAmount') newKey = 'Total Billed'
          if (key === 'PaymentAmount') newKey = 'Payment Total'
          if (key === 'AdjustmentAmount') newKey = 'Adjustment Total'

          return (
            <ClaimKPI key={key} tablet={tablet}>
              <span>{convertNumToCurrency(claim.totals[key])}</span>
              <p>{newKey.toUpperCase()}</p>
            </ClaimKPI>
          )
        })}
      </ClaimKPISContainer>
    )
  }

  const renderServices = (service, serviceIndex) => {
    return (
      <ServiceContainer key={serviceIndex} index={serviceIndex} tablet={tablet}>
        <SpecimenGrid portableDevice={portableDevice}>
          <span style={{ gridArea: 'CPTDesc', fontSize: '1.1rem', wordBreak: mobile ? 'break-word' : 'normal' }}>{service.CptDescription}</span>
          <span style={{ gridArea: 'CPTCode' }}>
            {service.CptCode} {service.UnitCount && service.UnitCount > 1 ? ' x ' + service.UnitCount : ''}
          </span>
          <span style={{ gridArea: 'DOS' }}>DOS {buildDate(service.ServiceDate)}</span>
          {service.denials.length ? (
            <DenialCount
              onClick={e =>
                openModal(e, {
                  modalLevel: 'service-reasons',
                  modalContent: { CPTCode: service.CptCode, CPTDescription: service.CptDescription, content: service.denials },
                })
              }
              reasonCode
              portableDevice={portableDevice}
            >
              {service.denials.length} Reason code{service.denials.length === 1 ? '' : 's'}
            </DenialCount>
          ) : (
            <div style={{ gridArea: 'denialCount' }} />
          )}
          {service.DXCodes.length ? (
            <div className="flexRow" style={{ flexWrap: 'wrap', gridArea: 'DXCodes', gridColumn: 'span 3' }}>
              <span style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>DX Codes</span>
              {service.DXCodes.map((code, i) => (
                <p style={{ margin: '.5rem 1rem .5rem 0' }} key={i}>
                  {code}
                </p>
              ))}
            </div>
          ) : null}
          {service.Modifiers ? (
            <div className="flexRow" style={{ flexWrap: 'wrap', gridColumn: 'span 3', gridArea: 'Modifiers' }}>
              <span style={{ marginRight: '1rem' }}>Modifiers</span>
              <p>{service.Modifiers}</p>
            </div>
          ) : null}
        </SpecimenGrid>
        <SpecimenPayorGrid tablet={tablet}>
          <span>Billed Amount</span>
          <span style={{ gridArea: '1 / 2 / span 1/ span 2' }}>{convertNumToCurrency(service.BilledAmount)}</span>

          {service.payors.map((payor, i) => (
            <React.Fragment key={payor.PayorName + payor.AdjustmentAmount + payor.DatePaid}>
              <p>{payor.PayorName}</p>
              <p>
                Adjustment: {convertNumToCurrency(payor.AdjustmentAmount)} {payor.AdjustmentDate ? '(' + buildDate(payor.AdjustmentDate) + ')' : ''}
              </p>
              <p>
                Paid: {convertNumToCurrency(payor.PaidAmount)} {payor.DatePaid ? '(' + buildDate(payor.DatePaid) + ')' : ''}
              </p>
            </React.Fragment>
          ))}
          <span>Balance</span>
          <span>{convertNumToCurrency(service.Balance)}</span>
        </SpecimenPayorGrid>
      </ServiceContainer>
    )
  }

  const renderSpecimens = () => {
    return claim.specimens?.map(specimen => (
      <SpecimenContainer key={specimen.SpecimenNumber}>
        <AccessionHeader darkMode={theme === 'Dark'} tablet={tablet}>
          <div className="flexRow" style={{ justifyContent: 'space-between', marginBottom: '.5rem' }}>
            <h2>Accession: {specimen.SpecimenNumber}</h2>
          </div>
          {specimen.ClinicName ? <h3 style={{ margin: '1rem 0', fontSize: tablet ? '1rem' : '1.2rem', fontWeight: 600 }}>{specimen.ClinicName}</h3> : null}
          <ClaimDetails tablet={tablet}>
            {specimen.BillableProvider ? (
              <div>
                <BoldSpan tablet={tablet}>Billable Provider</BoldSpan>
                <p>{specimen.BillableProvider}</p>
              </div>
            ) : null}
            {specimen.ReferringProvider ? (
              <div>
                <BoldSpan tablet={tablet}>Referring Provider</BoldSpan>
                <p>{specimen.ReferringProvider}</p>
              </div>
            ) : null}
            {specimen.TaxID ? (
              <div>
                <BoldSpan tablet={tablet}>TaxID</BoldSpan>
                <p>{specimen.TaxID}</p>
              </div>
            ) : null}
            {specimen.PlaceOfService ? (
              <div>
                <BoldSpan tablet={tablet}>Place of Service</BoldSpan>
                <p>{specimen.PlaceOfService}</p>
              </div>
            ) : null}
          </ClaimDetails>
        </AccessionHeader>
        {specimen.services.map((service, i) => renderServices(service, i))}
      </SpecimenContainer>
    ))
  }

  const exitMobileClaimMode = () => {
    dispatch(setClaimIndex(0))
    dispatch(setMobileClaimMode(false))
  }

  return filteredClaims.length ? (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', width: '100%', height: '100%', boxSizing: 'border-box' }}>
      {mobile ? (
        <>
          <ArrowBack onClick={exitMobileClaimMode} />
          <MobileClaimInfoContainer>
            <BoldSpan tablet={tablet}>Claim Number</BoldSpan>
            <p>{formatID(claim.ClaimNumber)}</p>
            <BoldSpan tablet={tablet}>Billing Date</BoldSpan>
            <p>{buildDate(claim.BillingDate)}</p>
          </MobileClaimInfoContainer>
        </>
      ) : null}
      <div style={{ display: 'flex', width: '100%' }}>{claim.totals && Object.keys(claim.totals).length ? renderKPIs() : false}</div>
      <div style={{ height: '100%', width: '100%', overflow: mobile ? 'visible' : 'auto' }} ref={claimTop}>
        <AccessionHeader style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem 0' }}>
          <h2 style={{ fontSize: '1.4rem' }}>Claim {claim.ClaimNumber}</h2>
          {claim.remarks?.length ? (
            <DenialCount
              onClick={e => openModal(e, { modalLevel: 'remarks', modalContent: { ClaimNumber: claim.ClaimNumber, content: claim.remarks } })}
              portableDevice={portableDevice}
            >
              {claim.remarks.length} Remark{claim.remarks.length === 1 ? '' : 's'}
            </DenialCount>
          ) : null}
        </AccessionHeader>
        {renderSpecimens()}
      </div>
    </div>
  ) : (
      <p>No claims match your search.</p>
  )
}

export default Claim
