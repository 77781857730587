import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../type";
import { AppDispatch } from "../../redux/store";
import { api, protectedApi } from "../../utils/api";
import { deleteCookie, endSession, getCookie } from "../../utils/security";
import { setCookie, logout } from "../login/userSlice";
import { togglemFAModal, fpEngageMultiFactor, resetmFA, initiateElevatedAuth } from '../mfa/mFASlice';
import { preloginCheck } from "../login/loginSlice";

interface IFilter {
  key: keyof typeof initialPasswordResetState;
  data: any;
}

interface PasswordResetState {
  username: string;
  usernameSubmitted: RequestStatus;
  error: string;
  passwordChangesLoaded: RequestStatus;
  criticalError: boolean;
}

const initialPasswordResetState = {
  username: '',
  usernameSubmitted: "idle",
  error: '',
  passwordChangesLoaded: "idle",
  criticalError: false
};

const initialState = initialPasswordResetState as PasswordResetState;
/* FORGOT PASSWORD ROUTES */
export const sendUsername = createAsyncThunk<
  { success: boolean; message: string; Token: string, username: string },
  { UserName: string },
  { dispatch: AppDispatch }
>("passwordReset/sendUsername", async (payload, thunkAPI) => {
  return api
    .post("/forgotPassword", payload)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        thunkAPI.dispatch(
          setCookie({
            name: process.env.REACT_APP_FORGOT_PASSWORD_TOKEN,
            val: data.Token,
          })
        );
        thunkAPI.dispatch(
          fpEngageMultiFactor({controller: AbortController})
        )
      } else {
        throw new Error()
      }
      return data
    })
    .catch((error) => {
      thunkAPI.dispatch(setPWResetError({message: error.response.data.message, criticalError: false}))
      return error.response.data
    })
});

export const fpSubmitPassword = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "passwordReset/fpSubmitPassword",
  async (payload, thunkAPI) => {
    const token = process.env.REACT_APP_FORGOT_PASSWORD_AUTH_TOKEN

    return protectedApi(payload.controller, getCookie(token))
      .post("/forgotPassword/updatePassword", {Password: payload.Password})
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          setTimeout(() => { // Allowing time for the success message to display before redirecting
            endSession()
          }, 1000 * 5)
          return data
        } else {
          throw new Error()
        }
      })
      .catch(error => {
        if (error.response.status === 400) { // 401 error and can continue
          thunkAPI.dispatch(setPWResetError({message: error.response.data.message, criticalError: false}))
        } else {
          thunkAPI.dispatch(setPWResetError({message: error.response.data.message, criticalError: true}))
            setTimeout(() => {
              endSession()
          }, 1000 * 3)
        }
        return {...error.response.data, status: error.response.status}
      })
  }
)

/* SETTINGS MENU PASSWORD RESET ROUTES */
export const submitSettingsFormPassword = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>(
  "passwordReset/submitSettingsFormPassword",
  async (payload, thunkAPI) => {
    if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
      if (payload.Password) {
        return protectedApi(
          payload.controller,
          getCookie(process.env.REACT_APP_SECURITY_TOKEN)
        )
          .post("/users/settings/updatePassword", { Password: payload.Password })
          .then((res) => res.data)
          .then((data) => {
            if (data.success) {
              return data
            } else {
              throw new Error()
            }
          })
          .catch(error => {
            if (error.response.status === 400) { // 400: Password is historic or too short
              thunkAPI.dispatch(setPWResetError({message: error.response.data.message, criticalError: false}))
            } else if (error.response.data.tokenExpired) { 
              thunkAPI.dispatch(setPWResetError({ message: 'Your MFA validation has expired. Please complete MFA again.', criticalError: true }))
              setTimeout(() => {
                if (process.env.REACT_APP_SECURITY_TOKEN) {
                  deleteCookie(process.env.REACT_APP_SECURITY_TOKEN)
                }
                if (process.env.REACT_APP_ELEVATED_TOKEN) {
                  deleteCookie(process.env.REACT_APP_ELEVATED_TOKEN)
                }
                thunkAPI.dispatch(resetmFA({ saveSecurityAuth: false }))
                thunkAPI.dispatch(initiateElevatedAuth({controller: new AbortController()}))
                thunkAPI.dispatch(togglemFAModal(true))
              }, 3 * 1000)
            } else { // 500: Server error
              thunkAPI.dispatch(setPWResetError({message: error.response.data.message, criticalError: true}))
              setTimeout(() => {
                thunkAPI.dispatch(logout({ controller: new AbortController() }))
              }, 1000 * 3)
            }
            return error.response.data
          })
      } else {
        return { notSent: true };
      }
    } else {
      thunkAPI.dispatch(setPWResetError({message: 'Your credentials are expired. Please log in again.', criticalError: true}))
      setTimeout(() => {
        thunkAPI.dispatch(logout({ controller: new AbortController() }))
      }, 5 * 1000)
    }
  }
);

/* EXPIRED PASSWORD ROUTES */
export const submitNewPassword = createAsyncThunk<
  { message: string; success: boolean },
  { Password: string; path: string; controller: AbortController },
  { dispatch: AppDispatch }
>(
  "passwordReset/submitNewPassword", 
  async (payload, thunkAPI) => {
    const token = process.env.REACT_APP_PRELOGIN_TOKEN
    return protectedApi(payload.controller, getCookie(token))
      .post("/preLogin/updatePassword", payload) // update route
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          thunkAPI.dispatch(preloginCheck({controller: new AbortController()}))
          return data
        } else {
          throw new Error()
        }
      })
      .catch(error => {
        if (error.response.status === 400) { // 400: Password is historic or too short
          thunkAPI.dispatch(setPWResetError({message: error.response.data.message, criticalError: false}))
        } else if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(setPWResetError({ message: 'Your session has timed out. Please try again.', criticalError: true }))
          setTimeout(() => {
            endSession()
          }, 1000 * 5)
        } else { // 500: Server error
          thunkAPI.dispatch(setPWResetError({message: error.response.data.message, criticalError: true}))
          setTimeout(() => {
            endSession()
          }, 1000 * 5)
        }
        return error.response.data
      })
  }
)

export const passwordResetSlice = createSlice({
  name: "passwordReset",
  initialState: initialState,
  reducers: {
    updatePasswordResetInput(state, action: PayloadAction<IFilter>) {
      //@ts-ignore
      state[action.payload.key] = action.payload.data;
    },
    resetState(state) {
      Object.assign(state, initialPasswordResetState);
    },
    resetPasswordChanges(state) {
      state.passwordChangesLoaded = 'idle';
    },
    setPWResetError(state, action) {
      state.error = action.payload.message
      state.criticalError = action.payload.criticalError
    },
  },
  extraReducers: (builder) => {
    /* FORGOT PASSWORD EXTRA REDUCERS */
    builder.addCase(sendUsername.pending, (state) => {
      state.usernameSubmitted = "pending";
    });
    builder.addCase(sendUsername.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.usernameSubmitted = "succeeded";
        state.username = action.payload.username;
      } else {
        state.usernameSubmitted = 'rejected'
      }
    });
    builder.addCase(sendUsername.rejected, (state) => {
      state.usernameSubmitted = "rejected"
    });
    builder.addCase(fpSubmitPassword.pending, (state, action) => {
      state.passwordChangesLoaded = "pending";
    });
    builder.addCase(fpSubmitPassword.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.passwordChangesLoaded = 'succeeded';
      } else {
        state.passwordChangesLoaded = 'rejected';
        state.criticalError = action.payload.status === 400 ? false : true
      }
    });
    builder.addCase(fpSubmitPassword.rejected, (state, action: any) => {
      state.passwordChangesLoaded = 'rejected';
      state.criticalError = action.payload.status === 400 ? false : true
    });
    /* SETTINGS MENU RESET PASSWORD EXTRA REDUCERS */
    builder.addCase(submitSettingsFormPassword.pending, (state, action) => {
      state.passwordChangesLoaded = "pending";
      state.error = '';
    });
    builder.addCase(submitSettingsFormPassword.fulfilled, (state, action) => {
      if (action.payload && action.payload.success) {
        state.passwordChangesLoaded = "succeeded";
      } else if (Object.keys(action.payload).includes('notSent') && action.payload.notSent) { 
        state.passwordChangesLoaded = "idle";
      } else {
        state.passwordChangesLoaded = "rejected";
        if (action.payload.message === 'UNABLE TO UPDATE PASSWORD BECAUSE PASSWORD IS HISTORIC') {
          state.error = action.payload.message
        }
      }
    });
    builder.addCase(submitSettingsFormPassword.rejected, (state, action: any) => {
      if (Object.keys(action.payload).includes('notSent') && action.payload.notSent) { 
        state.passwordChangesLoaded = "idle";
      } else {
        state.passwordChangesLoaded = "rejected";
        if (action.payload.message === 'UNABLE TO UPDATE PASSWORD BECAUSE PASSWORD IS HISTORIC') {
          state.error = action.payload.message
        }
      }
    })

    /* EXPIRED PASSWORD EXTRA REDUCERS*/
    builder.addCase(submitNewPassword.pending, (state) => {
      state.error = '';
      state.passwordChangesLoaded = "pending";
    });
    builder.addCase(submitNewPassword.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.passwordChangesLoaded = "succeeded";
      } else {
        state.passwordChangesLoaded = "rejected";
      }
    });
  },
});

export const {
  updatePasswordResetInput,
  resetState,
  resetPasswordChanges,
  setPWResetError
} = passwordResetSlice.actions;

export default passwordResetSlice.reducer;
