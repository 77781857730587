import React, { useCallback, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import { setSortDirection } from '../searchSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import ScrollTopArrow from './ScrollTopArrow'
import { MobilePatientManagementContainer, ResultsWrapper } from '../../../patientInfo/search/searchStyles'
import MobileSearchResultCard from './mobileSearchResultCard'
import MobileSearchResultControls from './mobileSearchResultControls'

const SearchResultMobile = ({ handleSort, end, setEnd }) => {
  const scrollRef = useRef(null)
  const topElRef = useRef(null)

  const dispatch = useAppDispatch()

  const searchResults = useAppSelector(state => state.search.sortedResults)

  const config = {
    delta: 30,
    preventDefaultTouchmoveEvent: false,
    trackTouch: true,
    trackMouse: false,
  }

  const handlers = useSwipeable({
    onSwipedUp: e => handleSwipe(e),
    ...config,
  })

  const handleSwipe = e => {
    if (e.dir === 'Up') {
      setEnd(end + 10)
    }
  }

  const setSortUp = useCallback(
    payload => {
      dispatch(setSortDirection(payload))
    },
    [dispatch]
  )

  const buildResults = () => {
    return searchResults.slice(0, end).map((patient, i) => {
      if (i === 0) {
        return <MobileSearchResultCard ref={topElRef} key={i} patient={patient} />
      } else {
        return <MobileSearchResultCard key={i} patient={patient} />
      }
    })
  }

  const handleScroll = e => {
    let range = []
    for (let i = scrollRef.current.clientHeight - 100; i <= scrollRef.current.clientHeight + 100; i++) {
      range.push(i)
    }
    // const scrollRange = [scrollRef.current.clientHeight, scrollRef.current.clientHeight+1, scrollRef.current.clientHeight+2, scrollRef.current.clientHeight+3, scrollRef.current.clientHeight-2, scrollRef.current.clientHeight-3, scrollRef.current.clientHeight-1]
    const currentBottom = Math.round(scrollRef.current.scrollHeight - scrollRef.current.scrollTop)
    if (range.includes(currentBottom)) {
      setEnd(end + 1)
    }
  }

  const scrollTop = () => {
    topElRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    setEnd(10)
  }

  return (
    <>
      <MobilePatientManagementContainer className="hideScroll" {...handlers}>
        {searchResults.length > 0 ? <MobileSearchResultControls setSortUp={setSortUp} handleSort={handleSort} /> : null}
        <ResultsWrapper id="results-wrapper" key="ResultsWrapper" ref={scrollRef} onWheel={e => handleScroll(e)}>
          {buildResults()}
        </ResultsWrapper>
        {end > 10 ? <ScrollTopArrow scrollTop={scrollTop} /> : null}
      </MobilePatientManagementContainer>
    </>
  )
}

export default SearchResultMobile
