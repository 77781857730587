import {
  QuickSearchContainer,
  QuickSearchli,
  QuickSearchul,
} from '../patientInfo/search/searchStyles'
import DotLoader from '../misc/dotLoader'
import { useEffect, useRef } from 'react'
import { useAppSelector } from '../../redux/store'
import QuickSearchLineResult from './QuickSearchLineResult'
import { FlexWrapper } from '../../styles/viewerStyles'
import { ErrorWrapper } from '../settings/settingsStyles'
import { ErrorMessage } from '../modals/modalStyles'

const QuickSearchResults = ({ clearResults, setInput }) => {
  const quickRef = useRef()

  const quickSearchResults = useAppSelector((state) => state.quickSearch.quickSearchResults)
  const quickSearchSubmitted = useAppSelector((state) => state.quickSearch.quickSearchSubmitted)
  const error = useAppSelector(state => state.quickSearch.error)
  const mobile = useAppSelector((state) => state.nav.mobile)

  useEffect(() => {
    const handleClickAway = (e) => {
      const searchbar = document.querySelector('#quicksearch')
      if (!quickRef.current.contains(e.target)) {
        if (e.target.id !== 'quicksearch') {
          searchbar.value = ''
          clearResults()
        }
      }
    }
    document.addEventListener('mousedown', handleClickAway, false)
    return () => {
      document.removeEventListener('mousedown', handleClickAway, false)
    }
  }, [clearResults, setInput])

  const buildResults = () => {
    if (quickSearchSubmitted === 'pending') {
      return (
        <FlexWrapper>
          <DotLoader isLoading={true} />
        </FlexWrapper>
      )
    } else if (
      quickSearchResults !== [] &&
      quickSearchSubmitted === 'succeeded'
    ) {
      return (
        <QuickSearchul>
          {quickSearchResults?.length ? (
            quickSearchResults.map((res) => {
              return (
                <QuickSearchLineResult
                  result={res}
                  clearResults={clearResults}
                />
              )
            })
          ) : (
            <QuickSearchli style={{ textAlign: 'center' }} mobile={mobile}>
              No results found
            </QuickSearchli>
          )}
        </QuickSearchul>
      )
    } else if (
      quickSearchResults !== [] &&
      quickSearchSubmitted === 'succeeded'
    ) {
      return <h3>Your search returned no results.</h3>
    } else if (
      quickSearchResults !== [] &&
      quickSearchSubmitted === 'rejected'
    ) {
      return (
        <QuickSearchul style={{marginTop: mobile ? '35px' : 0}}>
          <QuickSearchli style={{ textAlign: 'center' }} mobile={mobile}>
            <ErrorWrapper>
              <ErrorMessage>{error}</ErrorMessage>
            </ErrorWrapper>
          </QuickSearchli>
        </QuickSearchul>
      )
    }
  }

  return (
    <QuickSearchContainer
      ref={quickRef}
      loading={quickSearchSubmitted === 'pending' ? 'true' : 'false'}
      mobile={mobile}
    >
      {buildResults()}
    </QuickSearchContainer>
  )
}

export default QuickSearchResults
