import styled from 'styled-components'

export const ChartPlaceholderDiv = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`
