import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../type";
import { AppDispatch } from "../../redux/store";
import { protectedApi } from "../../utils/api";
import { endSession, getCookie } from "../../utils/security";
import { preloginCheck } from "../login/loginSlice";
import { setModal } from "../modals/modalSlice";
import { fetchRefreshToken, logout } from "../login/userSlice";

interface Terms {
  terms: string;
  termsID: string;
  termsFetched: RequestStatus;
  termsAccepted: boolean | null;
  acceptTermsStatus: RequestStatus;
  error: string;
}

const intitalTermsState = {
  terms: "",
  termsID: "",
  termsFetched: "idle",
  termsAccepted: null,
  acceptTermsStatus: 'idle',
  error: '',
};

// Settings routes
export const getTermsOfServiceSettings = createAsyncThunk<
  any,
  { ViewTerm: boolean; controller: AbortController },
  { dispatch: AppDispatch }
>("terms/getTermsOfServiceSettings", async (payload, thunkAPI) => {
  const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN);
  if (token) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .post("users/getTermsOfService", payload)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() => {
              thunkAPI.dispatch(getTermsOfServiceSettings(payload))
            })
          );
        } else {
          thunkAPI.dispatch(setTermsError(error.response.data.message))
          setTimeout(() => {
            thunkAPI.dispatch(setModal({ key: "terms", data: false}))
          }, 1000 * 5)
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(setTermsError('Your credentials have expired. Please log in again.'))
    setTimeout(() => {
      thunkAPI.dispatch(logout({controller: new AbortController()}));
    }, 5 * 1000)
  }
});

// Login routes
export const getTermsOfService = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("terms/getTermsOfService", async (payload, thunkAPI) => {
  return protectedApi(
    payload.controller,
    getCookie(process.env.REACT_APP_PRELOGIN_TOKEN)
  )
    .get("preLogin/getTermsOfService")
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return data
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      const message = error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : 'Something went wrong. Please contact support. Redirecting you to login...'
      thunkAPI.dispatch(setTermsError(message))
      setTimeout(() => {
        endSession()
      }, 1000 * 5)
      return error.response.data
    })
});

export const acceptTermsOfService = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("terms/acceptTerms", async (payload, thunkAPI) => {
  return protectedApi(
    payload.controller,
    getCookie(process.env.REACT_APP_PRELOGIN_TOKEN)
  )
    .post("preLogin/saveUserAcknowledgement", payload)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        thunkAPI.dispatch(preloginCheck({controller: new AbortController()}))
        return data
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      const message = error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : 'Something went wrong. Please contact support. Redirecting you to login...'
      thunkAPI.dispatch(setTermsError(message))
      setTimeout(() => {
        endSession()
      }, 1000 * 5)
      return error.response.data
    })
});

const initialState = intitalTermsState as Terms;

export const termSlice = createSlice({
  name: "terms",
  initialState: initialState,
  reducers: {
    setTermsError(state, action) {
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTermsOfServiceSettings.pending, (state) => {
      state.termsFetched = "pending";
      state.error = '';
    });
    builder.addCase(getTermsOfServiceSettings.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.termsFetched = "succeeded";
        state.terms = action.payload.results[0].FullText;
        state.termsID = action.payload.results[0].TermID;
        state.termsAccepted = action.payload.results[0].Accepted;
      } else {
        state.termsFetched = "rejected";
        state.error = action.payload.message;
      }
    });
    builder.addCase(getTermsOfServiceSettings.rejected, (state, action: any) => {
      state.termsFetched = "rejected";
      state.error = action.payload.message;
    })
    builder.addCase(getTermsOfService.pending, (state) => {
      state.termsFetched = "pending";
      state.error = '';
    });
    builder.addCase(getTermsOfService.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.termsFetched = "succeeded";
        state.terms = action.payload.termsFullText;
        state.termsID = action.payload.termsID;
      } else {
        state.termsFetched = "rejected";
        state.error = action.payload.message;
      }
    });
    builder.addCase(getTermsOfService.rejected, (state, action) => {
      state.termsFetched = "rejected";
      //@ts-ignore
      state.error = action.payload.message;
    });
    builder.addCase(acceptTermsOfService.pending, (state, action) => {
      state.acceptTermsStatus = "pending"
      state.error = '';
    });
    builder.addCase(acceptTermsOfService.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.acceptTermsStatus = "succeeded"
        state.termsAccepted = action.payload.success;
      } else {
        state.acceptTermsStatus = "rejected"
      }
    });
    builder.addCase(acceptTermsOfService.rejected, (state, action) => {
      state.acceptTermsStatus = "rejected"
    })
  },
});

export const {
  setTermsError
} = termSlice.actions;

export default termSlice.reducer;
