import React from 'react'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PhoneLink } from '../patientInfo/patientInfoStyles'

const PhoneNumber = ({ number }) => {
  const buildPhoneNumber = () => {
    if (number === 'N/A') return 'N/A'

    const area = number.substring(0, 3)
    const firstThree = number.substring(3, 6)
    const last = number.substring(6)

    return (
      <PhoneLink href={`tel:${number}`}>
        ({area}) {firstThree}-{last}
        <FontAwesomeIcon icon={faPhone} />
      </PhoneLink>
    )
  }

  return buildPhoneNumber()
}

export default PhoneNumber
