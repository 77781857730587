import React, { useState, useEffect, useCallback } from 'react'
import { DashCard } from '../dashboardStyles'
import BarChartPlaceholder from '../placeholders/BarChartPlaceholder'
import CustomTooltip from '../../misc/CustomTooltip'
import BrokenChart from '../placeholders/BrokenChart'
import GCRChart from './GCRChart'
import GCRChartMobile from './GCRChartMobile'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { fetchChartData } from '../dashboardSlice'
import DotLoader from '../../misc/dotLoader'
import { FlexWrapper } from '../../../styles/viewerStyles'
import { useViewport } from '../../../utils/hooks'
import { Info } from '@mui/icons-material'

const GCRChartContainer = ({ visible }) => {
  const [mobile, setMobile] = useState(false)

  const { width } = useViewport()

  const dispatch = useAppDispatch()

  const dataLoaded = useAppSelector(state => state.dashboard.gcr.loaded)
  const gcr = useAppSelector(state => state.dashboard.gcr.data)
  const error = useAppSelector(state => state.dashboard.gcr.error)
  const clientSwitching = useAppSelector(state => state.users.switchingClients)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)

  const fetchGCRTotals = useCallback(() => {
    dispatch(fetchChartData({ chart: 'gcr', controller: new AbortController() }))
  }, [dispatch])

  useEffect(() => {
    if (width < 1025) {
      setMobile(true)
    } else {
      setMobile(false)
    }
    return () => {}
  }, [width])

  useEffect(() => {
    if (visible && ClientConnectionID) {
      fetchGCRTotals()
    }
  }, [ClientConnectionID])

  const formatYLabels = (engine, label) => {
    label.text = `${label.text}%`
    return label
  }

  const buildDataLabels = ht => {
    return ht.item.GCR.toString().substring(0, 4) + '%'
  }

  const buildTooltip = ht => {
    return `<span class='chart-tooltip'>
      <strong>${ht.item.PeriodAbbreviation || ''}</strong><br/>
      ${buildDataLabels(ht)}
      </span>
    `
  }

  const renderChart = () => {
    if (error) {
      return <BrokenChart refreshFn={fetchGCRTotals} header={'GCR'} chartType={1} />
    } else {
      if (mobile) {
        return <GCRChartMobile gcr={gcr} formatYLabels={formatYLabels} buildDataLabels={buildDataLabels} />
      } else {
        return <GCRChart gcr={gcr} formatYLabels={formatYLabels} buildDataLabels={buildDataLabels} buildTooltip={buildTooltip} />
      }
    }
  }

  return (
    <>
      {visible ? (
        <DashCard loaded={dataLoaded !== 'succeeded'} cardWidth={width > 1220 ? 2 : 6} error={error}>
          {dataLoaded !== 'succeeded' || clientSwitching === 'pending' ? (
            <BarChartPlaceholder title="Total GCR by Month" />
          ) : dataLoaded === 'succeeded' ? (
            <>
              <CustomTooltip title="Including current month, 6 month lookback of GCR as calculated by (Monthly Collections) / (Previous Month’s Charges)">
                <Info className="tooltip-icon" />
              </CustomTooltip>
              {renderChart()}
            </>
          ) : (
            <BarChartPlaceholder loaded title="Total GCR by Month" />
          )}
        </DashCard>
      ) : null}
    </>
  )
}

export default GCRChartContainer
