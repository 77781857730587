import { useState, useEffect, useCallback } from 'react'
import { PageNumWrapper, PageItem } from '../patientInfo/search/searchStyles'
import { KeyboardDoubleArrowLeftSharp, KeyboardDoubleArrowRightSharp } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { setPage } from '../patientInfo/search/searchSlice'

const Pagination = () => {
  const dispatch = useAppDispatch()

  const [pages, setPages] = useState([])
  const { resultsPerPage } = useAppSelector(state => state.search)
  const totalRecords = useAppSelector(state => state.search.searchResults).length
  const currentPage = useAppSelector(state => state.search.page)

  const changePage = useCallback(
    payload => {
      dispatch(setPage(payload))
    },
    [dispatch]
  )

  const range = (from, to, step = 1) => {
    let i = from
    const range = []

    while (i <= to) {
      range.push(i)
      i += step
    }

    return range
  }

  const fetchPageNumbers = () => {
    const totalPages = Math.ceil(totalRecords / resultsPerPage)
    const startPage = Math.max(currentPage, 1)

    const endPage = Math.min(totalPages, currentPage + 2)

    let pagination = range(startPage, endPage)

    let hasLeftSpill = false
    let hasRightSpill = false
    if (totalPages > 2) {
      hasLeftSpill = currentPage !== 0
      hasRightSpill = currentPage !== totalPages - 1
    }

    switch (true) {
      // current page is 1
      case !hasLeftSpill && hasRightSpill: {
        pagination = range(startPage, endPage + 1)
        setPages(pagination)
        break
      }
      // if current page is numberOfPages/resultsPerPage rounded up
      case hasLeftSpill && !hasRightSpill: {
        pagination = range(startPage - 1, endPage)
        setPages(pagination)
        break
      }
      default:
        if (totalPages === 1) {
          setPages([1])
          break
        } else if (totalPages === 2) {
          setPages([1, 2])
          break
        } else {
          setPages(pagination)
          break
        }
    }
  }

  useEffect(() => {
    fetchPageNumbers()
  }, [totalRecords, currentPage, resultsPerPage])

  const buildPageNumbers = () => {
    let toPrint = pages.map((mappedPage, i) => (
      <PageItem key={mappedPage} active={mappedPage === currentPage + 1} onClick={() => changePage(mappedPage - 1)}>
        {mappedPage}
      </PageItem>
    ))
    toPrint.unshift(
      <PageItem key="doubleleft" onClick={() => changePage(0)}>
        <KeyboardDoubleArrowLeftSharp />
      </PageItem>
    )
    toPrint.push(
      <PageItem key="doubleright" onClick={() => changePage(Math.ceil(totalRecords / resultsPerPage) - 1)}>
        <KeyboardDoubleArrowRightSharp />
      </PageItem>
    )
    return toPrint
  }

  // if there is only 1 page worth of results pagination is hidden
  return pages.length <= 1 ? null : <PageNumWrapper>{buildPageNumbers()}</PageNumWrapper>
}

export default Pagination
