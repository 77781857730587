import React from 'react'

// Redux
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { toggleConnectionSwitcher } from '../../login/userSlice'

// Styling
import { MobileClientTitle, MobileConnectionSwitcherContainer } from '../../nav/siteHeader/SiteHeaderStyles'
import { Menu } from '@mui/icons-material'

const MobileConnectionSwitcher = () => {
  const activeClient = useAppSelector(state => state.users.Client)
  const fetchingUser = useAppSelector(state => state.users.fetchingUser)
  const quickSearchSubmitted = useAppSelector(state => state.quickSearch.quickSearchSubmitted)
  const quickSearchTerm = useAppSelector(state => state.quickSearch.quickTerm) // adding due to z-index issue with quick search results modal
  const availableClients = useAppSelector(state => state.users.connectionSwitcher.clients)

  const dispatch = useAppDispatch()

  return (
    <MobileConnectionSwitcherContainer QuickSearchResults={(quickSearchSubmitted === 'pending' || quickSearchSubmitted === 'succeeded') && quickSearchTerm.length > 2}>
      {activeClient.ClientApps.length === 1 && availableClients.length === 1 ? null : <Menu style={{ marginRight: '10px', height: '100%' }} onClick={() => dispatch(toggleConnectionSwitcher())}/>}
      <MobileClientTitle>
        {fetchingUser === 'pending' ? '' : activeClient.ClientName}
      </MobileClientTitle>
    </MobileConnectionSwitcherContainer>
  )
}

export default MobileConnectionSwitcher;