// React
import { useState, useEffect, useCallback } from 'react'

// Redux
import { setMenu } from '../../nav/navSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { setModal } from '../../modals/modalSlice'
import { logout, setTheme } from '../../login/userSlice'

// styling
import { UserMenuContainer, UserMenuHeader, UserMenuName, UserMenuList, UserMenuListItem, UserMenuListItemLink } from '../../settings/userMenu/userMenuStyles'
import { ToggleSwitch, Toggle, UnstyledButton } from '../../../styles/buttonStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import TonalityIcon from '@mui/icons-material/Tonality'
import LoginIcon from '@mui/icons-material/Login'
import { UserMenuButton } from '../../nav/siteHeader/SiteHeaderStyles'

// hooks
import { useOutsideClick } from '../../../utils/hooks'

const UserMenu = ({ menuRef }) => {
  const dispatch = useAppDispatch()
  const UserName = useAppSelector(state => (state.users.PreferredName ? state.users.PreferredName : state.users.FirstName) + ' ' + state.users.LastName)
  const userInits = useAppSelector(state => state.users.UserInitials)
  const { termsModal, settings } = useAppSelector(state => state.modals)
  const { menuActive, mobile } = useAppSelector(state => state.nav)

  const ThemeID = useAppSelector(state => state.users.ThemeID)

  const toggleMenu = useCallback(
    payload => {
      dispatch(setMenu(payload))
    },
    [dispatch]
  )

  const toggleModal = useCallback(
    payload => {
      dispatch(setModal(payload))
    },
    [dispatch]
  )

  useOutsideClick(menuRef, () => (menuActive ? toggleMenu(false) : null))

  const callLogout = useCallback(() => {
    dispatch(logout({ controller: new AbortController() }))
  }, [dispatch])

  const [checked, set] = useState(ThemeID === '2' ? true : false)

  //necessary to set checked on component mount, otherwise if the theme is initially dark, the first toggle will not flip to light

  useEffect(() => {
    if (ThemeID === '2') {
      set(true)
    } else {
      set(false)
    }
  }, [ThemeID])

  // Selecting theme
  const toggleTheme = useCallback(
    payload => {
      dispatch(setTheme({ ...payload, controller: new AbortController() }))
    },
    [dispatch]
  )

  const handleClick = () => {
    if (!checked) {
      set(true)
      toggleTheme({ ThemeID: '2', ThemeName: 'Dark' })
    } else {
      set(false)
      toggleTheme({ ThemeID: '1', ThemeName: 'Light' })
    }
    toggleMenu(false)
  }

  //open and close the terms and conditions modal
  const toggleTermsModalVisible = e => {
    e.stopPropagation()
    toggleModal({ key: 'terms', data: !termsModal })
    toggleMenu(false)
  }

  //send user to /client for the settings
  const handleSettings = () => {
    toggleMenu(false)
    toggleModal({ key: 'settings', data: !settings })
  }

  const buildMenu = () => {
    return (
      <UserMenuContainer ref={menuRef} style={{ transform: menuActive ? 'translateY(0)' : `translateY(${mobile ? '-150%' : '-100%'})` }} mobile={mobile}>
        <UserMenuHeader>
          <UserMenuButton style={{ marginTop: '10px' }} menuActive={menuActive}>
            {userInits}
          </UserMenuButton>
          <UserMenuName>{`${UserName}`}</UserMenuName>
        </UserMenuHeader>
        <UserMenuList>
          <UserMenuListItem onClick={e => handleSettings(e)}>
            <div>
              <FontAwesomeIcon icon={faCog} style={{ fontSize: '24px' }} />
            </div>
            <UnstyledButton>
              <UserMenuListItemLink data-testid="user-settings">Manage Settings</UserMenuListItemLink>
            </UnstyledButton>
          </UserMenuListItem>
          <UserMenuListItem>
            <div>
              <TonalityIcon style={{ fontSize: '24px' }} />
            </div>
            <UserMenuListItemLink>
              Light
              <ToggleSwitch onClick={() => handleClick()}>
                <input type="checkbox" checked={ThemeID === '2'} readOnly />
                <Toggle />
              </ToggleSwitch>
              Dark
            </UserMenuListItemLink>
          </UserMenuListItem>
          <UserMenuListItem onClick={e => callLogout()}>
            <div>
              <LoginIcon style={{ fontSize: '24px' }} />
            </div>
            <UnstyledButton>
              <UserMenuListItemLink>Logout</UserMenuListItemLink>
            </UnstyledButton>
          </UserMenuListItem>
          <UserMenuListItem
            onClick={toggleTermsModalVisible}
            style={{
              justifyContent: 'center',
              paddingLeft: '0',
              borderBottom: 'none',
            }}
          >
            <UnstyledButton>
              <UserMenuListItemLink style={{ fontSize: '14px' }}>Terms of Service</UserMenuListItemLink>
            </UnstyledButton>
          </UserMenuListItem>
        </UserMenuList>
      </UserMenuContainer>
    )
  }

  return buildMenu()
}

export default UserMenu
