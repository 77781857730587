import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { findPermission } from './utils/helperMethods';

// Components
import DotLoader from './components/misc/dotLoader';
import DashboardContainer from './components/dashboard/DashboardContainer';
import LoginContainer from './components/login/LoginContainer';
import PageNotFound from './components/nav/PageNotFound';
import PatientInfoContainer from './components/patientInfo/PatientInfoContainer';
import PatientInfoLoader from './components/patientInfo/PatientInfoLoader';
import SearchResults from './components/patientInfo/search/results/searchResults';
import RedirectLimbo from './components/login/redirection/RedirectLimbo';
import ReportingHome from './components/reporting/reportingHome';
import ReportViewer from './components/reporting/ReportViewer';

// Redux
import { useAppDispatch, useAppSelector } from './redux/store';
import { resetSearch } from './components/patientInfo/search/searchSlice';

// Styles and images
import { FlexWrapper, ViewerDiv, ViewerStatement } from './styles/viewerStyles';

const Viewer = () => {

  const history = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn)
  const userFetched = useAppSelector(state => state.users.fetchingUser === 'succeeded')

  const mobile = useAppSelector(state => state.nav.mobile)
  const dashboardPermissions = useAppSelector(state => state.users.UserPermissions.Dashboard)
  const patientPermissions = useAppSelector(state => state.users.UserPermissions.Patients)
  const reportPermissions = useAppSelector(state => state.users.UserPermissions.Reporting)
  const claimsLoaded = useAppSelector(state => state.patients.currentPatient.claimsLoaded)
  const demographicsLoaded = useAppSelector(state => state.patients.currentPatient.demographicsLoaded)
  const patientARLoaded = useAppSelector(state => state.patients.currentPatient.patientARLoaded)
  
  useEffect(() => {
    if (!location.pathname.includes('patientManagement')) {
      dispatch(resetSearch())
    }

    return () => {
    }
  }, [location, dispatch])

  // Monitoring if a user is not logged in
  useEffect(() => {
    if (!isLoggedIn && !location.pathname.includes('/login')) {
      history("/login")
    }
  }, [isLoggedIn])

  const renderLoader = () => {
    return (
      <FlexWrapper>
        <DotLoader isLoading={true} width={mobile ? '80%' : '20%'} height="100%" dotSize={mobile ? '30px' : '2vw'}/>
      </FlexWrapper>
    )
  }

  const permissionsRedirect = () => {    
    const redirectInfo = [
      { permission: '15', permissionsData: dashboardPermissions, redirect: '/dashboard' },
      { permission: '16', permissionsData: patientPermissions, redirect: '/patientManagement' },
      { permission: '14', permissionsData: reportPermissions, redirect: '/reporting' },
    ]

    const firstAvailable = redirectInfo.find((feature) => findPermission(feature.permission, feature.permissionsData))

    if (firstAvailable) {
      return <Navigate to={firstAvailable.redirect} />
    }

    return (
      <FlexWrapper flexFlow='column'>
        <ViewerStatement>You do not have access to ClientPortal features.</ViewerStatement>
        <ViewerStatement>Please contact your administrator for access.</ViewerStatement>
      </FlexWrapper>
    )
  }

  return (
    <ViewerDiv location={window.location.pathname}>
      <Routes>
        <Route path="/">
          {/* Have to redirect in case of users who may have bookmarked the page
              Cannot redirect to /dashboard as they may not have ClientPortal permissions*/}
          <Route path='/launchPad' element={<RedirectLimbo />}/>
          <Route path="/dashboard" element={
            userFetched ?
              (findPermission('15', dashboardPermissions) ?
                <DashboardContainer />
                :
                permissionsRedirect())
              :
              renderLoader()
        } /> 
          <Route path="/patientManagement" element={userFetched ?
            findPermission('16', patientPermissions) ?
              <SearchResults 
              searchPatientsAllowed={findPermission('40', patientPermissions)}
              />
              :
              permissionsRedirect()
              :
              renderLoader()
          } />
          <Route path="/patientManagement/:patientId" element={isLoggedIn ?
            claimsLoaded === 'succeeded' || demographicsLoaded === 'succeeded' || patientARLoaded === 'succeeded' ||
            claimsLoaded === 'rejected' || demographicsLoaded === 'rejected' || patientARLoaded === 'rejected' ?
              <PatientInfoContainer
                patientDemographicsAllowed={findPermission('44', patientPermissions)}
                patientClaimsAllowed={findPermission('42', patientPermissions)}
                exportPatientClaimsAllowed={findPermission('45', patientPermissions)}
                patientARAllowed={findPermission('43', patientPermissions)}
              />
              :
              <PatientInfoLoader
                patientDemographicsAllowed={findPermission('44', patientPermissions)}
                patientClaimsAllowed={findPermission('42', patientPermissions)}
                patientARAllowed={findPermission('43', patientPermissions)}
              />
            :
            <Navigate to='/login' /> 
            }
          />
          <Route exact path="/reporting" element={userFetched ?
            findPermission('14', reportPermissions) && !mobile ? <ReportingHome /> : permissionsRedirect()
            :
            renderLoader()
          }
          />
          <Route path="/reporting/:reportId" element={userFetched ?
            <ReportViewer />
            :
            renderLoader()
          }
          />
          <Route path="/login" element={isLoggedIn ?
            <Navigate to="/" />
            :
            <LoginContainer/>
          } />
          <Route path="" element={isLoggedIn ? <RedirectLimbo/> : <Navigate to='/login'/>}/>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ViewerDiv>
  )
}

export default Viewer;