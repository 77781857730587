import { useState, useRef, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { useIdleTimer } from 'react-idle-timer'
import { logout } from '../login/userSlice'
import { useOutsideClick } from '../../utils/hooks'
import {
  ModalBackground,
  ModalBlur,
  ModalContainer,
  ModalTitle,
} from './modalStyles'
import CloseIcon from '@mui/icons-material/Close';

const TimeoutMonitor = () => {
  const [idleModal, toggleIdleModal] = useState(false)
  const [timeUntilLogout, setTimeUntilLogout] = useState(60)

  const modalRef = useRef()

  const timeoutMinutes = useAppSelector((state) => state.users.TimeoutMinutes ? state.users.TimeoutMinutes : 15)

  const dispatch = useAppDispatch()

  /* onPrompt */
  let modalCountdown = null;

  const onPrompt = () => {
    toggleIdleModal(true)
    modalCountdown = setInterval(() => {
      setTimeUntilLogout(Math.round(getRemainingTime() / 1000))
    }, 1000)
  }

  /* Declaring timer */
  const onIdle = () => {
    callLogout()
  }

  const onActive = () => {
    toggleIdleModal(false)
    modalCountdown = null
  }

  const { getRemainingTime, reset } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    timeout: 1000 * 60 * (timeoutMinutes - 1),
    promptTimeout: 1000 * 60,
    throttle: 500,
    crossTab: true,
    syncTimers: 100,
    stopOnIdle: true,
  })

  /* console.log for testing purposes only */
  // useEffect(() => {
  //   setInterval(() => {
  //     console.log(getRemainingTime())
  //   }, 1000)
  // }, [])

  /* Handling outside click */
  useOutsideClick(modalRef, () => closeModal())

  const closeModal = () => {
    reset()
    toggleIdleModal(false)
  }

  /* Calling logout */
  const callLogout = useCallback(() => {
    dispatch(logout({ controller: new AbortController() })) // logout() will call deleteAllCookies()
  }, [dispatch])

  return (
    <>
      {idleModal ? (
        <ModalBackground>
          <ModalBlur>
            <ModalContainer ref={modalRef}>
              <CloseIcon onClick={() => closeModal() } style={{cursor: "pointer", alignSelf: "flex-end"}} />
              <ModalTitle>Session Timeout Warning</ModalTitle>
              <p>
                You have been inactive for too long. You will be logged out in{' '}
                <span>{timeUntilLogout}</span> seconds.
              </p>
            </ModalContainer>
          </ModalBlur>
        </ModalBackground>
      ) : null}
    </>
  )
}

export default TimeoutMonitor
