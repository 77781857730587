import { useState, useEffect, useCallback } from 'react'
import Countdown from '../misc/Countdown'
import DotLoader from '../misc/dotLoader'
import { endSession, getCookie } from '../../utils/security'

// Styling
import { PhyForm } from '../../styles/formStyles'
import { ErrorMessage } from '../modals/modalStyles'
import { ErrorWrapper } from '../settings/settingsStyles'
import {
  MFAInstructions,
  MFAHelpStatement,
  ResendButton,
  MFALink,
  ResendIcon,
} from './multiFactorStyles'
import { FlexWrapper } from '../../styles/viewerStyles'

// Icons
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';

// Redux
import { useAppDispatch, useAppSelector } from '../../redux/store'
import {
  engageMultiFactor,
  fpEngageMultiFactor,
  elevatedEngageMultiFactor,
  submitLoginPasscode,
  fpSubmitPasscode,
  submitElevatedPasscode, 
} from './mFASlice'
import PasscodeInputs from './passcodeInputs'
import { LoginButton } from '../login/loginStyles'


const MFActorAuth = ({elevated, closeSettings}) => {
  const passcodeLength = 6;

  const [resendButtonStage, setResendButtonStage] = useState('default')
  const [passcode, updatePasscode] = useState(new Array(passcodeLength).fill(''))
  const [passcodeValid, setPasscodeValid] = useState(null)
  const [fpCodeOptions, togglefpCodeOptions] = useState(false)

  const dispatch = useAppDispatch()
  const criticalError = useAppSelector(state => state.mFA.criticalError)
  const error = useAppSelector((state) => state.mFA.error)
  const passcodeType = useAppSelector((state) => state.mFA.passcodeType)
  const passCodeDestination = useAppSelector((state) => state.mFA.passCodeDestination)
  const canAttemptAgain = useAppSelector((state) => state.mFA.canAttemptAgain)
  const canGenerateNewPassCode = useAppSelector((state) => state.mFA.canGenerateNewPassCode)
  const settingsInitiateRequest = useAppSelector(state => state.mFA.settingsInitiateRequest)
  const initialMFARequest = useAppSelector((state) => state.mFA.initialMFARequest)
  const submitPasscodeRequest = useAppSelector((state) => state.mFA.submitPasscodeRequest)
  const forgotPassword = useAppSelector(state => state.nav.forgotPassword)
  const mobile = useAppSelector(state => state.nav.mobile)
  const theme = useAppSelector(state => state.users.ThemeName)

  const abortController = new AbortController()

  // Logout user if their session times out
  let pushTimer

  useEffect(() => {
    let timeout

    timeout = setTimeout(() => {
      elevated ?
        closeSettings()          
        :
        endSession()
    }, 1000 * 60 * 3)

    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch, pushTimer])

  /* LISTENING FOR initiateElevatedAuth */
  const elevatedCookie = getCookie(process.env.REACT_APP_ELEVATED_TOKEN)
  useEffect(() => {
    if (settingsInitiateRequest === 'succeeded' && elevatedCookie && initialMFARequest === 'idle') {
      dispatch(elevatedEngageMultiFactor({controller: abortController }))
    }
  }, [elevatedCookie])

  /* MONITORING INPUTS */
  useEffect(() => {
    const fullPasscode = [...passcode].join('');
    if (fullPasscode.length === passcodeLength) {
      handleSubmit()
    }
  })

  /* SUBMIT CODE */
  const submitLoginCode = useCallback(
    (payload) => {
      dispatch(
        submitLoginPasscode({
          passcode: payload,
          controller: abortController,
        })
      )
    },
    [dispatch]
  )

  const submitSettingsPasscode = useCallback(
    (payload) => {
      dispatch(
        submitElevatedPasscode ({
          passcode: payload,
          controller: abortController,
        })
      )
    },
    [dispatch]
  )

  const submitFPCode = useCallback(
    (payload) => {
      dispatch(
        fpSubmitPasscode({
          passcode: payload,
          controller: abortController,
        })
      )
    },
    [dispatch]
  )

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault()
      e.target.blur()  
    }

    const fullPasscode = [...passcode].join('');
    let passcodeLengthValid = fullPasscode.length === passcodeLength;
    let passcodeOnlyDigits = /^\d+$/.test(fullPasscode)

    if (passcodeLengthValid && passcodeOnlyDigits) {
      if (!criticalError) {
        const joinedPasscode = [...passcode].join('')
        if (forgotPassword) {
          submitFPCode(joinedPasscode)
        } else if (elevated) {
          submitSettingsPasscode(joinedPasscode)
        } else {
          submitLoginCode(joinedPasscode)
        }
        updatePasscode(new Array(passcodeLength).fill(''))
      }
    } else {
      setPasscodeValid(false)
    }
  }

  /* RESENDING SMS CODE */
  const sendCode = (type) => {
    const payload = { factor: type, controller: abortController };
    if (forgotPassword) {
      dispatch(fpEngageMultiFactor(payload))
    } else if (elevated) {
      dispatch(elevatedEngageMultiFactor(payload))
    } else {
      dispatch(engageMultiFactor(payload))
    }
  }

  const resendCode = () => {
    if (passcodeType === 'SMS') {
      if (resendButtonStage !== 'showOptions') {setResendButtonStage('showOptions')}
    } else {
      sendCode('EMAIL')
      if (resendButtonStage !== 'countdown') {setResendButtonStage('countdown')}
    }
  }

  const handleOptionButton = (type) => {
    sendCode(type)
    setResendButtonStage('countdown')
  }
  
  const buildResendButton = () => {
    //setTimeout(()=>setResendButton(false), 60000)
    if (canGenerateNewPassCode) {
      if (resendButtonStage === 'countdown') {
        return <ResendButton mobile={mobile} data-testid="mfa-resend-button">Resend SMS Code <Countdown initialSeconds={60} /></ResendButton>
      } else if (resendButtonStage === 'showOptions') {
        return (
          <FlexWrapper flexFlow="column" align="center">
            <MFAHelpStatement
              style={{ marginBottom: '15px', cursor: 'default' }}
              elevated={elevated}
              lightTheme={theme === 'Light'}
            >
              How would you like your passcode sent to you?
            </MFAHelpStatement>
            <FlexWrapper>
              <FlexWrapper flexFlow="column" align="center" width="auto" justify="flex-start">
                <ResendIcon onClick={(e) => handleOptionButton('EMAIL')}>
                  <EmailIcon />
                </ResendIcon>
                <ResendButton
                  style={{ margin: 0 }}
                  elevated={elevated}
                  lightTheme={theme === 'Light'}
                >
                  EMAIL
                </ResendButton>
              </FlexWrapper>
              <FlexWrapper flexFlow="column" align="center" width="auto" justify="flex-start">
                <ResendIcon onClick={(e) => handleOptionButton('SMS')}>
                  <SmsIcon/>
                </ResendIcon>
                <ResendButton
                  style={{ margin: 0 }}
                  elevated={elevated}
                  lightTheme={theme === 'Light'}
                >
                  SMS
                </ResendButton>
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
        )
      } else {
        if (forgotPassword) {
          return (
            <LoginButton
              mobile={mobile}
              role="button"
              onClick={() => resendCode()}
              data-testid="mfa-resend-button"
              style={{ marginBottom: '30px' }}
              login={!elevated}
            >
              RESEND CODE
            </LoginButton>
          )
        } else {
          return (
            <FlexWrapper onClick={() => resendCode()} flexFlow="column" align="center">
              <ResendIcon elevated={elevated} lightTheme={theme === 'Light'}>
                {passcodeType === 'SMS' ? <SmsIcon /> : <EmailIcon/>}
              </ResendIcon>
              <ResendButton
                mobile={mobile}
                role="button"
                data-testid="mfa-resend-button"
                elevated={elevated}
                lightTheme={theme === 'Light'}
              >
                RESEND {passcodeType === 'SMS' ? 'SMS ': ''}CODE
              </ResendButton>
            </FlexWrapper>
          )
        }
      }
    }
  }

  /* RENDERING FUNCTIONS */
  const renderInstructions = () => {
    if (canGenerateNewPassCode && !canAttemptAgain) {
      return (
        <MFAInstructions
          data-testid="mfa-instructions"
          mobile={mobile}
          elevated={elevated}
          lightTheme={theme === 'Light'}
        >
          Please request a new passcode.
        </MFAInstructions>
      )
    } else {
      let phrase;
      if (forgotPassword) {
        phrase = (passcodeType === 'SMS' ? 'device' : 'email')
      } else {
        phrase = (passcodeType === 'SMS' ? 'phone number ending in xxx-xxx-' : 'email ')
      } 
      return (
        <MFAInstructions
          data-testid="mfa-instructions"
          mobile={mobile}
          elevated={elevated}
          lightTheme={theme === 'Light'}
        >
          Please enter the security code sent to your {phrase}{forgotPassword ? null : passCodeDestination}.
        </MFAInstructions>
      )
    }
  }

  const renderHelpStatement = () => {
    if (forgotPassword) {
      if (canGenerateNewPassCode) {
        return (<>
          <MFAHelpStatement
            style={{
              color: '#373D4D',
              fontWeight: '600',
              textDecoration: "none",
              fontSize: '1.1em'
            }}
            mobile={mobile}
            onClick={e => togglefpCodeOptions(!fpCodeOptions)}
            data-testid="mfa-help-statement"
            elevated={elevated}
            lightTheme={theme === 'Light'}
          >
            {canAttemptAgain ? 'Didn\'t receive a code?' : null}
          </MFAHelpStatement>
          {
            fpCodeOptions ? (
              <FlexWrapper flexFlow="column" align="center">
                <MFAHelpStatement
                  style={{ marginBottom: '15px', cursor: 'default' }}
                  elevated={elevated}
                  lightTheme={theme === 'Light'}
                >
                  How would you like your passcode sent to you?
                </MFAHelpStatement>
                <FlexWrapper>
                  <FlexWrapper flexFlow="column" align="center" width="auto" justify="flex-start">
                    <ResendIcon onClick={(e) => handleOptionButton('EMAIL')}>
                      <EmailIcon />
                    </ResendIcon>
                    <ResendButton
                      style={{ margin: 0 }}
                      elevated={elevated}
                      lightTheme={theme === 'Light'}
                    >
                      EMAIL
                    </ResendButton>
                  </FlexWrapper>
                  <FlexWrapper flexFlow="column" align="center" width="auto" justify="flex-start">
                    <ResendIcon onClick={(e) => handleOptionButton('SMS')}>
                      <SmsIcon/>
                    </ResendIcon>
                    <ResendButton
                      style={{ margin: 0 }}
                      elevated={elevated}
                      lightTheme={theme === 'Light'}
                    >
                      SMS
                    </ResendButton>
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
            ) : null}
        </>)
      } else {
        return (
          <>
            <MFAHelpStatement
              mobile={mobile}
              data-testid="mfa-help-statement"
              elevated={elevated}
              lightTheme={theme === 'Light'}
            >
              Didn't receive a code?
            </MFAHelpStatement>
          <MFALink type='tel' href="tel:+18778462953" elevated={elevated}>Please contact our support at (877) 846-2953</MFALink>
          </>
        )
      }
    } else if (!forgotPassword) {
      if (passcodeType === 'SMS') {
        if (canGenerateNewPassCode) {
          return canAttemptAgain ? (
            <>
              <MFAHelpStatement
                mobile={mobile}
                data-testid="mfa-help-statement"
                elevated={elevated}
                lightTheme={theme === 'Light'}
              >
              Not your phone number?
            </MFAHelpStatement>
            <MFALink
                onClick={(e) => sendCode('EMAIL')}
                elevated={elevated}
            >
              Send me an email passcode.
            </MFALink>
            </>
          ) : null
        } else {
          return (
            <>
            <MFAHelpStatement
              mobile={mobile}
              data-testid="mfa-help-statement"
              elevated={elevated}
              lightTheme={theme === 'Light'}
            >
              Not your phone number?
            </MFAHelpStatement>
            <MFALink type='tel' href="tel:+18778462953" elevated={elevated}>Please contact our support at (877) 846-2953</MFALink>
            </>
          )
        }
      } else {
        return (canGenerateNewPassCode) ? null : (
          <>
            <MFAHelpStatement
              mobile={mobile}
              data-testid="mfa-help-statement"
              elevated={elevated}
              lightTheme={theme === 'Light'}
            >
              Didn't receive a code?
            </MFAHelpStatement>
          <MFALink type='tel' href="tel:+18778462953" elevated={elevated}>Please contact our support at (877) 846-2953</MFALink>
          </>
        )
      }
    }
  }

  return (
    settingsInitiateRequest === 'pending' || initialMFARequest === 'pending' || submitPasscodeRequest === 'pending' ? (
      <DotLoader width="250px" height="200px" dotSize="30px" isLoading={initialMFARequest === 'pending' || submitPasscodeRequest === 'pending'} />
    ): (
      <FlexWrapper flexFlow='column' justifyContent='center' align='center'>
        {passcodeValid === false || error ? (
          <ErrorWrapper style={{ marginBottom: '10px', minWidth: 'auto', width: '35%' }} data-testid="mfa-error-wrapper">
            {passcodeValid === false ? <ErrorMessage>Your passcode must be a {passcodeLength}-digit number.</ErrorMessage> : null}
            {error ? <ErrorMessage>{error}</ErrorMessage> : null}
          </ErrorWrapper>
        ) : null}
        <PhyForm
          height="80%"
          submitMarginTop='15px'
          onSubmit={(e) => handleSubmit(e)}
          style={elevated ? null : {color: '#FFFFFF'}}
        > 
          {renderInstructions()}
          {renderHelpStatement()}
          {canGenerateNewPassCode && !canAttemptAgain
            ? null
            : <PasscodeInputs
                passcodeLength={passcodeLength}
                passcode={passcode}
                updatePasscode={updatePasscode}
                elevated={elevated}
              />
          }
          {forgotPassword && (!canGenerateNewPassCode || canAttemptAgain) ? null : (
            <FlexWrapper align='center' flexFlow='column'>
              {buildResendButton()}
            </FlexWrapper>
          )}
        </PhyForm>
      </FlexWrapper>
    )
  )
}

export default MFActorAuth