import React from 'react'
import { FavoriteButtonContainer } from '../reportStyles'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { useAppSelector } from '../../../redux/store'

function FavoriteButton({ report, toggleFavorite }) {
  const theme = useAppSelector(state => state.users.ThemeName)

  const handleToggle = report => {
    toggleFavorite && toggleFavorite(report)
  }

  return (
    <FavoriteButtonContainer onClick={() => handleToggle(report)} lightMode={theme === 'Light'}>
      {report.IsFavorite ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
    </FavoriteButtonContainer>
  )
}

export default FavoriteButton
