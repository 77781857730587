import React, { useCallback, useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import { useOutsideClick, useViewport } from '../../utils/hooks'
import DotLoader from '../misc/dotLoader'

// Styling
import { LoginButton, LoginForm } from '../login/loginStyles'
import { TermsActionBar, TermsCard, TermsContainer, TermsTitle, TermsCheckbox, TermsFlexWrapper } from './termsStyles'
import { ModalContainer, ModalBackground, ModalContent, ErrorMessage } from '../modals/modalStyles'
import { ErrorWrapper } from '../settings/settingsStyles'

// Icons
import { UnstyledButton } from '../../styles/buttonStyles'

// Redux
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { acceptTermsOfService, getTermsOfService, getTermsOfServiceSettings } from './termsSlice'
import { setModal } from '../../components/modals/modalSlice'
import { Close } from '@mui/icons-material'

const TermsOfService = props => {
  const termsModalRef = useRef()
  const [checked, toggleChecked] = useState(false)
  const [error, toggleError] = useState(false)

  const termsModal = useAppSelector(state => state.modals.terms)
  const terms = useAppSelector(state => state.terms.terms)
  const termsID = useAppSelector(state => state.terms.termsID)
  const termsFetched = useAppSelector(state => state.terms.termsFetched)
  const theme = useAppSelector(state => state.users.ThemeName)
  const termsError = useAppSelector(state => state.terms.error)
  const mobile = useAppSelector(state => state.nav.mobile)

  const dispatch = useAppDispatch()
  const { width, height } = useViewport()

  // Redux callbacks
  const toggleModal = useCallback(
    payload => {
      dispatch(setModal({ key: 'terms', data: payload }))
    },
    [dispatch]
  )

  const getTermsOfServiceWithText = useCallback(
    payload => {
      dispatch(getTermsOfService(payload))
    },
    [dispatch]
  )

  const getTermsOfServiceWithTextSettings = useCallback(
    payload => {
      dispatch(getTermsOfServiceSettings(payload))
    },
    [dispatch]
  )

  const callAcceptTermsOfService = useCallback(
    payload => {
      dispatch(
        acceptTermsOfService({
          TermID: payload,
          ScreenWidth: width,
          ScreenHeight: height,
          BrowserName: sessionStorage.getItem('userBrowser'),
          BrowserVersion: sessionStorage.getItem('browserVersion'),
          controller: new AbortController(),
        })
      )
    },
    [dispatch]
  )

  // Fetch terms
  useEffect(() => {
    if (termsFetched === 'idle') {
      if (props.login) {
        getTermsOfServiceWithText({
          controller: new AbortController(),
        })
      } else {
        getTermsOfServiceWithTextSettings({
          controller: new AbortController(),
        })
      }
    }
  }, []) //eslint-disable-line

  // Close modal
  useOutsideClick(termsModalRef, () => {
    toggleModal(!termsModal)
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (checked) {
      toggleError(false)
      callAcceptTermsOfService(termsID)
    } else {
      toggleError(true)
    }
  }

  const renderTerms = () => {
    return (
      <TermsContainer mobile={mobile} login={props.login}>
        <TermsTitle mobile={mobile} login={props.login}>{`${props.login ? 'Please accept the Terms of Service Agreement.' : 'Terms of Service'}`}</TermsTitle>
        {error || termsError ? (
          <ErrorWrapper>
            {error ? <ErrorMessage>Please select the checkbox below.</ErrorMessage> : null}
            {termsError ? <ErrorMessage>{termsError}</ErrorMessage> : null}
          </ErrorWrapper>
        ) : null}
        <TermsCard login={props.login} mobile={mobile}>
          {parse(terms)}
        </TermsCard>
        {props.login ? (
          <TermsActionBar>
            <LoginForm
              onSubmit={e => {
                handleSubmit(e)
              }}
            >
              <TermsFlexWrapper mobile={mobile} align="center">
                <TermsCheckbox
                  type="checkbox"
                  name="acceptTerms"
                  id="acceptTerms"
                  title="Accept Terms of Service"
                  checked={checked}
                  onChange={e => toggleChecked(!checked)}
                  error={error}
                />
                <label htmlFor="acceptTerms">Accept Terms of Service</label>
              </TermsFlexWrapper>
              <LoginButton type="submit" mobile={mobile} login={props.login}>
                SUBMIT
              </LoginButton>
            </LoginForm>
          </TermsActionBar>
        ) : null}
      </TermsContainer>
    )
  }

  const renderLoginTermsContainer = () => {
    return termsFetched === 'pending' ? <DotLoader isLoading={termsFetched === 'pending'} width="250px" height="200px" dotSize="30px" /> : renderTerms()
  }

  const renderSettingsTermsContainer = () => {
    return (
      <ModalBackground>
        <ModalContainer ref={termsModalRef} mobile={mobile}>
          <UnstyledButton
            style={{
              alignSelf: 'flex-end',
              color: theme === 'Light' ? '#000000' : '#FFFFFF',
              marginTop: '5px',
            }}
            onClick={() => toggleModal(false)}
          >
            <Close fontSize='large'/>
          </UnstyledButton>
          <ModalContent style={{ margin: 0, width: '100%' }}>
            {termsFetched === 'pending' ? <DotLoader isLoading={termsFetched === 'pending'} width="250px" height="200px" dotSize="30px" /> : renderTerms()}
          </ModalContent>
        </ModalContainer>
      </ModalBackground>
    )
  }

  return props.login ? renderLoginTermsContainer() : renderSettingsTermsContainer()
}

export default TermsOfService
