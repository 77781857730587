import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../type"
import { AppDispatch } from "../../redux/store"
import { protectedApi } from "../../utils/api"
import { getCookie } from "../../utils/security"
import { fetchRefreshToken, logout } from "../login/userSlice"


interface ModalState {
    support: boolean,
    terms: boolean,
    settings: boolean,
    supportTicketCategories: any[],
    categoriesLoading: RequestStatus,
    ticketSubmitting: RequestStatus,
    supportErrors: boolean
}

const initialModalState = {
    support: false,
    terms: false,
    settings: false,
    supportTicketCategories: [],
    categoriesLoading: 'idle',
    ticketSubmitting: 'idle',
    supportErrors: false
}

interface ModalToggle {
    key: keyof typeof initialModalState,
    data: boolean
}

const initialState = initialModalState as ModalState

export const getCategories = createAsyncThunk<any, any, {dispatch: AppDispatch}>(
    'modals/getCategories',
  async (payload, thunkAPI) => {
    const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN)
    if (token) {
      return protectedApi(payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN)).get('/support/getTicketCategories')
        .then(res => res.data)
        .then(data => {
          if (data.success) {
            return data
          } else {
              throw new Error()
          }
        })
        .catch(error => {
          if (error.response.data.tokenExpired) {
            thunkAPI.dispatch(fetchRefreshToken(() => thunkAPI.dispatch(getCategories({ controller: new AbortController() }))))
          }
          return error.response.data
        })
    } else {
        setSupportError(true)
        setTimeout(() => {
            thunkAPI.dispatch(logout({controller: new AbortController()}));
        }, 5 * 1000)
    }
  }
)

export const sendSupportRequest = createAsyncThunk<any, any, {dispatch: AppDispatch}>(
    'modals/sendSupportRequest',
    async (payload, thunkAPI) => {
        if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
            return protectedApi( payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN)).post('/support/saveHelpDeskTicket', payload)
                .then(res => res.data)
                .then(data => {
                    if(data.success){
                        return data
                    }else {
                        throw new Error()
                    }
                })
                .catch(error => {
                    if(error.response.data.tokenExpired){
                        thunkAPI.dispatch(fetchRefreshToken(() => thunkAPI.dispatch(sendSupportRequest(payload))))
                    }
                    return error.response.data
                })
        } else {
            setSupportError(true)
            setTimeout(() => {
                thunkAPI.dispatch(logout({controller: new AbortController()}));
            }, 5 * 1000)
        }
    }
)

export const modalSlice = createSlice({
    name: 'modals',
    initialState: initialState,
    reducers: {
        setSupportError(state, action) {
            state.supportErrors = action.payload
        },
        setModal(state, action:PayloadAction<ModalToggle>){
            // @ts-ignore
            state[action.payload.key] = action.payload.data
        },
        resetSupport(state){
            state.ticketSubmitting = 'idle'
            state.supportErrors = false
        }
    },
    extraReducers: builder => {
        builder.addCase(getCategories.pending, (state)=>{
            state.categoriesLoading = 'pending'
            state.supportErrors = false
        })
        builder.addCase(getCategories.fulfilled, (state, action)=>{
            if(action.payload.success){
                state.supportTicketCategories = action.payload.results
                state.categoriesLoading = 'succeeded'
            } else {
                state.categoriesLoading = 'rejected'
                state.supportErrors = true
            }
        })
        builder.addCase(getCategories.rejected, (state) => {
            state.categoriesLoading = 'rejected'
            state.supportErrors = true
        })
        builder.addCase(sendSupportRequest.pending, (state)=>{
            state.ticketSubmitting = 'pending'
            state.supportErrors = false
        })
        builder.addCase(sendSupportRequest.fulfilled, (state, action)=>{
            if (action.payload.success) {
                state.ticketSubmitting = 'succeeded'
                if (!action.payload.emailSent) {
                    state.supportErrors = true;
                }
            } else {
                state.ticketSubmitting = 'rejected'
                state.supportErrors = true
            }
        })
        builder.addCase(sendSupportRequest.rejected, (state) => {
          state.ticketSubmitting = 'rejected'
          state.supportErrors = true
        })
    }
})

export const { setSupportError, setModal, resetSupport } = modalSlice.actions
export default modalSlice.reducer