import styled, { keyframes } from 'styled-components'

const slideInAndOut = keyframes`
    0%{
        top: -100%;
    }

    20%{
        top: -10px;
    }

    80%{
        top:-10px
    }
    100%{
        top: -100%;
    }
`

export const SuccessMessage = styled.div`
    width: calc(100% + 20px);
    z-index: 1000;
    position: absolute;
    top: 0px; 
    margin: 0;
    color: #ffffff;
    font-family: "WorkSans-Regular", "Work Sans", sans-serif;
    background: #373d4d;
    text-align: left;
    line-height: normal;
    animation: ${slideInAndOut} 5s;
    &.reset-password {
        z-index: 1000;
        left: 0px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: min(max(2vw, 18px), 30px);
        font-weight: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.optional-page {
        z-index: 1000;
        left: 0px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: min(max(2vw, 18px), 30px);
        font-weight: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`