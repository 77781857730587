import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Report, ReportCategory, RequestStatus } from "../../../type";
import { AppDispatch } from "../../redux/store";
import { protectedApi } from "../../utils/api";
import { getCookie } from "../../utils/security";
import { fetchRefreshToken, logout } from "../login/userSlice";

interface ReportState {
  reports: Report[];
  reportCategories: ReportCategory;
  toView: string;
  reportsLoading: RequestStatus;
  settingReport: RequestStatus;
  wijmoReportLoading: RequestStatus;
}

const initialReportState = {
  reports: [],
  reportCategories: {},
  toView: "",
  reportsLoading: "idle",
  settingReport: "idle",
  wijmoReportLoading: "idle",
};

const initialState = initialReportState as ReportState;

export const fetchReports = createAsyncThunk<
  { success: boolean; results: Report[] },
  { controller: AbortController },
  { dispatch: AppDispatch }
>("report/fetchReports", async (payload, thunkAPI) => {
  const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN);
  if (token) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .get(`/rcm/reports/getReportList`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() => thunkAPI.dispatch(fetchReports(payload)))
          );
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
});

export const sendSaveFavoriteReport = createAsyncThunk<
  { tokenExpired?: boolean; success: boolean; results: { RecordID: string }[] },
  { ReportID: string; IsFavorite: boolean; controller: AbortController },
  { dispatch: AppDispatch }
>("report/saveFavoriteReport", async (payload, thunkAPI) => {
  const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN);
  if (token) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .post(`/rcm/reports/saveFavorite`, payload)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() =>
              thunkAPI.dispatch(sendSaveFavoriteReport(payload))
            )
          );
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
});

export const setReportToView = createAsyncThunk<any, any>(
  'report/setReportToView',
  (payload) => {
    return payload
  }
)

export const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    updateWijmoLoading(state, action) {
      state.wijmoReportLoading = action.payload ? "pending" : "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportToView.pending, (state) => {
      state.settingReport = "pending";
    });
    builder.addCase(setReportToView.fulfilled, (state, action) => {
      if (action.payload) {
        state.toView = action.payload
        state.settingReport = "succeeded";
      }
    });
    builder.addCase(fetchReports.pending, (state) => {
      state.reportsLoading = "pending";
    });
    builder.addCase(fetchReports.rejected, (state) => {
      state.reportsLoading = "rejected";
    });
    builder.addCase(fetchReports.fulfilled, (state, action) => {
      if (action.payload.success) {
        const reportCategories: {
          [index: string]: {
            ReportCategoryName: string;
            ReportCategoryID: string;
          };
        } = {};
        action.payload.results.forEach((rep) => {
          if (reportCategories[rep.ReportCategoryName]) {
            return;
          } else {
            reportCategories[rep.ReportCategoryName] = {
              ReportCategoryName: rep.ReportCategoryName,
              ReportCategoryID: rep.ReportCategoryID,
            };
          }
        });
        state.reportCategories = reportCategories;
        state.reports = action.payload.results;
        state.reportsLoading = "succeeded";
      } else {
        state.reportsLoading = "rejected";
      }
    });
    builder.addCase(sendSaveFavoriteReport.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.reports.forEach((rep: Report) => {
          if (rep.ReportID === action.meta.arg.ReportID) {
            rep.IsFavorite = action.meta.arg.IsFavorite;
          }
        });
      }
    });
  },
});

export const { updateWijmoLoading } = reportSlice.actions;
export default reportSlice.reducer;
