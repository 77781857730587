import { useRef } from 'react'
import { Close } from '@mui/icons-material'
import { ModalBackground } from '../../modals/modalStyles'
import { useOutsideClick } from '../../../utils/hooks'
import { DenialsGrid, DenialsModalContainer, DenialsModalHeader, DenialsModalTitle, ModalCloseIconWrapper, ModalBlock, ServiceBlock, DenialsModalContent } from './claimStyles'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { updatePatientModal } from '../patientSlice'

const ReasonsRemarksModal = () => {
  const dispatch = useAppDispatch()
  const mobile = useAppSelector(state => state.nav.mobile)
  const { modalLevel, modalContent } = useAppSelector(state => state.patients)

  const modalRef = useRef()

  useOutsideClick(modalRef, () => {
    dispatch(updatePatientModal({ modalOpen: false }))
  })

  const renderTitle = () => {
    if (modalLevel === 'service-reasons') return <DenialsModalTitle>{modalContent.CPTDescription + ' (' + modalContent.CPTCode + ') Reason Codes'}</DenialsModalTitle>

    const claimNum = 'Claim ' + modalContent.ClaimNumber
    const title = `${modalLevel.includes('remarks') ? 'Remarks' : ''}${modalLevel === 'remarks-reasons' ? ' and ' : ''}${modalLevel === 'remarks-reasons' ? 'Reason Codes' : ''}`

    return (
      <DenialsModalTitle>
        {claimNum} {title}
      </DenialsModalTitle>
    )
  }

  const renderContent = () => {
    if (modalLevel === 'remarks-reasons') {
      return (
        <>
          <h3>Claim Remarks</h3>
          {modalContent.content.length
            ? modalContent.content.map((remark, i) => (
                <div style={{ marginTop: '2rem' }} key={i}>
                  <DenialsGrid style={{ marginBottom: '1.5rem' }} mobile={mobile}>
                    <p>{remark.RemarkCategoryDescription}</p>
                    <p>{remark.RemarkCode}</p>
                    <p style={{ gridColumn: mobile ? 'span 2' : 'auto' }}>{remark.RemarkDescription}</p>
                  </DenialsGrid>
                </div>
              ))
            : null}
          {modalContent.specimens.map(specimen => {
            if (specimen.denialCount) {
              return (
                <ModalBlock>
                  <h3>Accession {specimen.SpecimenNumber}</h3>
                  {specimen.services.map((service, i) => {
                    if (service.denials.length) {
                      return (
                        <ServiceBlock key={i}>
                          <h4>
                            {service.CptDescription} ({service.CptCode}) Reason Codes
                          </h4>
                          <DenialsGrid mobile={mobile} noBorder>
                            {service.denials.map(denial => (
                              <>
                                <p>{denial.DenialCategoryDescription}</p>
                                <p>{denial.DenialAbbreviation}</p>
                                <p style={{ gridColumn: mobile ? 'span 2' : 'auto' }}>{denial.DenialCommentDescription}</p>
                              </>
                            ))}
                          </DenialsGrid>
                        </ServiceBlock>
                      )
                    }
                    return null
                  })}
                </ModalBlock>
              )
            }
            return null
          })}
        </>
      )
    } else if (modalLevel === 'remarks') {
      return (
        <DenialsGrid style={{ marginTop: '2rem', marginBottom: '1.5rem' }} mobile={mobile}>
          {modalContent.content.map(remark => (
            <>
              <p>{remark.RemarkCategoryDescription}</p>
              <p>{remark.RemarkCode}</p>
              <p style={{ gridColumn: mobile ? 'span 2' : 'auto' }}>{remark.RemarkDescription}</p>
            </>
          ))}
        </DenialsGrid>
      )
    } else if (modalLevel === 'accession-reasons') {
      return modalContent.specimens.map(specimen => {
        if (!specimen.denialCount) return null
        return (
          <ModalBlock>
            <h3>Accession {specimen.SpecimenNumber}</h3>
            {specimen.services.map(service => {
              if (!service.denials.length) return null
              return (
                <ServiceBlock>
                  <h4>
                    {service.CptDescription} ({service.CptCode}) Reason Codes
                  </h4>
                  <DenialsGrid mobile={mobile}>
                    {service.denials.map(denial => (
                      <>
                        <p>{denial.DenialCategoryDescription}</p>
                        <p>{denial.DenialAbbreviation}</p>
                        <p style={{ gridColumn: mobile ? 'span 2' : 'auto' }}>{denial.DenialCommentDescription}</p>
                      </>
                    ))}
                  </DenialsGrid>
                </ServiceBlock>
              )
            })}
          </ModalBlock>
        )
      })
    } else if (modalLevel === 'service-reasons') {
      return (
        <DenialsGrid mobile={mobile}>
          {modalContent.content.map(denial => (
            <>
              <p>{denial.DenialCategoryDescription}</p>
              <p>{denial.DenialAbbreviation}</p>
              <p style={{ gridColumn: mobile ? 'span 2' : 'auto' }}>{denial.DenialCommentDescription}</p>
            </>
          ))}
        </DenialsGrid>
      )
    }
  }

  return (
    <ModalBackground>
      <DenialsModalContainer ref={modalRef} mobile={mobile}>
        <DenialsModalContent>
          <DenialsModalHeader>
            {renderTitle()}
            <ModalCloseIconWrapper style={{ width: 'fit-content' }}>
              <Close onClick={() => dispatch(updatePatientModal({ modalOpen: false }))} sx={{ fontSize: '1.5rem' }} />
            </ModalCloseIconWrapper>
          </DenialsModalHeader>
          <div style={{ overflowY: 'auto', height: '100%', width: '100%' }}>{renderContent()}</div>
        </DenialsModalContent>
      </DenialsModalContainer>
    </ModalBackground>
  )
}

export default ReasonsRemarksModal
