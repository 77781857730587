import { faArrowCircleLeft, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as wjChart from '@grapecity/wijmo.react.chart'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import { useRef } from 'react'
import { UnstyledButton } from '../../../styles/buttonStyles'
import { DashCardHeader } from '../dashboardStyles'
import CustomTooltip from '../../misc/CustomTooltip'

const AgingAnalysisMobile = ({
  formatYLabels,
  formatXLabels,
  buildSeries,
  showData,
  header,
  bindingX,
  bucket,
  selectionChanged,
  calculateYAxisMax,
  goToReport,
  reportName,
}) => {
  const chartRef = useRef(null)

  const calcMarginLeft = () => {
    if (bindingX === 'PayorDescription' && showData.length > 0 && Object.keys(showData[0]).includes('PayorDescription')) {
      const insuranceLabels = showData.map(data => formatXLabels(null, { text: data?.PayorDescription }).text)
      const longestStr = Math.max(...insuranceLabels.map(label => label.length || 0))
      return 25 + longestStr * 8
    }
    return 60
  }

  const calcMarginBottom = () => {
    if (bindingX === 'PayorDescription' && showData.length > 0 && Object.keys(showData[0]).includes('PayorDescription')) {
      return 30
    } else if (bindingX === 'AgingBucket' && showData.length > 0 && Object.keys(showData[0]).includes('AgingBucket')) {
      let insurances = []
      showData.forEach(bucket => {
        Object.keys(bucket).forEach(key => {
          if (insurances.includes(key)) return
          insurances.push(key)
        })
      })
      return 40 + insurances.length * 10
    }
    return 40
  }

  return (
    <>
      <DashCardHeader>
        {bindingX === 'AgingBucket' ? null : (
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            style={{ position: 'absolute', left: '10px', cursor: 'pointer' }}
            size="lg"
            onClick={() => selectionChanged(chartRef.current.control)}
          />
        )}
        <h4>{bucket ? `${header}: Days ${bucket}` : `${header} by Days`}</h4>
      </DashCardHeader>
      <wjChart.FlexChart
        ref={chartRef}
        bindingX={bindingX}
        selectionMode="Point"
        selectionChanged={e => selectionChanged(e)}
        itemsSource={showData}
        stacking="Stacked"
        chartType="Bar"
        plotMargin={`0 40 ${calcMarginBottom()} ${calcMarginLeft()}`}
      >
        <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
        {buildSeries()}
        <wjChart.FlexChartAxis
          wjProperty="axisY"
          binding="AgingBucket"
          itemFormatter={(eng, label) => formatXLabels(eng, label)}
          reversed={bindingX === 'PayorDescription'}
        ></wjChart.FlexChartAxis>
        <wjChart.FlexChartAxis
          wjProperty="axisX"
          max={calculateYAxisMax()}
          majorUnit={calculateYAxisMax() / 10}
          itemFormatter={(engine, label) => formatYLabels(engine, label)}
        ></wjChart.FlexChartAxis>
        <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </>
  )
}

export default AgingAnalysisMobile
