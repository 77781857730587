import React from "react";
import {
  LoginTipH3,
  LoginTipH4,
  LoginTipLI,
  LoginTipP,
} from "../../login/loginStyles";

const LoginTip = ({ tip }) => {
  return (
    <div style={{ display: "inline", width: '100%' }}>
      {tip?.title                     ? <LoginTipH3>{tip?.title}</LoginTipH3>                                 : null}
      {tip?.description               ? <LoginTipP desc={tip?.description}>{tip?.description}</LoginTipP>     : null}
      {tip?.description2              ? <LoginTipP desc={tip?.description2}>{tip?.description2}</LoginTipP>   : null}
      {tip?.prerequirements           ? <LoginTipH4>{tip?.prerequirements}</LoginTipH4>                       : null}
      {tip?.requirements?.length > 0  ? (
        <ul>
          {tip.requirements.map((requirement, i) => (
            <LoginTipLI id={`${i}`} key={i}>
              {requirement}
            </LoginTipLI>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default LoginTip