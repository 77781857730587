import styled from 'styled-components'

export const SplashPageDiv = styled.div`
    position: absolute;
    z-index: 2003;
    height: 100%;
    min-height: 400px;
    width: 100%;
    background-image: linear-gradient(69.64503881506613deg, #692c87 20.797354163717554%, #7ed1e7 85.20262009857542%);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: #FFFFFF;
`

export const CloseIconWrapper = styled.div<{
  mobile: boolean
}>`
  color: #FFF;
  position: absolute;
  top: ${props => props.mobile ? '35px' : '15px'};
  right: 30px;
  cursor: pointer;
`;

/* APP COMPONENTS */

export const AppCirclesContainer = styled.div`
  width: 110%;
  margin-left: -30px;
  padding: 2rem 3rem;
  background: #738693;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ClientSearchWrapper = styled.div<{
  mobile: boolean,
  theme: string,
}>`
  height: 50px;
  width: ${props => props.mobile ? '90%' : '50%'};
  margin: ${props => props.mobile ? '20px 1.5rem' : '20px 2rem'};
  padding: 2px;
  border-radius: 30px;
  border: 1px solid ${props => props.theme.restyle.primary};
  background-color: rgba(255, 255, 255, 0.7);
  color: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: center;
  transition: border-color .5s;
`

export const ClientFilterSearch = styled.input`
  height: 50px;
  width: 100%;
  margin: 20px 0;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-family: "WorkSans-Regular", "Work Sans", sans-serif;
  font-size: 14px;
  color: #03263c;
  text-align: left;
  :focus {
    outline: none;
  }
`

export const SplashpageWelcome = styled.h1`
  margin: 50px;
  font-size: 40px;
  font-family: "Lato-Regular", "Lato", sans-serif;
  font-weight: 400;
  text-align: center;
  line-height: 52px;
`;