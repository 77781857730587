import axios from 'axios'
import { endSession, getCookie, resetRefresh, setCookie } from './security';

export const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    withCredentials: false,
    cancelToken: new CancelToken(function (cancel) {
    }),
    // headers: {
    //   "token-exp": "10s",
    // }
});

export const protectedApi = (controller, cookie) => axios.create({
    baseURL:  process.env.REACT_APP_API_URL,
    timeout: 90000,
    headers: { 
        "Authorization": `Bearer ${cookie}`,
        "client-connection": sessionStorage.getItem('ClientConnectionID'),
        // "token-exp": "15s",
        },
    signal: controller.signal
})

export const refreshToken = (cookie) => axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}auth/token`,
    timeout: 60000,
    headers: {
        "Authorization": `Bearer ${cookie}`,
        // "token-exp": "15s",
    }
})

export const getRefreshToken = async (fn) => {
  refreshToken(getCookie(process.env.REACT_APP_REFRESH_TOKEN)).get()
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        setCookie(process.env.REACT_APP_ACCESS_TOKEN, data.AccessToken)
        resetRefresh()
        if (typeof fn === 'function') { fn() }
      } else if (!data.success) {
        endSession('Your session has expired. Please log in again.')
        // store.dispatch({type: 'LOGOUT'})
      } else {
        endSession('An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.')
        // store.dispatch({type: 'LOGOUT'})
      }
    })
    .catch(error => {
      endSession('An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.')
    }
  )
}

// ------------------ LOGIN API CALLS ----------------- //

export const submitResetPassword = async (payload) => {
    try{
        const response = await protectedApi(getCookie(process.env.REACT_APP_RESET_PASSWORD_TOKEN)).post('/auth/resetPassword', payload)
        if(response.data.success){
            return { success: response.data.success, message: 'Password Successfully Changed! You will be redirected to login.' }
        }else{
            return { success: response.data.success, message: response.data.message }
        }
    }catch(error){
        throw error
    }
}

