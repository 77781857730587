import styled from 'styled-components'
import { useState, useEffect } from 'react'

const StyledDotLoader = styled.div`
  width: ${(props) => props.width || '100px'};
  height: ${(props) => props.height || '100px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;

  & p {
    width: ${(props) => props.dotSize || '15px'};
    height: ${(props) => props.dotSize || '15px'};
    background: #692c87;
    border-radius: 100%;
    box-shadow: 0px 2px 0px #181d24;
    animation: bounce 1s ease-in-out infinite;
  }

  & p:nth-of-type(1) {
    animation-delay: 0s;
  }
  & p:nth-of-type(2) {
    animation-delay: 0.25s;
  }
  & p:nth-of-type(3) {
    animation-delay: 0.5s;
  }
  & p:nth-of-type(4) {
    animation-delay: 0.75s;
  }
  & p:nth-of-type(5) {
    animation-delay: 1s;
  }

  @keyframes bounce {
    50% {
      transform: translateY(100%);
      background: #1a5192;
      box-shadow: 0px 6px 0px #181d24;
    }
  }
`

const DotLoader = (props) => {
  const { isLoading, lifespan } = props
  const [minLifespanReached, setMinLifespanReached] = useState(false)

  useEffect(() => {
    let timer
    timer = setTimeout(() => {
      setMinLifespanReached(true)
    }, lifespan * 1000)
    return () => clearTimeout(timer)
  }, [isLoading, lifespan])

  const loader = (
    <StyledDotLoader {...props}>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
    </StyledDotLoader>
  )

  // if content loading
  if (isLoading) return loader
  // if content is not loading, and minimum lifespan has not been reached
  if (!isLoading && !minLifespanReached) return loader
  // if content is not loading, and minimum lifespan has been reached
  if (!isLoading && minLifespanReached) return null
  return null
}

export default DotLoader
