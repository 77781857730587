/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import {
  BalanceGrid,
  BalanceContainer,
  Arrow,
  BalanceHeader,
  BalanceGroup,
  BalanceDiv,
  BalGridRowHeader,
  BGridData,
  InsurancePendingDiv,
  PatientResponsibilityDiv,
  BalanceSummary,
  CollapseIconWrapper,
  PatientResponsibilityDivSummary,
  InsurancePendingDivSummary,
  MobileBalanceGrid,
  BalanceGroupMobile,
} from './patientInfoStyles'

import DotLoader from '../misc/dotLoader'
import { convertNumToCurrency, isNotEmpty } from '../../utils/helperMethods'
import { useAppSelector } from '../../redux/store'
import { FlexWrapper } from '../../styles/viewerStyles'
import ExpandMore from '@mui/icons-material/ExpandMore'

const PatientARSummary = () => {
  const [collapsible, setCollapsible] = useState(false)
  const [expandSummary, toggleSummary] = useState(false)
  const [insuranceTotal, setInsuranceTotal] = useState(null)
  const [patientTotal, setPatientTotal] = useState(null)
  const [collectionsTotal, setCollectionsTotal] = useState(null)
  const [collections, setCollections] = useState(null)

  const accounts = useAppSelector(state => state.patients.currentPatient.patientAR)
  const patientARLoaded = useAppSelector(state => state.patients.currentPatient.patientARLoaded)
  const { mobile, tablet, portableDevice } = useAppSelector(state => state.nav)

  // INITIALIZING TOTALS
  useEffect(() => {
    setInsuranceTotal(accounts?.Insurance?.find(obj => obj.BucketName === 'Total').Total)
    setPatientTotal(accounts?.Patient?.find(obj => obj.BucketName === 'Total').Total)
    setCollectionsTotal(accounts?.Collections?.find(obj => obj.BucketName === 'Total').Total)
  }, [accounts])

  useEffect(() => {
    // returns true if Patient Balance has been sent to collections
    setCollections(accounts?.Collections && collectionsTotal > 0)
  }, [accounts.Collections, collectionsTotal])

  // INITIALIZING GUI
  useEffect(() => {
    if (insuranceTotal || patientTotal || collectionsTotal) toggleSummary(true)
    if (insuranceTotal || patientTotal || collectionsTotal) setCollapsible(true)
  }, [insuranceTotal, patientTotal, collectionsTotal])

  const buildBalanceData = (account, row) => {
    if (account[0].BalanceType !== 'Collections') {
      return account.map((obj, i) => {
        const gridArea = `${row}${i + 2}`
        if (obj.BucketName !== 'Total') {
          // if this is the 91+ bucket then there is no right border
          let style = i === 3 ? { gridArea: gridArea, borderRight: 'none' } : { gridArea: gridArea, borderRight: '1px solid var(--color-accent-alsogrey)' }
          return (
            <BGridData key={gridArea} style={style} tablet={tablet}>
              <p>{convertNumToCurrency(obj.Total)}</p>
            </BGridData>
          )
        }
      })
    } else {
      return account.map((obj, i) => {
        const gridArea = `${row}${i + 2}`
        let style
        // check if there is a Collections Balance
        if (obj.Total > 0) {
          // if there is a Collections Balance the text will be red
          // if this is the 91+ bucket then there is no right border
          style =
            i === 3
              ? { gridArea: gridArea, color: 'var(--color-accent-red)', borderRight: 'none' }
              : {
                  gridArea: gridArea,
                  color: 'var(--color-accent-red)',
                  borderRight: '1px solid var(--color-accent-alsogrey)',
                }
        } else {
          // if there is no Collection Balance the text will be the normal grey
          // if this is the 91+ bucket then there is no right border
          style = i === 3 ? { gridArea: gridArea, borderRight: 'none' } : { gridArea: gridArea, borderRight: '1px solid var(--color-accent-alsogrey)' }
        }
        if (obj.BucketName !== 'Total') {
          return (
            <BGridData
              key={gridArea}
              style={{
                ...style,
                borderTop: '1px solid var(--color-accent-alsogrey)',
              }}
              tablet={tablet}
            >
              <p>{convertNumToCurrency(obj.Total)}</p>
            </BGridData>
          )
        }
      })
    }
  }

  const buildBalanceDataMobile = (account, row) => {
    return account.map((obj, i) => {
      const gridArea = `${row}${i + 2}`
      if (obj.BucketName !== 'Total') {
        return (
          <BGridData key={gridArea} style={{ gridArea }} mobile>
            <p>{convertNumToCurrency(obj.Total)}</p>
          </BGridData>
        )
      }
    })
  }

  const buildPatientResponsibilityData = () => {
    if (!collections) {
      return accounts.Patient.map((obj, i) => {
        const gridArea = `Ca${i + 2} / Ca${i + 2} / Cb${i + 2} / Cb${i + 2} `
        if (obj.BucketName !== 'Total') {
          const style = i === 3 ? { gridArea: gridArea, borderRight: 'none' } : { gridArea: gridArea, borderRight: '1px solid var(--color-accent-alsogrey)' }
          return (
            <BGridData key={gridArea} style={style} tablet={tablet}>
              <p>{convertNumToCurrency(obj.Total)}</p>
            </BGridData>
          )
        }
      })
    } else {
      return (
        <>
          {buildBalanceData(accounts.Patient, 'Ca')}
          {buildBalanceData(accounts.Collections, 'Cb')}
        </>
      )
    }
  }

  const buildPatientResponsibilityDataMobile = () => {
    return (
      <>
        {buildBalanceDataMobile(accounts.Patient, 'C')}
        {collections ? (
          <>
            <BalGridRowHeader style={{ gridArea: 'D1' }} mobile>
              <h3>In Collections</h3>
            </BalGridRowHeader>
            {buildBalanceDataMobile(accounts.Collections, 'D')}
          </>
        ) : null}
      </>
    )
  }

  const findPatientResponsibilityTotal = () => {
    let total = patientTotal + collectionsTotal
    if (collections) {
      return <p>{convertNumToCurrency(total)}</p>
    } else {
      return <p>{convertNumToCurrency(total)}</p>
    }
  }

  const renderBalanceGrid = () => {
    return mobile ? (
      <MobileBalanceGrid collections={collections}>
        <BalGridRowHeader style={{ gridArea: 'A1' }} mobile>
          <h3>Insurance Pending</h3>
        </BalGridRowHeader>
        {buildBalanceDataMobile(accounts.Insurance, 'A')}
        <InsurancePendingDiv style={{ gridArea: 'A6', padding: '0 .2rem' }}>
          <h4>Insurance Pending</h4>
          <p>{convertNumToCurrency(insuranceTotal)}</p>
        </InsurancePendingDiv>
        <BalanceGroupMobile style={{ gridArea: 'B2' }}>0-30</BalanceGroupMobile>
        <BalanceGroupMobile style={{ gridArea: 'B3' }}>31-60</BalanceGroupMobile>
        <BalanceGroupMobile style={{ gridArea: 'B4' }}>61-90</BalanceGroupMobile>
        <BalanceGroupMobile style={{ gridArea: 'B5' }}>91+</BalanceGroupMobile>
        <BalGridRowHeader style={{ gridArea: 'C1' }} mobile>
          <h3>Patient Responsibility</h3>
        </BalGridRowHeader>
        {buildPatientResponsibilityDataMobile()}
        <PatientResponsibilityDiv collections={collections} style={{ gridArea: 'C6', padding: '0 .2rem' }}>
          <h4>Due from Patient</h4>
          {collections ? <p>{convertNumToCurrency(patientTotal + collectionsTotal)}</p> : <p>{convertNumToCurrency(patientTotal)}</p>}
        </PatientResponsibilityDiv>
      </MobileBalanceGrid>
    ) : (
      <BalanceGrid collections={collections}>
        <BalanceGroup style={{ gridArea: 'A1' }}></BalanceGroup>
        <BalanceGroup style={{ gridArea: 'A2' }}>0-30</BalanceGroup>
        <BalanceGroup style={{ gridArea: 'A3' }}>31-60</BalanceGroup>
        <BalanceGroup style={{ gridArea: 'A4' }}>61-90</BalanceGroup>
        <BalanceGroup style={{ gridArea: 'A5' }}>91+</BalanceGroup>
        <BalanceGroup style={{ gridArea: 'A6' }}></BalanceGroup>

        <BalGridRowHeader style={{ gridArea: 'B1' }} tablet={tablet}>
          <h3>Insurance Pending</h3>
        </BalGridRowHeader>
        {buildBalanceData(accounts.Insurance, 'B')}
        <Arrow style={{ gridArea: 'B6', display: 'none' }}></Arrow>
        {collections ? (
          <>
            <BalGridRowHeader
              style={{
                gridArea: 'C1',
                marginBottom: '1px',
              }}
              tablet={tablet}
            >
              <h3>Patient Responsibility</h3>
            </BalGridRowHeader>
            <BalGridRowHeader
              style={{
                gridArea: 'C1a',
                marginTop: '1px',
              }}
              tablet={tablet}
            >
              <h3>In Collections</h3>
            </BalGridRowHeader>
          </>
        ) : (
          <BalGridRowHeader style={{ gridArea: 'C1 / C1 /C1a / C1a' }} tablet={tablet}>
            <h3>Patient Responsibility</h3>
          </BalGridRowHeader>
        )}
        {buildPatientResponsibilityData()}
        <Arrow style={{ gridArea: 'C6', display: 'none' }}></Arrow>
        <InsurancePendingDiv style={{ gridArea: 'B7' }}>
          <p style={{ fontSize: tablet ? '.6rem' : '.8rem' }}>Insurance Pending</p>
          <p>{convertNumToCurrency(insuranceTotal)}</p>
        </InsurancePendingDiv>
        <PatientResponsibilityDiv collections={collections} style={{ gridArea: 'C7' }}>
          <p style={{ fontSize: tablet ? '.6rem' : '.8rem' }}>Due from Patient</p>
          {findPatientResponsibilityTotal()}
        </PatientResponsibilityDiv>
      </BalanceGrid>
    )
  }

  const renderARSummary = () => {
    // Render grid or summary view
    return expandSummary ? (
      renderBalanceGrid()
    ) : (
      <BalanceSummary mobile={mobile} tablet={tablet}>
        <InsurancePendingDivSummary mobile={portableDevice}>
          <p>Insurance Pending</p>
          <p>{convertNumToCurrency(insuranceTotal)}</p>
        </InsurancePendingDivSummary>
        <PatientResponsibilityDivSummary collections={collections} mobile={portableDevice}>
          <p>Due from Patient</p>
          {findPatientResponsibilityTotal()}
        </PatientResponsibilityDivSummary>
      </BalanceSummary>
    )
  }

  return (
    <BalanceDiv expanded={expandSummary} mobile={mobile} tablet={tablet} collections={collections}>
      <BalanceHeader className="flexRow" onClick={() => (collapsible ? toggleSummary(!expandSummary) : null)} mobile={mobile} tablet={tablet}>
        <h3>PATIENT A/R SUMMARY</h3>
        {collapsible ? (
          <CollapseIconWrapper expanded={expandSummary} style={{ marginLeft: '0.25rem' }}>
            <ExpandMore />
          </CollapseIconWrapper>
        ) : null}
      </BalanceHeader>
      <BalanceContainer flexStart={tablet && !expandSummary}>
        {accounts && isNotEmpty(accounts) ? (
          renderARSummary()
        ) : (
          <FlexWrapper>
            {patientARLoaded === 'rejected' ? (
              <h3 style={{ fontWeight: 400 }}>There is an issue with this patient's account.</h3>
            ) : (
              <DotLoader isLoading={true} width="200px" dotSize="25px" />
            )}
          </FlexWrapper>
        )}
      </BalanceContainer>
    </BalanceDiv>
  )
}

export default PatientARSummary
