import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { RequestStatus } from '../../../type'
import DotLoader from '../misc/dotLoader'
import { PermissionSearchWrapper, PermissionTable, PermissionUserSearch } from './permissionStyles'
import UserPermissionRow from './UserPermissionRow'
import { useViewport } from '../../utils/hooks'
import { useAppSelector } from '../../redux/store'
import { ErrorWrapper } from '../settings/settingsStyles'
import { ErrorMessage } from '../../components/modals/modalStyles'

interface Props {
    users: any[],
    fetchingUserPermissions: RequestStatus,
    selected: string
}

const PermissionsTableContainer = (props: Props) => {

    const [searchTerm, setSearchTerm] = useState('')
    const error = useAppSelector(state => state.permissions.error)
  
    const { width } = useViewport()

    const buildUsers = () => {
        if(!!searchTerm){
            const filteredUsers = props.users.filter((user:any)=> user.UserName.toLowerCase().includes(searchTerm.toLowerCase()))
            return filteredUsers.map(user => {
              return <UserPermissionRow key={`${user.UserID}:${user.UserName}`} user={user} width={width} />
            })
        }else{
            return props.users.map(user => {
                return <UserPermissionRow key={`${user.UserID}:${user.UserName}`} user={user} width={width} />
            })
        }
    }

  const displayPermissionsErrors = () => {
      return error ? (
        <ErrorWrapper style={{marginBottom: '10px'}}>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorWrapper>
      ) : null 
    }

    return (
        <PermissionTable>
          <div style={{display: 'flex', justifyContent:'center', flexFlow: 'column'}}>
          {props.fetchingUserPermissions === 'succeeded' ? 
          <>
            <h2>{props.selected} Permissions</h2>
              {displayPermissionsErrors()}  
              <PermissionSearchWrapper>
                <FontAwesomeIcon icon={faSearch} />
                   <PermissionUserSearch
                    type='text'
                    onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setSearchTerm(e.target.value)}
                    value={searchTerm}
                 />
              </PermissionSearchWrapper>
          </>
          : null}
          {props.fetchingUserPermissions === 'rejected' ? 
            <h1> You don't have access to view permissions </h1> : null}
          </div>
          {props.fetchingUserPermissions !== 'pending' ? buildUsers() : <DotLoader isLoading={true} width="250px" height="200px" dotSize="30px" />}
        </PermissionTable>
    )
}

export default PermissionsTableContainer
