import React, { useState, useEffect } from 'react'
import { DashCard } from '../dashboardStyles'
import * as wjChart from '@grapecity/wijmo.react.chart'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import BarChartPlaceholder from '../placeholders/BarChartPlaceholder'
import BrokenChart from '../placeholders/BrokenChart'

const SalesRepPerformanceChartContainer = ({ salesRepData, mobile, formatYLabels, status, toggleDataFetched }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (status.fetched) {
      setLoading(false)
    }
    return () => {}
  }, [status])

  const calculateYAxisMax = () => {
    // get largest amount from array of collection objects
    const largestNum = Math.ceil(
      salesRepData
        .map(data => data.SalesTotal)
        .sort((a, b) => a - b)
        .pop()
    )
    // get the place value of left most num
    const tens = parseInt(
      largestNum
        .toString()
        .split('')
        .map((num, i) => (i === 0 ? '1' : '0'))
        .join('')
    )
    // round to next 1eX where X equals the left most place value
    return Math.ceil(largestNum / tens) * tens
  }

  const calculateRightYAxisMax = () => {
    const largestNum = Math.ceil(
      salesRepData
        .map(data => data.SpecimenCount)
        .sort((a, b) => a - b)
        .pop()
    )
    // get the place value of left most num
    const tens = parseInt(
      largestNum
        .toString()
        .split('')
        .map((num, i) => (i === 0 ? '1' : '0'))
        .join('')
    )
    // round to next 1eX where X equals the left most place value
    return Math.ceil(largestNum / tens) * tens
  }

  const buildChart = () => {
    return (
      <>
        <h4>Sales Rep Performance</h4>
        <wjChart.FlexChart bindingX="SalesRepID" itemsSource={salesRepData} palette={['#16B671', '#2F6C93']}>
          <wjChart.FlexChartSeries
            name="Sales Total"
            binding="SalesTotal"
            cssClass="bar"
            // eslint-disable-next-line no-template-curly-in-string
            tooltipContent="${y}"
            style={{ fill: '#16B671' }}
          ></wjChart.FlexChartSeries>
          <wjChart.FlexChartSeries name="Specimen Count" binding="SpecimenCount" chartType="Line" tooltipContent="{y}">
            <wjChart.FlexChartAxis wjProperty="axisY" position="Right" axisLine={false} min={0} max={calculateRightYAxisMax()}></wjChart.FlexChartAxis>
          </wjChart.FlexChartSeries>
          <wjChart.FlexChartAxis
            wjProperty="axisY"
            axisLine={false}
            max={calculateYAxisMax()}
            itemFormatter={(eng, label) => formatYLabels(eng, label)}
            origin={-10}
          ></wjChart.FlexChartAxis>
          <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
          <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
        </wjChart.FlexChart>
      </>
    )
  }

  const refreshChart = async () => {
    setLoading(true)
    toggleDataFetched({ name: 'salesRepData', fetched: false, error: false })
  }

  const renderChart = () => {
    if (status.fetched) {
      if (status.error) {
        return <BrokenChart refreshFn={refreshChart} header={'Sales Rep Performance'} chartType={1} />
      } else {
        return buildChart()
      }
    }
  }

  return (
    <DashCard loaded={loading} cardWidth={mobile ? 6 : 2} error={status.error}>
      {loading ? (
        <BarChartPlaceholder cardWidth={mobile ? 6 : 2} />
      ) : (
        <>
          {/* <CustomTooltip tip="Lorem ipsum dolor sit amet." /> */}
          {renderChart()}
        </>
      )}
    </DashCard>
  )
}

export default SalesRepPerformanceChartContainer
