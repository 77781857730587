import React, { useCallback, useEffect } from 'react'
import KPI from './kpi'
import CarouselContainer from '../../misc/carousel/CarouselContainer'

import CarouselPositionIndicator from '../../misc/carousel/CarouselPositionIndicators'
import { PositionIndicatorWrapper } from '../../misc/carousel/carouselStyles'
import { fetchChartData } from '../dashboardSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import DotLoader from '../../misc/dotLoader'
import { KPILoadingContainer } from './kpiStyles'

const KPIContainer = ({ mobile }) => {
  const { data, error, loaded } = useAppSelector(state => state.dashboard.kpis)
  const clientTypeID = useAppSelector(state => state.users.ClientTypeID)
  const switchingClients = useAppSelector(state => state.users.switchingClients)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)

  const dispatch = useAppDispatch()
  const controller = new AbortController()

  const fetchKpiData = useCallback(() => {
    dispatch(fetchChartData({ chart: 'kpis', controller: controller }))
  }, [dispatch])

  useEffect(() => {
    if (ClientConnectionID) {
      fetchKpiData()
    }
  }, [ClientConnectionID])

  const buildKpis = () => {
    let kpiSlides = []
    let dictionary = {
      SpecimenCountToday: {
        header: "Today's Specimen Count",
        position: 5,
        clientType: '4',
        tooltip: 'Specimens transmitted today. May include specimens with incomplete information.',
      },
      SpecimenCountMonthToDate: {
        header: 'Month-to-Date Specimen Count',
        position: 6,
        clientType: '4',
        tooltip: 'Specimens transmitted this month. May include specimens with incomplete information.',
      },
      CollectionsToday: {
        header: "Today's Collections",
        position: 1,
        clientType: 'all',
        tooltip: 'Total collections posted in today’s payment batch. May include pending payments.',
      },
      CollectionsMonthToDate: {
        header: 'Month-to-Date Collections',
        position: 2,
        clientType: 'all',
        tooltip: 'Total collections month to date. May include pending payments.',
      },
      BillingToday: {
        header: "Today's Billings",
        position: 3,
        clientType: 'all',
        tooltip: 'Total charges entered today.',
      },
      BillingMonthToDate: {
        header: 'Month-to-Date Billings',
        position: 4,
        clientType: 'all',
        tooltip: 'Total charges entered for the month.',
      },
    }
    data.forEach(kpi => {
      let kpiSlide
      for (const [key, value] of Object.entries(kpi)) {
        if (dictionary[key].clientType !== 'all') {
          if (dictionary[key].clientType === clientTypeID) {
            kpiSlide = (
              <KPI
                mobile={mobile}
                key={key}
                amount={value}
                header={dictionary[key].header}
                position={dictionary[key].position}
                tip={dictionary[key].tooltip}
                loaded={loaded}
                error={error}
                refreshKPI={fetchKpiData}
              />
            )
            kpiSlides.push(kpiSlide)
          }
        } else {
          kpiSlide = (
            <KPI
              mobile={mobile}
              key={key}
              posted={value.posted}
              pending={value.pending}
              header={dictionary[key].header}
              position={dictionary[key].position}
              tip={dictionary[key].tooltip}
              loaded={loaded}
              error={error}
              refreshKPI={fetchKpiData}
            />
          )
          kpiSlides.push(kpiSlide)
        }
      }
    })
    return kpiSlides.sort((a, b) => a.props.position - b.props.position)
  }

  const buildPositionIndicators = () => {
    let positionArray = []
    for (let i = 0; i < data.length; i++) {
      const positionIndicator = <CarouselPositionIndicator key={`indicator.${i}`} selected={false} />
      positionArray.push(positionIndicator)
    }
    return positionArray
  }

  const buildSlides = () => {
    return data.length > 0 && loaded === 'succeeded' ? (
      <>{buildKpis()}</>
    ) : loaded === 'pending' || switchingClients === 'pending' ? (
      <KPILoadingContainer>
        <DotLoader isLoading={true} />
      </KPILoadingContainer>
    ) : (
      <>
        <KPI loaded={loaded} refreshKPI={fetchKpiData} key="Today's Collections" id="1" type="cash" header="Today's Collections" purpose="collections" />
        <KPI loaded={loaded} refreshKPI={fetchKpiData} key="Month-to-Date Collections" id="2" type="cash" header="Month-to-Date Collections" purpose="collections" />
        <KPI loaded={loaded} refreshKPI={fetchKpiData} key="Today's Billings" id="3" type="cash" header="Today's Billings" purpose="billings" />
        <KPI loaded={loaded} refreshKPI={fetchKpiData} key="Month-to-Date Billings" id="4" type="cash" header="Month-to-Date Billings" purpose="billings" />
        <KPI loaded={loaded} refreshKPI={fetchKpiData} key="Today's Specimen Count" id="5" type="count" header="Today's Specimen Count" purpose="specimens" />
        <KPI loaded={loaded} refreshKPI={fetchKpiData} key="Month-to-Date Specimen Count" id="6" type="count" header="Month-to-Date Specimen Count" purpose="specimens" />
      </>
    )
  }

  const buildCarousel = () => {
    if (loaded === 'pending') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <KPILoadingContainer>
            <DotLoader isLoading={true} />
          </KPILoadingContainer>
          <PositionIndicatorWrapper>{buildPositionIndicators()}</PositionIndicatorWrapper>
        </div>
      )
    } else {
      return <CarouselContainer>{buildKpis()}</CarouselContainer>
    }
  }

  return <>{mobile ? buildCarousel() : buildSlides()}</>
}

export default KPIContainer
