import React, { useCallback } from 'react'
import SiteHeaderSearchContainer from './SiteHeaderSearchContainer'
import AppSidebar from '../AppSidebar'

// Redux
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { setMenu, toggleMobileSidebar } from '../navSlice'

// Styles
import { LogoImageContainer, LogoImage, MobileSiteHeaderContainer, UserMenuWrapper, UserMenuButton } from './SiteHeaderStyles'
import { IconWrapper, MobileNavImage } from '../tabStyles'
import { FlexWrapper } from '../../../styles/viewerStyles'

// Icons
import navLogo from '../../../images/normal_u47.png'
import new_logo_circle from '../../../images/new_logo_circle.png'
import normal_u29 from '../../../images/normal_u29.svg'
import MenuIcon from '@mui/icons-material/Menu'

const MobileSiteHeader = ({ quickSearchAllowed }) => {
  const theme = useAppSelector(state => state.users.ThemeName)
  const UserInitials = useAppSelector(state => state.users.UserInitials)
  const menuActive = useAppSelector(state => state.nav.menuActive)
  const mobileSidebar = useAppSelector(state => state.nav.mobileSidebar)

  const dispatch = useAppDispatch()

  const toggleMenu = useCallback(
    payload => {
      dispatch(setMenu(payload))
    },
    [dispatch]
  )

  const handleMenuClick = e => {
    if (mobileSidebar) return
    e.stopPropagation()
    toggleMenu(!menuActive)
  }

  /* RENDER FUNCTIONS */
  const buildMenuInit = () => {
    if (!menuActive) {
      return (
        <UserMenuButton key="user-menu-button-closed" style={{ margin: 0, width: '24px', height: '24px' }} onClick={handleMenuClick} data-testid="user-initials-menu-button">
          <p>{UserInitials}</p>
        </UserMenuButton>
      )
    } else {
      return (
        <UserMenuButton as="div" key="user-menu-button-open" style={{ margin: 0, width: '24px', height: '24px' }}>
          <p>{UserInitials}</p>
        </UserMenuButton>
      )
    }
  }

  return (
    <>
      <MobileSiteHeaderContainer theme={theme}>
        <FlexWrapper justify="flex-start" onClick={() => dispatch(toggleMobileSidebar(!mobileSidebar))}>
          <MenuIcon style={{ height: '100%', margin: '0 10px', fontSize: '44px', cursor: 'pointer' }} />
        </FlexWrapper>
        <IconWrapper style={{ height: '100%' }}>
          <LogoImageContainer>
            <LogoImage src={normal_u29} alt="circle" style={{ marginBottom: '-46px', marginLeft: '45px', height: '16px', width: '16px' }} />
            <LogoImage src={new_logo_circle} style={{ marginBottom: '-46px', marginLeft: '-15px', height: '14px', width: '14px', zIndex: '1000' }} />
          </LogoImageContainer>
          <FlexWrapper align="center">
            <MobileNavImage src={navLogo} alt="Phytest Logo" />
          </FlexWrapper>
        </IconWrapper>
        <FlexWrapper justify="flex-end">
          {quickSearchAllowed ? <SiteHeaderSearchContainer /> : null}
          <UserMenuWrapper style={{ margin: '0 16px' }}>{buildMenuInit()}</UserMenuWrapper>
        </FlexWrapper>
      </MobileSiteHeaderContainer>
      <AppSidebar />
    </>
  )
}

export default MobileSiteHeader
