import React, { useCallback, useEffect, useState } from 'react'
import { trimSearchInput } from '../../../utils/helperMethods'
import DotLoader from '../../misc/dotLoader'

// Redux
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { fetchAccessibleApps, switchConnections, toggleConnectionSwitcher, updateAppRedirecting } from '../../login/userSlice'

// Styling
import { ClientFilterSearch, ClientSearchWrapper, CloseIconWrapper } from '../../login/splashStyle'
import {
  AppCirclesContainer,
  ClientCirclesContainer,
  ClientCircleWrapper,
  ConnectionSwitcherContainer,
  ConnectionSwitcherCircle,
  ConnectionSwitcherHeader,
  ConnectionSwitcherLabel,
  ConnectionSwitcherAppButton,
  ConnectionSwitcherAppContainer,
} from '../../nav/siteHeader/SiteHeaderStyles'

// Icons
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { FlexWrapper } from '../../../styles/viewerStyles'
import { ErrorWrapper } from '../../settings/settingsStyles'
import { ErrorMessage } from '../../modals/modalStyles'

const ConnectionSwitcher = () => {
  const dispatch = useAppDispatch()

  const [clientFilter, setClientFilter] = useState('')
  const [selectedClient, selectClient] = useState(null)
  const [selectedApp, selectApp] = useState(null)

  const availableClients = useAppSelector(state => state.users.connectionSwitcher.clients)
  const recentClients = useAppSelector(state => state.users.connectionSwitcher.recentClients)
  const selectedClientsApp = useAppSelector(state => state.users.connectionSwitcher.selectedClientApps)
  const userSliceRequests = useAppSelector(state => state.users.requests)
  const Client = useAppSelector(state => state.users.Client)
  const clientsFetched = useAppSelector(state => state.users.requests.fetchAccessibleClients)
  const showConnectionSwitcher = useAppSelector(state => state.users.connectionSwitcher.showConnectionSwitcher)
  const switchingClients = useAppSelector(state => state.users.switchingClients)
  const mobile = useAppSelector(state => state.nav.mobile)
  const theme = useAppSelector(state => state.users.ThemeName)
  const LastAccessedAppID = useAppSelector(state => state.users.Client.LastAccessedAppID)
  const error = useAppSelector(state => state.users.connectionSwitcher.error)

  /* RESETTING CONNECTIONSWITCHER ON TOGGLE */
  useEffect(() => {
    if (!showConnectionSwitcher) {
      if (clientFilter) {
        setClientFilter('')
      }
      if (selectedClient) {
        selectClient(null)
      }
      if (selectedApp) {
        selectApp(null)
      }
    }
  }, [showConnectionSwitcher])

  /* HANDLING CLIENT SELECTION */
  const handleClientSelection = client => {
    dispatch(
      fetchAccessibleApps({
        controller: new AbortController(),
        ClientID: client.ClientID,
      })
    )
    selectClient(client)
  }

  /* HANDLING APP SELECTION */
  const handleAppSelection = (appID, sameClient) => {
    if (appID !== '1') {
      dispatch(updateAppRedirecting(true))
    }
    selectApp(appID)
    if (sameClient) {
      selectClient(sameClient)
    }
  }

  /* SUBMITTING /updateClientConnection */
  const updateClientConnection = useCallback(
    payload => {
      dispatch(
        switchConnections({
          ...payload,
          controller: new AbortController(),
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    if (selectedClient && selectedApp && switchingClients !== 'pending') {
      if (selectedApp === '1' && selectedClient.ClientID === Client.ClientID) {
        dispatch(toggleConnectionSwitcher(false))
        return
      }

      updateClientConnection({
        AppID: selectedApp,
        ClientID: selectedClient.ClientID,
      })
    }
  }, [selectedClient, selectedApp])

  /* RENDERING CIRCLES */
  const buildApps = () => {
    return Client.ClientApps.map(app => (
      <ClientCircleWrapper key={app.AppName}>
        <ConnectionSwitcherCircle
          mobile={mobile}
          onClick={() => handleAppSelection(app.AppID, Client)}
          current={LastAccessedAppID === app.AppID}
          style={{ fontSize: mobile ? '15px' : '18px' }}
        >
          {app.AppName === 'ClientPortal' ? 'Client Portal' : app.AppName}
        </ConnectionSwitcherCircle>
      </ClientCircleWrapper>
    ))
  }

  const renderCircleContent = client => {
    if (selectedClient === client) {
      if (userSliceRequests.fetchAccessibleApps === 'pending' || selectedClientsApp.length === 0) {
        // Dot loader
        return (
          <ConnectionSwitcherAppContainer>
            <DotLoader isLoading={true} />
            {/* ^^^ NEED TO UPDATE THIS to only render if request has taken more time and then, once rendered, take a minimum of X seconds */}
          </ConnectionSwitcherAppContainer>
        )
      } else {
        // Render available apps
        return (
          <ConnectionSwitcherAppContainer>
            {selectedClientsApp
              .filter(app => app.AppName !== 'DualRemit')
              .map(app => (
                <ConnectionSwitcherAppButton onClick={() => handleAppSelection(app.AppID)} key={app.AppName}>
                  {app.AppName}
                </ConnectionSwitcherAppButton>
              ))}
          </ConnectionSwitcherAppContainer>
        )
      }
    } else {
      // Client circle
      return (
        <ConnectionSwitcherCircle current={client.ClientID === Client.ClientID} mobile={mobile} onClick={() => handleClientSelection(client)}>
          {/* Cannot use str.replace() as it's possible to have a lab abbrv include 'CBO' multiple times */}
          {/* e.g., ABC ORG abbreviated 'ACBORGCBO'. If you client.ClientAbbrv.replace('CBO', ' CBO') the result would be 'A CBORGCBO'*/}
          {client.ClientName.includes(' - CBO') && client.ClientAbbrv.slice(client.ClientAbbrv.length - 3) === 'CBO'
            ? client.ClientAbbrv.slice(0, client.ClientAbbrv.length - 3) + ' CBO'
            : client.ClientAbbrv}
        </ConnectionSwitcherCircle>
      )
    }
  }

  const buildClients = section => {
    let availableClientsByApp = section === 'recent' ? recentClients : availableClients

    if (clientFilter) {
      availableClientsByApp = availableClientsByApp.filter(
        client => client.ClientName.toLowerCase().includes(clientFilter) || client.ClientAbbrv.toLowerCase().includes(clientFilter)
      )
    }

    return availableClientsByApp.map(client => (
      <ClientCircleWrapper key={`connection-switcher-${client.ClientAbbrv}${section === 'recent' ? '-recent' : ''}`}>
        {renderCircleContent(client)}
        <ConnectionSwitcherLabel mobile={mobile} onClick={() => handleClientSelection(client)}>
          {client.ClientName}
        </ConnectionSwitcherLabel>
      </ClientCircleWrapper>
    ))
  }

  /* MAIN RENDERING STATEMENT */
  return (
    <ConnectionSwitcherContainer className={showConnectionSwitcher ? 'switcher-enter' : 'switcher-exit'} mobile={mobile}>
      <CloseIconWrapper mobile={mobile} error={error} onClick={() => dispatch(toggleConnectionSwitcher())}>
        <CloseIcon style={{ fontSize: '30px' }} />
      </CloseIconWrapper>
      {error ? (
        <ErrorWrapper style={{ width: '100%', marginLeft: '-20px' }}>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorWrapper>
      ) : null}
      {/* APPS */}
      {clientsFetched === 'succeeded' && Client.ClientApps.length > 1 ? (
        <AppCirclesContainer mobile={mobile}>
          <ConnectionSwitcherHeader applications mobile={mobile}>
            Applications
          </ConnectionSwitcherHeader>
          <FlexWrapper justify="flex-start" style={{ flexWrap: 'wrap' }}>
            {buildApps()}
          </FlexWrapper>
        </AppCirclesContainer>
      ) : null}
      {/* CLIENT SEARCH */}
      {availableClients.length > 1 ? (
        <ClientSearchWrapper mobile={mobile}>
          <SearchIcon style={{ margin: '0 15px', color: '#373d4d', height: '24px' }} />
          <ClientFilterSearch
            autoComplete="off"
            onChange={e => {
              selectClient(null)
              setClientFilter(trimSearchInput(e.target.value))
            }}
            placeholder="Client Name or Abbreviation"
            type="text"
            name="quicksearch"
            id="quicksearch"
            value={clientFilter}
          />
        </ClientSearchWrapper>
      ) : null}
      {/* RECENT CLIENTS */}
      {clientFilter || availableClients.length < 7 ? null : (
        <>
          <ConnectionSwitcherHeader mobile={mobile}>Recent Clients</ConnectionSwitcherHeader>
          {clientsFetched === 'succeeded' ? <ClientCirclesContainer mobile={mobile}>{buildClients('recent')}</ClientCirclesContainer> : null}
        </>
      )}
      {/* ALL CLIENTS */}
      {availableClients.length > 1 ? (
        <>
          <ConnectionSwitcherHeader mobile={mobile}>{clientFilter ? '' : 'All Clients'}</ConnectionSwitcherHeader>
          {clientsFetched === 'succeeded' ? <ClientCirclesContainer mobile={mobile}>{buildClients()}</ClientCirclesContainer> : null}
        </>
      ) : null}
    </ConnectionSwitcherContainer>
  )
}

export default ConnectionSwitcher
