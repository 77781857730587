import styled from 'styled-components'

export const PageNotFoundContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.restyle.primary};
  transition: color 0.5s;
  box-sizing: border-box;

  h1,
  h2 {
    margin: 0.5rem 0;
    font-weight: 500;
  }

  h1 {
    font-size: 2.5rem;
  }

  svg {
    margin-top: 3rem;
    height: 30vmin;
    width: 30vmin;
    fill: var(--color-primary-lightBlue);
  }

  button {
    margin-top: 5rem;
  }
`
