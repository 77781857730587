import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../type";
import { AppDispatch } from "../../redux/store";
import { protectedApi } from "../../utils/api";
import { deleteCookie, endSession, getCookie } from "../../utils/security";
import { preloginCheck } from "../login/loginSlice";
import { setModal } from "../modals/modalSlice";
import { resetSettingsSlice } from "../settings/settingsSlice";
import { logout, setCookie, fetchRefreshToken } from "../login/userSlice";

interface mFAState {
  initialMFARequest: RequestStatus;
  submitPasscodeRequest: RequestStatus;
  settingsInitiateRequest: RequestStatus;
  passcodeType: null | string;
  passCodeDestination: null | string;
  authCompleted: boolean;
  fpAuthCompleted: boolean;
  stAuthCompleted: boolean;
  error: string;
  criticalError: boolean;
  canAttemptAgain: boolean;
  canGenerateNewPassCode: boolean;
  modalVisible: boolean;
}

const initialmFAState = {
  initialMFARequest: 'idle',
  submitPasscodeRequest: "idle",
  settingsInitiateRequest: 'idle',
  passcodeType: null,
  passCodeDestination: null,
  authCompleted:
  !!getCookie(process.env.REACT_APP_ACCESS_TOKEN) &&
  !!getCookie(process.env.REACT_APP_REFRESH_TOKEN),
  fpAuthCompleted: false,
  stAuthCompleted: false,
  error: '',
  criticalError: false,
  canAttemptAgain: false,
  canGenerateNewPassCode: false,
  modalVisible: false,
};

const initialState = initialmFAState as mFAState;

/* MAIN LOGIN ROUTES */
export const engageMultiFactor = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "mFA/engageMultiFactor",
  async (payload, thunkAPI) => {
    let token = process.env.REACT_APP_LOGIN_TOKEN;

    return protectedApi(payload.controller, getCookie(token))
      .post("/auth/engageMultiFactor", payload)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        thunkAPI.dispatch(setMFAError({
          message: error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : error.response.data.message,
          // must set to false, otherwise the submit button will be disabled and the user will not be able to attempt again
          criticalError: false
        }))
        setTimeout(() => {
          endSession('', true)
        }, 1000 * 3)
        return error.response.data
      })
  }
);

export const submitLoginPasscode = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "mFA/submitLoginPasscode",
  async (payload, thunkAPI) => {
    let token = process.env.REACT_APP_LOGIN_TOKEN;
    return protectedApi(payload.controller, getCookie(token))
      .post(
        "/auth/validatePassCode",
        {passCode: payload.passcode}
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.success && data.preLoginToken) {
          deleteCookie(token);
          thunkAPI.dispatch(
            setCookie({
              name: process.env.REACT_APP_PRELOGIN_TOKEN,
              val: data.preLoginToken,
            })
          );
          thunkAPI.dispatch(preloginCheck({ controller: new AbortController() }))
          return data;
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.status === 500 || error.response.status === 400) { // 500: Error within the server route
          thunkAPI.dispatch(
            setMFAError({
              message: error.response.status === 500 ? error.response.data.message : 'Something went wrong. Please contact support. Redirecting you to login...',
              criticalError: true
            })
          )
          setTimeout(() => {
            endSession()
          }, 1000 * 5)
          return { ...error.response.data, update: false }
        } else if (error.response.status === 401) { // 401: incorrect passcode supplied
            if (error.response.data.canAttemptAgain || error.response.data.canGenerateNewPassCode) { // 401 error and can continue
              return { ...error.response.data, update: true };
            } else { // 401 error and cannot continue
              thunkAPI.dispatch(setMFAError({message: error.response.data.message, criticalError: true}))
              setTimeout(() => {
                endSession()
              }, 1000 * 5)
              return {...error.response.data, update: false}
            }
        } else {
          thunkAPI.dispatch(setMFAError({
            message: error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : error.response.data.message,
            criticalError: true
          }))
          setTimeout(() => {
            endSession()
          }, 1000 * 3)
          return error.response.data
        }
      });
  }
);

/* FORGOT PASSWORD ROUTES */
export const fpEngageMultiFactor = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "mFA/fpEngageMultiFactor",
  async (payload, thunkAPI) => {
    let token = process.env.REACT_APP_FORGOT_PASSWORD_TOKEN;
    
    return protectedApi(payload.controller, getCookie(token))
      .post("/forgotPassword/engageMultiFactor", payload)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        thunkAPI.dispatch(setMFAError({
          message: error.response.data.message,
          criticalError: true
        }))
        setTimeout(() => {
          endSession()
        }, 1000 * 3)
        return error.response.data
      })
  }
);

export const fpSubmitPasscode = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "forgotPassword/fpSubmitPasscode",
  async (payload, thunkAPI) => {
    let token = process.env.REACT_APP_FORGOT_PASSWORD_TOKEN;

    return protectedApi(payload.controller, getCookie(token))
      .post(
        'forgotPassword/validatePasscode', { passCode: payload.passcode })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          thunkAPI.dispatch(
            setCookie({
              name: process.env.REACT_APP_FORGOT_PASSWORD_AUTH_TOKEN,
              val: data.Token,
            })
          );
          return data
        } else {
          throw new Error()
        }
      })
      .catch(error => {
        if (error.response.status === 500) { // 500: Error within the server route
          thunkAPI.dispatch(setMFAError({message: error.response.data.message, criticalError: true}))
          setTimeout(() => {
            endSession()
          }, 1000 * 3)
          return { ...error.response.data, update: false }
        } else if (error.response.status === 401) { // 401: incorrect passcode supplied
            if (error.response.data.canAttemptAgain || error.response.data.canGenerateNewPassCode) { // 401 error and can continue
              return {...error.response.data, update: true};
            } else { // 401 error and cannot continue
              thunkAPI.dispatch(setMFAError({message: error.response.data.message, criticalError: true}))
              setTimeout(() => {
                endSession()
              }, 1000 * 3)
              return {...error.response.data, update: false}
            }
        } else {
          thunkAPI.dispatch(setMFAError({
            message: error.response.data.message,
            criticalError: true
          }))
          setTimeout(() => {
            endSession()
          }, 1000 * 3)
          return error.response.data
        }
      })
  }
)

/* SETTINGS MENU ROUTES */
export const initiateElevatedAuth = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "mFA/initiateElevatedAuth",
  async (payload, thunkAPI) => {
    if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
      let token = process.env.REACT_APP_ACCESS_TOKEN;
      return protectedApi(payload.controller, getCookie(token))
        .get("/users/settings/initiateElevatedAuth")
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            thunkAPI.dispatch(setCookie({
              name: process.env.REACT_APP_ELEVATED_TOKEN,
              val: data.elevatedToken,
            }))
            return data
          } else {
            throw new Error()
          }
        })
        .catch((error) => {
          if (error.response.data.tokenExpired) {
            thunkAPI.dispatch(
              fetchRefreshToken(() =>
                thunkAPI.dispatch(initiateElevatedAuth(payload))
              )
            );
          } else {
            thunkAPI.dispatch(setMFAError({ message: error.response.data.message, criticalError: true }))
            setTimeout(() => {
              // If an error, close modal
              thunkAPI.dispatch(setModal({ key: 'settings', data: false }))
              thunkAPI.dispatch(resetSettingsSlice())
              thunkAPI.dispatch(resetmFA({ saveSecurityAuth: false }))
            }, 3 * 1000)
          }
          return error.response.data
        });
    } else {
      thunkAPI.dispatch(setMFAError({ message: 'Your credentials are expired. Please log in again.', criticalError: true }))
      setTimeout(() => {
        thunkAPI.dispatch(logout({ controller: new AbortController() }));
      }, 5 * 1000)
    }
  }
);

export const elevatedEngageMultiFactor = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "mFA/elevatedEngageMultiFactor", 
  async (payload, thunkAPI) => {
    if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
      let token = process.env.REACT_APP_ELEVATED_TOKEN;

      return protectedApi(payload.controller, getCookie(token))
        .post("/auth/elevated/engageMultiFactor", payload)
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            return data
          } else {
            throw new Error()
          }
        })
        .catch((error) => {
          thunkAPI.dispatch(setMFAError({ message: error.response.data.message, criticalError: true }))
          setTimeout(() => {
            // If an error, close modal
            // Cannot re-initiate elevated auth as it could cause a loop
            deleteCookie(process.env.REACT_APP_ELEVATED_TOKEN)
            thunkAPI.dispatch(setModal({key: 'settings', data: false}))
            thunkAPI.dispatch(resetSettingsSlice())
            thunkAPI.dispatch(resetmFA({ saveSecurityAuth: false }))
          }, 3 * 1000)
          return error.response.data
        })
    } else {
      thunkAPI.dispatch(setMFAError('Your credentials are expired. Please log in again.'))
      setTimeout(() => {
        thunkAPI.dispatch(logout({ controller: new AbortController() }));
      }, 5 * 1000)
    }
  }
);

export const submitElevatedPasscode = createAsyncThunk<any, any, { dispatch: AppDispatch }>(
  "mFA/submitElevatedPasscode",
  async (payload, thunkAPI) => {
    if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
      let token = process.env.REACT_APP_ELEVATED_TOKEN;

      return protectedApi(payload.controller, getCookie(token))
        .post(
          "/auth/elevated/validatePassCode",
          { passCode: payload.passcode }
        )
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            deleteCookie(token)
            thunkAPI.dispatch(setCookie({
              name: process.env.REACT_APP_SECURITY_TOKEN,
              val: data.elevatedToken,
            }))
            return data;
          } else {
            throw new Error()
          }
        })
        .catch((error) => {
          if (error.response.status === 500 || error.response.status === 400) { // 500: Error within the server route
            thunkAPI.dispatch(
              setMFAError({
                message: error.response.status === 500 ? error.response.data.message : 'Something went wrong. Please contact support. Redirecting you to login...',
                criticalError: true
              })
            )
            setTimeout(() => {
              thunkAPI.dispatch(logout({ controller: new AbortController() }))
            }, 1000 * 5)
            return { ...error.response.data, update: false }
          } else if (error.response.status === 401) { // 401: incorrect passcode supplied
            if (error.response.data.canAttemptAgain || error.response.data.canGenerateNewPassCode) { // 401 error and can continue
              return { ...error.response.data, update: true };
            } else if (error.response.data.tokenExpired) { // 401: token expired
              thunkAPI.dispatch(setMFAError({
                message: error.response.data.message,
                criticalError: true
              }))
              setTimeout(() => {
                deleteCookie(process.env.REACT_APP_ELEVATED_TOKEN)
                thunkAPI.dispatch(setModal({key: 'settings', data: false}))
                thunkAPI.dispatch(resetSettingsSlice())
                thunkAPI.dispatch(resetmFA({ saveSecurityAuth: false }))
              }, 1000 * 3)
              return error.response.data
            } else { // 401 error and cannot continue
              thunkAPI.dispatch(setMFAError({ message: 'Passcode attempts exceeded the limit. Please try again. Redirecting you to login...', criticalError: true }))
              setTimeout(() => {
                thunkAPI.dispatch(logout({ controller: new AbortController() }))
              }, 1000 * 5)
              return { ...error.response.data, update: false }
            }
          } else {
            thunkAPI.dispatch(setMFAError({
              message: error.response.data.message,
              criticalError: true
            }))
            setTimeout(() => {
              deleteCookie(process.env.REACT_APP_ELEVATED_TOKEN)
              thunkAPI.dispatch(setModal({key: 'settings', data: false}))
              thunkAPI.dispatch(resetSettingsSlice())
              thunkAPI.dispatch(resetmFA({ saveSecurityAuth: false }))
            }, 1000 * 3)
            return error.response.data
          }
        });
    } else {
      thunkAPI.dispatch(setMFAError('Your credentials are expired. Please log in again.'))
      setTimeout(() => {
        thunkAPI.dispatch(logout({ controller: new AbortController() }));
      }, 5 * 1000)
    }
  }
);

const mFASlice = createSlice({
  name: "mFA",
  initialState: initialState,
  reducers: {
    updateStAuthCompleted(state, action) {
      state.stAuthCompleted = action.payload;
    },
    togglemFAModal(state, action) {
      state.modalVisible = action.payload;
    },
    resetmFA(state, action) {
      // Resets the slice except for state.authCompleted and, depending on payload, stAuthCompleted
      state.initialMFARequest = 'idle';
      state.submitPasscodeRequest = "idle";
      state.settingsInitiateRequest = 'idle';
      state.passcodeType = null;
      state.passCodeDestination = null;
      state.fpAuthCompleted = false;

      if (!action.payload.saveSecurityAuth) {
        state.stAuthCompleted = false;
      }

      state.error = '';
      state.criticalError = false;
      state.canAttemptAgain = false;
      state.canGenerateNewPassCode = false;
      state.modalVisible = false;
    },
    setMFAError(state, action) {
      state.error = action.payload.message
      state.criticalError = action.payload.criticalError
    }
  },
  extraReducers: (builder) => {
    /* MAIN LOGIN FUNCTIONS */
    builder.addCase(engageMultiFactor.pending, (state) => {
      state.initialMFARequest = "pending";
      state.passCodeDestination = null;
      state.error = ''
    });
    builder.addCase(engageMultiFactor.fulfilled, (state, action) => {
      if (action.payload.success) {
        if (!state.modalVisible) { state.modalVisible = true}
        state.initialMFARequest = "succeeded";
        state.passcodeType = action.payload.passCodeTypeSent;
        state.passCodeDestination = action.payload.passCodeDestination;
        state.canAttemptAgain = action.payload.canAttemptAgain
        state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
      } else {
        state.initialMFARequest = 'rejected'
        // must leave criticalError as false, otherwise the submit button will be disabled and the user will not be able to attempt again
      }
    });
    builder.addCase(engageMultiFactor.rejected, (state) => {
      state.initialMFARequest = 'rejected'
      // must leave criticalError as false, otherwise the submit button will be disabled and the user will not be able to attempt again
    });
    builder.addCase(submitLoginPasscode.pending, (state) => {
      state.submitPasscodeRequest = "pending";
      state.error = ''
    });
    builder.addCase(submitLoginPasscode.fulfilled, (state, action) => {
      state.initialMFARequest = 'idle';
      if (action.payload.success) {
        state.submitPasscodeRequest = "succeeded";
        
        state.passcodeType = null;
        state.authCompleted = true;
        state.modalVisible = false;
      } else {
        state.submitPasscodeRequest = 'rejected'
        if (action.payload.update) {
          state.canAttemptAgain = action.payload.canAttemptAgain
          state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
          state.error = 'Invalid passcode. Please try again.';
        } else {
          state.criticalError = true
        }
      }
    });
    builder.addCase(submitLoginPasscode.rejected, (state, action: any) => {
      state.submitPasscodeRequest = 'rejected'
      if (action.payload.update) {
        state.canAttemptAgain = action.payload.canAttemptAgain as boolean
        state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
        state.error = 'Invalid passcode. Please try again.';
      } else {
        state.criticalError = true
      }
    });
    /* FORGOT PASSWORD FUNCTIONS */
    builder.addCase(fpEngageMultiFactor.pending, (state) => {
      state.initialMFARequest = "pending";
      state.error = ''
    });
    builder.addCase(fpEngageMultiFactor.fulfilled, (state, action) => {
      if (action.payload.success) {
        if (!state.modalVisible) { state.modalVisible = true}
        state.initialMFARequest = "succeeded";
        state.passcodeType = action.payload.passCodeTypeSent;
        state.canAttemptAgain = action.payload.canAttemptAgain
        state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
      } else {
        state.initialMFARequest = 'rejected'
        state.criticalError = true
      }
    });
    builder.addCase(fpEngageMultiFactor.rejected, (state) => {
      state.initialMFARequest = 'rejected'
      state.criticalError = true
    });
    builder.addCase(fpSubmitPasscode.pending, (state) => {
      state.submitPasscodeRequest = "pending";
      state.error = ''
    });
    builder.addCase(fpSubmitPasscode.fulfilled, (state, action) => {
      state.initialMFARequest = 'idle';
      if (action.payload.success) {
        state.submitPasscodeRequest = "succeeded";
        state.passcodeType = null;
  
        if (action.payload.Token) {
          state.fpAuthCompleted = true;
          state.modalVisible = false;
        }
      } else {
        state.submitPasscodeRequest = "rejected";
        if (action.payload.update) {
          state.canAttemptAgain = action.payload.canAttemptAgain
          state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
          state.error = 'Invalid passcode. Please try again.';
        } else {
          state.criticalError = true
        }
      }
    });
    builder.addCase(fpSubmitPasscode.rejected, (state, action: any) => {
      state.initialMFARequest = 'idle';
      state.submitPasscodeRequest = "rejected";
      if (action.payload.update) {
        state.canAttemptAgain = action.payload.canAttemptAgain
        state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
        state.error = 'Invalid passcode. Please try again.';
      } else {
        state.criticalError = true
      }
    });
    /* SETTINGS MENU FUNCTIONS */
    builder.addCase(initiateElevatedAuth.pending, (state) => {
      state.settingsInitiateRequest = "pending";
      state.error = ''
    });
    builder.addCase(initiateElevatedAuth.fulfilled, (state, action) => {
      if (action.payload.success) {
        if (!state.modalVisible) { state.modalVisible = true}
        state.settingsInitiateRequest = "succeeded";
      } else {
        state.settingsInitiateRequest = 'rejected'
        state.error = action.payload.message
      }
    });
    builder.addCase(initiateElevatedAuth.rejected, (state, action) => {
      state.settingsInitiateRequest = 'rejected'
      //@ts-ignore
      state.error = action.payload.message
    });
    builder.addCase(elevatedEngageMultiFactor.pending, (state) => {
      state.initialMFARequest = "pending";
      state.passCodeDestination = null;
      state.error = ''
    });
    builder.addCase(elevatedEngageMultiFactor.fulfilled, (state, action) => {
      if (action.payload.success) {
        if (!state.modalVisible) { state.modalVisible = true}
        state.initialMFARequest = "succeeded";
        state.passcodeType = action.payload.passCodeTypeSent;
        state.passCodeDestination = action.payload.passCodeDestination;
        state.canAttemptAgain = action.payload.canAttemptAgain
        state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
      } else {
        state.initialMFARequest = 'rejected'
        state.criticalError = true
      }
    });
    builder.addCase(elevatedEngageMultiFactor.rejected, (state) => {
      state.initialMFARequest = 'rejected'
      state.criticalError = true
    })
    builder.addCase(submitElevatedPasscode.pending, (state, action) => {
      state.submitPasscodeRequest = "pending";
      state.error = ''
    });
    builder.addCase(submitElevatedPasscode.fulfilled, (state, action) => {
      state.initialMFARequest = 'idle';
      if (action.payload.success) {
        state.submitPasscodeRequest = "succeeded";
        
        state.passcodeType = null;
        state.stAuthCompleted = true;
        state.modalVisible = false;
      } else {
        state.submitPasscodeRequest = 'rejected'
        if (action.payload.update) {
          state.canAttemptAgain = action.payload.canAttemptAgain
          state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
          state.error = 'Invalid passcode. Please try again.';
        } else {
          state.criticalError = true
        }
      }
    })
    builder.addCase(submitElevatedPasscode.rejected, (state, action: any) => {
      state.initialMFARequest = 'idle';
      state.submitPasscodeRequest = 'rejected'
      if (action.payload.update) {
        state.canAttemptAgain = action.payload.canAttemptAgain
        state.canGenerateNewPassCode = action.payload.canGenerateNewPassCode;
        state.error = 'Invalid passcode. Please try again.';
      } else {
        state.criticalError = true
      }
    })
  },
});

export const {
  resetmFA,
  updateStAuthCompleted,
  togglemFAModal,
  setMFAError
} = mFASlice.actions;

export default mFASlice.reducer;

