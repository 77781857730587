/* eslint-disable no-loop-func */
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import ClaimToolbar from './ClaimToolbar'
import DotLoader from '../../misc/dotLoader'
import ClaimsSidebar from './ClaimsSidebar'
import Claim from './Claim'
import { ClaimsSideContent, PatientClaimsSection, CardContainerDiv, NoClaimsStatement, ClaimContentWrapper } from './claimStyles'
import MobilePatientClaimCard from './MobilePatientClaimCard'
import { useEffect } from 'react'
import ReasonsRemarksModal from './ReasonsRemarksModal'

const ClaimCardContainer = ({ exportPatientClaimsAllowed }) => {
  const claims = useAppSelector(state => state.patients.currentPatient.claims)
  const filteredClaims = useAppSelector(state => state.patients.currentPatient.filteredClaims)
  const claimsLoaded = useAppSelector(state => state.patients.currentPatient.claimsLoaded)
  const mobile = useAppSelector(state => state.nav.mobile)
  const tablet = useAppSelector(state => state.nav.tablet)
  const mobileClaimMode = useAppSelector(state => state.patients.currentPatient.mobileClaimMode)
  const demographicsExpanded = useAppSelector(state => state.patients.demographicsExpanded)
  const modalOpen = useAppSelector(state => state.patients.modalOpen)

  const dispatch = useAppDispatch()

  const renderClaimContent = () => {
    if (claimsLoaded === 'succeeded' && claims.length > 0) {
      return mobile && !mobileClaimMode ? (
        // Rendering mobile
        filteredClaims.length ? (
          <>
            {filteredClaims.map((claim, i) => (
              <MobilePatientClaimCard claim={claim} index={i} key={i} />
            ))}
          </>
        ) : (
          <p>No claims match your search.</p>
        )
      ) : (
        // Rendering tablet/desktop
        <Claim />
      )
    } else if (claimsLoaded === 'succeeded' && claims.length === 0) {
      return <NoClaimsStatement>This patient doesn't have any charges.</NoClaimsStatement>
    } else {
      return <DotLoader isLoading={true} width="20%" dotSize="30px" />
    }
  }

  return (
    <>
      {modalOpen ? <ReasonsRemarksModal /> : null}
      <PatientClaimsSection className="flexRow" mobile={mobile} tablet={tablet} mobileClaimMode={mobileClaimMode}>
        {claims.length > 0 && !mobile ? (
          <ClaimsSideContent mobile={mobile} demographicsExpanded={demographicsExpanded}>
            <ClaimToolbar exportPatientClaimsAllowed={exportPatientClaimsAllowed} />
            <ClaimsSidebar />
          </ClaimsSideContent>
        ) : null}
        <ClaimContentWrapper demographicsExpanded={demographicsExpanded} mobile={mobile}>
          {mobile && !mobileClaimMode ? <ClaimToolbar exportPatientClaimsAllowed={exportPatientClaimsAllowed} /> : null}
          <CardContainerDiv mobile={mobile}>{renderClaimContent()}</CardContainerDiv>
        </ClaimContentWrapper>
      </PatientClaimsSection>
    </>
  )
}

export default ClaimCardContainer
