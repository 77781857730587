import { useEffect, useState } from 'react'
import { SearchResultContainer } from '../searchStyles'
import { useViewport } from '../../../../utils/hooks'
import SearchResultsTable from './searchResultsTable'
import SearchBar from '../SearchBar'
import SearchResultMobile from '../mobile/searchResultMobile'
import SearchResultControls from './searchResultControls'
import DotLoader from '../../../misc/dotLoader'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { resetSearch, setSortDirection, setSortKey } from '../searchSlice'
import { setTab } from '../../../nav/navSlice'
import ConnectionSwitcher from '../../../nav/connectionSwitcher/ConnectionSwitcher'
import { FlexWrapper, ViewerContainer } from '../../../../styles/viewerStyles'

const SearchResults = ({ searchPatientsAllowed, match }) => {
  const [end, setEnd] = useState(10)

  const { width } = useViewport()
  const breakpoint = 1024

  const dispatch = useAppDispatch()

  const searchResults = useAppSelector(state => state.search.sortedResults)
  const { resultsPerPage, searchSlice, searchSubmitted, sortUp, sortKey } = useAppSelector(state => state.search)
  const fetchingUser = useAppSelector(state => state.users.switchingClients)
  const showConnectionSwitcher = useAppSelector(state => state.users.connectionSwitcher.showConnectionSwitcher)

  const handleMobileSort = e => {
    handleSort(e.target.value)
  }

  useEffect(() => {
    dispatch(setTab('patientManagement'))
    if (fetchingUser === 'pending') {
      dispatch(resetSearch())
    }
    return () => {}
  }, [fetchingUser, dispatch])

  const handleSort = payload => {
    if (sortKey === payload) {
      dispatch(setSortDirection(!sortUp))
    } else {
      dispatch(setSortKey(payload))
    }
  }

  const renderResults = () => {
    if (width < breakpoint) {
      return <SearchResultMobile handleSort={handleMobileSort} end={end} setEnd={setEnd} />
    } else {
      const startResults = searchSlice
      const endResults = startResults + resultsPerPage
      return (
        <>
          {searchResults.length > 10 ? <SearchResultControls /> : null}
          <SearchResultsTable match={match} results={searchResults.slice(startResults, endResults)} handleSort={handleSort} />
        </>
      )
    }
  }

  const renderContent = () => {
    if (searchSubmitted === 'pending') {
      return (
        <FlexWrapper height="50%">
          <DotLoader isLoading={true} width="300px" height="200px" dotSize="30px" />
        </FlexWrapper>
      )
    }

    if (searchPatientsAllowed && searchSubmitted === 'succeeded') {
      if (searchResults.length) {
        return renderResults()
      } else {
        return <p style={{ fontSize: '1.2rem' }}>No search results found.</p>
      }
    }

    return null
  }

  return (
    <ViewerContainer>
      <ConnectionSwitcher />
      <SearchResultContainer mobile={width < breakpoint} connectionSwitcher={showConnectionSwitcher}>
        <SearchBar mobile={width < breakpoint} placeHolder="e.g. John Doe" searchPatientsAllowed={searchPatientsAllowed} setEnd={setEnd} />
        {renderContent()}
      </SearchResultContainer>
    </ViewerContainer>
  )
}

export default SearchResults
