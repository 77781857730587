import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { PatientContainer, PatientDataSection, PatientPageHalf, PatientPageWrapper } from './patientInfoStyles'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { resetPatient } from './patientSlice'
import { setTab } from '../nav/navSlice'
import { resetSearch } from './search/searchSlice'
import { usePrevious, useViewport } from '../../utils/hooks'
import ConnectionSwitcher from '../nav/connectionSwitcher/ConnectionSwitcher'
import PatientSidebar from './PatientSidebar'
import PatientARSummary from './PatientARSummary'
import ClaimCardContainer from './claims/ClaimCardContainer'
import PatientInfoBlock from './PatientInfoBlock'
import { ViewerContainer } from '../../styles/viewerStyles'

const PatientInfoContainer = ({ patientDemographicsAllowed, patientClaimsAllowed, exportPatientClaimsAllowed, patientARAllowed }) => {
  const { width } = useViewport()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const client = useAppSelector(state => state.users.Client.ClientID)
  const showConnectionSwitcher = useAppSelector(state => state.users.connectionSwitcher.showConnectionSwitcher)
  const mobile = useAppSelector(state => state.nav.mobile)
  const tablet = useAppSelector(state => state.nav.tablet)
  const mobileClaimMode = useAppSelector(state => state.patients.currentPatient.mobileClaimMode)
  const demographicsExpanded = useAppSelector(state => state.patients.demographicsExpanded)

  const prevClient = usePrevious(client)

  useEffect(() => {
    if (prevClient && prevClient !== client) {
      dispatch(resetSearch())
      navigate('/patientManagement')
    }

    return () => {}
  }, [client, prevClient])

  useEffect(() => {
    dispatch(setTab('patientManagement'))
    return () => dispatch(resetPatient())
  }, []) //eslint-disable-line

  useEffect(() => {
    return () => resetPatient()
  }, []) //eslint-disable-line

  return (
    <ViewerContainer>
      <ConnectionSwitcher />
      <PatientContainer width={width} connectionSwitcher={showConnectionSwitcher} mobile={mobile} tablet={tablet}>
        {mobile ? (
          <PatientPageWrapper direction={mobile || tablet ? 'column' : 'row'} demographicsExpanded={demographicsExpanded} mobile={mobile}>
            {patientDemographicsAllowed && !mobileClaimMode ? <PatientSidebar /> : null}
            <PatientDataSection mobile={mobile} tablet={tablet} demographicsExpanded={demographicsExpanded}>
              {patientARAllowed && !mobileClaimMode ? <PatientARSummary /> : null}
              {patientClaimsAllowed ? <ClaimCardContainer exportPatientClaimsAllowed={exportPatientClaimsAllowed} /> : null}
            </PatientDataSection>
          </PatientPageWrapper>
        ) : (
          <>
            <PatientPageHalf style={{ height: 'fit-content' }}>
              <PatientInfoBlock />
              {patientARAllowed ? <PatientARSummary /> : null}
            </PatientPageHalf>
            <PatientPageHalf style={{ height: '100%', paddingBottom: '1rem' }}>
              {patientDemographicsAllowed ? <PatientSidebar /> : null}
              {patientClaimsAllowed ? <ClaimCardContainer exportPatientClaimsAllowed={exportPatientClaimsAllowed} /> : null}
            </PatientPageHalf>
          </>
        )}
        <Outlet />
      </PatientContainer>
    </ViewerContainer>
  )
}

export default PatientInfoContainer
