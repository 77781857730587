import React, { useContext } from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { ThemeContext } from 'styled-components'

const CustomTooltip = props => {
  const appTheme = useContext(ThemeContext)

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0}>
      {props.children}
    </Tooltip>
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: appTheme.viewer.background,
      color: appTheme.restyle.primary,
      border: '1px solid ' + appTheme.restyle.primary,
      borderRadius: 0,
      fontFamily: 'var(--font-branding)',
      fontSize: '.9rem',
      marginTop: props.claimSidebar ? '14px' : '4px !important',
    },
    p: {
      margin: 0,
    },
  }))

  return <StyledTooltip {...props}>{props.children}</StyledTooltip>
}

export default CustomTooltip
