import styled from 'styled-components'
import { UnstyledButton } from '../../../styles/buttonStyles'
import { PhyInputs, PhySelect } from '../../../styles/formStyles'

export const SearchResultContainer = styled.div`
  height: calc(100% - 110px);
  width: 100%;
  position: relative;
  display: ${props => (props.connectionSwitcher ? 'none' : 'flex')};
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  color: ${props => props.theme.restyle.primary};
  transition: color 0.5s;
`
export const HistoricNameIcon = styled.div`
  cursor: pointer;

  svg {
    rotate: ${props => (props.showHistoric ? '180deg' : '0')};
    transition: rotate 0.75s;
  }
`

/* #region Search Results Table */
export const SearchResultsDiv = styled.div`
  width: 80%;
  height: 70%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  overflow: hidden auto;
`
export const SearchResultHead = styled.div`
  width: 100%;
  padding: 0.5rem;
  background-color: ${props => props.theme.viewer.background};
  display: grid;
  grid-template-columns: 5% 20% 20% 15% 20% 20%;
  border-bottom: 1px solid var(--color-accent-alsogrey);
  position: sticky;
  top: 0;
  z-index: 12;
  transition: background-color 0.5s;
`
export const SearchHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: default;

  h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }

  svg {
    margin-left: 0.3rem;
    font-size: 1.2rem;
    cursor: pointer;
  }

  :first-child {
    grid-column: 1 / span 2;
  }
`
export const SearchResultsWrapper = styled.div`
  max-height: 400px;
  width: 100%;
  display: grid;
  grid-template-columns: 5% 20% 20% 15% 20% 20%;
  padding: 0 0.5rem;

  > * {
    height: auto;
    padding: 0.6rem 0;
    color: ${props => props.theme.restyle.primary};
    text-decoration: none;
    transition: color 0.5s;
    display: flex;
    align-items: center;
  }

  > *:nth-child(n + 7) {
    border-top: 1px solid var(--color-accent-alsogrey) !important;

    :focus {
      border-width: 1px 0 0 !important; // must be !important to overwrite wijmo CSS
    }
  }

  > *:nth-child(-n + 6):focus {
    border: none !important; // must be !important to overwrite wijmo CSS
  }

  > *.historic-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`
export const ResultData = styled.div`
  width: 100%;
  font-size: 1rem;
  z-index: 10;
  position: relative;
  text-align: left;

  &#historicData {
    margin-top: 0.5rem;
  }
`
/* #endregion Search Results Table */

export const HistoricNameUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding-left: 20px;
  }
`

export const SearchForm = styled.form`
  height: ${props => (props.searchActive ? '20%' : '70%')};
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  text-align: ${props => (props.mobile ? 'center' : 'left')};
  padding-bottom: 10px;
  transition: height 1s;
`

export const SearchLabelPM = styled.label`
  font-size: min(max(1vw, 14px), 16px);
  margin: 5px;
  width: 100%;
  max-width: 400px;
  transition: color 0.5s;
`

export const SearchText = styled.input`
  height: 40px;
  width: 100%;
  border: none;
  background-color: inherit;
  color: inherit;
  font-size: 1.5em;
  transition: background-color 0.5s;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${props => props.theme.shades.dark};
    transition: color 0.5s;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props => props.theme.shades.dark};
    transition: color 0.5s;
  }
`

export const SearchInputs = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  overflow: hidden;
  border-bottom: 3px solid #1a5192;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  &:focus-within {
    box-sizing: border-box;
    outline: none;
    border: none;
    border-bottom: 3px solid #7ed1e7;
    transition: border-bottom 300ms linear;
    div {
      svg {
        transition: color 300ms linear;
        padding-right: 10px;
        color: #7ed1e7;
      }
    }
  }
  div {
    svg {
      padding-right: 10px;
      color: #1a5192;
    }
  }
`

export const PageNumWrapper = styled.div`
  height: 100%;
  font-weight: 900;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`
export const PageItem = styled(UnstyledButton)`
  display: table-cell;
  margin: 0 0.5rem;
  padding: 0 0.25rem;
  text-align: center;
  border-bottom: ${props => (props.active ? '2px solid ' + props.theme.restyle.primary : 'none')};
  cursor: pointer;
  transition: border-bottom-color 0.5s;

  svg:focus {
    outline: none;
  }
`

export const MobilePatientManagementContainer = styled.div`
  width: 90%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  ${
    '' /* margin: 0 1em;
        padding: 1em 0; */
  }
  align-items: center;
  justify-content: center;
`

export const MobileSearchControlWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  label {
    margin-right: 5px;
  }
  svg {
    margin: 0 5px;
    cursor: pointer;
  }
`

export const PhySelectSort = styled(PhySelect)`
  width: fit-content;
  font-family: inherit;
  font-size: inherit;

  option {
    font-size: 0.8rem;
  }
`

export const PhyInputsSort = styled(PhyInputs)`
  border: none;
  background-color: transparent;

  &:focus-within,
  div {
    border: none;
    outline: none;
  }
`

export const DropdownWrapper = styled.div`
  height: 2em;
  width: 3em;
  margin: 1px;
  border: 2px solid ${props => props.theme.inputs.border};
  border-radius: 5px;
  background-color: ${props => props.theme.inputs.background};
  padding: 4px 8px;
  display: grid;
  position: relative;
  grid-template-areas: 'select';
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s, border-color 0.5s;
  &:focus-within {
    box-sizing: content-box;
    outline: none;
    border: none;
    margin: 3px;
    box-shadow: 3px 3px 0 ${props => props.theme.dashboard.main}, -3px -3px 0 ${props => props.theme.patientManagement.main};
    transition: box-shadow 300ms linear;
  }
  &:before {
    content: '';
    width: 0.8em;
    height: 0.5em;
    margin-right: 5px;
    background-color: ${props => props.theme.inputs.text};
    justify-self: end;
    clip-path: polygon(0 0, 25% 0, 50% 50%, 75% 0, 100% 0, 50% 100%);
    grid-area: select;
    position: absolute;
    left: -10px;
    transition: background-color 0.5s;
  }
`

export const MobileDropdownWrapper = styled.div`
  height: 1em;
  width: 120px;
  margin: 1px;
  border-radius: 5px;
  background-color: ${props => props.theme.inputs.background};
  padding: 4px 8px;
  display: grid;
  position: relative;
  grid-template-areas: 'select';
  align-items: center;
  transition: background-color 0.5s;
  cursor: pointer;
  &:focus-within {
    box-sizing: content-box;
    outline: none;
    border: none;
  }
  &:before {
    content: '';
    width: 0.8em;
    height: 0.5em;
    margin-right: 15px;
    background-color: ${props => props.theme.shades.text};
    justify-self: end;
    clip-path: polygon(0 0, 25% 0, 50% 50%, 75% 0, 100% 0, 50% 100%);
    grid-area: select;
    position: absolute;
    left: 0px;
    transition: background-color 0.5s;
  }
`

export const MobileSortOption = styled.option`
  color: ${props => props.theme.restyle.primary};
  transition: color 0.5s;
`

export const ArrowDiv = styled.div`
  height: 3rem;
  width: 3rem;
  position: fixed;
  bottom: 20px;
  z-index: 1000;
  background-color: var(--color-accent-purple);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-sizing: border-box;
  right: 10px;
  svg {
    position: fixed;
    height: 3rem;
    width: 3rem;
    bottom: 20px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 1;
  }
`
export const ResultsWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`

export const SearchErrorMessage = styled.h5`
  color: var(--color-accent-red);
`

export const QuickSearchContainer = styled.div`
  position: absolute;
  z-index: 2003;
  top: 60px;
  left: ${props => (props.mobile ? '-150px' : 'auto')};
  display: flex;
  flex-direction: column;
  height: ${props => (props.loading ? '300px' : 'fit-content')};
  max-height: 300px;
  min-width: ${props => (props.mobile ? '300px' : '350px')};
  width: 300%;
  max-width: 500px;
  background: ${props => props.theme.viewer.background};
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
  color: ${props => props.theme.shades.text};
  scrollbar-width: none; /* Firefox */
  transition: background-color 0.5s, box-shadow 0.5s;
  &::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 4px 2px 15px ${props => props.theme.shades.shadow};
`

export const QuickSearchul = styled.ul`
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export const QuickSearchli = styled.li`
  min-height: 24px;
  color: ${props => props.theme.shades.text};
  text-decoration: none;
  padding: ${props => (props.mobile ? '5px' : '2px 5px 3px 5px')};
  line-height: 1.5em;
  border-bottom: 1px solid #cccccc;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  transition: color 0.5s;
  &:hover {
    background: ${props => props.theme.reporting.main};
    color: white;
  }
`
export const MobileSortArrow = styled.div`
  div {
    flex-direction: column;
    font-size: 0.8rem;
  }

  svg {
    margin: 0;
    font-size: 1.5rem;
  }
`
