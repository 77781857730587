import { createSlice } from '@reduxjs/toolkit'

interface NavState {
  selectedTab: string
  menuActive: boolean
  supportSliderActive: boolean
  mobile: boolean
  tablet: boolean
  portableDevice: boolean
  width: number
  height: number
  forgotPassword: boolean
  mobileSidebar: boolean
}

const initialNavState = {
  selectedTab: 'dashboard',
  menuActive: false,
  supportSliderActive: false,
  mobile: false,
  tablet: false,
  portableDevice: false,
  width: 0,
  height: 0,
  forgotPassword: false,
  mobileSidebar: false,
}

const initialState = initialNavState as NavState

export const navSlice = createSlice({
  name: 'nav',
  initialState: initialState,
  reducers: {
    setTab(state, action) {
      state.selectedTab = action.payload
    },
    setMenu(state, action) {
      state.menuActive = action.payload
    },
    updateViewport(state, action) {
      const { mobile, tablet } = action.payload

      state.width = action.payload.width
      state.height = action.payload.height
      state.mobile = mobile
      state.tablet = tablet
      state.portableDevice = mobile || tablet
    },
    toggleForgotPassword(state, action) {
      state.forgotPassword = action.payload
    },
    toggleMobileSidebar(state, action?) {
      state.mobileSidebar = action.payload ? action.payload : !state.mobileSidebar
    },
  },
})

export const { setTab, setMenu, updateViewport, toggleForgotPassword, toggleMobileSidebar } = navSlice.actions
export default navSlice.reducer
