export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const trimSearchInput = (str) => {
  return str.replace(/^\s*/, "");
};

export const isNotEmpty = (obj) => {
  return Object.keys(obj).length > 0;
};

export const convertNumToCurrency = (val) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(val);
};

export const formatID = (id) => {
  return id || "--";
};

export const buildDate = (date) => {
  if (date) {
    let dateObj = new Date(date);
    let month = dateObj.getUTCMonth() + 1;
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    return `${month}/${day}/${year}`;
  } else {
    return "--";
  }
};

// Make a 2D array out of an array of nested objects
export class ExportTable {
  constructor(data = [], metadata = []) {
    this.metadata = metadata;
    this.data = JSON.parse(JSON.stringify(data));
  }

  static isPrimitive(thing) {
    return thing !== Object(thing);
  }

  get table() {
    return this.buildTable();
  }

  buildTable() {
    let results = [this.buildHeader()];
    for (const record of this.data) {
      results = [...results, ...this.buildRowsFromRecord(record)];
    }
    return results;
  }

  buildRowsFromRecord(record) {
    return this.traverseRecord(record, [], []);
  }

  traverseRecord(record, row = [], rows = []) {
    // copy existing rows into new ones
    let results = [...rows];
    // check for null record
    if (!record) return results;
    // copy row into new row
    let newRow = [...row];

    // get fields column by column
    for (const [i, column] of this.metadata.entries()) {
      let newValue = newRow[i];
      for (const keystring of column.keystrings) {
        if (record[keystring] !== undefined) {
          newValue = record[keystring];
          if (column.formatter !== undefined) {
            newValue = column.formatter(newValue);
          }
        }
      }
      newRow[i] = newValue;
    }

    const fields = Object.entries(record);

    // Base case: primitives only
    let isLeaf = true;
    for (const [key, value] of fields) {
      if (!ExportTable.isPrimitive(value)) {
        isLeaf = false;
      }
    }
    if (isLeaf) {
      // fill with default values
      for (const [i, column] of this.metadata.entries()) {
        let defaultValue = column.defaultValue;
        if (defaultValue === undefined) {
          defaultValue = "";
        }
        if (newRow[i] === undefined) {
          newRow[i] = defaultValue;
        }
      }
      results.push(newRow);
      return results;
    }

    // Recursive case 1: subarrays
    for (const [key, subTable] of fields) {
      if (Array.isArray(subTable)) {
        for (const subRecord of subTable) {
          let newTable = this.traverseRecord(subRecord, newRow, []);
          results = [...results, ...newTable];
        }
      }
    }

    // Recursive case 2: object - currently excluded
    for (const [key, obj] of fields) {
      if (typeof obj === "object" && !Array.isArray(obj)) {
        //   let newTable = this.traverseRecord(obj, [], [])
        //   results = [...results, ...newTable]
      }
    }

    return results;
  }

  buildHeader() {
    return this.metadata.map((field) => field.label);
  }
}

export const arrayUnique = (array) => {
  const a = array;
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};

export const compareObjects = (a, b, key, order) => {
  let objA = a[key] ? `${a[key]}` : "";
  let objB = b[key] ? `${b[key]}` : "";

  if (key !== "DateOfBirth" || "apmId") {
    objA = objA.toUpperCase().trim();
    objB = objB.toUpperCase().trim();
  } else if (key.toUpperCase().includes("DATE")) {
    objA = new Date(objA);
    objB = new Date(objB);
  }
  let comparison = 0;
  if (order) {
    if (objA > objB) {
      comparison = 1;
    } else if (objA < objB) {
      comparison = -1;
    }
  } else {
    if (objA < objB) {
      comparison = 1;
    } else if (objA > objB) {
      comparison = -1;
    }
  }
  return comparison;
};

export const checkBrowser = () => {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf("Chrome") > -1;

  // Detect Internet Explorer
  let IExplorerAgent = userAgentString.indexOf("MSIE") > -1;

  // Detect Firefox
  let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

  // Detect Safari
  let safariAgent = userAgentString.indexOf("Safari") > -1;

  // Detect MSFTEdge
  let edgeAgent = userAgentString.indexOf("Edg") > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }

  // Detect Opera
  let operaAgent = userAgentString.indexOf("OP") > -1;
  let browserVersion;
  // Discard Chrome since it also matches Opera
  if ((chromeAgent && operaAgent) || (chromeAgent && edgeAgent)) {
    chromeAgent = false;
  }
  if (chromeAgent) {
    sessionStorage.setItem("userBrowser", "Chrome");
    browserVersion = navigator.userAgent
      .split(" ")
      .find((str) => str.includes("Chrome"));
    sessionStorage.setItem("browserVersion", browserVersion);
  } else if (IExplorerAgent) {
    sessionStorage.setItem("userBrowser", "IE");
  } else if (firefoxAgent) {
    sessionStorage.setItem("userBrowser", "Firefox");
    browserVersion = navigator.userAgent
      .split(" ")
      .find((str) => str.includes("Firefox"));
    sessionStorage.setItem("browserVersion", browserVersion);
  } else if (safariAgent) {
    sessionStorage.setItem("userBrowser", "Safari");
    browserVersion = navigator.userAgent
      .split(" ")
      .find((str) => str.includes("Safari"));
    sessionStorage.setItem("browserVersion", browserVersion);
  } else if (operaAgent) {
    sessionStorage.setItem("userBrowser", "Opera");
    browserVersion = navigator.userAgent
      .split(" ")
      .find((str) => str.includes("OPR"));
    sessionStorage.setItem("browserVersion", browserVersion);
  } else if (edgeAgent) {
    sessionStorage.setItem("userBrowser", "Edge");
    browserVersion = navigator.userAgent
      .split(" ")
      .find((str) => str.includes("Edg"));
    sessionStorage.setItem("browserVersion", browserVersion);
  } else {
    sessionStorage.setItem("userBrowser", "Other");
    sessionStorage.setItem("browserVersion", "N/A");
  }
};

export const checkOS = () => {
  let OSVersion = "Unknown OS";
  let userAgentString = navigator.userAgent;

  if (userAgentString.indexOf("Win") !== -1) {
    OSVersion = "Windows";
  } else if (userAgentString.indexOf("Mac") !== -1) {
    OSVersion = "MacOS";
  } else if (userAgentString.indexOf("X11") !== -1) {
    OSVersion = "UNIX OS";
  } else if (userAgentString.indexOf("Linux") !== -1) {
    OSVersion = "Linux OS";
  }

  sessionStorage.setItem("OSVersion", OSVersion);
};

const merge = (left, right, key, dir) => {
  let result = [];
  let leftIdx = 0;
  let rightIdx = 0;
  while (leftIdx < left.length && rightIdx < right.length) {
    if (dir) {
      if (left[leftIdx][key] < right[rightIdx][key]) {
        result.push(right[rightIdx]);
        rightIdx++;
      } else {
        result.push(left[leftIdx]);
        leftIdx++;
      }
    } else {
      if (left[leftIdx][key] > right[rightIdx][key]) {
        result.push(right[rightIdx]);
        rightIdx++;
      } else {
        result.push(left[leftIdx]);
        leftIdx++;
      }
    }
  }
  while (leftIdx < left.length) {
    result.push(left[leftIdx]);
    leftIdx++;
  }
  while (rightIdx < right.length) {
    result.push(right[rightIdx]);
    rightIdx++;
  }
  return result;
};

export const mergeSort = (arr, key, dir = true) => {
  if (arr.length < 2) {
    return arr;
  }
  let midpoint = Math.floor(arr.length / 2);
  let left = arr.slice(0, midpoint);
  let right = arr.slice(midpoint);
  return merge(mergeSort(left, key, dir), mergeSort(right, key, dir), key, dir);
};

export const getMonth = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

// -------- Alert Banner Sorting Methods ----------- //

export const sortByMessageType = (a, b) => {
  return (
    parseInt(a.MessageTypeID) - parseInt(b.MessageTypeID) ||
    sortByMessagePriority(a, b)
  );
};

const sortByMessagePriority = (a, b) => {
  return (
    b.DisplayOrderPriority - a.DisplayOrderPriority || sortByStartDate(a, b)
  );
};

const sortByStartDate = (a, b) => {
  return new Date(b.StartDate) - new Date(a.StartDate) || sortByEndDate(a, b);
};

const sortByEndDate = (a, b) => {
  return new Date(a.StopDate) - new Date(b.StopDate);
};

export const findPermission = (permissionID, permissions) => {
  if (typeof permissionID === "string" && permissionID === "-1") {
    return true;
  }
  let permitted = false;
  const foundPermission =
    permissions &&
    permissions.find((permission) => permission.PermissionID === permissionID);
  if (foundPermission) {
    permitted = foundPermission.UserHasPermission;
  }
  return permitted;
};
