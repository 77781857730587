import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { useAppSelector } from '../../redux/store'
import { SearchInput } from '../../styles/appStyles'
import { SearchReportsWrapper } from './reportStyles'

const Filter = ({ filter, onFilterChange }) => {
  const theme = useAppSelector(state => state.users.ThemeName)

  // Event handler for filter change
  const handleFilterChange = event => {
    const newFilter = event.target.value
    onFilterChange(newFilter)
  }

  return (
    <SearchReportsWrapper>
      <SearchIcon />
      <SearchInput type="text" placeholder="Search Reports" value={filter} darkMode={theme === 'Dark'} onChange={handleFilterChange} style={{ maxWidth: '120px' }} />
    </SearchReportsWrapper>
  )
}

export default Filter
