import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ArrowDiv } from '../searchStyles'
import { ArrowUpward } from '@mui/icons-material'

const ScrollTopArrow = ({ scrollTop }) => {
  return (
    <ArrowDiv>
      <ArrowUpward onClick={() => scrollTop()} htmlColor="#FFF" />
    </ArrowDiv>
  )
}

export default ScrollTopArrow
