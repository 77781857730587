import { HelpStatement } from "../../login/loginStyles";
import PhoneNumber from "../../misc/PhoneNumber";
import { useViewport } from "../../../utils/hooks";

const SupportMessage = (props) => {
  const { width } = useViewport();

  const atLogin = window.location.pathname.includes('login')

  const componentColor = atLogin ? '#FFFFFF' : null

  return(
    <div style={{marginBottom: '8px', color: componentColor}}>
      <HelpStatement>
        Need help signing in? Call 
        {width < 1024 && !atLogin
          ? [" ", <PhoneNumber key={'phone'} number="8778462953"/>] /* tablet and mobile*/
          : [" ", <a key={'link'} type='tel' href="tel:+18778462953" style={{ color: '#FFFFFF', textDecoration: 'none' }}>(877) 846-2953</a>]} {/*desktop version and homepage version*/}
      </HelpStatement>
    </div>
  )
}

export default SupportMessage;