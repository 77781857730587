import styled from 'styled-components'

export const PhyInputs = styled.div<{
  width?: string
}>`
  display: flex;
  align-items: center;
  width: ${props => props.width || '80%'};
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
  overflow: hidden;
  background: ${props => props.theme.inputs.background};
  border: none;
  border-bottom: 2px solid ${props => props.theme.shades.light};
  color: ${props => props.theme.inputs.text};
  margin: 3px;
  box-sizing: border-box;
  padding-left: 5px;
  @media screen and (max-width: 500px) {
    font-size: 10px;
    width: ${props => props.width || '100%'};
  }
  &:focus-within {
    box-sizing: content-box;
    outline: 1px solid #777;
    border-radius: 3px;
    border: none;
  }
  div {
    color: white;
    background: ${props => props.theme.reporting.main};
    border-left: 1px solid ${props => props.theme.inputs.border};
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const PhyInputText = styled.input`
  height: 40px;
  min-height: 40px;
  width: 100%;
  border: none;
  background-color: ${props => props.theme.inputs.background};
  font-size: 1.25em;
  color: ${props => props.theme.inputs.text};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${props => props.theme.shades.placeholder};
  }
`

export const PhyInputsArea = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-between;
  overflow: hidden;
  background: ${props => props.theme.shades.dark};
  border: 2px solid ${props => props.theme.shades.light};
  margin: 3px;
  border-radius: 5px;
  box-sizing: border-box;
  &:focus-within {
    box-sizing: content-box;
    outline: 1px solid #777;
    border: none;
    box-shadow: 3px 3px 0 ${props => props.theme.launchPad.main}, -3px -3px 0 ${props => props.theme.patientManagement.dark};
    transition: box-shadow 300ms linear;
  }

  @media screen and (min-width: 1024px) {
    height: 200px;
  }
`

export const PhyInputTextArea = styled.textarea`
  border: none;
  width: 100%;
  height: 100px;
  background-color: ${props => props.theme.viewer.background};
  font-size: 1em;
  padding: .5rem;
  box-sizing: border-box;
  color: ${props => props.theme.inputs.text};
  &:focus {
    outline: none;
  }

  @media screen and (min-width: 1024px) {
    height: 200px;
  }
`

export const PhySelect = styled.select`
  height: 40px;
  width: 100%;
  border: none;
  background-color: ${props => props.theme.viewer.background};
  font-size: 1.2em;
  cursor: pointer;
  color: ${props => props.theme.restyle.primary};
  transition: background-color 0.5s, color 0.5s;

  &:focus {
    outline: none;
    border: none;
  }
  option {
    cursor: pointer;
  }
`

export const PhyInputLabel = styled.label`
  text-align: left;
  color: ${props => props.theme.inputs.text};
  width: 80%;
  margin-top: 15px;
  font-weight: 700;
`

export const PhyForm = styled.form<{
  height?: string
  submitMarginTop?: string
}>`
  width: 100%;
  height: ${props => props.height || '100%'};
  color: ${props => props.theme.shades.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    margin-top: ${props => props.submitMarginTop || '15px'};
  }
  @media screen and (max-width: 600px) {
    div {
      div {
        select {
          font-size: 16px;
        }
        margin-bottom: 0;
      }
      #mobileHide {
        display: none;
      }
    }
  }
`

export const RequiredField = styled.span`
  color: #999;
`
