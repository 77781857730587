import React, { useCallback, useEffect, useRef, useState } from 'react'
import PermissionsContainer from '../permissions/PermissionsContainer'
import SecurityContainer from './security/SecurityContainer'
import AccountsContainer from './accounts/AccountsContainer'
import SubMenu from './SubMenu'

// Hooks and services
import { deleteCookie } from '../../utils/security'

// Styling
import { faAngleDown, faBars, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UnstyledButton } from '../../styles/buttonStyles'
import { ModalBackground } from '../modals/modalStyles'
import {
  MobileSettingsTabsContainer,
  SettingsContent,
  SettingsMenuIconContainer,
  SettingsModalContainer,
  SettingsSidebarContainer,
  SettingsSidebarItem,
} from './settingsStyles'
import CloseIcon from '@mui/icons-material/Close';
import { UserMenuButton } from '../nav/siteHeader/SiteHeaderStyles'

// Redux
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { setModal } from '../modals/modalSlice'
import { togglemFAModal, resetmFA, initiateElevatedAuth } from '../mfa/mFASlice'
import { resetSettingsSlice } from './settingsSlice'

interface Props {
  width: number
}

const SettingsModal = (props: Props) => {

  const dispatch = useAppDispatch()

  type settingsTabs = 'Security' | 'Account' | 'Permissions'
  const settingsTabs = ['Security', 'Account', 'Permissions']

  const mFAVisible = useAppSelector((state) => state.mFA.modalVisible)
  const stAuthCompleted = useAppSelector(state => state.mFA.stAuthCompleted)
  const userInitials = useAppSelector((state) => state.users.UserInitials)
  const theme = useAppSelector(state => state.users.ThemeName)
  const mobile = useAppSelector(state => state.nav.mobile)
  const width = useAppSelector(state => state.nav.width)

  const [selected, setSelected] = useState<settingsTabs>('Account')
  const [selectedPermission, setSelectedPermission] = useState('User')
  const [selectedAccount, setSelectedAccount] = useState('Profile')
  const [selectedSecurity, setSelectedSecurity] = useState('Password')
  const [permissionsSubMenuOpen, setPermissionsSubMenuOpen] = useState(false)
  const [securitySubMenuOpen, setSecuritySubMenuOpen] = useState(false)
  const [accountSubMenuOpen, setAccountSubMenuOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  // Handle mobileMenu
  const mountRef = useRef(false)

  useEffect(() => {
    if (mobile) {
      if (mountRef.current) {
        setMobileMenuOpen(false)
      } else {
        setMobileMenuOpen(true)
        mountRef.current = true
      }
    }
  }, [setMobileMenuOpen, selected, selectedPermission])

  // Handle modal close
  const toggleAuthModal = useCallback(
    (payload) => {
      dispatch(togglemFAModal(payload))
    },
    [dispatch]
  )

  const callResetSettingsSlice = useCallback(() => {
    dispatch(resetSettingsSlice())
  }, [dispatch])

  const resetmFAAuth = useCallback((payload) => {
    dispatch(resetmFA(payload))
  }, [dispatch])

  const closeSettingsModal = useCallback(() => {
    dispatch(setModal({ key: 'settings', data: false }))
  }, [dispatch])

  const handleClose = () => {
    if (process.env.REACT_APP_SECURITY_TOKEN) {
      deleteCookie(process.env.REACT_APP_SECURITY_TOKEN)
    }
    if (process.env.REACT_APP_ELEVATED_TOKEN) {
      deleteCookie(process.env.REACT_APP_ELEVATED_TOKEN)
    }
    callResetSettingsSlice()
    resetmFAAuth({saveSecurityAuth: false})
    closeSettingsModal()
  }

  // Handle navigation
  useEffect(() => {
    setMobileMenuOpen(false)
  }, [setMobileMenuOpen, selected])

  useEffect(() => {
    let hasNotRun = true;
    if (stAuthCompleted && hasNotRun) {
      setSecuritySubMenuOpen(true)
      hasNotRun = false
    }
  }, [stAuthCompleted])

  const handleSubMenuClick = (selectedSubMenu: settingsTabs) => {
    setSelected(selectedSubMenu)

    if (selectedSubMenu === 'Security') {
      if (stAuthCompleted) {
        setPermissionsSubMenuOpen(false)
        setAccountSubMenuOpen(false)
        setSecuritySubMenuOpen(!securitySubMenuOpen)
      } else {
        dispatch(initiateElevatedAuth({controller: new AbortController()}))
        toggleAuthModal(true)
      }
    } else if (selectedSubMenu === 'Account') {
      setSecuritySubMenuOpen(false)
      setPermissionsSubMenuOpen(false)
      setAccountSubMenuOpen(!accountSubMenuOpen)
    } else {
      setSecuritySubMenuOpen(false)
      setPermissionsSubMenuOpen(!permissionsSubMenuOpen)
      setAccountSubMenuOpen(false)
    }
  }

  /* HANDLING IF SECURITY TOKEN EXPIRES WHILE ON SECURITY SUBPAGE */
  useEffect(() => {
    // If elevated MFA is re-triggered for an expired security token, hide sub-menu until they are re-authenticated
    if (mFAVisible && securitySubMenuOpen) {
      setSecuritySubMenuOpen(false)
    }

    // If a user completes elevated MFA again after their security token has expired, display the sub-menu
    if (stAuthCompleted && !securitySubMenuOpen) {
      setSecuritySubMenuOpen(true)
    }
  }, [mFAVisible])
  
  // Render functions
  const buildContent = () => {
    if (selected === 'Security') {
      return <SecurityContainer selected={selectedSecurity} handleClose={handleClose} />
    } else if (selected === 'Permissions') {
      return <PermissionsContainer selected={selectedPermission} />
    } else if (selected === 'Account') {
      return <AccountsContainer selected={selectedAccount} />
    }
  }

  const buildTabs = () => {
    return (
      <>
        <SettingsSidebarItem
          onClick={(e) => handleSubMenuClick('Account')}
          selected={selected === 'Account'}
          data-testid="accountTab"
          mobile={mobile}
        >
          <UnstyledButton>
            <span style={{width: '100%', display: 'flex', alignItems: 'center'}}>
              Account&nbsp;
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </UnstyledButton>
          {accountSubMenuOpen ? (
            <SubMenu
              name="Account"
              selected={selectedAccount}
              setSelected={setSelected}
              setSelectedSubitem={setSelectedAccount}
              closeMobileMenu={setMobileMenuOpen}
              items={['Profile', 'Theme']}
            />
          ) : null}
        </SettingsSidebarItem>
        <SettingsSidebarItem
          onClick={(e) => handleSubMenuClick('Security')}
          selected={selected === 'Security'}
          data-testid="securityTab"
          mobile={mobile}
        >
          <UnstyledButton>
            <span style={{width: '100%', display: 'flex', alignItems: 'center'}}>
              Security&nbsp;
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </UnstyledButton>
          {securitySubMenuOpen ? (
            <SubMenu
              name="Security"
              selected={selectedSecurity}
              setSelected={setSelected}
              setSelectedSubitem={setSelectedSecurity}
              closeMobileMenu={setMobileMenuOpen}
              items={['Password', 'Multifactor Authentication']}
            />
          ) : null}
        </SettingsSidebarItem>
        <SettingsSidebarItem
          onClick={(e) => handleSubMenuClick('Permissions')}
          selected={selected === 'Permissions'}
          mobile={mobile}
        >
          <UnstyledButton>
            <span style={{width: '100%', display: 'flex', alignItems: 'center'}}>
              Permissions&nbsp;
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </UnstyledButton>
          {permissionsSubMenuOpen ? (
            <SubMenu
              name="Permissions"
              selected={selectedPermission}
              setSelected={setSelected}
              setSelectedSubitem={setSelectedPermission}
              closeMobileMenu={setMobileMenuOpen}
              items={['User', 'Client Portal', 'PFR', 'DualRemit']}
            />
          ) : null}
        </SettingsSidebarItem>
        {mobile || width > 900 ? null : (
          <SettingsSidebarItem style={{width: '25%'}}>
            <CloseIcon
              onClick={e => handleClose()}
              id="tabletCloseIcon"
              style={{
                fontSize: '40px',
                color: '#FFFFFF',
                cursor: 'pointer',
                marginRight: '5px'
              }}
            />
          </SettingsSidebarItem>
        )}
      </>
    )
  }

  return (
    <ModalBackground>
      <SettingsModalContainer>
        <SettingsSidebarContainer>
          <div className="settingsHeader">
            <FontAwesomeIcon icon={faCog} color="white" />
            <h3>Settings</h3>
          </div>
          {mobile ? (
            <SettingsMenuIconContainer>
              <UnstyledButton
                onClick={(e) => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <FontAwesomeIcon icon={faBars} color="white" />
              </UnstyledButton>
              <CloseIcon
                onClick={e => handleClose()}
                id="mobileCloseIcon"
                style={{
                  fontSize: '40px',
                  color: '#FFF',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
              />  
            </SettingsMenuIconContainer>
          ) : (
            buildTabs()
          )}
        </SettingsSidebarContainer>
        <SettingsContent style={{ height: '100%'}} removePadding={mobileMenuOpen}>
          {width > 900 ? (
            <CloseIcon
              onClick={e => handleClose()}
              id="desktopCloseIcon"
              style={{
                position: 'absolute',
                top: mobile ? '25px' : '15px',
                right: mobile ? '25px' : '15px',
                fontSize: '40px',
                color: theme === 'Light' ? '#000000' : '#FFFFFF',
                cursor: 'pointer',
              }}
            />
          ) : null }
          {mobileMenuOpen ? (
            <MobileSettingsTabsContainer
              onClick={(e) => setMobileMenuOpen(false)}
            >
              {buildTabs()}
            </MobileSettingsTabsContainer>
          ) : (
            buildContent()
          )}
        </SettingsContent>
        {/* @ts-ignore */}
        <UserMenuButton style={{ position: 'absolute', bottom: '10px', left: '10px' }}>{userInitials}</UserMenuButton>
      </SettingsModalContainer>
    </ModalBackground>
  )
}

export default SettingsModal