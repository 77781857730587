import { useCallback } from 'react';
import OptionalProfile from './OptionalProfile';
import ThemeSelector from './themeSelector';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { resetMessage, updateSettingsStatus } from '../settingsSlice';
import RequestStatusLoader from '../../misc/RequestStatusLoader';


const AccountsContainer = (props) => {

  const dispatch = useAppDispatch()

  const saveSuccessful = useAppSelector(state => state.settings.saveSuccessful)

  const removeSuccessMessageTimeout = useCallback(
    () => {
      setTimeout(()=>{
        dispatch(resetMessage());
      }, 5000)
    },
    [dispatch],
  )

  const handleSuccess = () => {
    if (saveSuccessful === 'succeeded') {
      removeSuccessMessageTimeout();
      return (
        <div style={{ justifyContent: 'center', display: 'flex', padding: '20px' }}>
          <RequestStatusLoader
            requestStatus={saveSuccessful}
            resetFunction={() => dispatch(updateSettingsStatus({ request: 'getOptional', status: 'idle' }))}
          />
        </div>
      )
    }
  }

  const buildComponents = () => {
    if (props.selected === 'Profile') {
        return <OptionalProfile settings />
      } else {
        return <ThemeSelector /> 
    }
  }
    return (
      <>  
        {buildComponents()}
        {handleSuccess()}
      </>
    )
}

export default AccountsContainer