import { useCallback, useEffect } from "react";

//Components
import ResetPassword from "../../password/resetPassword";
import UpdateMFAForm from "./UpdateMFAForm";
import MultiFactorAuth from '../../mfa/MultiFactorAuth'

// Redux
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { resetSettingsSlice } from "../settingsSlice";
import { resetmFA } from "../../mfa/mFASlice";

// Hooks and services
import { findPermission } from "../../../utils/helperMethods";

//Styles
import { FlexWrapper } from "../../../styles/viewerStyles";
import { deleteCookie, getCookie } from "../../../utils/security";

const SecurityContainer = (props) => {

  const dispatch = useAppDispatch()

  const mFAModalVisible = useAppSelector(state => state.mFA.modalVisible)
  const globalPermissions = useAppSelector(state => state.users.UserPermissions.Global)

  /* HANDLING SECURITY PAGES UNMOUNT */
  const resetSettings = useCallback(
    () => {
        dispatch(resetSettingsSlice())
    }, 
    [dispatch]
  )
  
  useEffect(() => {
    return () => {
      const elevatedToken = process.env.REACT_APP_ELEVATED_TOKEN
      if (getCookie(elevatedToken)) {
        deleteCookie(elevatedToken)
      }
      resetSettings()
      dispatch(resetmFA({saveSecurityAuth: true}))
    };
  }, []); //eslint-disable-line

  /* RENDER FUNCTIONS*/
  const renderContent = () => {
    if (findPermission('1', globalPermissions)) {
      if (mFAModalVisible) {
        return <MultiFactorAuth elevated closeSettings={props.handleClose} />
      } else {
      return props.selected === "Password" ?
        ( <FlexWrapper>
            <ResetPassword process='settings'/>
          </FlexWrapper>
        ) : <UpdateMFAForm />
      }
    } else {
      return null
    };
  }

return renderContent();
};

export default SecurityContainer;
