import styled from 'styled-components'

export const CarouselDiv = styled.div`
  width: 100%;
  overflow: hidden;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-content: center;
`
export const CarouselWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  overflow: visible;
  svg {
    cursor: pointer;
    padding: 0px 5px;
  }
`

export const PositionIndicatorWrapper = styled.div`
  width: 100%;
  justify-content: center;
  height: 20px;
  display: flex;
  flex-flow: row;
  padding-top: 5px;
  padding-bottom: 5px;

  svg {
    cursor: pointer;
    padding: 0;
  }
`
export const PositionIndicatorDiv = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin: 3px;
  border: 1px solid ${props => props.theme.icons.position};
  background-color: ${props => (props.selected ? props.theme.icons.position : null)};
  transition: background-color 0.5s, border-color 0.5s;
`
