import { useCallback, useEffect, useRef, useState } from "react";
import DotLoader from "../../misc/dotLoader";
import { deleteAllCookies } from "../../../utils/security";
import { useViewport } from "../../../utils/hooks";

// Redux
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { callLogin } from "../loginSlice";
import { toggleForgotPassword } from "../../nav/navSlice";

// Styling
import {
  HelpStatement,
  LoginButton,
  LoginField,
  LoginForm,
  LoginInput,
  StatementWrapper,
  LoginStatement
} from "../../login/loginStyles";
import { ErrorMessage } from "../../modals/modalStyles";
import { ErrorWrapper } from "../../settings/settingsStyles";

// Icons
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';

const Login = () => {
  const { width, height } = useViewport();

  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [inputErrors, setInputErrors] = useState('')

  const mobile = useAppSelector(state => state.nav.mobile)
  const errors = useAppSelector((state) => state.login.errors);
  const mfaCriticalError = useAppSelector(state => state.mFA.criticalError)
  const mfaError = useAppSelector(state => state.mFA.error)
  const healthCheckPassed = useAppSelector((state) => state.healthCheck.HealthCheckPassed);
  const loginPending = useAppSelector(state => state.login.loginPending)
  const initialMFARequest = useAppSelector(state => state.mFA.initialMFARequest)
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn)

  const logoutError = sessionStorage.getItem('LogoutMessage')

  const usernameRef = useRef()

  /* RESETTING UPON MOUNT */
    /* If user is anywhere in the login process and refreshes the page, they will be 
  redirected to the main login screen and redux will be reset. 
  This useEffect ensures cookies are cleared as well. */
  useEffect(() => {
    if (!isLoggedIn) {
      deleteAllCookies()
    }
    return () => {};
  }, []);

  /* LOGIN FUNCTIONS */
  const fetchCallLogin = useCallback(
    (payload) => {
      dispatch(callLogin(payload));
    },
    [dispatch]
  );

  //trim password before submitting and check if still long enough
  const clickLogin = (e) => {
    e.preventDefault();
    e.target.blur();

    if (email && password) {
      setInputErrors('')
      const payload = {
        UserName: `${email}`,
        Password: `${password}`,
        Browser: sessionStorage.getItem("userBrowser"),
        BrowserVersion: sessionStorage.getItem("browserVersion"),
        OperatingSystem: sessionStorage.getItem("OSVersion"),
        isTrustedDevice: true,
        ViewportHeight: height,
        ViewportWidth: width
      };
      fetchCallLogin(payload);
    } else {
      setInputErrors(`A ${email ? '' : 'username'}${!email && !password ? ' and a ' : ''}${password ? '' : 'password'} is required.`)
    }
  };

  return (
    (loginPending === 'pending' || initialMFARequest === 'pending') ? (
      <DotLoader isLoading={loginPending === 'pending' || initialMFARequest === 'pending'} width="250px" height="200px" dotSize="30px"/>
    ) : (
      <LoginForm
        id="login-form"
        autoComplete="off"
        onSubmit={(e) => clickLogin(e)}
      >
        <StatementWrapper>
          <LoginStatement mobile={mobile}>
            Easily access all of your lab's data on collections, provider management, invoices, and more.
          </LoginStatement>
        </StatementWrapper>
          {inputErrors ? (
            <ErrorWrapper>
              <ErrorMessage>{inputErrors}</ErrorMessage>
            </ErrorWrapper>
          ) : null}
        {errors.length > 0 || mfaError || logoutError ? (
          <ErrorWrapper data-testid="login-error-wrapper">
            {logoutError ? <ErrorMessage>{logoutError}</ErrorMessage> : null}
            {errors.map((err) => {
              return <ErrorMessage key={err}>{err}</ErrorMessage>
            })}
            {mfaError ? <ErrorMessage>{mfaError}</ErrorMessage> : null}
          </ErrorWrapper>
        ) : null}
        <LoginField mobile={mobile} disabled={!healthCheckPassed}>
          <EmailIcon sx={{ color: '#373d4d', margin: '0 10px'}} />
          <LoginInput
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            value={email}
            autoComplete="off"
            onChange={(e) =>
              setEmail(e.target.value)
            }
            disabled={!healthCheckPassed}
            ref={usernameRef}
            data-test='username'
          />
        </LoginField>
        <LoginField mobile={mobile} disabled={!healthCheckPassed}>
          <KeyIcon sx={{ color: '#373d4d', margin: '0 10px'}} />
          <LoginInput
            type="password"
            id="login-password"
            name="password"
            placeholder="Password"
            value={password}
            autoComplete="off"
            onChange={(e) =>
              setPassword(e.target.value)
            }
            disabled={!healthCheckPassed}
            data-test='password'
        />
        </LoginField>
        <HelpStatement>
          <button
            style={{ backgroundColor: 'rgba(255, 255, 255, 0)', border: 'none'}}
            data-testid="forgot-password-link"
            onClick={(e) => dispatch(toggleForgotPassword(true))}
            type="button"  
          >
              <p
                style={{
                  color: '#FFFFFF',
                  fontSize: '14px',
                  margin: '7px 0',
                  cursor: 'pointer'
                }}>Forgot Password</p>
          </button>
        </HelpStatement>
        {!mfaCriticalError ? (
          <LoginButton
            form="login-form"
            type="submit"
            disabled={!healthCheckPassed}
            data-test="login-submit-button"
            mobile={mobile}
            login
          >
            LOG IN
          </LoginButton>
        ) : null}
      </LoginForm>
    )
  );
};

export default Login;
