import { useState, useEffect, useCallback } from 'react'

import ReportListCategory from './reportListCategory'
import ReportFavorites from './favorites/reportFavorites'

import { ReportHomeContainer, ReportContent, FavoritesWrapper, SideBarButton, CategoryWrapper, MobileReportList, BuildContainer, PermContainer } from './reportStyles'
import DotLoader from '../misc/dotLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import CloseIcon from '@mui/icons-material/Close'

import { Transition } from 'react-transition-group'
import { useSwipeable } from 'react-swipeable'

import { useViewport } from '../../utils/hooks'
import { ModalBackground } from '../../components/modals/modalStyles'
import { fetchReports, sendSaveFavoriteReport, setReportToView } from './reportSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { setTab } from '../nav/navSlice'
import { findPermission } from '../../utils/helperMethods'
import ConnectionSwitcher from '../nav/connectionSwitcher/ConnectionSwitcher'
import Filter from './reportingFilter'
import { ViewerContainer } from '../../styles/viewerStyles'

/*Return an array of only the reports for a given category*/

const ReportingHome = () => {
  const [isActive, setActive] = useState(false)
  const [openCategories, toggleCategories] = useState([])
  const [filter, setFilter] = useState('')

  const { width } = useViewport()
  const breakpoint = 700

  const dispatch = useAppDispatch()

  const reports = useAppSelector(state => state.reports.reports)
  const reportsLoading = useAppSelector(state => state.reports.reportsLoading)
  const reportPermissions = useAppSelector(state => state.users.UserPermissions.Reporting)
  const showConnectionSwitcher = useAppSelector(state => state.users.connectionSwitcher.showConnectionSwitcher)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)
  // const theme = useAppSelector(state => state.users.ThemeName) // 'Light' or 'Dark'

  const getReports = useCallback(() => {
    dispatch(fetchReports({ controller: new AbortController() }))
  }, [dispatch])

  useEffect(() => {
    dispatch(setTab('reporting'))
    getReports()
    return () => {}
  }, []) //eslint-disable-line

  // Re-fetching the reports list once the user switches clients
  useEffect(() => {
    if (reportsLoading !== 'pending') {
      getReports()
    }
  }, [ClientConnectionID])

  const handlers = useSwipeable({
    onSwipedUp: e => handleSwipe(e),
    onSwipedDown: e => handleSwipe(e),
  })

  const handleSwipe = e => {
    if (e.initial[1] < 120) {
      setActive(e.dir === 'Down')
    } else {
      setActive(e.dir !== 'Up')
    }
  }

  const handleCategoriesToggle = cat => {
    let newCategories
    if (openCategories.includes(cat)) {
      newCategories = [...openCategories].filter(el => el !== cat)
    } else {
      newCategories = [...openCategories, cat]
    }
    toggleCategories(newCategories)
  }

  const checkOpen = cat => {
    return openCategories.includes(cat)
  }

  // build array of favorited reports
  const getFavorites = () => {
    return reports.filter(report => report.IsFavorite)
  }

  const handleFilterChange = newFilter => {
    setFilter(newFilter)
  }

  // build hashMap of of {[ReportCategoryName]: [...reports]}
  const getReportList = filter => {
    let reportHash = {}
    if (reports.length > 0) {
      reports.forEach(report => {
        if (report.ReportCategoryName.toLowerCase().includes(filter.toLowerCase()) || report.ReportName.toLowerCase().includes(filter.toLowerCase())) {
          if (reportHash[report.ReportCategoryName]) {
            reportHash[report.ReportCategoryName] = [...reportHash[report.ReportCategoryName], report]
          } else {
            reportHash[report.ReportCategoryName] = [report]
          }
        }
      })
    }
    return reportHash
  }

  const buildReportList = () => {
    const reportList = getReportList(filter)

    if (Object.keys(reportList).length) {
      let categoriesToPrint = []
      let alphabetizedCategories = Object.entries(reportList).sort()
      for (const [categoryName, categoryReports] of alphabetizedCategories) {
        let component = (
          <ReportListCategory
            goToReport={goToReport}
            toggleFavorite={toggleFavorite}
            key={categoryName}
            toggleCategories={handleCategoriesToggle}
            open={!checkOpen(categoryName)}
            category={categoryName}
            reports={categoryReports}
          />
        )
        categoriesToPrint.push(component)
      }
      return categoriesToPrint
    }

    return (
      <div>
        <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>No search results found.</p>
      </div>
    )
  }

  const goToReport = useCallback(
    payload => {
      dispatch(setReportToView(payload))
    },
    [dispatch]
  )

  const toggleFavorite = useCallback(
    report => {
      const payload = {
        ReportID: report.ReportID,
        IsFavorite: !report.IsFavorite,
      }
      dispatch(sendSaveFavoriteReport({ ...payload, controller: new AbortController() }))
    },
    [dispatch]
  )

  const defaultStyle = {
    transition: 'all 300ms ease-in-out',
    height: 0,
  }

  const transitionStyles = {
    entering: { height: 'calc(100% - 120px)' },
    entered: { height: 'calc(100% - 120px)' },
    exiting: { height: 0 },
    exited: { height: 0 },
  }

  const buildMobile = () => {
    return (
      <>
        <SideBarButton onClick={() => setActive(!isActive)}>
          <FontAwesomeIcon icon={!isActive ? faChevronCircleDown : faChevronCircleUp} size="2x" />
        </SideBarButton>
        <Transition in={isActive} timeout={300}>
          {state => (
            <MobileReportList
              {...handlers}
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              className={`hideScroll`}
            >
              <CloseIcon
                onClick={() => setActive(!isActive)}
                style={{
                  fontSize: '30px',
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                }}
              />
              <h4 style={{ textAlign: 'center', marginBottom: '0' }}>Categories</h4>
              {buildReportList()}
            </MobileReportList>
          )}
        </Transition>
        <FavoritesWrapper>
          <ReportFavorites goToReport={goToReport} toggleFavorite={toggleFavorite} loaded={reportsLoading === 'succeeded'} reports={getFavorites()} />
        </FavoritesWrapper>
      </>
    )
  }

  const buildDesktop = () => {
    return (
      <PermContainer>
        {findPermission('14', reportPermissions) ? (
          <BuildContainer>
            {getFavorites().length ? (
              <FavoritesWrapper>
                <h4 style={{ alignSelf: 'flex-start', marginBottom: '10px' }}>Saved Reports</h4>
                <div style={{ width: '100%', maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <ReportFavorites goToReport={goToReport} toggleFavorite={toggleFavorite} loading={reportsLoading === 'pending'} reports={getFavorites()} />
                </div>
              </FavoritesWrapper>
            ) : null}
            <div style={{ display: 'flex', height: '100%', width: '95%', padding: '10px', margin: '10px' }}>
              <CategoryWrapper className={`hideScroll`}>
                <Filter filter={filter} onFilterChange={handleFilterChange} />
                {reports.length > 0 || reportsLoading !== 'pending' ? (
                  <>{buildReportList()}</>
                ) : (
                  <ModalBackground>
                    <DotLoader isLoading={true} />
                  </ModalBackground>
                )}
              </CategoryWrapper>
            </div>
          </BuildContainer>
        ) : null}
      </PermContainer>
    )
  }

  return (
    <ViewerContainer>
      <ConnectionSwitcher />
      <ReportHomeContainer connectionSwitcher={showConnectionSwitcher}>
        <ReportContent>{width <= breakpoint ? buildMobile() : buildDesktop()}</ReportContent>
      </ReportHomeContainer>
    </ViewerContainer>
  )
}

export default ReportingHome
