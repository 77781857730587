import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BannerMessage, RequestStatus } from '../../../type'
import { AppDispatch } from '../../redux/store'
import { api, protectedApi } from '../../utils/api'
import { getCookie } from '../../utils/security'
import { fetchRefreshToken, logout } from '../login/userSlice'

interface AlertState {
  allAlerts: BannerMessage[]
  alertIDs: string[]
  alertLength: number
  modalVisible: boolean
  protectedFetchBannerMessagesStatus: RequestStatus
  unprotectedFetchBannerMessagesStatus: RequestStatus
}

const initialAlertState = {
  allAlerts: [],
  alertIDs: [],
  alertLength: 0,
  modalVisible: false,
  protectedFetchBannerMessagesStatus: 'idle',
  unprotectedFetchBannerMessagesStatus: 'idle',
}

const initialState = initialAlertState as AlertState

export const fetchProtectedBannerMessages = createAsyncThunk<any, any, { dispatch: AppDispatch }>('alert/fetchProtectedBannerMessages', async (payload, thunkAPI) => {
  if (!!getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    return protectedApi(payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN))
      .get('/global/getAllBannerMessages')
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          thunkAPI.dispatch(addBannerMessages(data.results))
          return data
        } else {
          throw {
            data: data,
            thrownError: true,
            error: new Error(),
          }
        }
      })
      .catch(error => {
        if (error.thrownError) {
          error = { response: error }
        }

        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(fetchRefreshToken(() => thunkAPI.dispatch(fetchProtectedBannerMessages(payload))))
        }

        return error.response.data
      })
  } else {
    thunkAPI.dispatch(
      logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' })
    )
  }
})

export const fetchUnprotectedBannerMessages = createAsyncThunk<any, any, { dispatch: AppDispatch }>('alert/fetchUnprotectedBannerMessages', async (payload, thunkAPI) => {
  const handleFetchError = () => {
    const error = {
      MessageID: `banner-fetch-critical`,
      MessageTypeID: '1',
      MessageSummary: 'A network issue has occurred.',
      FullMessage: 'A network issue has occurred. Please contact the help desk at (877) 846-2953.',
      MessageTypeDescription: 'Alert',
      read: false,
    }
    thunkAPI.dispatch(addBannerMessages([error]))
  }

  return api
    .get('/global/getUnprotectedBannerMessages')
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        thunkAPI.dispatch(addBannerMessages(data.results))
        return data
      } else {
        handleFetchError()
        return data
      }
    })
    .catch(error => {
      if (error.thrownError) {
        error = { response: error }
      }
      handleFetchError()
      return error.response.data
    })
})

export const alertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    addBannerMessages(state, action) {
      if (action.payload && action.payload.length > 0) {
        const modifiedAlerts = action.payload.map((alert: any) => {
          return {
            ...alert,
            read: false,
          }
        })
        state.allAlerts = modifiedAlerts
        state.alertLength = modifiedAlerts.length
      }
    },
    clearBannerMessage(state, action) {
      const readIndex = state.allAlerts.findIndex(msg => msg.MessageID === action.payload)
      state.allAlerts[readIndex].read = true
    },
    toggleAlertModal(state) {
      state.modalVisible = !state.modalVisible
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProtectedBannerMessages.pending, state => {
      state.protectedFetchBannerMessagesStatus = 'pending'
    })
    builder.addCase(fetchProtectedBannerMessages.fulfilled, (state, action: any) => {
      state.protectedFetchBannerMessagesStatus = action.payload.success ? 'succeeded' : 'rejected'
    })
    builder.addCase(fetchProtectedBannerMessages.rejected, state => {
      state.protectedFetchBannerMessagesStatus = 'rejected'
    })
    builder.addCase(fetchUnprotectedBannerMessages.pending, state => {
      state.unprotectedFetchBannerMessagesStatus = 'pending'
    })
    builder.addCase(fetchUnprotectedBannerMessages.fulfilled, (state, action: any) => {
      state.unprotectedFetchBannerMessagesStatus = action.payload.success ? 'succeeded' : 'rejected'
    })
    builder.addCase(fetchUnprotectedBannerMessages.rejected, state => {
      state.unprotectedFetchBannerMessagesStatus = 'rejected'
    })
  },
})

export const { addBannerMessages, clearBannerMessage, toggleAlertModal } = alertSlice.actions
export default alertSlice.reducer
