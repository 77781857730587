import { setTheme } from '../../login/userSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { ThemeContainer, Theme, ThemeBoxColor, ThemeName } from '../../modals/modalStyles';
import { SectionTitle } from '../settingsStyles';
import { FlexWrapper } from '../../../styles/viewerStyles';

const ThemeSelector = (props) => {
  const dispatch = useAppDispatch()

  const theme = useAppSelector(state => state.users.ThemeName)
  const mobile = useAppSelector(state => state.nav.mobile)
  
  const changeTheme = (themeID, themeName) => {
    dispatch(
      setTheme({
        ThemeID: themeID,
        ThemeName: themeName,
        controller: new AbortController(),
      })
    )
    // saveTheme has been abstracted to services/api.js
  }

  const renderThemeContainer = (id, name) => {
    return (
      <Theme onClick={() => changeTheme(id, name)} currentTheme={theme} name={name}>
          <ThemeBoxColor color="#1A5192" />
          <ThemeBoxColor color="#692C87" />
          <ThemeBoxColor color="#B65EC7" />
          <ThemeBoxColor color="#7ED1E7" />
          <ThemeName>{name}</ThemeName>
      </Theme>
    )
  }

  return (
    <FlexWrapper height='70%' flexFlow="column">
      <SectionTitle mobile={mobile} settings style={{marginBottom: '60px'}}>Select your theme</SectionTitle>
      <ThemeContainer>
        {renderThemeContainer('1', 'Light')}
        {renderThemeContainer('2', 'Dark')}
      </ThemeContainer>
    </FlexWrapper>
  )
}

export default ThemeSelector
