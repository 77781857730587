import React, { useState, useEffect, useContext, useCallback } from 'react'
import { DashCard } from '../dashboardStyles'
import * as wjChart from '@grapecity/wijmo.react.chart'
import BarChartPlaceholder from '../placeholders/BarChartPlaceholder'
import { ThemeContext } from 'styled-components'
import { convertNumToCurrency } from '../../../utils/helperMethods'
import CustomTooltip from '../../misc/CustomTooltip'
import BrokenChart from '../placeholders/BrokenChart'
import AgingAnalysis from './AgingAnalysis'
import AgingAnalysisMobile from './AgingAnalysisMobile'
import { fetchChartData } from '../dashboardSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { useViewport } from '../../../utils/hooks'
import { Info } from '@mui/icons-material'

const AgingAnalysisChartContainer = ({ formatYLabels, goToReport, visible }) => {
  const [showData, setShowData] = useState([])
  const [header, setHeader] = useState('Aging Analysis')
  const [bindingX, setBindingX] = useState('AgingBucket')
  const [bucket, changeBucket] = useState('')
  const [mobile, setMobile] = useState(false)
  // const [loaded, setLoaded] = useState(false)

  const { width } = useViewport()
  const dispatch = useAppDispatch()
  const arData = useAppSelector(state => state.dashboard.ar.data)
  const { reportName, error, loaded } = useAppSelector(state => state.dashboard.ar)
  const clientSwitching = useAppSelector(state => state.users.switchingClients)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)

  const fetchArData = useCallback(() => {
    dispatch(fetchChartData({ chart: 'ar' }))
  }, [dispatch])

  useEffect(() => {
    if (arData?.length > 0 && visible && ClientConnectionID) {
      setShowData(getGroupData(arData))
    }
    return () => {}
  }, [arData, ClientConnectionID])

  // useEffect(() => {
  //     if(showData.length > 0){
  //         setLoaded(true)
  //     }
  //     return () => {
  //     }
  // }, [showData.length])

  useEffect(() => {
    if (width < 1025) {
      setMobile(true)
    } else {
      setMobile(false)
    }
    return () => {}
  }, [width])

  const getGroupData = data => {
    const dataArr = []
    data?.forEach(current => {
      let payer = current.PayorDescription
      if (payer === 'Blue Cross Blue Shield') {
        payer = 'BCBS'
      }
      if (payer === 'Commercial Insurance') {
        payer = 'Commercial'
      }
      if (payer === 'United Healthcare') {
        payer = 'UHC'
      }
      let datapoint = dataArr.find(el => el.AgingBucket === current.AgingBucket)
      if (datapoint) {
        if (datapoint[payer]) {
          datapoint[payer] += current.VoucherBalance
        } else {
          datapoint[payer] = current.VoucherBalance
        }
      } else {
        let newDatapoint = {}
        newDatapoint.AgingBucket = current.AgingBucket
        newDatapoint[payer] = current.VoucherBalance
        dataArr.push(newDatapoint)
      }
    })
    // sort array by AgingBucket                        Gets the first 1-3 digits of the AgingBucket String and parse into Number
    return dataArr.sort((a, b) => parseInt(a.AgingBucket.split('-')[0].match(/\d/g).join('')) - parseInt(b.AgingBucket.split('-')[0].match(/\d/g).join('')))
  }

  const groupByPayorByAgingBucket = useCallback(() => {
    let arr = [...arData].filter(obj => obj.AgingBucket === bucket)
    let hash = []
    arr.forEach(curr => {
      let datapoint = hash.find(obj => obj.PayorDescription === curr.PayorDescription)
      if (datapoint) {
        datapoint.VoucherBalance += curr.VoucherBalance
      } else {
        let newObj = {}
        newObj.PayorDescription = curr.PayorDescription
        newObj.VoucherBalance = curr.VoucherBalance
        hash.push(newObj)
      }
    })
    return hash.sort((a, b) => (a.PayorDescription > b.PayorDescription ? 1 : -1))
  }, [arData, bucket])

  useEffect(() => {
    if (bindingX === 'AgingBucket') {
      setShowData(getGroupData(arData))
    } else if (bindingX === 'PayorDescription') {
      setShowData(groupByPayorByAgingBucket(arData))
    }
  }, [arData, bindingX, groupByPayorByAgingBucket])

  useEffect(() => {
    setBindingX('AgingBucket')
    changeBucket('')
  }, [ClientConnectionID])

  const theme = useContext(ThemeContext)

  const selectionChanged = e => {
    if (e && e.selection) {
      const point = e.selection.collectionView.currentItem
      if (Object.keys(point).includes('PayorDescription')) {
        changeBucket('')
        setShowData(getGroupData(arData))
        setBindingX('AgingBucket')
        setHeader('Aging Analysis')
      } else {
        changeBucket(point.AgingBucket)
        setBindingX('PayorDescription')
        e.selection = null
      }
    } else {
      changeBucket('')
      setShowData(getGroupData(arData))
      setBindingX('AgingBucket')
      setHeader('Aging Analysis')
    }
  }

  const getPayors = () => {
    if (showData.length > 0) {
      const payers = []
      showData.forEach(data => {
        Object.keys(data).forEach(key => {
          if (!payers.includes(key)) {
            payers.push(key)
          }
        })
      })
      return payers.filter(el => el !== 'AgingBucket').sort()
    } else {
      return []
    }
  }

  const buildTooltip = ht => {
    if (ht.item.AgingBucket) {
      // get values from each bar in group and sum them
      let total = Object.values(ht.item)
        .filter(el => typeof el === 'number')
        .reduce((a, b) => a + b)
      return `<span class='chart-tooltip'>
            <strong>${ht.name ? ht.name : ht.item.PayorDescription}</strong><br/>
            ${convertNumToCurrency(ht.value)}<br/>
            <br/>
            <strong>${ht.item.AgingBucket} Total</strong><br/>
            ${convertNumToCurrency(total)}
            </span>`
    } else {
      return `<span class='chart-tooltip'>
            <strong>${ht.name ? ht.name : ht.item.PayorDescription}</strong><br/>
            ${convertNumToCurrency(ht.value)}
            </span>
            `
    }
  }

  const formatXLabels = (eng, label) => {
    if (label.text === 'Blue Cross Blue Shield') {
      label.text = 'BCBS'
    }
    if (label.text === 'United Healthcare') {
      label.text = 'UHC'
    }
    if (label.text === 'Commercial Insurance') {
      label.text = 'Commercial'
    }
    if (label.text === 'United Medical Resources') {
      label.text = 'UMR'
    }
    if (mobile) {
      if (label.text === 'Cigna Commercial') label.text = 'Cigna Comm'
      if (label.text === 'Medicare Advantage') label.text = 'Medicare Adv'
      if (label.text === 'Tricare/Champus/VA') label.text = 'TRI/CHA/VA'
    }
    return label
  }

  const calculateYAxisMax = () => {
    // get largest VoucherBalance from array of collection objects
    const largestNum = Math.ceil(
      showData
        .map(data =>
          Object.values(data)
            .filter(el => typeof el === 'number')
            .reduce((a, b) => a + b)
        )
        .sort((a, b) => a - b)
        .pop()
    ).toString()
    // get the place value of left most num
    const splitNums = largestNum.toString().split('')
    let newTens = parseInt(
      splitNums
        .map((num, i) => {
          let numVal
          if (i === 0) {
            splitNums[1] > 5 ? (numVal = (parseInt(num) + 1).toString()) : (numVal = num)
          } else if (i === 1) {
            num > 5 ? (numVal = '0') : (numVal = (parseInt(num) + 1).toString())
          } else {
            numVal = '0'
          }
          return numVal
        })
        .join('')
    )
    return newTens
  }

  const formatBars = (engine, ht, defaultFormat, agingBucket = false) => {
    let cssName = agingBucket ? ht.name : ht.item.PayorDescription
    if (cssName === 'Blue Cross Blue Shield') cssName = 'bcbs'
    if (cssName === 'Cigna Commercial') cssName = 'cignacom'
    if (cssName === 'Cigna Medicare') cssName = 'cignamca'
    if (cssName === 'Commercial Insurance') cssName = 'commercial'
    if (cssName === 'Medicare Advantage') cssName = 'mcareadv'
    if (cssName === 'Patient Responsibility') cssName = 'patient'
    if (cssName === 'Tricare/Champus/VA') cssName = 'tricare'
    if (cssName === 'United Healthcare') cssName = 'uhc'
    if (cssName === 'United Medical Resources') cssName = 'umr'
    if (cssName === 'Veterans') cssName = 'va'

    engine.fill = theme.providers?.[cssName.toLowerCase()]?.main || '#CCC'
    engine.stroke = 'none'
    defaultFormat()
  }

  const buildSeries = () => {
    if (bindingX === 'AgingBucket') {
      return getPayors().map(payer => {
        let cssName = payer
        if (cssName === 'Blue Cross Blue Shield') cssName = 'BCBS'
        if (cssName === 'Cigna Commercial') cssName = 'cignacom'
        if (cssName === 'Cigna Medicare') cssName = 'cignamca'
        if (cssName === 'Commercial Insurance') cssName = 'Commercial'
        if (cssName === 'Medicare Advantage') cssName = 'mcareadv'
        if (cssName === 'Patient Responsibility') cssName = 'patient'
        if (cssName === 'Tricare/Champus/VA') cssName = 'tricare'
        if (cssName === 'United Healthcare') cssName = 'UHC'
        if (cssName === 'United Medical Resources') cssName = 'umr'
        if (cssName === 'Veterans') cssName = 'va'
        return (
          <wjChart.FlexChartSeries
            cssClass={cssName.toLowerCase()}
            binding={payer}
            name={payer}
            key={payer}
            tooltipContent={ht => (mobile ? '' : buildTooltip(ht))}
            itemFormatter={(engine, ht, defaultFormat) => formatBars(engine, ht, defaultFormat, true)}
          ></wjChart.FlexChartSeries>
        )
      })
    } else {
      return (
        <wjChart.FlexChartSeries
          cssClass="bar"
          binding="VoucherBalance"
          itemFormatter={(engine, ht, defaultFormat) => formatBars(engine, ht, defaultFormat)}
          tooltipContent={ht => (mobile ? '' : buildTooltip(ht))}
        ></wjChart.FlexChartSeries>
      )
    }
  }

  const renderChart = () => {
    if (loaded === 'succeeded') {
      if (error) {
        return <BrokenChart refreshFn={fetchArData} header={'Aging Analysis'} chartType={1} />
      }
      if (mobile) {
        return (
          <AgingAnalysisMobile
            clientSwitching={clientSwitching}
            formatYLabels={formatYLabels}
            formatXLabels={formatXLabels}
            buildSeries={buildSeries}
            showData={showData}
            header={header}
            bindingX={bindingX}
            bucket={bucket}
            selectionChanged={selectionChanged}
            calculateYAxisMax={calculateYAxisMax}
            goToReport={goToReport}
            reportName={reportName}
          />
        )
      } else {
        return (
          <AgingAnalysis
            clientSwitching={clientSwitching}
            formatYLabels={formatYLabels}
            formatXLabels={formatXLabels}
            buildSeries={buildSeries}
            showData={showData || []}
            header={header}
            bindingX={bindingX}
            bucket={bucket}
            selectionChanged={selectionChanged}
            calculateYAxisMax={calculateYAxisMax}
            goToReport={goToReport}
            reportName={reportName}
          />
        )
      }
    }
  }

  return (
    <>
      {visible ? (
        <DashCard loaded={loaded !== 'succeeded'} cardWidth={width > 1220 ? 3 : 6} error={error}>
          {loaded === 'pending' || clientSwitching === 'pending' ? (
            <BarChartPlaceholder cardWidth={3} title="Aging Analysis by Days" />
          ) : arData?.length ? (
            <>
              <CustomTooltip title="Snapshot of accounts receivable, broken down by 30 day aging bucket. Click on any column to expand by payer.">
                <Info className="tooltip-icon" />
              </CustomTooltip>
              {renderChart()}
            </>
          ) : (
            <BarChartPlaceholder loaded title="Aging Analysis by Days" />
          )}
        </DashCard>
      ) : null}
    </>
  )
}

export default AgingAnalysisChartContainer
