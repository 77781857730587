import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const TabDiv = styled.div`
  width: ${props => (props.mobile ? '100%' : '200px')};
  min-width: 200px;
  height: 100%;
  height: -webkit-fill-available;
  position: ${props => (props.mobile ? 'absolute' : 'static')};
  display: flex;
  flex-direction: column;
  z-index: 200;
  background-color: #373d4d;
  transition: transform 0.75s;

  ${props =>
    props.mobile
      ? props.mobileSidebar
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `
      : null}
`

export const TabWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin-top: ${props => (props.mobile ? '95px' : 0)};
  text-align: center;
  line-height: 21px;
  font-family: 'MaterialIcons-Regular', 'Material Icons', sans-serif;
  color: #ffffff;
`

export const IconWrapper = styled.div`
  width: 100%;
  height: 120px;
`

export const MobileIconWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const NavImage = styled.img`
  max-width: 76px;
  max-height: 60px;
  margin-top: 20px;
`

export const MobileNavImage = styled.img`
  width: 40px;
  height: 40px;
`

export const TabBlock = styled.div`
  height: 10%;
  width: ${props => (props.purpose === 'support' ? 'auto' : '100%')};
  min-height: 90px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: ${props => (props.purpose === 'support' ? 'absolute' : 'relative')};
  bottom: ${props => (props.purpose === 'support' ? 0 : 'auto')};
  cursor: pointer;
  color: ${props => (props.selected ? '#7ED1E7' : '#FFF')};
  :hover {
    color: #7ed1e7;
  }
`
export const SelectedTab = styled.div`
  height: 45px;
  width: 5px;
  background-color: ${props => (props.selected ? '#7ed1e7' : '#373d4d')};
  margin-right: 25px;
`

export const TabLabel = styled.p`
  font-family: 'WorkSans-Regular', 'Work Sans', sans-serif;
  font-size: 18px;
  margin-left: 10px;
`

export const TabTrapezoid = styled(Link)`
  position: relative;
  text-decoration: none;
  min-height: 100px;
  height: 100%;
  min-height: 90px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: ${props => (props.selected || props.hovered ? props.theme[props.purpose].main : 'transparent')};
  width: 100px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: visible;
`

export const HiddenHover = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 100;
`
