import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Client,
  FeaturePermission,
  RequestStatus,
  UserFeaturePermission,
} from "../../../type";
import { AppDispatch, RootState } from "../../redux/store";
import { getRefreshToken, protectedApi } from "../../utils/api";
import { getCookie, endSession } from "../../utils/security";

interface UserState {
  UserName: string;
  UserID: string | number;
  UserInitials: string;
  PreferredName: string;
  FirstName: string;
  LastName: string;
  UserPermissions: FeaturePermission;
  ResultsPerPage: number;
  fetchingUser: RequestStatus;
  switchingClients: RequestStatus;
  appRedirecting: boolean;
  ThemeID: number;
  ThemeName: "Light" | "Dark";
  TimeoutMinutes: number;
  Client: Client;
  connectionSwitcher: {
    showConnectionSwitcher: boolean;
    clients: any[];
    recentClients: any[];
    selectedClientApps: any[];
    error: string;
  };
  settingCookies: RequestStatus;
  UserEmail: string;
  ClientAdminPrivileges: any[];
  requests: {
    fetchUserInfo: RequestStatus;
    fetchAccessibleClients: RequestStatus;
    fetchAccessibleApps: RequestStatus;
    fetchClientConnection: RequestStatus;
  };
  DaysToPasswordExpiration: null | number;
}

// const initialUserState_TEST = {
//   UserName: "Test",
//   UserID: "1",
//   UserInitials: "TW",
//   FirstName: "Test",
//   LastName: "West",
//   Permissions: [],
//   UserPermissions: {},
//   fetchingUser: "idle",
//   switchingClients: "idle",
//   ResultsPerPage: 10,
//   clients: [],
//   recentClients: [],
//   ThemeID: 1,
//   ThemeName: "Light",
//   TimeoutMinutes: 15,
//   Client: {
//     ClientAbbrv: "",
//     ClientApps: [],
//     ClientID: "",
//     ClientName: "",
//     ClientTypeID: "",
//     IsActive: false,
//   },
//   showConnectionSwitcher: false,
//   clientsFetched: "idle",
//   UserEmail: "",
//   settingCookies: "idle",
//   apps: [
//     {
//       app: "PFR",
//       icon: PFRIcon,
//       button: PFRButton,
//       location: process.env.REACT_APP_PFR_URL,
//       isActive: false,
//       isAvailable: false,
//       PermissionID: "3",
//     },
//     {
//       app: "ClientPortal",
//       icon: PortalIcon,
//       button: PortalButton,
//       location: process.env.REACT_APP_PORTAL_URL,
//       isActive: true,
//       isAvailable: false,
//       PermissionID: "2",
//     },
//     {
//       app: "DualRemit",
//       icon: DRIcon,
//       button: DRButton,
//       location: process.env.REACT_APP_DR_URL,
//       isActive: false,
//       isAvailable: false,
//       PermissionID: "4",
//     },
//   ],
//   ClientAdminPrivileges: [],
// };

const initialUserState = {
  UserName: "",
  UserID: "",
  UserInitials: "",
  PreferredName: "",
  FirstName: "",
  LastName: "",
  UserPermissions: {},
  fetchingUser: "idle",
  switchingClients: "idle",
  appRedirecting: false,
  ResultsPerPage: 10,
  ThemeID: 1,
  ThemeName: "Light",
  TimeoutMinutes: 15,
  Client: {
    ClientAbbrv: "",
    ClientName: "",
    ClientID: "",
    ClientTypeID: "",
    ClientApps: [],
    LastAccessedAppID: "",
    ClientConnectionID: "",
  },
  connectionSwitcher: {
    showConnectionSwitcher: false,
    clients: [],
    recentClients: [],
    selectedClientApps: [],
    error: '',
  },
  UserEmail: "",
  settingCookies: "idle",
  ClientAdminPrivileges: [],
  requests: {
    fetchUserInfo: 'idle',
    fetchAccessibleClients: 'idle',
    fetchAccessibleApps: 'idle',
    fetchClientConnection: 'idle',
  },
  DaysToPasswordExpiration: null,
};

const initialState = initialUserState as UserState;
// const initialState = initialUserState_TEST as UserState;

export const setCookie = createAsyncThunk<any, any>(
  "user/settingCookie",
  async (payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((cookie: { name: string; val: string }) => {
        if (cookie.name === process.env.REACT_APP_REFRESH_TOKEN) {
          document.cookie = `${process.env.REACT_APP_REFRESH_TOKEN}=${
            cookie.val
          }; path=/; ${
            process.env.REACT_APP_COOKIE_OPTIONS
          }`;
        } else if (cookie.name === "_redirected") {
          document.cookie = `${cookie.name}=${cookie.val}; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`;
        } else {
          document.cookie = `${cookie.name}=${cookie.val}; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`;
        }
      });
      return payload;
    } else {
      if (payload.name === process.env.REACT_APP_REFRESH_TOKEN) {
        document.cookie = `${process.env.REACT_APP_REFRESH_TOKEN}=${
          payload.val
        }; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`;
      } else if (payload.name === "_redirected") {
        document.cookie = `${payload.name}=${payload.val}; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`;
      } else {
        document.cookie = `${payload.name}=${payload.val}; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`;
      }
      return payload;
    }
  }
);

export const fetchRefreshToken = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("user/fetchRefreshToken", async (payload, thunkAPI) => {
  // @ts-ignore
  if (
    thunkAPI.getState().users.settingCookies === "pending"
  ) {
    return { fetchingNewToken: true };
  } else {
    return getRefreshToken(payload);
  }
});

export const fetchInitialState = createAsyncThunk<
  any,
  { controller: AbortController },
  { dispatch: AppDispatch }
>("user/fetchInitialState", async (payload, thunkAPI) => {
  const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN);
  if (token) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .get("/users/getInitialState")
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          // Checking if user permissions have changed since the Client information is cached
          const storedSessionData = JSON.parse(sessionStorage.getItem('ActiveClientDetails') as string)
          const ClientConnection = data.initialState.ClientConnection

          // Setting ClientConnectionID for fetchAccessibleClients call
            // First check sessionStorage
            // Then check localStorage
            // Then call fetchClientConnection

            if (sessionStorage.getItem('ActiveClientDetails')) {
              thunkAPI.dispatch(updateClientFromStorage('session'))
            } else if (localStorage.getItem('ActiveClientDetails')) {
              thunkAPI.dispatch(updateClientFromStorage('local'))
            } else {
              thunkAPI.dispatch(fetchClientConnection({controller: new AbortController()}))
            }

          if (storedSessionData.ClientConnectionID === ClientConnection.ClientConnectionID && // must add a client check or will run when switching clients and update from local storage which causes bugs with multiple tabs
              JSON.stringify(storedSessionData.UserPermissions) !== JSON.stringify(ClientConnection.UserPermissions)
          ) {
            // If this if statement is true, a client's UserPermissions have changed and the cache and redux need to be updated
            // JSON.stringify is necessary to compare two objects in JavaScript
            localStorage.setItem('ActiveClientDetails', JSON.stringify(ClientConnection))
            localStorage.setItem('ClientConnectionID', ClientConnection.ClientConnectionID)
            thunkAPI.dispatch(updateClientFromStorage('local'))
          }

          sessionStorage.setItem('User', data.initialState.UserInfo.UserEmail)
          localStorage.setItem('User', data.initialState.UserInfo.UserEmail)

          thunkAPI.dispatch(fetchAccessibleClients({controller: new AbortController()}))
          thunkAPI.dispatch(toggleConnectionSwitcher({ toggle: false }))
          return data
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() => {
              thunkAPI.dispatch(
                fetchInitialState({ controller: new AbortController() })
              )
            })
          );
        } else {
          thunkAPI.dispatch(logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' }))
        }
        return error.response.data
      });
  } else {
    if (window.location.pathname.includes("login")) {
      return { data: { success: false }, notLoggedIn: true };
    } else {
      thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
    }
  }
});

export const fetchUserInfo = createAsyncThunk<
  any,
  { controller: AbortController },
  { dispatch: AppDispatch }
>("user/fetchUserInfo", async (payload, thunkAPI) => {
  const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN);
  if (token) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .get("/users/getUserInfo")
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data;
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() => {
              thunkAPI.dispatch(
                fetchUserInfo(payload)
              )
            })
          );
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
});

export const fetchAccessibleClients = createAsyncThunk<
  any,
  { controller: AbortController },
  { dispatch: AppDispatch }
>("user/fetchAccessibleClients", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .get("/users/getAccessibleClients")
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data;
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() =>
              thunkAPI.dispatch(fetchAccessibleClients(payload))
            )
          );
        } else {
          thunkAPI.dispatch(setConnectionSwitcherError(error.response.data.message))
          setTimeout(() => {
            thunkAPI.dispatch(toggleConnectionSwitcher(false))
          }, 3 * 1000)
        }
        return error.response.data
      })
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
});

// In ConnectionSwitcher, fetches the available apps for the client the user selects
export const fetchAccessibleApps = createAsyncThunk<
  any,
  { controller: AbortController, ClientID: string },
  { dispatch: AppDispatch }
>("user/fetchAccessibleApps", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .post("/users/getAccessibleApps", {ClientID: payload.ClientID})
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data;
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() => thunkAPI.dispatch(fetchAccessibleApps(payload)))
          );
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
});

export const fetchClientConnection = createAsyncThunk<
  any,
  { controller: AbortController, backgroundUpdate?: boolean },
  { dispatch: AppDispatch }
>("user/fetchClientConnection", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .get("/users/getActiveConnectionDetails")
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          // Updating session and local storage
          const activeConnection = data.activeConnection
          sessionStorage.setItem('ActiveClientDetails', JSON.stringify(activeConnection))
          sessionStorage.setItem('ClientConnectionID', activeConnection.ClientConnectionID)
          localStorage.setItem('ActiveClientDetails', JSON.stringify(activeConnection))
          localStorage.setItem('ClientConnectionID', activeConnection.ClientConnectionID)

          return {...payload, ...data};
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() =>
              thunkAPI.dispatch(fetchClientConnection(payload))
            )
          );
        } else {
          thunkAPI.dispatch(setConnectionSwitcherError(error.response.data.message))
          setTimeout(() => {
            thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
          }, 3 * 1000)
        }
        return {...payload, ...error.response.data}
      });
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
});

export const switchConnections = createAsyncThunk<
  any,
  { AppID: string; ClientID: string; backgroundUpdate?: boolean;  controller: AbortController},
  { dispatch: AppDispatch }
>("user/switchConnections", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .post("/users/updateClientConnection", {
        ClientID: payload.ClientID,
        AppID: payload.AppID,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          thunkAPI.dispatch(fetchClientConnection({controller: new AbortController(), backgroundUpdate: payload.backgroundUpdate}))
          return {...payload, ...data}
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() => thunkAPI.dispatch(switchConnections(payload)))
          );
          // @ts-ignore
        } else {
          thunkAPI.dispatch(setConnectionSwitcherError(error.response.data.message))
          setTimeout(() => {
            thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
          }, 3 * 1000)
        }
        return {...payload, ...error.response.data}
      });
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
});

export const setTheme = createAsyncThunk<
  any,
  { ThemeID: number; ThemeName: "Light" | "Dark"; controller: AbortController },
  { dispatch: AppDispatch }
>("users/setTheme", async (payload, thunkAPI) => {
  const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN);
  if (token) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .post("/users/settings/saveSettings", payload)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return {...data, ...payload}
        } else {
          throw {
            data: data,
            thrownError: true,
            error: new Error(),
          }
        }
      })
      .catch((error) => {
        if (error.thrownError) {
          error = {response: error}
        }

        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() => thunkAPI.dispatch(setTheme(payload)))
          );
        }
        return error.response.data
      })
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
});

export const logout = createAsyncThunk<
  any,
  { controller: AbortController, message? : string },
  { dispatch: AppDispatch }
>("user/logout", async (payload, thunkAPI) => {
  let token;

  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    token = process.env.REACT_APP_REFRESH_TOKEN
  } else if (getCookie(process.env.REACT_APP_ACCESS_TOKEN)) {
    token = process.env.REACT_APP_ACCESS_TOKEN
  } else if (getCookie(process.env.REACT_APP_LOGIN_TOKEN)) {
    token = process.env.REACT_APP_LOGIN_TOKEN
  } else if (getCookie(process.env.REACT_APP_FORGOT_PASSWORD_TOKEN)) {
    token = process.env.REACT_APP_FORGOT_PASSWORD_TOKEN
  } else if (getCookie(process.env.REACT_APP_FORGOT_PASSWORD_AUTH_TOKEN)) {
    token = process.env.REACT_APP_FORGOT_PASSWORD_AUTH_TOKEN
  } else if (getCookie(process.env.REACT_APP_PRELOGIN_TOKEN)) {
    token = process.env.REACT_APP_PRELOGIN_TOKEN
  }

  return protectedApi(
    payload.controller,
    getCookie(token)
  )
    .get("/auth/logout")
    .then((res) => res.data)
    .then((data) => {
      endSession(payload?.message)
    });
});

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    toggleConnectionSwitcher(state, action?) {
      state.connectionSwitcher.showConnectionSwitcher = action.payload ? action.payload.toggle : !state.connectionSwitcher.showConnectionSwitcher;
    },
    resetLaunchState(state) {
      state.switchingClients = "idle";
      state.settingCookies = "idle";
    },
    updateUserPermission(state, action) {
      if (
        state.Client.ClientID === action.payload.ClientID &&
        !!state.UserPermissions[action.payload.category]
      ) {
        action.payload.permitted.forEach(
          (permittedPermission: {
            PermissionID: string;
            Permitted: boolean;
          }) => {
            const foundPermission = state.UserPermissions[
              action.payload.category
            ].find((permission: UserFeaturePermission) => {
              return (
                permission.PermissionID === permittedPermission.PermissionID
              );
            });
            if (foundPermission) {
              foundPermission.UserHasPermission = true;
            }
          }
        );
        action.payload.unpermitted.forEach(
          (unpermittedPermission: {
            PermissionID: string;
            Permitted: boolean;
          }) => {
            const foundPermission = state.UserPermissions[
              action.payload.category
            ].find((permission: UserFeaturePermission) => {
              return (
                permission.PermissionID === unpermittedPermission.PermissionID
              );
            });
            if (foundPermission) {
              foundPermission.UserHasPermission = false;
            }
          }
        );
      }
    },
    findEditPermissionClients(state, action) {
      if (action.payload) {
        const currentUserAdminPermissions = action.payload.filter(
          (userPermission: any) => userPermission.UserID === state.UserID
        );
        if (currentUserAdminPermissions[0].Features.Admin) {
          state.ClientAdminPrivileges = currentUserAdminPermissions.reduce(
            (a: any, b: any) => {
              const clientAdminPrivilege = {
                clientID: b.ClientID,
                Admin: [...b.Features.Admin],
              };
              a.push(clientAdminPrivilege);
              return a;
            },
            []
          );
        }
      }
    },
    setConnectionSwitcherError(state, action) {
      state.connectionSwitcher.error = action.payload
    },
    updateAppRedirecting(state, action) {
      state.appRedirecting = action.payload
    },
    updateClientFromStorage(state, action) {
      const storage = action.payload === 'session' ? sessionStorage : localStorage

      // Updating sessionStorage from localStorage, if needed
      if (action.payload === 'local') {
        //@ts-ignore
        sessionStorage.setItem('ActiveClientDetails', localStorage.getItem('ActiveClientDetails'))
        //@ts-ignore
        sessionStorage.setItem('ClientConnectionID', localStorage.getItem('ClientConnectionID'))
        //@ts-ignore
        sessionStorage.setItem('User', localStorage.getItem('User'))
      } else {
        // When updating from sessionStorage, checking that localStorage exists, and if not, updating it
        if (!localStorage.getItem('ActiveClientDetails')) {
          //@ts-ignore
          localStorage.setItem('ActiveClientDetails', sessionStorage.getItem('ActiveClientDetails'))
          //@ts-ignore
          localStorage.setItem('ClientConnectionID', sessionStorage.getItem('ClientConnectionID'))
          //@ts-ignore
          localStorage.setItem('User', sessionStorage.getItem('User'))
        }
      }

      // Updating redux
      // Needs to be updated last as once LastAccessedAppID is updated, it may trigger app forwarding
      //@ts-ignore
      const ActiveClientDetails = JSON.parse(storage.getItem('ActiveClientDetails'))

      state.UserPermissions = ActiveClientDetails.UserPermissions

      state.Client = {
        ClientAbbrv: ActiveClientDetails.ClientAbbr,
        ClientName: ActiveClientDetails.ClientName,
        ClientID: ActiveClientDetails.ClientID,
        ClientTypeID: ActiveClientDetails.ClientTypeID,
        ClientApps: ActiveClientDetails.AvailableApps,
        LastAccessedAppID: ActiveClientDetails.LastAccessedAppID,
        ClientConnectionID: ActiveClientDetails.ClientConnectionID,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCookie.pending, (state) => {
      state.settingCookies = "pending";
    });
    builder.addCase(setCookie.fulfilled, (state, action) => {
      if (action.payload) {
        state.settingCookies = "succeeded";
      }
    });
    builder.addCase(fetchInitialState.rejected, (state) => {
      state.fetchingUser = "rejected";
    });
    builder.addCase(fetchInitialState.pending, (state) => {
      state.fetchingUser = "pending";
    });
    builder.addCase(fetchInitialState.fulfilled, (state, action) => {
      if (action.payload && action.payload.success) {
        // Setting UserInfo
        const userInfo = action.payload.initialState.UserInfo

        state.UserID = userInfo.UserID;
        state.FirstName = userInfo.FirstName;
        state.LastName = userInfo.LastName;
        state.UserInitials = userInfo.UserInitials;
        state.UserEmail = userInfo.UserEmail;
        state.PreferredName = userInfo.PreferredName
        state.DaysToPasswordExpiration = userInfo.DaysToPasswordExpiration

        // Setting AppSettings
        const appInfo = action.payload.initialState.AppSettings

        state.ThemeID = appInfo.ThemeID;
        state.ThemeName = appInfo.ThemeName;
        state.TimeoutMinutes = appInfo.TimeoutMinutes;
        state.ResultsPerPage = appInfo.ResultsPerPage

        state.fetchingUser = "succeeded";
      } else {
        state.fetchingUser = "rejected";
      }
    });
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.requests.fetchUserInfo = "pending";
      state.connectionSwitcher.error = ''
    });
    builder.addCase(fetchUserInfo.rejected, (state) => {
      state.requests.fetchUserInfo = "rejected";
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      if (action.payload && action.payload.success) {
        state.PreferredName = action.payload.results.PreferredName
        state.UserInitials = action.payload.results.UserInitials
        state.requests.fetchUserInfo = "succeeded";
      } else {
        state.requests.fetchUserInfo = "rejected";
      }
    });
    builder.addCase(fetchAccessibleClients.pending, (state) => {
      state.requests.fetchAccessibleClients = "pending";
      state.connectionSwitcher.error = ''
    });
    builder.addCase(fetchAccessibleClients.rejected, (state, action: any) => {
      state.requests.fetchAccessibleClients = "rejected";
      state.connectionSwitcher.error = action.payload.message
    });
    builder.addCase(fetchAccessibleClients.fulfilled, (state, action) => {
      if (action.payload && action.payload.success) {
        state.connectionSwitcher.clients = action.payload.possibleConnections
        state.connectionSwitcher.recentClients = action.payload.recentConnections;
        state.requests.fetchAccessibleClients = "succeeded";
      } else {
        state.requests.fetchAccessibleClients = "rejected";
        state.connectionSwitcher.error = action.payload.message
      }
    });
    builder.addCase(fetchAccessibleApps.pending, (state) => {
      state.requests.fetchAccessibleApps = "pending";
      state.connectionSwitcher.selectedClientApps = [];
      state.connectionSwitcher.error = ''
    });
    builder.addCase(fetchAccessibleApps.rejected, (state, action: any) => {
      state.requests.fetchAccessibleApps = "rejected";
      state.connectionSwitcher.error = action.payload.message
    });
    builder.addCase(fetchAccessibleApps.fulfilled, (state, action) => {
      if (action.payload && action.payload.success) {
        state.connectionSwitcher.selectedClientApps = action.payload.accessibleApps || [];
        state.requests.fetchAccessibleApps = "succeeded";
      } else {
        state.requests.fetchAccessibleApps = "rejected";
        state.connectionSwitcher.error = action.payload.message
      }
    });
    builder.addCase(setTheme.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.ThemeID = action.payload.ThemeID
        state.ThemeName = action.payload.ThemeName
      }
    });
    builder.addCase(fetchClientConnection.pending, (state, action) => {
      if (!action.meta.arg.backgroundUpdate) {
        state.requests.fetchClientConnection = "pending";
        state.connectionSwitcher.error = ''
      }
    });
    builder.addCase(fetchClientConnection.rejected, (state, action: any) => {
      if (action.meta.arg && !action.meta.arg.backgroundUpdate) {
        state.requests.fetchClientConnection = "rejected";
        state.connectionSwitcher.error = action.payload.message
      }
    });
    builder.addCase(fetchClientConnection.fulfilled, (state, action) => {
      if (!action.meta.arg.backgroundUpdate) {
        if (action.payload.success) {
          const clientInfo = action.payload.activeConnection
  
          state.UserPermissions = clientInfo.UserPermissions
          state.requests.fetchClientConnection = 'succeeded'

          // state.Client needs to be updated last as once LastAccessedAppID is updated, it may trigger app forwarding
          state.Client = {
            ClientAbbrv: clientInfo.ClientAbbr,
            ClientName: clientInfo.ClientName,
            ClientID: clientInfo.ClientID,
            ClientTypeID: clientInfo.ClientTypeID,
            ClientApps: clientInfo.AvailableApps,
            LastAccessedAppID: clientInfo.LastAccessedAppID,
            ClientConnectionID: clientInfo.ClientConnectionID,
          }
        } else {
          state.requests.fetchClientConnection = 'rejected'
          state.connectionSwitcher.error = action.payload.message
        }
      }
    });
    builder.addCase(switchConnections.pending, (state, action) => {
      if (!action.meta.arg.backgroundUpdate) {
        state.switchingClients = "pending";
        state.connectionSwitcher.error = ''
      }
    });
    builder.addCase(switchConnections.rejected, (state, action: any) => {
      if (!action.payload.backgroundUpdate) {
        state.switchingClients = "rejected";
        state.connectionSwitcher.error = action.payload.message
      }
    });
    builder.addCase(switchConnections.fulfilled, (state, action) => {
      if (!action.payload.backgroundUpdate) {
        if (action.payload.success) {
          state.connectionSwitcher.showConnectionSwitcher = false;
          state.switchingClients = 'succeeded'
        } else {
          state.switchingClients = 'rejected'
          state.connectionSwitcher.error = action.payload.message
        }
      }
    });
  },
});

export const {
  toggleConnectionSwitcher,
  resetLaunchState,
  updateUserPermission,
  findEditPermissionClients,
  setConnectionSwitcherError,
  updateAppRedirecting,
  updateClientFromStorage
} = userSlice.actions;
export default userSlice.reducer;
