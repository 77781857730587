// React
import { findPermission } from '../../utils/helperMethods'
import { useLocation } from 'react-router-dom'

// Redux
import { useAppSelector } from '../../redux/store'

// Components
import Tab from './Tab'

// Styles
import { TabDiv, TabWrapper, IconWrapper } from './tabStyles'
import { NavImage } from './tabStyles'
import { LogoImage, LogoImageContainer } from './siteHeader/SiteHeaderStyles'

// Icons
import navLogo from '../../images/normal_u47.png'
import new_logo_circle from '../../images/new_logo_circle.png'
import normal_u29 from '../../images/normal_u29.svg'

const AppSidebar = () => {
  const location = useLocation()

  const fetchingUser = useAppSelector(state => state.users.fetchingUser)
  const { mobile, mobileSidebar } = useAppSelector(state => state.nav)
  const UserPermissions = useAppSelector(state => state.users.UserPermissions)

  const tabs = [
    { purpose: 'dashboard', title: 'Dashboard', PermissionID: '15', PermissionKey: 'Dashboard' },
    { purpose: 'patientManagement', title: 'Patients', PermissionID: '16', PermissionKey: 'Patients' },
    // { purpose: 'clientBill', title: 'Client Bill', PermissionID: '', PermissionKey: ''}, // Coming Soon!
    { purpose: 'reporting', title: 'Reports', PermissionID: '14', PermissionKey: 'Reporting' },
    { purpose: 'support', title: 'Support', PermissionID: '5', PermissionKey: 'Helpdesk' },
  ]

  /* BUILDING CLIENTPORTAL TABS */
  const buildTabs = () => {
    let filteredTabs = tabs.filter(tab => findPermission(tab.PermissionID, UserPermissions[tab.PermissionKey]))
    if (mobile) filteredTabs = filteredTabs.filter(tab => tab.purpose !== 'reporting')

    return filteredTabs.map(tab => <Tab key={`tab-${tab.title}`} purpose={tab.purpose} title={tab.title} selected={location.pathname.includes(tab.purpose)} />)
  }

  return (
    <TabDiv mobile={mobile} mobileSidebar={mobileSidebar}>
      <TabWrapper mobile={mobile}>
        {mobile ? null : (
          <IconWrapper>
            <LogoImageContainer>
              <LogoImage src={normal_u29} alt="circle" style={{ marginBottom: '-67px', marginLeft: '68px', height: '25px', width: '25px' }} />
              <LogoImage src={new_logo_circle} style={{ marginBottom: '-67px', marginLeft: '-23px', height: '21px', width: '21px', zIndex: '1000' }} />
            </LogoImageContainer>
            <NavImage src={navLogo} alt="Phytest Logo" style={{ height: '200px' }} />
          </IconWrapper>
        )}
        {fetchingUser === 'succeeded' ? buildTabs() : null}
      </TabWrapper>
    </TabDiv>
  )
}

export default AppSidebar
