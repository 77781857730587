import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import FavoriteButton from './favorites/favoriteButton'
import { NewReportMarker, ReportCardContainer, ReportCardContent } from './reportStyles'
import { Transition } from 'react-transition-group'

const ReportCard = ({ report, goToReport, toggleFavorite, favorites, favoriteCount, breakpoint }) => {
  const [mounted, mount] = useState(false)

  const history = useNavigate()

  useEffect(() => {
    mount(true)
    return () => {
      mount(false)
    }
  }, [])

  const handleClick = e => {
    if (e.target.id !== 'favorite') {
      goToReport(report.ReportName)
      history(`${report.ReportName}`)
    }
  }

  const duration = 300

  const defaultStyle = {
    transition: `all ${duration}ms linear`,
    opacity: 0,
    // visibility: 'hidden'
  }

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1, fontSize: '1em', visibility: 'visible' },
    exiting: { opacity: 0 },
    exited: { opacity: 0, width: '0%' },
  }

  return (
    <Transition in={mounted} timeout={duration} key={`report-${report.ReportID}`}>
      {state => (
        <ReportCardContainer
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          favorites={favorites}
          favoriteCount={favoriteCount}
          reportStatus={report.ReportStatus}
          breakpoint={breakpoint}
        >
          <div className="flexRow" style={{ width: '100%', justifyContent: 'space-between' }}>
            <FavoriteButton toggleFavorite={toggleFavorite} report={report} />
            {report.ReportStatus && <NewReportMarker>{report.ReportStatus.toUpperCase()}</NewReportMarker>}
          </div>
          <ReportCardContent onClick={e => handleClick(e)}>
            <h2>{report.ReportName}</h2>
            <p>
              <em>{report.ReportDescription}</em>
            </p>
          </ReportCardContent>
        </ReportCardContainer>
      )}
    </Transition>
  )
}

export default ReportCard
