import { Dashboard } from './dashboardStyles'
import './wijmoCharts.css'
import KPIContainer from './kpis/KPIContainer'
import { KPIWrapper } from './kpis/kpiStyles'
import DenialBreakdownChart from './denials/DenialBreakdown'
import { ViewerContainer } from '../../styles/viewerStyles'
import AgingAnalsysisChartContainer from './agingAnalysis/AgingAnalsysisChartContainer'
import ArByPayorContainer from './arByPayor/ArByPayorContainer'
import ChargesChartContainer from './charges/ChargesChartContainer'
import CollectionsChartContainer from './collections/CollectionsChartContainer'
import GCRChartContainer from './gcr/GCRChartContainer'
import SalesRepPerformanceChartContainer from './salesRep/SalesRepPerformanceChartContainer'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { setReportToView } from '../reporting/reportSlice'
import { useCallback, useEffect, useState } from 'react'
import { setTab } from '../nav/navSlice'
import { resetDashboard } from './dashboardSlice'
import { resetLaunchState } from '../login/userSlice'
import ConnectionSwitcher from '../nav/connectionSwitcher/ConnectionSwitcher'
import { useViewport } from '../../utils/hooks'
import { debounce } from 'lodash'

const DashboardGrid = () => {
  const clientTypeID = useAppSelector(state => state.users.ClientTypeID)
  const collectionsLoaded = useAppSelector(state => state.dashboard.collections.loaded)
  const theme = useAppSelector(state => state.users.ThemeID)
  const clientSwitching = useAppSelector(state => state.users.switchingClients)
  const DashboardPermissions = useAppSelector(state => state.users.UserPermissions.Dashboard)
  const mobile = useAppSelector(state => state.nav.mobile)
  const showConnectionSwitcher = useAppSelector(state => state.users.connectionSwitcher.showConnectionSwitcher)
  const fetchingUser = useAppSelector(state => state.users.fetchingUser)

  const history = useNavigate()

  const { width } = useViewport()

  const portableDevice = width < 1220 // tablet and mobile

  const dispatch = useAppDispatch()

  const [scrollCount, setScrollCount] = useState(0)

  useEffect(() => {
    setSelectedTab('dashboard')
    return () => {}
  }, [fetchingUser])

  useEffect(() => {
    if (clientSwitching === 'pending') {
      dispatch(resetDashboard())
    }
    return () => {}
  }, [clientSwitching, dispatch])

  useEffect(() => {
    if (collectionsLoaded === 'succeeded') {
      const flexChart = document.querySelectorAll('.wj-control.wj-flexchart')
      flexChart.forEach(el => {
        const yAxis = el.querySelector('svg>g.wj-axis-y')
        const gridLines = yAxis ? yAxis.querySelectorAll('.wj-gridline') : []
        const textLabels = yAxis ? yAxis.querySelectorAll('.wj-label') : []
        gridLines.forEach(line => {
          line.setAttribute('stroke', theme === '2' ? '#f1f1f1' : 'black')
        })
        textLabels.forEach(text => {
          text.setAttribute('stroke', theme === '2' ? '#f1f1f1' : 'black')
        })
      })
    }
    return () => {}
  }, [collectionsLoaded])

  const findPermission = permissionID => {
    let permitted = false
    const foundPermission = DashboardPermissions?.find(dPermission => dPermission.PermissionID === permissionID)
    if (foundPermission) {
      permitted = foundPermission.UserHasPermission
    }
    return permitted
  }

  const addReportToView = useCallback(
    payload => {
      dispatch(setReportToView(payload))
    },
    [dispatch]
  )

  const setSelectedTab = useCallback(
    payload => {
      dispatch(setTab(payload))
      dispatch(resetLaunchState())
    },
    [dispatch]
  )

  const formatYLabels = (engine, label) => {
    let splitText, mil, k, g
    const tens = label.text.match(/,/g)
    if (tens) {
      switch (tens.length) {
        case 1:
          splitText = label.text.split(',')
          // numbers in thousands position
          k = splitText[0]
          // first number in hundreds position
          if (k.length < 2) {
            g = splitText[1][0]
            g === '0' ? (label.text = `${k}K`) : (label.text = `${k}.${g}K`)
          } else {
            label.text = `${k}K`
          }
          return label
        case 2:
          splitText = label.text.split(',')
          // numbers in million position
          mil = splitText[0]
          // first number in thousands position
          k = splitText[1][0]
          label.text = `${mil}.${k}M`
          return label
        default:
          label.text = `${label.text}`
          return label
      }
    }
    label.text = `${label.text}`
    return label
  }

  const goToReport = reportName => {
    addReportToView(reportName)
    history(`/reporting/${reportName}`)
    setSelectedTab('reporting')
  }

  useEffect(() => {
    // Wijmo will not allow you to pass a className to the parent container of the chart tooltip (the <div class='wj-tooltip' /> element)
    // Only way I've found to pass CSS to the wijmo tooltip is by putting a class on its parent element, the DOM body
    if (theme === '2') {
      document.body.classList.add('chart-tooltip-darkMode')
    }

    if (theme === '1') {
      document.body.classList.remove('chart-tooltip-darkMode')
    }
  }, [theme])

  useEffect(() => {
    if (mobile) {
      let handleScroll = () => {
        setScrollCount(scrollCount + 1)
        let tooltipElement = document.getElementsByClassName('.wj-tooltip')
        if (tooltipElement.length) {
          tooltipElement.remove()
        }
      }

      document.body.addEventListener('scroll', debounce(handleScroll, 250))
      document.body.addEventListener('wheel', debounce(handleScroll, 250))

      return () => {
        document.body.removeEventListener('scroll', debounce(handleScroll, 250))
        document.body.removeEventListener('wheel', debounce(handleScroll, 250))
      }
    }
  }, [])

  return (
    <ViewerContainer dashboard mobile={mobile}>
      <ConnectionSwitcher />
      <Dashboard mobile={mobile} connectionSwitcher={showConnectionSwitcher} className={theme === '2' ? 'wj-dark-mode' : ''}>
        {findPermission('24') ? (
          <KPIWrapper mobile={mobile}>
            <KPIContainer mobile={portableDevice} />
          </KPIWrapper>
        ) : null}
        <CollectionsChartContainer goToReport={goToReport} formatYLabels={formatYLabels} visible={findPermission('18')} />
        <ChargesChartContainer goToReport={goToReport} formatYLabels={formatYLabels} visible={findPermission('19')} />
        <GCRChartContainer visible={findPermission('20')} />
        <AgingAnalsysisChartContainer goToReport={goToReport} formatYLabels={formatYLabels} visible={findPermission('21')} />
        <ArByPayorContainer goToReport={goToReport} formatYLabels={formatYLabels} visible={findPermission('22')} />
        <DenialBreakdownChart goToReport={goToReport} formatYLabels={formatYLabels} mobile={portableDevice} visible={findPermission('23')} />
        {clientTypeID === '4' ? <SalesRepPerformanceChartContainer formatYLabels={formatYLabels} mobile={portableDevice} /> : null}
      </Dashboard>
    </ViewerContainer>
  )
}

export default DashboardGrid
