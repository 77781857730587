import styled from 'styled-components'

export const KPIWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${props => props.mobile ? 0 : '1rem'};
`

const buildKPIBackground = props => {
  if (props.bgGradient) {
    return `linear-gradient( 0.25turn, ${props.theme.kpi.posted} ${props.bgGradient[0]} , ${props.theme.kpi.pending} ${props.bgGradient[0]} ${props.bgGradient[1]})`
  } else {
    return props.theme.kpi.pending
  }
}

export const KPIDiv = styled.div`
  margin: 0.5%;
  border: 1px solid ${props => props.theme.dashboard.cards.border};
  width: 100%;
  height: 100%;
  min-height: ${props => (props.loader ? '120px' : '100%')};
  min-width: ${props => (props.mobile ? '100%' : null)};
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
  background-color: ${props => (props.loader ? '#b5b5b5' : 'inherit')};
`

export const KPIDivHeader = styled.h3`
  font-weight: 500;
  width: 100%;
  font-size: min(max(1.2vw, 14px), 20px);
  text-align: center;
  margin: 0;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
`

export const KPIAmount = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  background: ${props => buildKPIBackground(props)};
  color: var(--color-accent-black);
`

export const KPIPosted = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 700;
  font-size: min(max(2vw, 14px), 24px);
  @media screen and (max-width: 1024px) {
    font-size: 28px;
  }
  label {
    margin: 0;
    font-size: min(max(1vw, 14px), 20px);
    font-weight: 400;
  }
`

export const KPIPending = styled(KPIPosted)`
  font-weight: 500;
  font-size: min(max(2vw, 14px), 22px);
  align-items: flex-end;
  @media screen and (max-width: 1024px) {
    font-size: 28px;
  }
`

export const KPILoadingContainer = styled.div`
  width: 100%;
  min-width: 90%;
  height: 100%;
  margin: 0.5%;
  border: 1px solid ${props => props.theme.dashboard.cards.border};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b5b5b5;
`
