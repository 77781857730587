import React from 'react'
import { CarouselDiv } from './carouselStyles'
import Carousel from './carousel'

const CarouselContainer = ({ children }) => {
  return (
    <CarouselDiv>
      <Carousel>{children}</Carousel>
    </CarouselDiv>
  )
}

export default CarouselContainer