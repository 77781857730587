import React, { useRef, useState } from 'react'
import { ClientPermission, UserFeaturePermission, UserPermission } from '../../../type'
import { findPermission } from '../../utils/helperMethods'
import { UpdatePermission, setPermissions } from './permissionsSlice'
import { PermissionCheckBoxDiv } from './permissionStyles'
import { useOutsideClick } from '../../utils/hooks'
import { useAppDispatch } from '../../redux/store'
import { LoginButton } from '../login/loginStyles'

type Props = {
  currentClient: ClientPermission | null
  CurrentClientAdminPermissions: any
  loggedInUserID: string | number
  loggedInClient: any
  touchedPermissions: Partial<UpdatePermission>
  user: UserPermission
  setExpandedForm: any
  expandedForm: boolean
  AdminPermissions: UserFeaturePermission[] | undefined
}

const UserPermissionForm = ({
  currentClient,
  CurrentClientAdminPermissions,
  loggedInUserID,
  loggedInClient,
  user,
  setExpandedForm,
  expandedForm,
  AdminPermissions,
}: Props) => {
  const [touchedPermissionIDs, setTouchedPermissionIDs] = useState<{ PermissionID: string; Permitted: boolean }[]>([])
  const [affectedUser, setAffectedUser] = useState({
    AffectedUserID: '',
    ClientID: '',
    ClientAbbreviation: '',
  })

  const dispatch = useAppDispatch()
  const formRef = useRef(null)

  useOutsideClick(formRef, () => setExpandedForm(false))

  const handleCheckboxClick = (payload: any) => {
    const statePayload = {
      AffectedUserID: payload.AffectedUserID,
      ClientID: payload.ClientID,
      ClientAbbreviation: payload.ClientAbbreviation,
    }
    let newPermissionIDs
    if (touchedPermissionIDs.some((permission: { PermissionID: any; Permitted: boolean }) => permission.PermissionID === payload.PermissionID)) {
      newPermissionIDs = [...touchedPermissionIDs].filter(permission => permission.PermissionID !== payload.PermissionID)
    } else {
      newPermissionIDs = [...touchedPermissionIDs, { PermissionID: payload.PermissionID, Permitted: payload.Permitted }]
    }
    setTouchedPermissionIDs(newPermissionIDs)
    setAffectedUser({ ...affectedUser, ...statePayload })
    // dispatch(handlePermissionChange(payload));
  }

  const handleFormSubmit = (e: any, payload: any) => {
    e.preventDefault()
    setExpandedForm(!expandedForm)
    let formPayload = {
      ...affectedUser,
      PermissionIDs: payload,
    }
    dispatch(
      // @ts-ignore
      setPermissions({
        ...formPayload,
        controller: new AbortController(),
        updateState: false,
      })
    )
  }

  const checkSubmitValidity = () => {
    let cannotSubmit = true
    if (user.UserID === loggedInUserID && findPermission('11', AdminPermissions)) {
      cannotSubmit = false
    } else if (user.UserID !== loggedInUserID) {
      if (currentClient?.ClientID === loggedInClient.ClientID && findPermission('12', AdminPermissions)) {
        cannotSubmit = false
      }
    }
    return cannotSubmit
  }

  const buildExpandedForm = () => {
    const buildFormSections = () => {
      const formSections: any = []
      if (currentClient) {
        for (const [feature, permissions] of Object.entries(currentClient.Features)) {
          const formSection = () => {
            return (
              <fieldset className="permission-fieldset">
                <legend>{feature}</legend>
                {permissions.map((permission: UserFeaturePermission) => {
                  const forClientAdminCheck = CurrentClientAdminPermissions.find((client: any) => {
                    // @ts-ignore
                    return client.clientID === currentClient.ClientID
                  })
                  let canEdit = findPermission('12', forClientAdminCheck?.Admin)
                  if (user.UserID === loggedInUserID) {
                    canEdit = findPermission('11', forClientAdminCheck?.Admin)
                  }

                  const forChecked = () => {
                    const permissionExists = touchedPermissionIDs.find(tpermission => permission.PermissionID === tpermission.PermissionID)
                    if (permissionExists) {
                      return permissionExists.Permitted
                    } else {
                      return permission.UserHasPermission
                    }
                  }
                  if (forChecked()) {
                    return (
                      <div>
                        <label htmlFor={permission.PermissionID}>
                          <input
                            type="checkbox"
                            id={permission.PermissionID}
                            name={permission.PermissionDescription}
                            onChange={() =>
                              canEdit
                                ? handleCheckboxClick({
                                    AffectedUserID: user.UserID,
                                    PermissionID: permission.PermissionID,
                                    ClientID: currentClient.ClientID,
                                    ClientAbbreviation: currentClient.ClientAbbreviation,
                                    Permitted: !permission.UserHasPermission,
                                    category: feature,
                                    addToTouched: true,
                                  })
                                : () => {}
                            }
                            disabled={!canEdit}
                            checked
                          />
                          {permission.PermissionDescription}
                        </label>
                      </div>
                    )
                  } else {
                    return (
                      <div>
                        <label htmlFor={permission.PermissionID}>
                          <input
                            type="checkbox"
                            id={permission.PermissionID}
                            name={permission.PermissionDescription}
                            onChange={() =>
                              handleCheckboxClick({
                                AffectedUserID: user.UserID,
                                PermissionID: permission.PermissionID,
                                ClientID: currentClient.ClientID,
                                ClientAbbreviation: currentClient.ClientAbbreviation,
                                Permitted: !permission.UserHasPermission,
                                category: feature,
                                addToTouched: true,
                              })
                            }
                            disabled={!canEdit}
                          />
                          {permission.PermissionDescription}
                        </label>
                      </div>
                    )
                  }
                })}
              </fieldset>
            )
          }
          formSections.push(formSection())
        }
      }
      return formSections
    }

    return (
      <form
        ref={formRef}
        // @ts-ignore
        onSubmit={e => handleFormSubmit(e, touchedPermissionIDs)}
      >
        <div>{buildFormSections()}</div>
        <LoginButton type="submit" disabled={checkSubmitValidity()}>
          Save Changes
        </LoginButton>
      </form>
    )
  }
  return <PermissionCheckBoxDiv>{buildExpandedForm()}</PermissionCheckBoxDiv>
}

export default UserPermissionForm
