import { ExpandMore } from '@mui/icons-material'
import { CollapseIconWrapper, PatientDataBlock, PatientInfoBlockContainer, PatientInfoName, ToggleDemographicsButton } from './patientInfoStyles'
import { toggleDemographicsExpanded } from './patientSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { buildDate } from '../../utils/helperMethods'

const PatientInfoBlock = () => {
  const dispatch = useAppDispatch()

  const patient = useAppSelector(state => state.patients.currentPatient.demographic)
  const demographicsExpanded = useAppSelector(state => state.patients.demographicsExpanded)
  const mobile = useAppSelector(state => state.nav.mobile)
  const tablet = useAppSelector(state => state.nav.tablet)

  return (
    <PatientInfoBlockContainer mobile={mobile} tablet={tablet} demographicsExpanded={demographicsExpanded}>
      <PatientInfoName tablet={tablet}>
        {patient?.FirstName?.toUpperCase()} {patient?.MiddleName?.toUpperCase()} {patient?.LastName?.toUpperCase()}
      </PatientInfoName>
      <PatientDataBlock tablet={tablet}>
        <p>{buildDate(patient?.DateOfBirth)}</p>
      </PatientDataBlock>
      <ToggleDemographicsButton tablet={tablet} onClick={() => dispatch(toggleDemographicsExpanded(!demographicsExpanded))}>
        {demographicsExpanded ? 'Hide' : 'View'} Demographics
        <CollapseIconWrapper expanded={demographicsExpanded} style={{ marginLeft: '0.25rem' }}>
          <ExpandMore />
        </CollapseIconWrapper>
      </ToggleDemographicsButton>
    </PatientInfoBlockContainer>
  )
}

export default PatientInfoBlock
