import React from 'react'
import { ChartPlaceholderDiv } from './placeholderStyles'
import * as wjChart from '@grapecity/wijmo.react.chart'
import { DashCardHeader } from '../dashboardStyles'
import DotLoader from '../../misc/dotLoader'
import { FlexWrapper } from '../../../styles/viewerStyles'

const PieChartPlaceholder = ({ cardWidth, loaded, title }) => {
  return (
    <>
      <ChartPlaceholderDiv cardWidth={cardWidth}>
        <DashCardHeader>
          <h4 style={{margin: '1rem 0'}}>{title}</h4>
        </DashCardHeader>
        {loaded ? (
          <wjChart.FlexPie palette={['#000', '#CCC', '#B2B2B2']} itemsSource={[{ data: 123 }, { data: 456 }, { data: 789 }]} binding="data">
            <wjChart.FlexChartLegend position="None" />
          </wjChart.FlexPie>
        ) : (
          <FlexWrapper height="calc(100% - 60px)">
            <DotLoader isLoading={true} width="400px" dotSize="40px" />
          </FlexWrapper>
        )}
      </ChartPlaceholderDiv>
    </>
  )
}

export default PieChartPlaceholder
