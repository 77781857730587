import { PatientInfoSideBar, PatientDataGroup, PatientDataBlock, ToggleDemographicsButton, PatientInfoName } from './patientInfoStyles'
import { buildDate } from '../../utils/helperMethods'
import DotLoader from '../misc/dotLoader'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { ExpandMore } from '@mui/icons-material'
import { toggleDemographicsExpanded } from './patientSlice'

const PatientSidebar = () => {
  const dispatch = useAppDispatch()

  const patient = useAppSelector(state => state.patients.currentPatient.demographic)
  const demographicLoaded = useAppSelector(state => state.patients.currentPatient.demographicLoaded)
  const { mobile, tablet, portableDevice } = useAppSelector(state => state.nav)
  const demographicsExpanded = useAppSelector(state => state.patients.demographicsExpanded)

  const checkSectionRender = (section, nestedSection) => {
    // Check whether section should be rendered
    // Only rendering section if one of the keys has a value
    const isInsurance = section.includes('Insurance')
    const keys = {
      identity: [['ApmID'], ['DateOfBirth'], ['Sex']],
      contact: [['StreetAddress1', 'StreetAddress2', 'AddressCity', 'AddressStateAbbreviation', 'AddressZipCode'], 'PhoneHome', 'PhoneMobile', 'PhoneWork', 'Email'],
      insurance: ['CompanyName', ['Street1', 'City', 'State', 'Zip'], 'Policy', 'GroupNumber', 'EffectiveDate'],
    }

    // Checking flat array for whole block or specific sub-section
    let array = keys[isInsurance ? 'insurance' : section]
    array = isNaN(nestedSection) ? array.flat() : array[nestedSection]

    return array.some(key => patient[isInsurance ? section + key : key])
  }

  const handleDisplay = val => {
    return val ? val : 'N/A'
  }

  const buildPhoneNumber = phoneNumber => {
    if (phoneNumber === 'N/A') return 'N/A'

    const area = phoneNumber.substring(0, 3)
    const firstThree = phoneNumber.substring(3, 6)
    const last = phoneNumber.substring(6)

    return `(${area}) ${firstThree}-${last}`
  }

  const checkMultiplePhoneNumbers = () => {
    // If just one phone number, then display as row; if more than one phone number, display as column
    const phoneArr = [patient?.PhoneHome, patient?.PhoneMobile, patient?.PhoneWork].filter(phone => phone)
    return phoneArr.length > 1
  }

  return (
    <PatientInfoSideBar
      demographicLoaded={demographicLoaded}
      demographicsExpanded={demographicsExpanded}
      mobile={mobile}
      portableDevice={portableDevice}
      className={mobile ? '' : 'hideScroll'}
    >
      {demographicLoaded !== 'succeeded' ? (
        <DotLoader isLoading={true} width="200px" />
      ) : (
        <>
          {mobile ? (
            <>
              <PatientInfoName tablet={tablet}>
                {patient?.FirstName?.toUpperCase()} {patient?.MiddleName?.toUpperCase()} {patient?.LastName?.toUpperCase()}
              </PatientInfoName>
              {patient?.DateOfBirth ? (
                <PatientDataBlock>
                  <h4 style={{ minWidth: 'auto', marginRight: '.5rem' }}>DOB</h4>
                  <p>{buildDate(patient?.DateOfBirth)}</p>
                </PatientDataBlock>
              ) : null}
              <ToggleDemographicsButton onClick={() => dispatch(toggleDemographicsExpanded(!demographicsExpanded))}>
                {demographicsExpanded ? 'Hide' : 'View'} Demographics
                <ExpandMore />
              </ToggleDemographicsButton>
            </>
          ) : null}
          {!portableDevice || demographicsExpanded ? (
            <>
              {/* Identity */}
              {checkSectionRender('identity') ? (
                <PatientDataGroup portableDevice={portableDevice} style={{ margin: 0 }}>
                  {patient?.ApmID ? (
                    <PatientDataGroup portableDevice={portableDevice} style={{ marginTop: mobile ? 'inherit' : 0 }}>
                      <h4 style={{ marginTop: mobile ? 'inherit' : 0 }}>PATIENT ID</h4>
                      <PatientDataBlock tablet={tablet}>
                        <p>{patient?.ApmID}</p>
                      </PatientDataBlock>
                    </PatientDataGroup>
                  ) : null}
                </PatientDataGroup>
              ) : null}
              {/* Contact */}
              {checkSectionRender('contact') ? (
                <PatientDataGroup portableDevice={portableDevice}>
                  <h4>CONTACT</h4>
                  {checkSectionRender('contact', 0) ? (
                    <PatientDataBlock tablet={tablet} column>
                      <h4>Address:</h4>
                      <p>{patient?.StreetAddress1}</p>
                      {patient?.StreetAddress2 ? <p>{patient?.StreetAddress2}</p> : null}
                      <p>
                        {patient?.AddressCity}
                        {patient?.AddressCity && patient?.AddressStateAbbreviation ? ',' : ''} {patient?.AddressStateAbbreviation} {patient?.AddressZipCode}
                      </p>
                    </PatientDataBlock>
                  ) : null}
                  {patient?.PhoneHome || patient?.PhoneMobile || patient?.PhoneWork ? (
                    <PatientDataBlock tablet={tablet} column={checkMultiplePhoneNumbers()}>
                      <h4>Phone:</h4>
                      {patient?.PhoneHome ? <p>{buildPhoneNumber(handleDisplay(patient?.PhoneHome))} (home)</p> : null}
                      {patient?.PhoneMobile ? <p>{buildPhoneNumber(handleDisplay(patient?.PhoneMobile))} (mobile)</p> : null}
                      {patient?.PhoneWork ? <p>{buildPhoneNumber(handleDisplay(patient?.PhoneWork))} (work)</p> : null}
                    </PatientDataBlock>
                  ) : null}
                  <></>
                  {patient?.Email ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Email:</h4>
                      <p style={{ wordBreak: 'break-word' }}>{patient?.Email}</p>
                    </PatientDataBlock>
                  ) : null}
                </PatientDataGroup>
              ) : null}
              {/* Primary Insurance */}
              {checkSectionRender('InsurancePrimary') ? (
                <PatientDataGroup portableDevice={portableDevice}>
                  <h4>PRIMARY INSURANCE</h4>
                  {patient?.InsurancePrimaryCompanyName ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Carrier:</h4>
                      <p>{handleDisplay(patient?.InsurancePrimaryCompanyName)}</p>
                    </PatientDataBlock>
                  ) : null}
                  <PatientDataBlock tablet={tablet} column>
                    {checkSectionRender('InsurancePrimary', 1) ? (
                      <>
                        <h4>Address:</h4>
                        <p>{patient?.InsurancePrimaryStreet1}</p>
                        {patient?.InsurancePrimaryStreet2 ? <p>{patient?.InsurancePrimaryStreet2}</p> : null}
                        <p>
                          {patient?.InsurancePrimaryCity}
                          {patient?.InsurancePrimaryCity && patient?.InsurancePrimaryState ? ', ' : ''}
                          {patient?.InsurancePrimaryState} {patient?.InsurancePrimaryZip}
                        </p>
                      </>
                    ) : null}
                  </PatientDataBlock>
                  {patient?.InsurancePrimaryPolicy ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Policy ID:</h4>
                      <p>{patient?.InsurancePrimaryPolicy}</p>
                    </PatientDataBlock>
                  ) : null}
                  {patient?.InsurancePrimaryGroupNumber ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Group Number:</h4>
                      <p>{handleDisplay(patient?.InsurancePrimaryGroupNumber)}</p>
                    </PatientDataBlock>
                  ) : null}
                  {patient?.InsurancePrimaryEffectiveDate ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Policy Effective:</h4>
                      <p>{buildDate(patient?.InsurancePrimaryEffectiveDate)}</p>
                    </PatientDataBlock>
                  ) : null}
                </PatientDataGroup>
              ) : null}
              {/* Secondary Insurance */}
              {checkSectionRender('InsuranceSecondary') ? (
                <PatientDataGroup portableDevice={portableDevice}>
                  <h4>SECONDARY INSURANCE</h4>
                  {patient?.InsuranceSecondaryCompanyName ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Carrier:</h4>
                      <p>{handleDisplay(patient?.InsuranceSecondaryCompanyName)}</p>
                    </PatientDataBlock>
                  ) : null}
                  <PatientDataBlock tablet={tablet} column>
                    {checkSectionRender('InsuranceSecondary', 1) ? (
                      <>
                        <h4>Address:</h4>
                        <p>{patient?.InsuranceSecondaryStreet1}</p>
                        {patient?.InsuranceSecondaryStreet2 ? <p>{patient?.InsuranceSecondaryStreet2}</p> : null}
                        <p>
                          {patient?.InsuranceSecondaryCity}
                          {patient?.InsuranceSecondaryCity && patient?.InsuranceSecondaryState ? ',' : ''}
                          {patient?.InsuranceSecondaryState} {patient?.InsuranceSecondaryZip}
                        </p>
                      </>
                    ) : null}
                  </PatientDataBlock>
                  {patient?.InsuranceSecondaryPolicy ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Policy ID:</h4>
                      <p>{patient?.InsuranceSecondaryPolicy}</p>
                    </PatientDataBlock>
                  ) : null}
                  {patient?.InsuranceSecondaryGroupNumber ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Group Number:</h4>
                      <p>{handleDisplay(patient?.InsuranceSecondaryGroupNumber)}</p>
                    </PatientDataBlock>
                  ) : null}
                  {patient?.InsuranceSecondaryEffectiveDate ? (
                    <PatientDataBlock tablet={tablet}>
                      <h4>Policy Effective:</h4>
                      <p>{buildDate(patient?.InsuranceSecondaryEffectiveDate)}</p>
                    </PatientDataBlock>
                  ) : null}
                </PatientDataGroup>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </PatientInfoSideBar>
  )
}

export default PatientSidebar
