import { setClaimIndex, toggleSelectClaim, updatePatientModal } from '../patientSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { buildDate, convertNumToCurrency } from '../../../utils/helperMethods'
import { BoldSpan, ClaimSidebar, ClaimSidebarItem, StyledCheckbox, ClaimSidebarIcons } from './claimStyles'
import { CurrencyExchange, Info } from '@mui/icons-material'
import CustomTooltip from '../../misc/CustomTooltip'
import { useState } from 'react'

const ClaimsSidebar = () => {
  const [tooltipText, setTooltipText] = useState('')

  const dispatch = useAppDispatch()
  const filteredClaims = useAppSelector(state => state.patients.currentPatient.filteredClaims)
  const selectedClaimIndex = useAppSelector(state => state.patients.currentPatient.selectedClaimIndex)
  const theme = useAppSelector(state => state.users.ThemeName)
  const exportMode = useAppSelector(state => state.patients.currentPatient.exportMode)
  const demographicsExpanded = useAppSelector(state => state.patients.demographicsExpanded)
  const tablet = useAppSelector(state => state.nav.tablet)

  const handleSidebarClick = (e, i, selected) => {
    e.stopPropagation()
    if (exportMode) {
      toggleClaim(e, i, selected)
    }
    dispatch(setClaimIndex(i))
  }

  const toggleClaim = (e, index, selected) => {
    e.stopPropagation()
    dispatch(toggleSelectClaim({ index, newVal: !selected }))
  }

  /* TOOLTIP FUNCTIONS */
  const countRemarksReasons = (remarks, denialCount) => {
    return (
      <>
        {remarks.length ? (
          <p>
            {remarks.length} Remark{remarks.length === 1 ? '' : 's'}
          </p>
        ) : null}
        {denialCount ? (
          <p>
            {denialCount} Reason code{denialCount === 1 ? '' : 's'}
          </p>
        ) : null}
      </>
    )
  }

  const updateTooltip = claim => {
    if (tooltipText) return tooltipText

    const accessionArray = claim.specimens.map(specimen => specimen.SpecimenNumber)

    // if one accession, generate "Accession 1234"
    // if multiple accessions, generate "Accessions" and then each accession on a new line
    if (accessionArray.length <= 1) {
      return 'Accession ' + (accessionArray[0] || ' not found')
    } else {
      return (
        <>
          <p>Accessions</p>
          {accessionArray.map(accession => (
            <p key={accession}>{accession}</p>
          ))}
        </>
      )
    }
  }

  const openModal = claim => {
    setTooltipText('')

    let modalLevel = claim.remarks.length && claim.denialCount ? 'remarks-reasons' : claim.remarks.length ? 'remarks' : 'accession-reasons'
    let modalContentObj

    if (modalLevel === 'remarks-reasons') {
      modalContentObj = { content: claim.remarks, specimens: claim.specimens }
    } else if (modalLevel === 'remarks') {
      modalContentObj = { content: claim.remarks }
    } else if (modalLevel === 'accession-reasons') {
      modalContentObj = { specimens: claim.specimens }
    }

    let payload = {
      modalOpen: true,
      modalLevel,
      modalContent: {
        ClaimNumber: claim.ClaimNumber,
        ...modalContentObj,
      },
    }

    dispatch(updatePatientModal(payload))
  }

  const renderClaims = () => {
    if (filteredClaims.length) {
      return filteredClaims.map((claim, i) => (
        <>
          <CustomTooltip enterDelay={500} enterNextDelay={100} title={updateTooltip(claim)} key={claim.ClaimNumber} followCursor claimSidebar>
            <ClaimSidebarItem
              onClick={e => handleSidebarClick(e, i, claim.selected)}
              onKeyDown={e => handleSidebarClick(e, i, claim.selected)}
              selected={i === selectedClaimIndex && !exportMode}
              even={i % 2 === 0}
              darkMode={theme === 'Dark'}
              tablet={tablet}
              data-test={'claim-sidebar-claimItem-' + i}
            >
              <div className="flexRow">
                {exportMode ? (
                  <StyledCheckbox type="checkbox" checked={claim.selected} size="1.2rem" style={{ alignSelf: 'center' }} onChange={e => toggleClaim(e, i, claim.selected)} />
                ) : null}
                <div>
                  <BoldSpan tablet={tablet} style={{ marginBottom: '.5rem' }} data-test={'claim-sidebar-claimItem-' + i + '-claimNumber'}>
                    Claim {claim.ClaimNumber}
                  </BoldSpan>
                  <p>Billed {buildDate(claim.BillingDate)}</p>
                </div>
              </div>
              <ClaimSidebarIcons demographicsExpanded={demographicsExpanded}>
                {claim.totals.Balance ? (
                  <div onMouseEnter={() => setTooltipText(convertNumToCurrency(claim.totals.Balance) + ' pending')} onMouseLeave={() => setTooltipText('')}>
                    <CurrencyExchange />
                  </div>
                ) : null}
                {claim.remarks.length || claim.denialCount ? (
                  <div onMouseEnter={() => setTooltipText(countRemarksReasons(claim.remarks, claim.denialCount))} onMouseLeave={() => setTooltipText('')}>
                    <button className="invisibleButton" onClick={() => openModal(claim)}>
                      <Info style={{ cursor: 'pointer' }} />
                    </button>
                  </div>
                ) : null}
              </ClaimSidebarIcons>
            </ClaimSidebarItem>
          </CustomTooltip>
        </>
      ))
    } else {
      return <p>No claims match your search.</p>
    }
  }

  return <ClaimSidebar demographicsExpanded={demographicsExpanded}>{renderClaims()}</ClaimSidebar>
}

export default ClaimsSidebar
