import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { buildDate, findPermission } from '../../../../utils/helperMethods'
import { HistoricNameIcon, ResultData } from '../searchStyles'
import HistoricNamesRow from './HistoricNamesRow'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useAppSelector } from '../../../../redux/store'

const SearchResultRow = ({ TopRowRef, patient }) => {
  const [showHistoric, setShowHistoric] = useState(false)
  const [historicNamesPermissions, setHistoricNamesPermission] = useState(false)
  const patientPermissions = useAppSelector(state => state.users.UserPermissions.Patients)

  useEffect(() => {
    setHistoricNamesPermission(findPermission('41', patientPermissions))
  }, [patientPermissions])

  return (
    <>
      {patient.patient_history && historicNamesPermissions ? (
        <HistoricNameIcon onClick={() => setShowHistoric(!showHistoric)} showHistoric={showHistoric} className={showHistoric ? 'historic-row' : ''}>
          <KeyboardArrowDown />
        </HistoricNameIcon>
      ) : (
        <div />
      )}
      <Link to={`${patient.id}`} tabIndex="0" aria-label="go to patient" className={showHistoric ? 'historic-row' : ''} ref={TopRowRef}>
        <ResultData>{patient.lastName}</ResultData>
        {historicNamesPermissions && showHistoric ? <HistoricNamesRow patientID={patient.id} /> : null}
      </Link>
      <Link to={`${patient.id}`} tabIndex="0" aria-label="go to patient" className={showHistoric ? 'historic-row' : ''}>
        <ResultData>{patient.firstName}</ResultData>
      </Link>
      <Link to={`${patient.id}`} tabIndex="0" aria-label="go to patient" className={showHistoric ? 'historic-row' : ''}>
        <ResultData>{patient.middleName}</ResultData>
      </Link>
      <Link to={`${patient.id}`} tabIndex="0" aria-label="go to patient" className={showHistoric ? 'historic-row' : ''}>
        <ResultData>{buildDate(patient.DateOfBirth)}</ResultData>
      </Link>
      <Link to={`${patient.id}`} tabIndex="0" aria-label="go to patient" className={showHistoric ? 'historic-row' : ''}>
        <ResultData>{patient.apmId}</ResultData>
      </Link>
    </>
  )
}

export default SearchResultRow
