import styled, { css } from 'styled-components'
import { UnstyledButton } from '../../styles/buttonStyles'
import { FlexWrapper } from '../../styles/viewerStyles'

export const FavoriteButtonContainer = styled(UnstyledButton)`
  display: flex;
  padding: 0;
  box-sizing: border-box;
  color: ${props => props.theme.restyle.secondary};

  svg {
    cursor: pointer;
    font-size: 24px;
    transition: color 0.5s;
  }

  svg:hover {
    color: ${props => (props.lightMode ? 'var(--color-primary-lightBlue)' : props.theme.restyle.purpleAccent)};
  }
`

export const ReportCardContent = styled(UnstyledButton)`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 5px;
  margin: 0px;
  h2 {
    font-size: clamp(0.4rem, 9rem, 1rem);
  }
  em {
    font-size: clamp(0.4rem, 0.9rem, 1rem);
  }
`
//box for each report card
export const ReportCardContainer = styled.div`
  width: 85%;
  height: 150px;
  min-width: ${props => ((props.favoriteCount < props.breakpoint + 1) ? (100 / props.breakpoint - 5 + '%') : (props.favorites ? '95%' : 'auto'))};
  border: 1px solid var(--color-accent-alsogrey);
  display: flex;
  margin: 5px;
  position: relative;
  flex-flow: column;
  align-items: center;
  padding: .5rem;
  box-sizing: border-box;
  cursor: pointer;

  ${props =>
    props.reportStatus &&
    css`
      border: 3px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(90deg, ${props => props.theme.restyle.purpleAccent}, var(--color-primary-lightBlue));

      ${FavoriteButtonContainer} {
        color: ${props => props.theme.restyle.purpleAccent};
      }
      ${FavoriteButtonContainer} svg:hover {
        color: var(--color-primary-lightBlue);
      }
    `}

  @media screen and (min-width: 1150px) {
    width: 40%;
  }

  @media screen and (min-width: 1440px) {
    width: 20%;
  }
  ${FavoriteButtonContainer} {
    box-sizing: border-box;
    width: 20%;
  }
  ${ReportCardContent} {
    &:last-child {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        margin: 0;
      }
    }
  }
`

export const FavoritesContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${ReportCardContainer}:hover {
    transition: all 0.3s ease-in-out;
  }
  ${ReportCardContainer}:nth-of-type(-n+4):hover {
    transform: translateY(-10px);
  }
  ${ReportCardContainer}:nth-of-type(n+5):hover {
    transform: translateY(10px);
  }
`
export const NewReportMarker = styled.p`
  background: var(--color-primary-lightBlue);
  color: #fff;
  margin: 0;
  padding: 0.4rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
`

export const ReportHomeContainer = styled.div`
  width: 100%;
  height: calc(100% - 110px);
  box-sizing: border-box;
  overflow-y: scroll;
  display: ${props => (props.connectionSwitcher ? 'none' : 'flex')};
  flex-direction: column;
  color: ${props => props.theme.restyle.primary};
  transition: color 0.5s;
  ::-webkit-scrollbar {
    display: none;
  }
`
// build and perm container created to handle overflow on reports page
export const BuildContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow: hidden auto;
`

export const PermContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow-y: scroll;
`

export const ReportContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

export const FavoritesWrapper = styled.div`
  width: 95%;
  height: fit-content;
  display: flex;
  font-size: 1.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 3px solid var(--color-accent-alsogrey);
`

export const SideBarButton = styled.div`
  width: 100%;
  height: 40px;
  margin: auto;
  display: flex;
  position: fixed;
  z-index: 100;
  top: 120px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.reporting.main};
  color: white;
`
// each category box - title h4 and button/report category h5 | alternatively each card instead of LI | changed to STYLED DIV - Change back to styled UL if using li
export const ReportCategoryList = styled.div`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  ${ReportCardContainer}:hover {
    transition: all 0.3s ease-in-out;
  }
  ${ReportCardContainer}:nth-of-type(-n+4):hover {
    transform: translateY(-10px);
  }
  ${ReportCardContainer}:nth-of-type(n+5):hover {
    transform: translateY(10px);
  }

  :last-child {
    padding-bottom: 2rem;
  }
`
//controls box for each list item with its toggle button
export const ReportCategoryListItem = styled.li`
  display: flex;
  flex-flow: row;
  height: 1rem;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1em;
  cursor: pointer;
  ${UnstyledButton} {
    h5 {
      font-size: 0.8rem;
      margin: 0;
      margin-left: 10px;
      font-weight: 500;
      line-height: 1.2em;
      text-align: left;
    }
    svg:hover {
      box-shadow: 2px 2px 2px #7ed1e7;
    }
    h5:hover {
      text-decoration: underline;
    }
  }
`

export const CategoryWrapper = styled.div`
  text-align: left;
  line-height: 10px;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: visible;
`

export const FavoriteReportPlaceholderDiv = styled.div`
  height: 40%;
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: var(--color-accent-alsogrey);
    width: 75%;
    font-size: 1rem;
  }

  @media screen and (min-width: 1024px) {
    width: 60vw;
  }
`

export const FaveReportPHExample = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    margin-left: 15px;
    color: ${props => props.theme.inputs.text};
  }
`

export const MobileReportList = styled.div`
  background-color: rgb(204, 204, 204);
  text-align: left;
  line-height: 40px;
  font-size: 1.2em;
  overflow-y: scroll;
  display: flex;
  scrollbar-width: none;
  flex-direction: column;
  padding-right: 1em;
  width: 100%;
  position: absolute;
  z-index: 200;
  top: 120px;
  ul {
    padding-left: 1em;
  }
`

export const ReportCarouselContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > * {
    width: ${props => (props.extraWide ? '28%' : '40%')};
    min-width: unset;
  }
`

export const SearchReportsWrapper = styled(FlexWrapper)`
  height: fit-content;
  width: fit-content;
  align-items: center;
  align-self: flex-end;

  svg {
    font-size: 22px;
    fill: ${props => props.theme.restyle.primary};
    transition: fill 0.5s;
  }
`
