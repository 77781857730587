import styled, { keyframes } from "styled-components";
import {
  SiteHeaderSearch,
  SiteHeaderSearchWrapper,
} from "../nav/siteHeader/SiteHeaderStyles";

export const PermissionTable = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
`;

export const PermissionCard = styled.div`
  width: 100%;
  height: fit-content;
  border: 1px solid ${(props) => props.theme.dashboard.cards.border};
  border-radius: 5px;
  margin: 15px;
  padding: 10px 15px;
  box-sizing: border-box;
`;

export const PermissionCardContent = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;
export const PermissionCardName = styled.div`
  width: 50%;
  min-width: 200px;
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
  flex: 1;
  div#name-init {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  p {
    font-size: 1.5em;
  }
`;

export const PermissionCardClient = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  select {
    margin-left: 15px;
    font-size: 1.5em;
    min-width: 135px;
  }
`;

export const PermissionCardBadges = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  div.Badges {
    flex-flow: row wrap;
    display: flex;
  }
`;

export const PermissionsInit = styled.div`
  color: white;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-right: 10px;
  box-sizing: border-box;
  font-size: 1.5em;
  border-radius: 100%;
  background: ${(props) => props.theme.launchPad.main};
`;

type BadgeProps = {
  permissionCategory?: string;
};

const growMenu = keyframes`
    0%{
        height: 0%;
        width: 100%;
    }
    100%{
        height: max-content;
    }
`;

export const PermissionBadge = styled.div.attrs((props: BadgeProps) => ({
  permissionCategory: props.permissionCategory || "Admin",
}))<BadgeProps>`
  position: relative;
  height: 3em;
  box-sizing: border-box;
  border-radius: 5px;
  background: ${(props) => props.theme.permissions[props.permissionCategory]};
  color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  cursor: pointer;
  padding: 0 10px;
  div {
    display: flex;
    align-items: center;
    width: 100%;
    span {
      font-size: 1.2em;
      margin: 5px;
    }
    svg {
      cursor: pointer;
      margin: 5px;
    }
  }
  &.addPermission {
    margin-left: 0px;
    background: transparent;
    font-size: 24px;
  }
`;

export const PermissionBadgeWrapper = styled.div`
  position: relative;
  margin: 5px;
`;

export const PermissionMenu = styled.div.attrs((props: BadgeProps) => ({
  permissionCategory: props.permissionCategory
    ? props.permissionCategory
    : "Admin",
}))<BadgeProps>`
  position: absolute;
  top: 25px;
  background: ${(props) => props.theme.permissions[props.permissionCategory]};
  color: white;
  border-radius: 0 0 5px 5px;
  height: fit-content;
  max-height: 400px;
  overflow-y: auto;
  animation: ${growMenu};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  padding: 0 10px 5px 10px;
  box-sizing: border-box;
  z-index: 101;
  div {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
`;

export const PermissionCheckbox = styled.div`
  box-sizing: border-box;
  width: max-content;
  display: flex;
  margin: 5px 0;
  cursor: pointer;
  label {
    cursor: pointer;
    margin: 0px 5px;
    width: max-content;
  }
  input {
    position: absolute;
    visibility: hidden;
  }
`;

export const PermissionSearchWrapper = styled(SiteHeaderSearchWrapper)`
  position: relative;
  border-bottom: initial;
  svg {
    position: absolute;
    left: 0px;
    margin-bottom: 10px;
  }
`;

export const PermissionUserSearch = styled(SiteHeaderSearch)`
  width: 33%;
  min-width: 200px;
  color: ${(props) => props.theme.inputs.text};
  padding-left: 25px;
  box-sizing: border-box;
  background: ${(props) => props.theme.viewer.background};
  border-bottom: 1px solid ${(props) => props.theme.inputs.text};
  margin-bottom: 5px;
`;

export const PermissionCheckBoxDiv = styled.div`
  form {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      fieldset.permission-fieldset {
        text-align: left;
      }
    }
  }
`;

export const PermissionsClientAbbreviation = styled.p`
  margin-left: 30px;
`;
