import styled from 'styled-components'

export const Dashboard = styled.div`
  width: 100%;
  height: calc(100% - 110px);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  margin-top: ${props => (props.mobile ? '1rem' : '0')};
  display: ${props => (props.connectionSwitcher ? 'none' : 'flex')};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem 0.5rem 2rem;
  color: ${props => props.theme.restyle.offPrimary}; // using to make light mode text slightly darker than charcoal but not black
  overflow: hidden auto;
  transition: color 0.5s;
`

export const DashCard = styled.div`
  margin: 0.5rem;
  flex-grow: 1;
  height: ${props => (props.loaded ? '430px' : 'max-content')};
  min-height: 430px;
  width: calc(15% * ${props => (props.error ? 2 : props.cardWidth)});
  border: 1px solid ${props => props.theme.dashboard.cards.border};
  padding: ${props => (props.loaded || props.error ? '0px' : '3px 15px 15px 15px')};
  color: ${props => props.theme.launchPad.cards.fontColor};
  position: relative;

  div.wj-flexchart {
    border: ${props => (props.loaded ? null : 'none')};
    box-sizing: border-box;
    padding: 0;
  }
  h4 {
    box-sizing: border-box;
    margin: 0 0 10px 0;
    font-size: min(max(1vw, 18px), 20px);
  }
  svg > g > * {
    color: ${props => props.theme.inputs.text} !important;
  }
  svg.tooltip-icon {
    fill: var(--color-accent-alsogrey);
    font-size: 1.2rem;
    float: right;
    margin-right: -10px;
  }
`

export const BrokenChartDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.35);
  padding: 15px 0;

  h1 {
    margin: 0px 15px;
  }

  .chartSVG {
    margin: 1rem 0;
    fill: var(--color-primary-lightBlue);
    height: 250px;
    width: 250px;
  }
`

export const BrokenKPIDiv = styled(BrokenChartDiv)`
  min-height: 150px;
  height: 100%;

  padding: 0px;
  h5 {
    margin: 0;
    margin-bottom: 5px;
  }
  button {
    height: 1.5em;
  }
`

export const CardContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
  padding: 0 15px;
`

export const ChartTitle = styled.h2`
  margin-bottom: 0;
`

export const DashCardHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 320px) {
    margin-bottom: 10px;
  }
  h4 {
    margin: 0;
    cursor: default;
    svg {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 450px) {
    h4 {
      font-size: 1em;
    }
  }
  #chart-back-arrow {
    position: absolute;
    left: 10px;
    // bottom: 24px;
    cursor: pointer;
  }
`

export const LegendBox = styled.div`
  border: 1px solid #000000;
  padding: 5px;
  margin: 0 15px 15px 15px;
  min-width: 60%;
`

export const LegendItems = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  font-size: 0.8em;
`

export const SearchCard = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    width: 100%;
    height: 160px;
    box-sizing: border-box;
    padding: 10px;
  }
`
