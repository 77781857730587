import React from "react";
import { useAppSelector } from "../../redux/store";

// Components
import DotLoader from "../misc/dotLoader";
import Login from "./loginPage/login";
import MFActorAuth from "../mfa/MultiFactorAuth";
import SupportMessage from "./loginPage/supportMessage";
import SubmitUsername from "./submitUsername";
import TermsOfService from "../termsOfService/TermsOfService";
import OptionalProfile from "../settings/accounts/OptionalProfile";
import UpdateMFAPreference from "./firstTimeLogin/UpdateMFAPreference";
import ResetPassword from "../password/resetPassword";

// Styling and icons
import {
  LoginBackground,
  LoginLogo,
  LoginWrapper,
  LoginFooter,
  LoginButton,
  LoginContentsWrapper
} from "./loginStyles";

// Icons and images
import NewLogoCircle  from "../../images/New-logo-circle-wordmark-White-2.png";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoginWelcomePage from "./redirection/LoginWelcomePage";
import { endSession } from "../../utils/security";

const LoginContainer = () => {

  const loginTask = useAppSelector(state => state.login.loginTask)
  const fetchingUser = useAppSelector(state => state.users.fetchingUser)
  const fpAuthCompleted = useAppSelector(state => state.mFA.fpAuthCompleted)
  const mfaModalVisible = useAppSelector(state => state.mFA.modalVisible)
  const forgotPassword = useAppSelector(state => state.nav.forgotPassword)
  const mobile = useAppSelector(state => state.nav.mobile)
  const preloginCheckStatus = useAppSelector(state => state.login.preloginCheckStatus)
  const loginPending = useAppSelector(state => state.login.loginPending)
  const initialMFARequest = useAppSelector(state => state.mFA.initialMFARequest)
  const welcomeTimer = useAppSelector(state => state.users.welcomeTimer)

  /* DETERMINING LOGIN FLOW */
  const loginFlow = () => {
    if (mfaModalVisible) {
      return <MFActorAuth/>
    } else if (forgotPassword) {
      return fpAuthCompleted ? <ResetPassword process='forgotPW'/> : <SubmitUsername/>
    } else if (loginTask === 'UpdatePassword') {
      return <ResetPassword process='login'/>
    } else if (loginTask === 'TermsOfService') {
      return <TermsOfService login />
    } else if (loginTask === 'OptionalData') {
      return <OptionalProfile />
    } else if (loginTask === 'SetMfaPreference') {
      return <UpdateMFAPreference />
    } else if (preloginCheckStatus === 'pending') {
      return <DotLoader isLoading={preloginCheckStatus === 'pending'} width="250px" height="200px" dotSize="30px"/>
    } else {
      return <Login/>
    }
  }

  /* RENDER FUNCTIONS */
  const buildBackToLogin = () => {
    const handleBackToLogin = () => {
      endSession()
    }

    if (mfaModalVisible || forgotPassword || preloginCheckStatus === 'pending' || (loginTask && loginTask !== 'UpdatePassword') || loginPending === 'pending' || initialMFARequest === 'pending') {
      return (
        <LoginButton
          onClick={() => handleBackToLogin()}
          icon
          mobile={mobile}
          login
        >
          <ChevronLeftIcon style={{marginRight: '5px'}} />
          BACK TO LOG IN
        </LoginButton>
      )
    }
  }

  const buildLogin = () => {
    if (fetchingUser === 'pending' || welcomeTimer) {
      return <LoginWelcomePage/>
    } else {
      return (
        <>
          <LoginLogo mobile={mobile} loginHome={!mfaModalVisible && !loginTask && !fpAuthCompleted}>
            <img className="logo" src={NewLogoCircle} alt="Phytest" />
          </LoginLogo>
          <LoginWrapper>
            {loginFlow()}
          </LoginWrapper>
          {buildBackToLogin()}
          <LoginFooter>
            <SupportMessage/>
          </LoginFooter>
        </>
      );
    }
  };

  return (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
    }}>
      <LoginBackground/>
      <LoginContentsWrapper>
        {buildLogin()}
      </LoginContentsWrapper>
    </div>
  )
};


export default LoginContainer
