import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { QuickSearchResult, RequestStatus } from '../../../type'
import { AppDispatch } from '../../redux/store'
import { protectedApi } from '../../utils/api'
import { getCookie } from '../../utils/security'
import { fetchRefreshToken, logout } from '../login/userSlice'

interface QuickSearchState {
  quickTerm: string
  quickSearchResults: QuickSearchResult[]
  quickSearchSubmitted: RequestStatus
  error: string
}

const initialQuickSearchState = {
  quickTerm: '',
  quickSearchResults: [],
  quickSearchSubmitted: 'idle',
  error: '',
}

const initialState = initialQuickSearchState as QuickSearchState

export const submitQuickSearch = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>('quickSearch/submitQuickSearch', async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    return protectedApi(
      payload.controller,
      getCookie(process.env.REACT_APP_ACCESS_TOKEN)
    )
      .post('/rcm/patients/quickSearch', { SearchString: payload.searchString })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data
        } else {
          throw {
            data: data,
            thrownError: true,
            error: new Error(),
          }
        }
      })
      .catch((error) => {
        if (error.thrownError) {
          error = {response: error}
        }

        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() => thunkAPI.dispatch(submitQuickSearch(payload)))
          )
        }
        return error.response.data
      })
  } else {
    thunkAPI.dispatch(logout({controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.'}))
  }
})

export const quickSearchSlice = createSlice({
  name: 'quickSearch',
  initialState: initialState,
  reducers: {
    updateQuickSearchTerm(state, action) {
      state.quickTerm = action.payload
    },
    resetQuickSearchState(state) {
      state.quickTerm = ''
      state.quickSearchResults = []
      state.quickSearchSubmitted = 'idle'
    },
    resetQuickSearchStatus(state) {
      state.quickSearchSubmitted = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitQuickSearch.pending, (state) => {
      state.quickSearchSubmitted = 'pending'
    })
    builder.addCase(submitQuickSearch.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.quickSearchSubmitted = 'succeeded'
        state.quickSearchResults = action.payload.results
      } else {
        state.quickSearchSubmitted = 'rejected'
        state.error = action.payload.message
      }
    })
    builder.addCase(submitQuickSearch.rejected, (state, action) => {
      state.quickSearchSubmitted = 'rejected'
      //@ts-ignore
      state.error = action.payload.message
    })
  },
})

export const {
  updateQuickSearchTerm,
  resetQuickSearchState,
  resetQuickSearchStatus,
} = quickSearchSlice.actions
export default quickSearchSlice.reducer
