import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../type";
import { AppDispatch } from "../../redux/store";
import { protectedApi } from "../../utils/api";
import { deleteCookie, getCookie } from "../../utils/security";
import { logout, fetchRefreshToken, fetchUserInfo } from "../login/userSlice";
import { initiateElevatedAuth, resetmFA, togglemFAModal } from "../mfa/mFASlice";

// dayJS imports
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { setModal } from "../modals/modalSlice";

interface Settings {
  phoneNumber: string;
  SMScapable: boolean | null;
  email: string;
  checkPhoneNumberStatus: RequestStatus;
  getMultiFactorPreferenceStatus: RequestStatus;
  multiFactorPreferences: {};
  updateMultiFactorPreference: RequestStatus;
  getSettingsHonorificsStatus: RequestStatus;
  getSettingsOptionalDataStatus: RequestStatus;
  settingsHonorifics: object[];
  optionalUserData: any;
  saveProfileStatus: RequestStatus;
  saveSuccessful: RequestStatus;
  errors: any[];
}

const initialSettings = {
  phoneNumber: '',
  SMScapable: true,
  email: '',
  checkPhoneNumberStatus: 'idle',
  getMultiFactorPreferenceStatus: 'idle',
  multiFactorPreferences: {},
  updateMultiFactorPreference: 'idle',
  getSettingsHonorificsStatus: 'idle',
  settingsHonorifics: [],
  getSettingsOptionalDataStatus: 'idle',
  optionalUserData: {
    FirstName: '',
    LastName: '',
    JobTitle: '',
    PreferredName: '',
    HonorificID: 1,
    Honorific: '',
    Pronoun: '',
    DateOfBirth: null,
  },
  saveProfileStatus: 'idle',
  saveSuccessful: 'idle',
  errors: [],
};

const initialState = initialSettings as Settings;

const resetModal = (thunkAPI: any) => {
  if (process.env.REACT_APP_SECURITY_TOKEN) {
    deleteCookie(process.env.REACT_APP_SECURITY_TOKEN)
  }
  if (process.env.REACT_APP_ELEVATED_TOKEN) {
    deleteCookie(process.env.REACT_APP_ELEVATED_TOKEN)
  }
  thunkAPI.dispatch(resetSettingsSlice())
  thunkAPI.dispatch(resetmFA({ saveSecurityAuth: false }))
}

/* UPDATE MFA FUNCTIONS */
export const updatePhoneNumber = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("settings/updateMFAForm", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    const token = process.env.REACT_APP_SECURITY_TOKEN;

    return protectedApi(payload.controller, getCookie(token))
      .post("/users/settings/updatePhoneNumber", payload)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data            
        }  else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.status === 400) { // Phone number does not meet character-limit, contains non-numeric characters, or is not a mobile number
          thunkAPI.dispatch(setSettingError(error.response.data.message))
        } else if (error.response.data.tokenExpired) { // 401 error
          thunkAPI.dispatch(setSettingError('Your MFA validation has expired. Please complete MFA again.'))
          setTimeout(() => {
            resetModal(thunkAPI)
            thunkAPI.dispatch(initiateElevatedAuth({controller: new AbortController()}))
            thunkAPI.dispatch(togglemFAModal(true))
          }, 3 * 1000)
        }  else {
          thunkAPI.dispatch(setSettingError(error.response.data.message))
          setTimeout(() => {
            resetModal(thunkAPI)
            thunkAPI.dispatch(setModal({key: 'settings', data: false}))
          }, 1000 * 5)
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(setSettingError('Your credentials are expired. Please log in again.'))
    setTimeout(() => {
      thunkAPI.dispatch(logout({ controller: new AbortController() }))
    }, 5 * 1000)
  }
});

export const getMultiFactorPreferenceTypes = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("settings/getMFAPreferenceType", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    const token = process.env.REACT_APP_ACCESS_TOKEN

    return protectedApi(payload.controller, getCookie(token))
      .get("/users/settings/getMultiFactorPreferenceTypes")
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data            
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() =>
              thunkAPI.dispatch(getMultiFactorPreferenceTypes(payload))
            )
          );
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(setSettingError('Your credentials are expired. Please log in again.'))
    setTimeout(() => {
      thunkAPI.dispatch(logout({ controller: new AbortController() }))
    }, 5 * 1000)
  }
});

export const updateMultiFactorPreference = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("settings/updateMFAPreferenceType", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    const token = process.env.REACT_APP_SECURITY_TOKEN;

    return protectedApi(payload.controller, getCookie(token))
      .post("/users/settings/updateMultiFactorPreference", payload)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data            
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(setSettingError('Your MFA validation has expired. Please complete MFA again.'))
          setTimeout(() => {
            resetModal(thunkAPI)
            thunkAPI.dispatch(initiateElevatedAuth({controller: new AbortController()}))
            thunkAPI.dispatch(togglemFAModal(true))
          }, 3 * 1000)
        } else {
          thunkAPI.dispatch(setSettingError(error.response.data.message))
          setTimeout(() => {
            resetModal(thunkAPI)
            thunkAPI.dispatch(setModal({key: 'settings', data: false}))
          }, 1000 * 5)
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(setSettingError('Your credentials are expired. Please log in again.'))
    setTimeout(() => {
      thunkAPI.dispatch(logout({ controller: new AbortController() }))
    }, 5 * 1000)
  }
});

/* UPDATE PROFILE FUNCTIONS */
export const getSettingsHonorifics = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("settings/getSettingsHonorifics", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    const token = process.env.REACT_APP_ACCESS_TOKEN

    return protectedApi(payload.controller, getCookie(token))
      .get("/users/settings/getHonorifics")
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data            
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() =>
              thunkAPI.dispatch(getSettingsHonorifics(payload))
            )
          );
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(setSettingError('Your credentials are expired. Please log in again.'))
    setTimeout(() => {
      thunkAPI.dispatch(logout({ controller: new AbortController() }))
    }, 5 * 1000)
  }
});

export const getSettingsOptionalData = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("settings/getSettingsOptionalData", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    const token = process.env.REACT_APP_ACCESS_TOKEN

    return protectedApi(payload.controller, getCookie(token))
      .get("/users/settings/getOptionalUserData")
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return data            
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() =>
              thunkAPI.dispatch(getSettingsOptionalData(payload))
            )
          );
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(setSettingError('Your credentials are expired. Please log in again.'))
    setTimeout(() => {
      thunkAPI.dispatch(logout({ controller: new AbortController() }))
    }, 5 * 1000)
  }
});

export const saveSettingsOptionalData = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("settings/saveSettingsOptionalData", async (payload, thunkAPI) => {
  if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
    const token = process.env.REACT_APP_ACCESS_TOKEN;

    return protectedApi(payload.controller, getCookie(token))
      .post("/users/settings/saveOptionalUserData", payload)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          thunkAPI.dispatch(fetchUserInfo({ controller: new AbortController() }));
          thunkAPI.dispatch(getSettingsOptionalData({ controller: new AbortController() }))
          return data
        } else {
          throw new Error()
        }
      })
      .catch((error) => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(
            fetchRefreshToken(() =>
              thunkAPI.dispatch(saveSettingsOptionalData(payload))
            )
          );
        }
        return error.response.data
      });
  } else {
    thunkAPI.dispatch(setSettingError('Your credentials are expired. Please log in again.'))
    setTimeout(() => {
      thunkAPI.dispatch(logout({ controller: new AbortController() }))
    }, 5 * 1000)
  }
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    updateSettingsStatus(state, action) {
      if (action.payload.request === 'updatePhone') {
        state.checkPhoneNumberStatus = action.payload.status
      } else if (action.payload.request === 'updateMFAPref') {
        state.updateMultiFactorPreference = action.payload.status
      } else if (action.payload.request === 'getMFAPref') {
        state.getMultiFactorPreferenceStatus = action.payload.status
      } else if (action.payload.request === 'getOptional') {
        state.getSettingsOptionalDataStatus = action.payload.status
      } else if (action.payload.request === 'getHonorifics') {
        state.getSettingsHonorificsStatus = action.payload.status
      }
    },
    resetRequestStatus(state, action) {
      if (action.payload === 'getOptionalData') {
        state.getSettingsOptionalDataStatus = 'idle'
      }
    },
    resetMessage(state) {
      state.saveSuccessful = 'idle';
      state.saveProfileStatus = 'idle';
    },
    resetSettingsSlice(state) {
      state.phoneNumber = '';
      state.email = '';
      state.checkPhoneNumberStatus = 'idle';
      state.getMultiFactorPreferenceStatus = 'idle';
      state.multiFactorPreferences = {};
      state.updateMultiFactorPreference = 'idle';
      state.saveProfileStatus = 'idle';
      state.saveSuccessful = 'idle';
      state.errors = [];
    },
    setSettingError(state, action) {
      state.errors.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePhoneNumber.pending, (state) => {
      state.checkPhoneNumberStatus = "pending"
      state.errors = []
    });
    builder.addCase(updatePhoneNumber.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.checkPhoneNumberStatus = 'succeeded'
      } else {
        state.checkPhoneNumberStatus = "rejected"
      }
    });
    builder.addCase(getMultiFactorPreferenceTypes.pending, (state) => {
      state.getMultiFactorPreferenceStatus = "pending"
      state.errors = []
    });
    builder.addCase(getMultiFactorPreferenceTypes.fulfilled, (state, action) => {
      if (action.payload.success) {
        const emailData: any = action.payload.MfaPreferenceTypes.find((pref: any) => pref.PreferenceTypeID === 1)
        const phoneData = action.payload.MfaPreferenceTypes.find((pref: any) => pref.PreferenceTypeID === 2)
        
        state.getMultiFactorPreferenceStatus = "succeeded"
        state.multiFactorPreferences = action.payload.MfaPreferenceTypes
        state.phoneNumber = phoneData.destination
        state.SMScapable = phoneData.phoneSMSCapable
        state.email = emailData.destination
      } else {
        state.getMultiFactorPreferenceStatus = "rejected"
        state.errors.push(action.payload.message)
      }
    });
    builder.addCase(getMultiFactorPreferenceTypes.rejected, (state, action) => {
      state.getMultiFactorPreferenceStatus = "rejected"
      //@ts-ignore
      state.errors.push(action.payload.message)
    });
    builder.addCase(updateMultiFactorPreference.pending, (state) => {
      state.updateMultiFactorPreference = "pending"
      state.errors = []
    });
    builder.addCase(updateMultiFactorPreference.fulfilled, (state, action) => {
      state.updateMultiFactorPreference = (action.payload.success ? "succeeded" : "rejected")
    });
    builder.addCase(getSettingsHonorifics.pending, (state) => {
      state.getSettingsHonorificsStatus = "pending"
      state.errors = []
    });
    builder.addCase(getSettingsHonorifics.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.getSettingsHonorificsStatus = "succeeded"
        state.settingsHonorifics = action.payload.honorifics
      } else {
        state.getSettingsHonorificsStatus = "rejected"
        state.errors.push(action.payload.message)
      }
    });
    builder.addCase(getSettingsHonorifics.rejected, (state, action) => {
      state.getSettingsHonorificsStatus = "rejected"
      //@ts-ignore
      state.errors.push(action.payload.message)
    });
    builder.addCase(getSettingsOptionalData.pending, (state) => {
      state.getSettingsOptionalDataStatus = "pending"
      state.errors = []
    });
    builder.addCase(getSettingsOptionalData.fulfilled, (state, action) => {
      if (action.payload.success) {
        dayjs().format()
        dayjs.extend(utc)
        dayjs.extend(timezone)
        dayjs.tz.setDefault("America/New_York")

        state.getSettingsOptionalDataStatus = "succeeded"
        state.optionalUserData.PreferredName = action.payload.PreferredName
        state.optionalUserData.FirstName = action.payload.FirstName
        state.optionalUserData.LastName = action.payload.LastName
        state.optionalUserData.JobTitle = action.payload.JobTitle
        state.optionalUserData.HonorificID = action.payload.HonorificID
        state.optionalUserData.Honorific = action.payload.Honorific
        state.optionalUserData.Pronoun = action.payload.Pronoun
        state.optionalUserData.DateOfBirth = action.payload.DateOfBirth === null ? null : dayjs.tz(action.payload.DateOfBirth)
      } else {
        state.getSettingsOptionalDataStatus = "rejected"
        state.errors.push(action.payload.message)
      }
    });
    builder.addCase(getSettingsOptionalData.rejected, (state, action) => {
      state.getSettingsOptionalDataStatus = "rejected"
      //@ts-ignore
      state.errors.push(action.payload.message)
    });
    builder.addCase(saveSettingsOptionalData.pending, (state) => {
      state.saveProfileStatus = "pending"
      state.errors = []
    });
    builder.addCase(saveSettingsOptionalData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.saveProfileStatus = "succeeded"
        state.saveSuccessful = "succeeded"
      } else {
        state.saveProfileStatus = "rejected"
        state.errors.push(action.payload.message)
      }
    });
    builder.addCase(saveSettingsOptionalData.rejected, (state, action) => {
      state.saveProfileStatus = "rejected"
      //@ts-ignore
      state.errors.push(action.payload.message)
    });
  },
});
  
export const {
  updateSettingsStatus,
  resetMessage,
  resetRequestStatus,
  resetSettingsSlice,
  setSettingError,
} = settingsSlice.actions;
export default settingsSlice.reducer;
