import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const PhytestTable = styled.table`
  margin: 0 auto;
  display: table;
  position: relative;
  table-layout: fixed;
  width: 80%;
  border-spacing: 0px;
  border-collapse: separate;
  color: ${props => props.theme.shades.text};
  thead tr {
    font-size: calc(12px + 6 * ((100vw - 320px) / 680));
    text-align: left;
    @media screen and(min-width:1000px) {
      font-size: 22px;
    }
  }
  tbody tr {
    font-size: calc(10px + 3 * ((100vw - 320px) / 680));
    text-align: left;
    @media screen and(min-width:1000px) {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`

export const PhytestTableHead = styled.thead`
  tr {
    &:first-child {
      th {
        &:first-child {
          width: 1em;
        }
        &:nth-child(n + 2) {
          width: 20%;
        }
      }
    }
  }
`

export const PhytestTableRow = styled(Link)`
  display: table-row;
  color: inherit;
  text-decoration: inherit;
  position: relative;
  width: 100%;
  height: 2.5em;
  box-sizing: border-box;
  &:hover {
    background: ${props => (!props.nohover ? props.theme.reporting.main : 'transparent')};
    color: white;
  }
`

export const PhytestTableHeader = styled.th`
  border-bottom: solid #858585 2px;
  width: 100%;
  height: 100%;
  position: sticky;
  background: ${props => props.theme.viewer.background};
  top: -80px;
  text-align: left;
  &:hover {
    color: #036c9b;
    cursor: default;
  }
`

export const PhytestTableControlWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 10px 20px;
  box-sizing: border-box;
  position: sticky;
  z-index: 20;
  top: 0;
  div {
    &:first-child {
      justify-content: flex-start;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
`

export const PhytestTableControlWrapperSearch = styled(PhytestTableControlWrapper)`
  width: 80%;
  top: 0px;
  position: static;
  margin: 1.5rem 0;
  padding: 0;
`

export const ControlLabel = styled.label`
  font-size: 1.1rem;
  margin-right: 10px !important;
  white-space: nowrap;
`

export const ControlCell = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: calc(12px + 3 * ((100vw - 320px) / 680)); */
  /* ^^ 👀 WHAT */
`

export const TableUl = styled.ul`
  list-style: none;
  text-align: left;
  margin: 0;
  padding-left: 0;
  li {
    line-height: 1.2;
  }
`

export const PhytestPatientInfoTH = styled.th`
  height: 40px;
  &:hover {
    color: ${props => (props.hoverable ? '#036C9B' : null)};
    cursor: default;
  }
`

export const HiddenCheckbox = styled.input`
  visibility: hidden;
  display: none;
`

export const TableCheckBox = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 2px;
  background: white;
  cursor: pointer;
`

export const TableDiv = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px 20px 0 20px;
`

export const TableData = styled.div`
  width: 100%;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  text-align: left;

  border-bottom: solid #858585 1px;
  position: relative;
  @media screen and (max-width: 767px) {
    font-size: 18px;
    text-overflow: ellipsis;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    z-index: 100;
    height: 100%;
  }
`

export const HistoricNameData = styled(PhytestTableRow)`
  border-top: none;
`

export const PatientInfoTableData = styled.td`
  height: 2.5em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  white-space: nowrap;
  font-size: 1em;
  @media screen and (max-width: 767px) {
    font-size: 18px;
    text-overflow: ellipsis;
  }
`

export const TablePlaceholder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.patientInfo.table.background};
`

export const TableHeaderPHDiv = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background: ${props => props.theme.patientInfo.table.header};
`

export const TableRowPlaceholder = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: ${props => props.theme.patientInfo.table.rowPrimary};
`

export const TableRowSecondaryPH = styled.div`
  width: 98%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  background: ${props => props.theme.patientInfo.table.rowSecondary};
`
