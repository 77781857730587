import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import PermissionsTableContainer from './PermissionsTableContainer'
import { SettingsContent } from '../settings/settingsStyles'
import { getPermissions } from './permissionsSlice'

interface Props {
    selected: string
}

const PermissionsContainer = (props: Props) => {

    const users = useAppSelector(state => state.permissions.userPermissions)
    const fetchingUserPermissions = useAppSelector(state => state.permissions.fetchingUserPermissions)

    const dispatch = useAppDispatch()
    
    useEffect(() => {
        let payload= {}
        switch(props.selected){
            case('Client Portal'):
                payload={AppID: 1}
                break
            case('PFR'):
                payload= {AppID: 2}
                break
            case('DualRemit'):
                payload={AppID: 3}
                break
        }
        dispatch(getPermissions({...payload, controller: new AbortController()}))
        return () => {
        }
    }, [props.selected, dispatch])

    return (
        <SettingsContent
            style={{
                justifyContent: 'flex-start',
                marginTop: '-10px',
            }}
        >   
            <PermissionsTableContainer
                users={users}
                fetchingUserPermissions={fetchingUserPermissions}
                selected={props.selected}
            />
        </SettingsContent>
    )
}

export default PermissionsContainer
