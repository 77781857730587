import { useLocation } from 'react-router-dom'
import { PageNotFoundContainer } from './navStyles'
import { useDispatch } from 'react-redux'
import { logout } from '../login/userSlice'
import { useAppSelector } from '../../redux/store'
import { endSession } from '../../utils/security'
import { LoginButton } from '../login/loginStyles'
import { Biotech } from '@mui/icons-material'

const PageNotFound = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn)

  const handleClick = () => {
    isLoggedIn ? dispatch(logout({ controller: new AbortController() })) : endSession()
  }

  return (
    <PageNotFoundContainer direction="column">
      <Biotech />
      <h1>Oops!</h1>
      <h1>404 Page not found</h1>
      <LoginButton onClick={handleClick}>Return Home</LoginButton>
    </PageNotFoundContainer>
  )
}

export default PageNotFound
