export const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
 
export const storeCookie= (cName) =>{
    const name = cName+'='
    const cookieArray = document.cookie.split(';')
    const cookie =  cookieArray.find(cookie=>cookie.includes(name)).split('=')
    sessionStorage.setItem(cookie[0], cookie[1])
}

export const setCookie = (name, val) => {
    if(name === process.env.REACT_APP_REFRESH_TOKEN){
        document.cookie = `${process.env.REACT_APP_REFRESH_TOKEN}=${val}; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`
    }else if(name === '_redirected'){
        document.cookie = `${name}=${val}; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`
    }else{
        document.cookie = `${name}=${val}; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`
    }
}

export const getCookie = (name) => {
  const cookie = document.cookie.split(';').find(cookie=> cookie.includes(name))
  if(cookie){
      return cookie.split('=')[1]
  }
}

export const resetRefresh = () => {
    const val = getCookie(process.env.REACT_APP_REFRESH_TOKEN)
    const name = process.env.REACT_APP_REFRESH_TOKEN
    document.cookie = `${name}=${val}; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS}`
}

export const deleteCookie = (name) => {
    if(!!getCookie(name)){
        document.cookie = `${name}=expired; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS} expires=Thu, 01 Jan 1970 00:00:01 GMT`
    }
}

export const deleteAllCookies = () =>{
    document.cookie.split(';').forEach(cookie=> {
        let cookieArr = cookie.split('=')
        document.cookie = `${cookieArr[0]}=expired; path=/; ${process.env.REACT_APP_COOKIE_OPTIONS} expires=Thu, 01 Jan 1970 00:00:01 GMT`
        } 
    )

}

// This function can be used when the user is not logged in (e.g., forgot password, first-time login)
// If the user is logged in, logout() should be called so the database is aware the login session has ended
export const endSession = (message, preventRefresh) => {
  if (message) {
    sessionStorage.setItem('LogoutMessage', message)
    sessionStorage.setItem('LogoutMessageExpires', new Date(new Date().getTime() + (3 * 60 * 1000))) // Will expire in 3 minutes
  }
  deleteAllCookies();
  if (!preventRefresh) { window.location.assign(process.env.REACT_APP_PORTAL_URL) };
}