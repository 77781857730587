import * as wjChart from '@grapecity/wijmo.react.chart'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import { convertNumToCurrency } from '../../../utils/helperMethods'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { DashCardHeader } from '../dashboardStyles'
import { UnstyledButton } from '../../../styles/buttonStyles'
import CustomTooltip from '../../misc/CustomTooltip'

const ChargesChartMobile = ({ charges, formatYLabels, goToReport, reportName }) => {
  const calculateYAxisMax = () => {
    // get largest amount from array of collection objects
    const largestNum = Math.ceil(
      charges
        .map(data => data.Amount)
        .sort((a, b) => a - b)
        .pop()
    )
    // get the place value of left most num
    const tens = parseInt(
      largestNum
        .toString()
        .split('')
        .map((num, i) => (i === 0 ? '1' : '0'))
        .join('')
    )
    // round to next 1eX where X equals the left most place value
    return Math.ceil(largestNum / tens) * tens
  }

  const formatXLabels = (eng, label) => {
    label.cls = 'mobile-label'
    return label
  }

  return (
    <>
      <DashCardHeader>
        <h4>Total Charges by Month</h4>
      </DashCardHeader>
      <wjChart.FlexChart itemsSource={charges} bindingX="PeriodAbbreviation" cssClass="dark" chartType="Bar" rendered={e => e.hostElement.classList.add('chart')}>
        <wjChart.FlexChartAxis itemFormatter={(engine, label) => formatYLabels(engine, label)} binding="Amount" wjProperty="axisX" max={calculateYAxisMax()} />
        <wjChart.FlexChartAxis wjProperty="axisY" binding="PeriodAbbreviation" itemFormatter={(eng, label) => formatXLabels(eng, label)}></wjChart.FlexChartAxis>
        <wjChart.FlexChartSeries
          style={{ fill: '#107F4F' }}
          binding="Amount"
          cssClass="bar"
          tooltipContent=''
        />
        <wjChart.FlexChartDataLabel content={ht => `${convertNumToCurrency(ht.item.Amount)}`} position={1}></wjChart.FlexChartDataLabel>
        <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </>
  )
}

export default ChargesChartMobile
