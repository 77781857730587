import styled from "styled-components";

interface SidebarProps {
  loading?: boolean;
  firstLogin?: boolean;
  selected?: boolean;
  mobile?: boolean;
  settings?: boolean;
}

interface InputProps {
  error?: boolean;
}

export const SettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  h3 {
    margin: 0px;
  }
`;

export const SettingsContent = styled.div<{
  removePadding?: boolean;
}>`
  display: flex;
  flex-flow: column;
  justify-content: center;
  grid-area: content;
  width: 100%;
  padding: ${(props) => (props.removePadding ? null : "10px")};
  box-sizing: border-box;
  overflow-x: auto;
`;

export const SettingsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SettingsModalContainer = styled.div.attrs(() => {})`
  height: 80%;
  width: 75%;
  position: relative;
  background-color: ${(props) => props.theme.viewer.background};
  color: ${(props) => props.theme.shades.text};
  overflow-y: auto;
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-template-rows: 100%;
  grid-template-areas: "sidebar content";
  transition: all 1000ms linear;
  overflow: hidden;
  .settingsHeader {
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    h3 {
      font-size: calc(1em + 0.5vmin);
    }
    svg {
      margin: 10px;
      font-size: calc(1em + 2vmin);
    }
  }

  @media screen and (max-width: 1250px) {
    height: calc(100% - 80px);
    width: 100%;
    top: 40px;
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    .settingsHeader {
      h3 {
        margin: 0.5em;
      }
    }
  }
`;

export const SettingsSidebarContainer = styled.ul`
  grid-area: sidebar;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  margin: 0;
  min-width: 200px;
  max-width: 300px;
  background: #373D4D;
  padding-left: 0px;
  @media screen and (max-width: 1250px) {
    width: 25%;
    top: 80px;
  }
  @media screen and (max-width: 900px) {
    justify-content: space-between;
    width: 100%;
    height: 65px;
    min-height: 65px;
    max-width: 100%;
    flex-flow: row;
  }
`;

export const MobileSettingsTabsContainer = styled.div`
  grid-area: content;
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: slategrey; */
  background: #555;
`;

export const SettingsMenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  svg {
    font-size: calc(1em + 2vmin);
  }
`;

export const SettingsSidebarItem = styled.li<SidebarProps>`
  height: fit-content;
  min-height: 65px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.selected ? '#7ED1E7' : '#FFF'};
  span {
    font-size: 1.5em;
    padding: 15px;
    margin-right: 10px;
  }
  &:hover {
    color: #7ED1E7;
  }
`;

export const SettingsViewer = styled.div<SidebarProps>`
  height: 100%;
  min-height: 100%;
  width: ${(props) => (props.mobile ? "100%" : "85%")};
  min-width: ${(props) => (props.mobile ? "100%" : "85%")};
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 5%;
`;

export const SettingsTitle = styled.div`
  height: 100px;
  min-height: 50px;
  width: 100%;
  background: #333333;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionTitle = styled.h3<SidebarProps>`
  font-size: ${(props) => props.mobile ? "1.1em" : "1.3em"};
  padding: ${(props) => props.mobile ? "1.1em" : ""};
  box-sizing: border-box;
  color: ${(props) => props.settings ? props.theme.optionalTitle.text : '#f1f1f1'};
  text-align: center;
  margin: 0%;
  margin-bottom: 1%;
`;

export const Section = styled.div<SidebarProps>`
  width: 100%;
  min-height: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.loading ? "center" : "center")};
  align-items: center;
  box-sizing: border-box;
  padding: ${(props) => (props.firstLogin ? "1vw" : "30px")};
  background: ${(props) => props.theme.launchPad.cards.background};
`;

export const FormSection = styled(Section)`
  min-height: 100px;
`;

export const TipSection = styled(Section)`
  width: 100%;
  display: flex;
  min-height: initial;
  flex-direction: column;
  justify-content: ${(props) => (props.loading ? "center" : "center")};
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  background: ${(props) => props.theme.launchPad.cards.background};
`;

export const SettingsLabel = styled.label`
  font-weight: bold;
  align-self: flex-start;
  margin-left: 10%;
`;

export const SettingsInput = styled.input<InputProps>`
  width: 80%;
  height: 2em;
  margin: 10px 0;
  box-sizing: border-box;
  font-size: 1em;
  background: ${(props) => props.theme.inputs.background};
  color: ${(props) => props.theme.inputs.text};
  border: none;
  border-bottom: 1px solid
    ${(props) =>
      props.error
        ? props.theme.providers.medicare.main
        : props.theme.inputs.text};
  &:focus {
    outline: none;
  }
`;

export const SettingsFieldSet = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-flow: column wrap;
  align-items: center;
  border: none;
`;

export const SettingsSelectDiv = styled.div`
  width: 100%;
  height: 2em;
  margin: 10px 0;
  box-sizing: border-box;
  font-size: 1em;
  border: none;
`;

export const SettingsSelect = styled.select<InputProps>`
    height: 40px;
    width: 80%;
    border: none;
    background: ${props=> props.theme.viewer.background};
    font-size: 1.2em;
    color: ${props=> props.theme.inputs.text};
    cursor: pointer;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  width: auto;
  min-width: 250px;
  flex-flow: column;
  align-items: center;
  border: 1px solid ${(props) => props.theme.providers.medicare.main};
  background: #ebc1c1;
  padding: 8px;
  box-sizing: border-box;
  text-align: center;
`;

export const SubMenuUnorderedList = styled.ul`
  font-size: 1em;
  height: max-content;
  padding: 1em;
  margin-right: 10px;
  margin-bottom: 10px;
  list-style: none;
  text-align: right;
  li {
    min-height: 1.3em;
    max-height: 1.3em;
    padding: .2em;
  }
  &:hover {
    li:hover {
      font-size: 1.1em;
      color: white;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 700px) {
    position: absolute;
    top: 65px;
    right: -10px;
    z-index: 200;
    width: 10em;
    /* background: slategrey; */
    background: #373D4D;
  }
`;
