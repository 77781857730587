import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/store'

// Styling
import { SelectedTab, TabBlock, TabLabel } from './tabStyles';

// Components
import { setModal } from '../../components/modals/modalSlice'

// Icons
import InsightsIcon from '@mui/icons-material/Insights';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SupportIcon from '@mui/icons-material/Support';
import { toggleConnectionSwitcher } from '../login/userSlice';
import { toggleMobileSidebar } from './navSlice';

const Tab = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const supportModal = useAppSelector(state => state.modals.support)
  const showConnectionSwitcher = useAppSelector(state => state.users.connectionSwitcher.showConnectionSwitcher)
  const mobile = useAppSelector(state => state.nav.mobileSidebar)

  const renderIcon = () => {
    switch (props.purpose) {
      case 'dashboard':
        return <InsightsIcon />
      case 'patientManagement':
        return <MedicalInformationIcon />
      case 'clientBill':
        return <ReceiptLongIcon />
      case 'reporting':
        return <AnalyticsIcon />
      case 'support':
        return <SupportIcon />
      default:
        return null
    }
  }

  const handleSupportToggle = useCallback(
    (payload) => {
        dispatch(setModal({key: 'support', data: payload}))
    },
    [dispatch],
  )

  const handleSupportClick = () =>{
    handleSupportToggle(!supportModal)
  }

  const handleClick = (e) => {
    e.stopPropagation()
    if (showConnectionSwitcher) {
      dispatch(toggleConnectionSwitcher({toggle: false}))
    }

    if (mobile) {
      dispatch(toggleMobileSidebar(false))
    }

    if (props.purpose === 'dashboard') {
      return navigate('/dashboard')
    } else if (props.purpose === 'patientManagement') {
      return navigate('/patientManagement')
    } else if (props.purpose === 'clientBill') {
      // return navigate('/clientBill') // Coming Soon!
    } else if (props.purpose === 'reporting') {
      return navigate('/reporting')
    } else if (props.purpose === 'support') {
      handleSupportClick()
    } else {
      return null
    }
  }

  return (
    <TabBlock purpose={props.purpose} selected={props.selected} onClick={(e) => handleClick(e)}>
      <SelectedTab selected={props.selected}/>
      {renderIcon()}
      <TabLabel>{props.title}</TabLabel>
    </TabBlock>
  )
}

export default Tab;