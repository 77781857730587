import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { CarouselWrapper, PositionIndicatorWrapper } from './carouselStyles'
import '../../../styles/stylesheets/animations.css'
import { useSwipeable } from 'react-swipeable'
import CarouselPositionIndicator from './CarouselPositionIndicators'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { ArrowBackIos, ArrowForward, ArrowForwardIos } from '@mui/icons-material'

const Carousel = ({ children, coordinates }) => {
  const [currentSlide, setCurrent] = useState(0)
  const [direction, setDirection] = useState('')
  const [prevDirection, setPrevDirection] = useState('Left')

  const carouselRef = useRef()

  //listen for changes in direction, length of 'children', and the current slide.
  useEffect(() => {
    //handle changes based on the direction of movement
    const handleSlideChange = () => {
      let next
      if (direction === 'Left') {
        next = (currentSlide + 1) % children.length
      } else if (direction === 'Right') {
        currentSlide - 1 < 0 ? (next = children.length - 1) : (next = currentSlide - 1)
      }
      setCurrent(next)
      setDirection(null)
    }
    //trigger handler when direction is changed
    if (direction && direction !== '') {
      handleSlideChange()
    }
  }, [direction, children.length, currentSlide])

  //listen for changes in direction and set prevDirection equal to it
  useEffect(() => {
    if (direction) {
      setPrevDirection(direction)
    }
  }, [direction])

  //assign class name for transition enter and exit
  const handleClassName = baseClassName => {
    if (direction) {
      switch (direction) {
        case 'Left':
          return 'carousel-slide-Left-exit'
        case 'Right':
          return 'carousel-slide-Right-exit'
        default:
          return baseClassName
      }
    }
  }

  //set direction on swipe
  const { ref } = useSwipeable({
    onSwiped: e => handleSwipe(e),
  })

  const handleSwipe = e => {
    const initY = e.initial[1]
    if (e.dir !== 'Down' && e.dir !== 'Up') {
      if (coordinates && initY < coordinates[1] && initY > coordinates[0]) {
        setDirection(e.dir)
      } else {
        setDirection(e.dir)
      }
    }
  }

  //use the wrapper as a ref?
  useEffect(() => {
    ref(carouselRef.current)
  })

  //class names to be used during the transition for animation purposes
  const nextClassNames = {
    enter: 'carousel-slide-Left-enter',
    enterActive: 'carousel-slide-Left-enter-active',
    enterDone: 'carousel-slide-enter-done',
    exit: handleClassName('carousel-slide-Left-exit'),
    exitActive: `${handleClassName('carousel-slide-Left-exit')}-active`,
    exitDone: 'carousel-slide-Left-done-exit',
  }

  const prevClassNames = {
    enter: 'carousel-slide-Right-enter',
    enterActive: 'carousel-slide-Right-enter-active',
    enterDone: 'carousel-slide-enter-done',
    exit: handleClassName('carousel-slide-Right-exit'),
    exitActive: `${handleClassName('carousel-slide-Right-exit')}-active`,
    exitDone: 'carousel-slide-Right-done-exit',
  }

  const buildPositionIndicators = children => {
    let positionArray = []
    for (let i = 0; i < children.length; i++) {
      const positionIndicator = <CarouselPositionIndicator key={`indicator.${i}`} selected={currentSlide === i} />
      positionArray.push(positionIndicator)
    }
    return positionArray
  }

  return (
    <CarouselWrapper ref={carouselRef}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {children && children.length > 0 && children[currentSlide] ? (
          <TransitionGroup component="div" className="carousel-group">
            <CSSTransition
              classNames={prevDirection === 'Left' ? nextClassNames : prevClassNames}
              timeout={{ enter: 1000, exit: 800 }}
              key={currentSlide}
              exit={false}
              style={{ width: '100%', minHeight: '340px' }}
            >
              {children[currentSlide]}
            </CSSTransition>
          </TransitionGroup>
        ) : null}
      </div>
      <PositionIndicatorWrapper>
        <ArrowBackIos fontSize="16px" onClick={() => setDirection('Right')} />
        {buildPositionIndicators(children)}
        <ArrowForwardIos fontSize="16px" onClick={() => setDirection('Left')} style={{ marginLeft: '.2rem' }} />
      </PositionIndicatorWrapper>
    </CarouselWrapper>
  )
}

export default Carousel
