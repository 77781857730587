import { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { sendUsername } from '../password/passwordResetSlice'
import { useViewport } from '../../utils/hooks'
import { emailPattern } from '../../utils/security'

// Styling
import { ErrorWrapper } from '../settings/settingsStyles'
import { ErrorMessage } from '../modals/modalStyles'
import {
  LoginButton,
  LoginField,
  LoginForm,
  LoginInput,
  LoginStatement,
  StatementWrapper,
} from './loginStyles'
import EmailIcon from '@mui/icons-material/Email';

const SubmitUsername = () => {
  const [username, updateUsername] = useState('')
  const [username2, updateUsername2] = useState('')

  const [validUsername, setValidUsername] = useState(true)
  const [matchingUsername, setMatchingUsername] = useState(true)

  const mobile = useAppSelector(state => state.nav.mobile)
  const criticalError = useAppSelector(state => state.passwordReset.criticalError)
  const pwError = useAppSelector(state => state.passwordReset.error)
  const mfaCriticalError = useAppSelector(state => state.mFA.criticalError)
  const mfaError = useAppSelector(state => state.mFA.error)

  const dispatch = useAppDispatch()

  const { width, height } = useViewport()

  const handleSubmit = (e) => {
    e.preventDefault()
    e.target.blur()

    const validUsernameLocal = username.match(emailPattern) && username2.match(emailPattern)
    const matchingUsernameLocal = (username === username2)

    setValidUsername(validUsernameLocal)
    setMatchingUsername(matchingUsernameLocal)

    if (validUsernameLocal && matchingUsernameLocal) {
      postUsername({
        UserName: username,
        Browser: sessionStorage.getItem("userBrowser"),
        BrowserVersion: sessionStorage.getItem("browserVersion"),
        OperatingSystem: sessionStorage.getItem("OSVersion"),
        isTrustedDevice: true,
        ViewportHeight: height,
        ViewportWidth: width
      })
    }
  }

  const postUsername = useCallback(
    (payload) => {
      dispatch(sendUsername(payload))
    },
    [dispatch]
  )

  return (
    <LoginForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
      <StatementWrapper>
        <LoginStatement mobile={mobile}>Please enter your username.</LoginStatement>
      </StatementWrapper>
      {!validUsername || !matchingUsername || mfaError || pwError ? (
        <ErrorWrapper>
          {validUsername ? null : <ErrorMessage>Please enter a valid email.</ErrorMessage>}
          {matchingUsername ? null : <ErrorMessage>Usernames must match.</ErrorMessage>}
          {mfaError ? <ErrorMessage>{mfaError}</ErrorMessage> : null}
          {pwError ? <ErrorMessage>{pwError}</ErrorMessage> : null}
        </ErrorWrapper>
      ) : null}
      <LoginField mobile={mobile}>
        <EmailIcon sx={{ color: '#373d4d', margin: '0 10px'}} />
        <LoginInput
          type="text"
          id="username"
          data-testid="username"
          name="username"
          value={username}
          autoComplete="off"
          onChange={(e) => updateUsername(e.target.value)}
          placeholder='Username'
        />
      </LoginField>
      <LoginField mobile={mobile}>
        <EmailIcon sx={{ color: '#373d4d', margin: '0 10px'}} />
        <LoginInput
        type="text"
          id="username2"
          data-testid="username2"
          name="username2"
          value={username2}
          autoComplete="off"
          onChange={(e) => updateUsername2(e.target.value)}
          placeholder='Confirm username'
        />
      </LoginField>
      {criticalError || mfaCriticalError ? null : (
          <LoginButton type="submit" mobile={mobile} login>SUBMIT</LoginButton>
      )}
    </LoginForm>
  )
}

export default SubmitUsername
