import { useCallback, useEffect, useRef, useState } from 'react'

export const useViewport = () => {
  // Initialize with width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  const handleResize = useCallback(() => {
    setWindowSize({
      // @ts-ignore
      width: window.innerWidth,
      // @ts-ignore
      height: window.innerHeight,
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])
  return windowSize
}

// @ts-ignore
export const useOutsideClick = (ref, callback) => {
  // @ts-ignore
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      e.stopPropagation()
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export const usePrevious = (value: any) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
