import { useCallback, useEffect, useState } from 'react'
import QuickSearchResults from '../../quickSearch/QuickSearchResults'
import { trimSearchInput } from '../../../utils/helperMethods'
import debounce from 'lodash/debounce'

// Styling
import { SiteHeaderSearch, SiteHeaderSearchWrapper } from './SiteHeaderStyles'
import SearchIcon from '@mui/icons-material/Search'

// Redux
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { resetQuickSearchState, resetQuickSearchStatus, submitQuickSearch, updateQuickSearchTerm } from '../../quickSearch/quickSearchSlice'
import { toggleMobileSidebar } from '../navSlice'

const SiteHeaderSearchContainer = () => {
  const dispatch = useAppDispatch()

  const quickSearchTerm = useAppSelector(state => state.quickSearch.quickTerm)
  const quickSearchSubmitted = useAppSelector(state => state.quickSearch.quickSearchSubmitted)
  const theme = useAppSelector(state => state.users.ThemeName)
  const { mobile, mobileSidebar } = useAppSelector(state => state.nav)

  const [expandInput, toggleExpandInput] = useState(!mobile)

  const updateSearchTerm = useCallback(
    payload => {
      dispatch(updateQuickSearchTerm(payload))
    },
    [dispatch]
  )

  const setInput = val => {
    if (mobileSidebar) dispatch(toggleMobileSidebar(false))
    updateSearchTerm(val)
  }

  const clearResults = useCallback(() => {
    dispatch(resetQuickSearchState())
  }, [dispatch])

  const submitQuickSearchTerm = useCallback(
    payload => {
      if (payload.length > 2) {
        dispatch(
          submitQuickSearch({
            searchString: payload,
            controller: new AbortController(),
          })
        )
      } else {
        dispatch(resetQuickSearchStatus())
      }
    },
    [dispatch]
  )

  const handleLookUp = debounce(input => submitQuickSearchTerm(input), 300)

  useEffect(() => {
    if (quickSearchTerm.length > 2) {
      handleLookUp(quickSearchTerm)
    }
    return () => {}
  }, [quickSearchTerm]) //eslint-disable-line

  useEffect(() => {
    if (quickSearchTerm === '') {
      clearResults()
    }
  }, [quickSearchTerm, clearResults])

  return (
    <SiteHeaderSearchWrapper mobile={mobile} expandInput={expandInput}>
      <SearchIcon
        style={{
          fontSize: '22px',
          fill: mobile || theme === 'Dark' ? '#FFFFFF' : 'var(--color-primary-charcoal)',
          cursor: 'pointer',
        }}
        onClick={e => toggleExpandInput(!expandInput)}
      />
      <SiteHeaderSearch
        autoComplete="off"
        onChange={e => setInput(trimSearchInput(e.target.value))}
        value={quickSearchTerm}
        placeholder={mobile ? 'Search' : 'Search Patients'}
        type="text"
        name="quicksearch"
        id="quicksearch"
        className={expandInput ? 'search-input-enter' : 'search-input-exit'}
        theme={theme}
        mobile={mobile}
      />
      {(quickSearchSubmitted === 'pending' || quickSearchSubmitted === 'succeeded' || quickSearchSubmitted === 'rejected') && quickSearchTerm.length > 2 ? (
        <QuickSearchResults setInput={setInput} clearResults={clearResults} />
      ) : null}
    </SiteHeaderSearchWrapper>
  )
}

export default SiteHeaderSearchContainer
