import { useCallback, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { setResultsPerPage } from '../searchSlice'
import Pagination from '../../../nav/Pagination'
import { MobileSortOption, PhySelectSort } from '../searchStyles'
import { ControlLabel, ControlCell, PhytestTableControlWrapperSearch } from './tableStyles'

const SearchResultControl = () => {
  const dispatch = useAppDispatch()

  const { resultsPerPage, searchResults } = useAppSelector(state => state.search)

  const handleSelect = e => {
    e.preventDefault()
    changeResultsPerPage(parseInt(e.target.value))
  }

  const selectEl = useRef(null)
  const changeResultsPerPage = useCallback(
    payload => {
      dispatch(setResultsPerPage(payload))
    },
    [dispatch]
  )

  return (
    <PhytestTableControlWrapperSearch>
      <ControlCell>{searchResults.length} result{searchResults.length === 1 ? '' : 's'}</ControlCell>
      <Pagination />
      <ControlCell>
        <ControlLabel>Results per page:</ControlLabel>
        <PhySelectSort value={resultsPerPage} ref={selectEl} type="dropdown" onChange={e => handleSelect(e)}>
          <MobileSortOption value="10">10</MobileSortOption>
          <MobileSortOption value="25">25</MobileSortOption>
          <MobileSortOption value="50">50</MobileSortOption>
        </PhySelectSort>
      </ControlCell>
    </PhytestTableControlWrapperSearch>
  )
}
export default SearchResultControl
