import { useEffect, useRef } from 'react'
import { SearchHeader, SearchResultHead, SearchResultsDiv, SearchResultsWrapper } from '../searchStyles'
import SearchResultRow from './searchResultRow'
import { useAppSelector } from '../../../../redux/store'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'

const SearchResultsTable = ({ results, handleSort, match }) => {
  /* Scrolling to the top of the table when the next page of results loads */
  const TopRow = useRef()
  const { sortUp, sortKey } = useAppSelector(state => state.search)

  useEffect(() => {
    TopRow.current?.scrollIntoView(false, { behavior: 'smooth' })
  }, [results])

  /* RENDER FUNCTIONS */

  const buildRows = () => {
    return results.map((patient, i) => {
      if (i === 0) {
        return <SearchResultRow TopRowRef={TopRow} match={match} key={i} patient={patient} />
      } else {
        return <SearchResultRow match={match} key={i} patient={patient} />
      }
    })
  }

  const sorted = name => {
    if (name === sortKey && sortUp) {
      return <ArrowUpward />
    } else if (name === sortKey && !sortUp) {
      return <ArrowDownward />
    }
  }

  return (
    <SearchResultsDiv>
      <SearchResultHead>
        <SearchHeader onClick={e => handleSort('lastName')} className="invisibleButton">
          <h3>Last Name</h3>
          {sorted('lastName')}
        </SearchHeader>
        <SearchHeader onClick={e => handleSort('firstName')} className="invisibleButton">
          <h3>First Name</h3>
          {sorted('firstName')}
        </SearchHeader>
        <SearchHeader onClick={e => handleSort('middleName')} className="invisibleButton">
          <h3>Middle Name</h3>
          {sorted('middleName')}
        </SearchHeader>
        <SearchHeader onClick={e => handleSort('DateOfBirth')} className="invisibleButton">
          <h3>Date of Birth</h3>
          {sorted('DateOfBirth')}
        </SearchHeader>
        <SearchHeader onClick={e => handleSort('apmId')} className="invisibleButton">
          <h3>Patient ID</h3>
          {sorted('apmId')}
        </SearchHeader>
      </SearchResultHead>
      <SearchResultsWrapper>{buildRows()}</SearchResultsWrapper>
    </SearchResultsDiv>
  )
}

export default SearchResultsTable
