import React from 'react'
import * as wjChart from '@grapecity/wijmo.react.chart'
import { DashCardHeader } from '../dashboardStyles'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'

const GCRChartMobile = ({ gcr, formatYLabels, buildDataLabels }) => {
  const formatXLabels = (eng, label) => {
    label.cls = 'mobile-label'
    return label
  }

  return (
    <>
      <DashCardHeader>
        <h4>Total GCR By Month</h4>
      </DashCardHeader>
      <wjChart.FlexChart itemsSource={gcr} bindingX="PeriodAbbreviation" cssClass="dark" chartType="Bar" rendered={e => e.hostElement.classList.add('chart')}>
        <wjChart.FlexChartAxis itemFormatter={(engine, label) => formatYLabels(engine, label)} binding="GCR" wjProperty="axisX" max={60} />
        <wjChart.FlexChartAxis wjProperty="axisY" binding="PeriodAbbreviation" itemFormatter={(eng, label) => formatXLabels(eng, label)}></wjChart.FlexChartAxis>
        <wjChart.FlexChartSeries style={{ fill: 'var(--color-primary-purple)' }} binding="GCR" cssClass="bar percentage" tooltipContent="" />
        <wjChart.FlexChartDataLabel position={1} cssClass="chart-label" content={ht => buildDataLabels(ht)} />
        <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </>
  )
}

export default GCRChartMobile
