import React, { useEffect } from 'react';
import DotLoader from './dotLoader';
import { FlexWrapper } from '../../styles/viewerStyles';
import CheckIcon from '@mui/icons-material/Check';

const RequestStatusLoader = ({ requestStatus, resetFunction }) => {
  useEffect(() => {
    if (resetFunction && requestStatus === 'succeeded') {
      setTimeout(() => {
        resetFunction()
      }, 5 * 1000)
    }
  }, [requestStatus])
  
  const toggleLoader = () => {
    if (requestStatus === 'pending') {
      return <DotLoader isLoading={requestStatus === 'pending'} width="250px" height="200px" dotSize="30px" />
    } else if (requestStatus === 'succeeded') {
      return (
        <FlexWrapper align="center" style={{color: '#86CCAB'}} justify="space-between" width="auto" height="auto">
          <CheckIcon style={{marginRight: '7px'}} />
          <p>Saved!</p>
        </FlexWrapper>
      )
    } else {
      return null
    }
  }

  return toggleLoader()
}

export default RequestStatusLoader;