import styled from 'styled-components'
import { PhyInputs, PhyInputsArea, PhySelect } from '../../styles/formStyles'
import { ModalContent } from '../modals/modalStyles'

export const SupportSlider = styled.div`
  position: absolute;
  left: -220px;
  width: 200px;
  height: 150px;
  border-radius: 0 5px 5px 0;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  bottom: 20px;
  z-index: 50;
  box-shadow: 5px 2px 15px #434343;
  ${'' /* padding: 10px; */}
  background: white;
  @media (max-height: 594px) {
    z-index: 0;
  }
  p {
    margin-top: 0px;
  }
`

export const SliderWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 20px;
`

export const InfoDiv = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1em;

  input {
    width: 50%;
  }
`

export const Statement = styled.p`
  font-size: 1em;
  line-height: 1em;
  width: fit-content;
  padding-bottom: 5px;
  margin: 0;

  a {
    color: ${props => props.theme.restyle.blueAccent};
    font-weight: 500;
  }
`

export const SupportInput = styled(PhyInputs)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.restyle.primary};
  border-radius: 0;
  background: transparent;
  line-height: 1em;
  font-size: 1em;
  input {
    background: transparent;
    font-size: 1em;
  }
  &:focus-within {
    box-shadow: initial;
    border-bottom: 1px solid ${props => props.theme.restyle.primary};
    border-radius: 0;
    outline: none;
    font-size: 1em;
  }
`

export const SupportSelect = styled(PhySelect)`
  background-color: ${props => props.theme.viewer.background};
  font-size: 1em;
  font-family: inherit;
`
export const SupportOption = styled.option`
  color: ${props => props.theme.inputs.text};
`

export const SupportDiv = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`

export const SupportArea = styled(PhyInputsArea)`
  background: transparent;
  border: 1px solid ${props => props.theme.restyle.primary};
  border-radius: 0;

  &:focus-within {
    border: 1px solid ${props => props.theme.restyle.primary};
    box-shadow: none;
    outline: none;
  }
`

export const SupportModalContent = styled(ModalContent)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 75%;
  overflow: hidden auto;
`
