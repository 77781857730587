import * as wjChart from '@grapecity/wijmo.react.chart'
import { ChartPlaceholderDiv } from './placeholderStyles'
import { DashCardHeader } from '../dashboardStyles'
import DotLoader from '../../misc/dotLoader'
import { FlexWrapper } from '../../../styles/viewerStyles'

const BarChartPlaceholder = ({ loaded = false, title }) => {
  return (
    <ChartPlaceholderDiv>
      <DashCardHeader>
        <h4 style={{margin: '1rem 0'}}>{title}</h4>
      </DashCardHeader>
      {loaded ? (
        <wjChart.FlexChart
          binding="data"
          itemsSource={[{ data: 123 }, { data: 231 }, { data: 321 }, { data: 456 }, { data: 546 }, { data: 789 }, { data: 987 }]}
          palette={['#000']}
        >
          <wjChart.FlexChartSeries></wjChart.FlexChartSeries>
        </wjChart.FlexChart>
      ) : (
        <FlexWrapper height="calc(100% - 60px)">
          <DotLoader isLoading={true} width="40%" dotSize="25px" />
        </FlexWrapper>
      )}
    </ChartPlaceholderDiv>
  )
}

export default BarChartPlaceholder
