import React, { useState, useEffect, useCallback, useRef } from 'react'
import { DashCard } from '../dashboardStyles'
import CustomTooltip from '../../misc/CustomTooltip'
import BarChartPlaceholder from '../placeholders/BarChartPlaceholder'
import BrokenChart from '../placeholders/BrokenChart'
import { useViewport } from '../../../utils/hooks'
import CollectionsChart from './CollectionsChart'
import CollectionsChartMobile from './CollectionsChartMobile'
import { fetchChartData } from '../dashboardSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import DotLoader from '../../misc/dotLoader'
import { FlexWrapper } from '../../../styles/viewerStyles'
import { Info } from '@mui/icons-material'
import { convertNumToCurrency } from '../../../utils/helperMethods'

const CollectionsChartContainer = ({ formatYLabels, goToReport, visible }) => {
  const [mobile, setMobile] = useState(false)

  const { reportName, data, error, loaded } = useAppSelector(state => state.dashboard.collections)
  const clientSwitching = useAppSelector(state => state.users.switchingClients)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)

  const dispatch = useAppDispatch()
  const { width } = useViewport()

  const theme = useAppSelector(state => state.users.ThemeID)

  const promiseRef = useRef(null)

  const fetchCollectionsData = useCallback(() => {
    dispatch(
      fetchChartData({
        controller: new AbortController(),
        chart: 'collections',
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (visible && ClientConnectionID) {
      fetchCollectionsData()
    }
    return () => {
      if (promiseRef.current) {
        promiseRef.current.abort()
      }
    }
  }, [ClientConnectionID])

  useEffect(() => {
    if (width < 1025) {
      setMobile(true)
    } else {
      setMobile(false)
    }
    return () => {}
  }, [width])

  // round largest amount to nearest place value
  const calculateYAxisMax = () => {
    // get largest VoucherBalance from array of collection objects
    const largestNum = Math.ceil(
      data
        .map(data => data.PaymentAmount)
        .sort((a, b) => a - b)
        .pop()
    )
    // get the place value of left most num
    const splitNums = largestNum.toString().split('')
    let newTens = parseInt(
      splitNums
        .map((num, i) => {
          let numVal
          if (i === 0) {
            splitNums[1] > 5 ? (numVal = (parseInt(num) + 1).toString()) : (numVal = num)
          } else if (i === 1) {
            num > 5 ? (numVal = '0') : (numVal = (parseInt(num) + 1).toString())
          } else {
            numVal = '0'
          }
          return numVal
        })
        .join('')
    )
    return newTens
  }

  const buildTooltip = ht => {
    return `<span class='chart-tooltip'>
      <strong>${ht.item.PeriodAbbreviation || ''}</strong><br/>
      ${convertNumToCurrency(ht.item.PaymentAmount)}
      </span>
    `
  }

  const renderChart = () => {
    if (loaded === 'succeeded') {
      if (error) {
        return <BrokenChart refreshFn={fetchCollectionsData} header={'Collections'} chartType={1} />
      } else {
        if (mobile) {
          return (
            <CollectionsChartMobile
              collections={data}
              formatYLabels={formatYLabels}
              goToReport={goToReport}
              reportName={reportName}
              calculateYAxisMax={calculateYAxisMax}
              theme={theme}
            />
          )
        } else {
          return (
            <CollectionsChart
              collections={data}
              formatYLabels={formatYLabels}
              goToReport={goToReport}
              reportName={reportName}
              calculateYAxisMax={calculateYAxisMax}
              theme={theme}
              buildTooltip={buildTooltip}
            />
          )
        }
      }
    }
  }

  return (
    <>
      {visible ? (
        <DashCard loaded={loaded !== 'succeeded'} cardWidth={width > 1220 ? 2 : 6} error={error}>
          {loaded === 'pending' || clientSwitching === 'pending' ? (
            <BarChartPlaceholder title="Total Collections by Month" />
          ) : data?.length ? (
            <>
              <CustomTooltip title="Including current month, 6 month lookback of collections by month.">
                <Info className="tooltip-icon" />
              </CustomTooltip>
              {renderChart()}
            </>
          ) : (
            <BarChartPlaceholder loaded title="Total Collections by Month" />
          )}
        </DashCard>
      ) : null}
    </>
  )
}

export default CollectionsChartContainer
