import styled from 'styled-components'

// #region Wrappers and Containers
export const PatientContainer = styled.div`
  width: 100%;
  height: ${props => (props.mobile ? '100%' : 'calc(100% - 90px)')};
  overflow: hidden;
  margin-top: ${props => (props.width < 700 ? '1rem' : 0)};
  display: ${props => (props.connectionSwitcher ? 'none' : 'flex')};
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  color: ${props => props.theme.restyle.primary};
  transition: color 0.5s;

  &:-webkit-scrollbar {
    display: none;
  }
  svg {
    transition: fill 0.5s, color 0.5s;
  }
  transition: color 0.5s;
`

export const PatientPageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${props => props.direction};
  padding: ${props => (props.mobile ? '0 .5rem' : '1rem 1.5rem 0 1.5rem')};
  box-sizing: border-box;
  color: ${props => props.theme.restyle.primary};
  overflow: ${props => (props.mobile ? 'hidden auto' : 'visible')};
  margin-bottom: ${props => (props.mobile && props.demographicsExpanded ? '1rem' : 0)};
`

export const PatientPageHalf = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
`

// #endregion

// #region Patient Sidebar

export const PatientInfoBlockContainer = styled.div`
  width: 20%;
  min-width: 20%;
  max-width: 20%;
  margin: ${props => (props.demographicsExpanded ? '1rem 1rem 0 1rem' : '1rem')};
  padding-bottom: 1.2rem;
  border-right: 3px solid ${props => props.theme.restyle.darkAccent};
  box-sizing: border-box;
  transition: margin-bottom ${props => (props.demographicsExpanded ? '0.1s ease 0.75s' : '0.1s')};
  padding-bottom: ${props => (props.mobile || props.tablet ? 0 : '1rem')};
`

export const PatientInfoSideBar = styled.div`
  height: ${props => (props.mobile ? 'fit-content' : '100%')};
  width: ${props => (props.mobile ? '100%' : props.demographicsExpanded ? '20%' : '0')};
  min-width: ${props => (props.mobile ? '100%' : props.demographicsExpanded ? '20%' : 0)};
  max-width: ${props => (props.mobile ? '100%' : props.demographicsExpanded ? '20%' : 0)};
  box-sizing: border-box;
  margin: ${props => (props.mobile ? 0 : props.demographicsExpanded ? '0 0 1rem 1rem' : 0)};
  padding: ${props => (!props.demographicLoaded ? '20px 0' : props.portableDevice ? 0 : props.demographicsExpanded ? '0 1rem 0 0' : 0)};
  text-align: left;
  border-right: ${props => (props.mobile || !props.demographicsExpanded ? 'none' : `3px solid ${props.theme.restyle.darkAccent}`)};
  border-bottom: ${props => (props.mobile ? `3px solid ${props.theme.restyle.darkAccent}` : 'none')};
  transition: width 1s, min-width 1s, max-width 1s, border-right 0.1s ease 0.7s;
`

export const PatientInfoName = styled.h1`
  width: fit-content;
  margin: 0;
  font-size: ${props => (props.tablet ? '1.2rem' : '1.7rem')};
  font-weight: 600;
  text-align: left;
`
export const PatientDataGroup = styled.div`
  margin: ${props => (props.portableDevice ? '.5rem 0' : '2rem 0')};
  font-size: ${props => (props.portableDevice ? '.9rem' : '1rem')};

  > h4 {
    width: fit-content;
    margin: 1rem 0;
  }
`
export const PatientDataBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  text-align: left;
  font-size: ${props => (props.tablet ? '.8rem' : '1rem')};
  &not:first-child {
    margin-top: 0.5rem;
  }

  h4,
  p {
    margin: 0.3rem 0;
  }

  h4 {
    min-width: 50%;
    margin-right: 0.5rem;
    font-weight: 500;
  }
`

export const ToggleDemographicsButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0.4rem 0;
  padding: 0;
  font-size: ${props => (props.tablet ? '.7rem' : '0.9rem')};
  outline: none;
  border: none;
  background: transparent;
  color: ${props => props.theme.restyle.primary};
  text-align: left;
  transition: color 0.75s;

  svg {
    fill: ${props => props.theme.restyle.primary};
  }
`

export const PatientSearchWrapper = styled.div`
  width: 80%;
  max-width: 14rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.restyle.primary};
  transition: border-color 0.5s;

  svg {
    height: 1rem;
    transition: all .5s;
  }
`
// #endregion

export const CollapseIconWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-left: ${props => (props.mobile ? '1rem' : '2rem')};

  svg {
    transition: rotate 0.4s;
    rotate: ${props => (props.expanded ? 0 : '180deg')};
  }
`
export const CollapseAllChargesWrapper = styled.div`
  width: 95%;
  margin: 0.2rem 0 1.2rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const PatientDataSection = styled.div`
  height: 100%;
  width: 100%;
  padding: ${props => (props.mobile || props.tablet ? '0' : '1rem 0 0 1rem')};
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: ${props => (props.tablet ? 0 : '1rem')};
  overflow: ${props => ((props.mobile && !props.demographicsExpanded) || props.tablet ? 'hidden auto' : 'visible')};
`

export const BalanceSummary = styled.div`
  display: flex;
  flex-direction: ${props => (props.mobile || props.tablet ? 'column' : 'row')};
  width: ${props => (props.tablet ? '50%' : '100%')};

  h4,
  p {
    margin: 0.5rem 0;
    white-space: nowrap;
  }

  h4 {
    margin-right: 1rem;
  }
`
// #region A/R Summary
// Header
export const BalanceDiv = styled.div`
  width: ${props => (props.mobile || props.tablet ? '100%' : '80%')};
  height: fit-content;
  display: flex;
  flex-direction: ${props => (props.expanded || props.mobile ? 'column' : 'row')};
  align-self: ${props => (props.expanded || props.mobile ? 'flex-start' : 'flex-start')};
  margin-right: 1rem;
  padding: 1rem 0;
  margin-bottom: ${props => (props.mobile ? 0 : props.collections ? '3rem' : '2rem')};
  padding-bottom: ${props => (props.mobile && !props.expanded ? '2rem' : '1rem')};
  box-sizing: border-box;
`

export const BalanceHeader = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 425;
  align-self: flex-start;
  cursor: ${props => (props.mobile ? 'pointer' : 'auto')};

  > h3 {
    white-space: nowrap;
    margin: 0;
    font-size: ${props => (props.tablet ? '.9rem' : '1.1rem')};
    font-weight: 500;
  }
`

export const BalanceContainer = styled.div`
  width: 100%;
  font: var(--font-branding);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BalanceGroup = styled.h4`
  font-size: 0.8rem;
  font-weight: 650;
  text-align: center;
  margin-top: 5px;
`

export const BalanceGroupMobile = styled(BalanceGroup)`
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-accent-alsogrey);
  }
`
// #endregion

export const BalanceGrid = styled.div`
  align-self: center;
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-areas:
    'A1 A2 A3 A4 A5 A6 A7'
    'B1 B2 B3 B4 B5 B6 B7'
    '. . . . . . .'
    'C1 Ca2 Ca3 Ca4 Ca5 C6 C7'
    'C1a Cb2 Cb3 Cb4 Cb5 C6 C7';
  grid-template-rows: 15px 30px 8px ${props => (!props.collections ? '15px 15px' : '30px 30px')};
  grid-template-columns: 20% 13.5% 13.5% 13.5% 13.5% 5% 21%;
  margin: 8px 0px;
  h4 {
    margin: 0;
  }
`

export const MobileBalanceGrid = styled(BalanceGrid)`
  height: 100%;
  grid-template-areas: 'A1  .  C1 D1' // Header
    'A2 B2 C2 D2' // 0-30 Bucket
    'A3 B3 C3 D3' // 31-60 Bucket
    'A4 B4 C4 D4' // 61-90 Bucket
    'A5 B5 C5 D5' // 91+ Bucket
    'A6  .  C6 C6'; // Summary boxes
  grid-template-columns: ${props => (props.collections ? '28% 16% 28% 28%' : '40% 20% 40%')};
  grid-template-rows: 50px 50px 50px 50px 50px 65px;
`

export const BGridData = styled.div`
  background: none;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.tablet ? '.8rem' : '1rem')};

  &:not(:last-of-type) {
    border-bottom: ${props => (props.mobile ? '1px solid var(--color-accent-alsogrey)' : 'none')};
  }
`

export const BalGridRowHeader = styled.div`
  display: flex;
  justify-content: ${props => (props.mobile ? 'center' : 'flex-start')};
  align-items: center;
  font-size: ${props => (props.mobile || props.tablet ? '.7rem' : '0.75rem')};
  font-weight: 400;
  text-align: ${props => (props.mobile ? 'center' : 'left')};

  h3 {
    font-weight: 450;
  }
`

export const Arrow = styled(BGridData)`
  align-self: center;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  background: white;
  border-left: 1px solid black;
`
const GridSummaryBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.shades.dark};
  box-sizing: border-box;

  p {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
  }
`

export const InsurancePendingDiv = styled(GridSummaryBox)`
  grid-area: 'B7';
  color: var(--color-accent-alsogrey);
  border: 1px solid var(--color-accent-alsogrey);
`

export const InsurancePendingDivSummary = styled(InsurancePendingDiv)`
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  margin-top: ${props => (props.mobile ? '1rem' : 0)};
`

export const PatientResponsibilityDiv = styled(GridSummaryBox)`
  color: white;
  background: ${props => (!props.collections ? 'var(--color-accent-green)' : 'var(--color-accent-red)')};
  grid-area: 'C7';
`

export const PatientResponsibilityDivSummary = styled(PatientResponsibilityDiv)`
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  margin-top: ${props => (props.mobile ? '1rem' : 0)};
  margin-left: ${props => (props.mobile ? 0 : '2rem')};
`

export const PatientInfoCard = styled.div`
  width: 100%;
  border: ${props => (props.selected ? '4px solid #16B671' : '2px solid #b5b5b5')};
  border-radius: ${props => (props.selected ? '9px' : '5px')};
  margin: ${props => {
    if (props.selectable) {
      if (props.selected) {
        return '12px auto'
      }
      return '15px auto'
    }
    return '16px auto'
  }};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const MobileCardHeader = styled.div`
  margin: 0;
  background-color: ${props => props.theme.patientInfo.cards.header};
`

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.patientInfo.cards.content};
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #000000;
  background-color: ${props => props.theme.patientInfo.cards.content};
  color: '#000000';
  cursor: pointer;
`

export const PhoneLink = styled.a`
  text-decoration: none;
  color: #fff;
  cursor: pointer;

  svg {
    margin-left: 0.5em;
    color: #fff;
  }
`
