import { StyledCheckbox, ClaimsToolbarButton, ClaimsToolbarContainer, ClaimsFilterLabel } from './claimStyles'
import { CSVLink } from 'react-csv'
import { ButtonIcon } from '../../../styles/buttonStyles'
import { useCallback, useContext, useEffect, useState } from 'react'
import { usePrevious } from '../../../utils/hooks'
import { buildCSVData, resetCSV, setFilteredClaims, setExportMode, toggleSelectAllClaims } from '../patientSlice'
import { buildDate, trimSearchInput } from '../../../utils/helperMethods'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { Close, FileDownload, FilterAlt, Search } from '@mui/icons-material'
import { SearchInput } from '../../../styles/appStyles'
import { setClaimIndex } from '../patientSlice'
import { FlexWrapper } from '../../../styles/viewerStyles'
import { ThemeContext } from 'styled-components'
import { PatientSearchWrapper } from '../patientInfoStyles'

const ClaimToolbar = ({ exportPatientClaimsAllowed }) => {
  const [input, setInput] = useState('')
  const [balanceFilter, toggleBalanceFilter] = useState(false)
  const [remarksFilter, toggleRemarksFilter] = useState(false)
  const [reasonCodesFilter, toggleReasonCodesFilter] = useState(false)

  const dispatch = useAppDispatch()

  const claims = useAppSelector(state => state.patients.currentPatient.claims)
  const filteredClaims = useAppSelector(state => state.patients.currentPatient.filteredClaims)
  const { mobile, tablet, portableDevice } = useAppSelector(state => state.nav)
  const exportMode = useAppSelector(state => state.patients.currentPatient.exportMode)
  const selectedClaims = filteredClaims.some(claim => claim.selected)
  const selectedClaimsArray = filteredClaims.filter(claim => claim.selected)
  const data = useAppSelector(state => state.patients.CSVData)
  const headers = useAppSelector(state => state.patients.CSVHeaders)
  const csvBuilding = useAppSelector(state => state.patients.csvBuilding)
  const prevSelectedClaims = usePrevious(selectedClaimsArray)
  const theme = useContext(ThemeContext)

  useEffect(() => {
    if (prevSelectedClaims && selectedClaimsArray.length !== prevSelectedClaims.length) {
      fetchCSVData(selectedClaimsArray)
    }
    return () => {}
  }, [selectedClaimsArray]) //eslint-disable-line

  const fetchCSVData = useCallback(
    async payload => {
      const response = await dispatch(buildCSVData({ files: payload }))
      return response
    },
    [dispatch]
  )

  const buildToggleExportButton = () => {
    if (exportPatientClaimsAllowed) {
      return (
        <ClaimsToolbarButton onClick={() => toggleExportMode(!exportMode)} mobile={mobile}>
          <ButtonIcon>{exportMode ? <Close /> : <FileDownload />}</ButtonIcon>
          <p>{exportMode ? 'Cancel Export' : 'Export Claims'}</p>
        </ClaimsToolbarButton>
      )
    }
  }

  const buildCSVLink = () => {
    const buttonContent = (
      <ClaimsToolbarButton disabled={!selectedClaims} onClick={selectedClaims ? null : () => toggleExportMode(!exportMode)} mobile={mobile}>
        <FileDownload />
        <p>{`Export ${selectedClaimsArray.length} ${selectedClaimsArray.length === 1 ? 'Claim' : 'Claims'}`}</p>
      </ClaimsToolbarButton>
    )

    if (csvBuilding === 'succeeded' && selectedClaims) {
      return (
        <CSVLink
          filename={'PatientClaimDetail.csv'}
          data={data}
          headers={headers}
          onClick={() => {
            dispatch(resetCSV())
            dispatch(toggleSelectAllClaims(false))
          }}
          style={{ textDecoration: 'none' }}
        >
          {buttonContent}
        </CSVLink>
      )
    } else {
      return buttonContent
    }
  }

  // #region Select/unselect behavior
  const handleSelectAllClaims = useCallback(
    payload => {
      dispatch(toggleSelectAllClaims(payload))
    },
    [dispatch]
  )

  const buildSelectAllButton = () => {
    return (
      <ClaimsToolbarButton onClick={() => handleSelectAllClaims(!selectedClaims)} mobile={mobile}>
        <ButtonIcon>{selectedClaimsArray.length > 0 ? <Close /> : <StyledCheckbox defaultChecked={false} style={{ marginRight: '1rem' }} />}</ButtonIcon>
        <p>{selectedClaimsArray.length > 0 ? 'Unselect All' : 'Select All'}</p>
      </ClaimsToolbarButton>
    )
  }
  // #endregion

  // #region Handling export mode
  const toggleExportMode = useCallback(
    payload => {
      if (!payload) {
        handleSelectAllClaims(false)
      }
      dispatch(setExportMode(payload))
    },
    [dispatch, handleSelectAllClaims]
  )
  // #endregion

  // #region Search/Filtering
  const resetSearch = () => {
    setInput('')
    dispatch(setClaimIndex(0))
  }

  const filterTests = () => {
    if (input === '' && !balanceFilter && !remarksFilter && !reasonCodesFilter) dispatch(setFilteredClaims(claims))
    const filterable = ['ClaimNumber', 'ServiceDate', 'BillingDate', 'SpecimenNumber', 'specimens', 'charges', 'CptDescription', 'DatePaid', 'CptCode']

    const filteredTests = claims.filter(claim => {
      let meetsSearchRequirement
      let meetsFilterRequirement

      // Checking filters
      if (!balanceFilter && !remarksFilter && !reasonCodesFilter) {
        // If no filters have been applied, then everything will pass the filters
        meetsFilterRequirement = true
      } else {
        const meetsBalanceFilter = balanceFilter ? !!claim.totals.Balance : false
        const meetsRemarksFilter = remarksFilter ? !!claim.remarks.length : false
        const meetsReasonCodesFilter = reasonCodesFilter ? !!claim.denialCount : false

        meetsFilterRequirement = meetsBalanceFilter || meetsRemarksFilter || meetsReasonCodesFilter
      }

      // Optimization: not checking search input if the claim doesn't pass any of the applied filters
      if (!meetsFilterRequirement) return false

      for (const [lvl1Key, lvl1Val] of Object.entries(claim)) {
        if (filterable.includes(lvl1Key)) {
          // Filtering on ServiceDate and BillingDate
          if (lvl1Key.toLowerCase().includes('date')) {
            if (buildDate(lvl1Val).includes(input)) {
              meetsSearchRequirement = true
              return meetsSearchRequirement && meetsFilterRequirement
            }
          }
          if ((lvl1Val ?? '').toString().toLowerCase().includes(input.toLowerCase())) {
            meetsSearchRequirement = true
            return meetsSearchRequirement && meetsFilterRequirement
          }
        }
        // Looping through specimens array
        if (Array.isArray(lvl1Val)) {
          lvl1Val.forEach(lvl1Object => {
            if (typeof lvl1Object === 'object') {
              for (const [lvl2Key, lvl2Val] of Object.entries(lvl1Object)) {
                if (filterable.includes(lvl2Key)) {
                  if ((lvl2Val ?? '').toString().toLowerCase().includes(input.toLowerCase())) {
                    meetsSearchRequirement = true
                    return meetsSearchRequirement && meetsFilterRequirement
                  }
                }
                if (Array.isArray(lvl2Val)) {
                  lvl2Val.forEach(lvl2Object => {
                    for (const [lvl3Key, lvl3Val] of Object.entries(lvl2Object)) {
                      if (filterable.includes(lvl3Key)) {
                        if (lvl3Key.toLowerCase().includes('date')) {
                          if (buildDate(lvl3Val).includes(input)) {
                            meetsSearchRequirement = true
                            return meetsSearchRequirement && meetsFilterRequirement
                          }
                        }
                        if ((lvl3Val ?? '').toString().toLowerCase().includes(input.toLowerCase())) {
                          meetsSearchRequirement = true
                          return meetsSearchRequirement && meetsFilterRequirement
                        }
                      }
                      if (Array.isArray(lvl3Val)) {
                        lvl3Val.forEach(lvl3Object => {
                          for (const [lvl4Key, lvl4Val] of Object.entries(lvl3Object)) {
                            if (filterable.includes(lvl4Key)) {
                              if (lvl4Key.toLowerCase().includes('date')) {
                                if (buildDate(lvl4Val).includes(input)) {
                                  meetsSearchRequirement = true
                                  return meetsSearchRequirement && meetsFilterRequirement
                                }
                              }
                              if ((lvl4Val ?? '').toString().toLowerCase().includes(input.toLocaleLowerCase())) {
                                meetsSearchRequirement = true
                                return meetsSearchRequirement && meetsFilterRequirement
                              }
                            }
                          }
                        })
                      }
                    }
                  })
                }
              }
            }
          })
        }
      }
      return meetsSearchRequirement && meetsFilterRequirement
    })
    dispatch(setFilteredClaims(filteredTests))
  }

  useEffect(() => {
    filterTests()
  }, [input, balanceFilter, remarksFilter, reasonCodesFilter])
  // #endregion

  const buildSearch = () => {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: mobile ? '1rem' : '.5rem', marginTop: mobile ? '1.5rem' : 0 }}>
        <Search style={{ marginRight: '1rem' }} />
        <PatientSearchWrapper>
          <SearchInput
            placeholder="Search Claims"
            title="Filter by Claim, Specimen, Test, CPT, or Date"
            type="text"
            id="searchBar"
            autoComplete="off"
            value={input}
            onInput={e => {
              setInput(trimSearchInput(e.target.value))
              dispatch(setClaimIndex(0))
            }}
            style={{ width: '100%', fontSize: '1rem', padding: 0 }}
            data-test="claim-toolbar-search-input"
          />
          {input ? <Close onClick={resetSearch} /> : null}
        </PatientSearchWrapper>
      </div>
    )
  }

  return (
    <ClaimsToolbarContainer mobile={mobile} tablet={tablet}>
      {exportMode ? (
        <>
          {buildCSVLink()}
          {buildSelectAllButton()}
        </>
      ) : null}
      {portableDevice ? null : buildToggleExportButton()}
      {exportMode ? null : (
        <>
          {buildSearch()}
          <FlexWrapper align="flex-start">
            <FilterAlt style={{ marginRight: '1rem', marginTop: '-.2rem' }} />
            <FlexWrapper flexFlow="column" align="flex-start">
              <ClaimsFilterLabel>
                <StyledCheckbox type="checkbox" onChange={() => toggleBalanceFilter(!balanceFilter)} checked={balanceFilter} data-test="claim-toolbar-balance-filter" />
                Pending balances
              </ClaimsFilterLabel>
              <ClaimsFilterLabel style={{ margin: '.5rem 0' }}>
                <StyledCheckbox type="checkbox" onChange={() => toggleRemarksFilter(!remarksFilter)} checked={remarksFilter} data-test="claim-toolbar-remarks-filter" />
                Claim remarks
              </ClaimsFilterLabel>
              <ClaimsFilterLabel>
                <StyledCheckbox
                  type="checkbox"
                  onChange={() => toggleReasonCodesFilter(!reasonCodesFilter)}
                  checked={reasonCodesFilter}
                  data-test="claim-toolbar-reasonCodes-filter"
                />
                Service reason codes
              </ClaimsFilterLabel>
            </FlexWrapper>
          </FlexWrapper>
        </>
      )}
    </ClaimsToolbarContainer>
  )
}

export default ClaimToolbar
