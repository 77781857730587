import React from 'react'
import { ReportCategoryList } from './reportStyles'
import ReportCard from './reportCard'
import { FlexWrapper } from '../../styles/viewerStyles'

const ReportListCategory = ({ category, reports, goToReport, toggleCategories, toggleFavorite, open }) => {
  //rendering category items as cards instead of UL/LI TODO: FIX STYLING. MAYBE ALTERNATIVE STYLING?
  const buildReportItems = () => {
    return reports.map(report => {
      return <ReportCard key={report.ReportName} report={report} toggleFavorite={toggleFavorite} goToReport={goToReport} />
    })
  }

  return (
    <ReportCategoryList>
      <h4>{category}</h4>
      <FlexWrapper flexFlow="row wrap" justify="flex-start" align="center">
        {buildReportItems()}
      </FlexWrapper>
    </ReportCategoryList>
  )
}

export default ReportListCategory
