import { ThemeProvider } from 'styled-components'
import { useAppSelector } from '../redux/store'

// #region Base for both themes
const providers = {
  aetna: { '030': '#D4B7E1', 3160: '#BB8CCF', 6190: '#A161BD', 91120: '#7D3F97', main: '#7D3F97', 121150: '#5F3073', 151180: '#462456', 180: '#23122B' },
  bcbs: { '030': '#ADE5FF', 3160: '#70D2FF', 6190: '#0AB1FF', 91120: '#008BCC', main: '#008BCC', 121150: '#00628F', 151180: '#003852', 180: '#001C29' },
  cigna: { '030': '#FCDAB0', 3160: '#FBC789', 6190: '#F9AC4D', 91120: '#F89820', main: '#F89820', 121150: '#DA7B07', 151180: '#B26406', 180: '#623804' },
  cignacom: { '030': '#4d9ad0', 3160: '#338cc9', 6190: '#1a7ec2', 91120: '#0170bc', main: '#0170bc', 121150: '#0064a9', 151180: '#005996', 180: '#004e83' },
  cignamca: { '030': '#4cdc7b', 3160: '#32d768', 6190: '#19d255', 91120: '#00ce43', main: '#00ce43', 121150: '#00b93c', 151180: '#00a435', 180: '#00902e' },
  humana: { '030': '#CDF0A8', 3160: '#AFE773', 6190: '#91DE3F', 91120: '#73C021', main: '#4E8416', 121150: '#4E8416', 151180: '#34570F', 180: '#152306' },
  medicare: { '030': '#EBC1C1', 3160: '#DC9393', 6190: '#CD6565', 91120: '#BE3E3D', main: '#BE3E3D', 121150: '#9A3232', 151180: '#6C2323', 180: '#3E1414' },
  mcareadv: { '030': '#ffa54c', 3160: '#ff9832', 6190: '#ff8b19', 91120: '#ff7f00', main: '#ff7f00', 121150: '#e57200', 151180: '#cc6500', 180: '#b25800' },
  medicaid: { '030': '#B2F4FB', 3160: '#52E7F4', 6190: '#0DC2D3', 91120: '#098E9A', main: '#076A73', 121150: '#076A73', 151180: '#04474D', 180: '#022427' },
  commercial: { '030': '#FEEC9A', 3160: '#FEE472', 6190: '#FEDD48', 91120: '#FED520', main: '#FED520', 121150: '#EEB701', 151180: '#D49501', 180: '#BE8501' },
  patient: { '030': '#FFB0C8', 3160: '#FF9EBB', 6190: '#FF7AA2', 91120: '#E05780', main: '#E05780', 121150: '#B9375E', 151180: '#8A2846', 180: '#602437' },
  pehp: { '030': '#7f9fb8', 3160: '#4c789c', 6190: '#32658e', 91120: '#003f72', main: '#003f72', 121150: '#00325b', 151180: '#002c4f', 180: '#00192d' },
  tricare: { '030': '#D37675', 3160: '#CC6262', 6190: '#AB3837', 91120: '#842B2A', main: '#842B2A', 121150: '#842B2A', 151180: '#4A1818', 180: '#230C0C' },
  uhc: { '030': '#99BBFF', 3160: '#4785FF', 6190: '#0A5CFF', 91120: '#003DB8', main: '#002776', 121150: '#002776', 151180: '#001B52', 180: '#000E29' },
  unmatched: { '030': '#E6E6E6', 3160: '#CCCCCC', 6190: '#B3B3B3', 91120: '#999999', main: '#808080', 121150: '#666666', 151180: '#4D4D4D', 180: '#333333' },
  umr: { '030': '#a5d791', 3160: '#96d07e', 6190: '#87ca6c', 91120: '#6abd48', main: '#6abd48', 121150: '#549739', 151180: '#4a8432', 180: '#2a4b1c  ' },
  va: { '030': ' #f96c8e', 3160: '#f8537c ', 6190: ' #f73a69 ', 91120: '#F50A44', main: '#F50A44', 121150: '#c40836', 151180: '#ab072f', 180: '#930628' },
  wellcare: { '030': '#b2e2e3', 3160: '#7fcfd1', 6190: '#4cbcbe', 91120: '#00a0a3', main: '#00a0a3', 121150: '#008082', 151180: '#006061', 180: '#004041' },
  misc: { main: '#858585' },
}

const ThemeBase = {
  alerts: { Alert: '#e05d62', Warning: '#f7bd5e', Notification: '#1a5192' },
  client: {
    light: '#D99843',
    main: '#D48828',
    dark: '#9C6329',
    tab: { paddingRight: '0px' },
  },
  kpi: { posted: 'var(--color-accent-green)', pending: 'var(--color-accent-light-grey)' },
  patientManagement: { main: '#25E491', dark: '#16B671' },
  permissions: {
    Admin: '#682B88',
    Reporting: '#2F6C93',
    Global: '#2F6C93',
    Manager: '#D48828',
    Helpdesk: '#D48828',
    Other: '#076A73',
    Patients: '#25E491',
    Dashboard: '#FEC601',
    Remit: '#682B88',
  },
  providers,
  reporting: { light: '#4591C3', main: '#2F6C93', dark: '#295069' },
  support: {
    light: '#CCC',
    main: '#B2B2B2',
    dark: '#858585',
    tab: { paddingRight: '5px' },
  },
}
// #endregion

const dashboardBase = {
  light: '#FED748',
  main: '#FEC601',
  dark: '#CB9F01',
  tab: { paddingRight: '0px' },
}

const launchPadBase = {
  light: '#9845C4',
  main: '#682B88',
  dark: '#471D5D',
  tab: { paddingRight: '0px' },
}

const Light = {
  ...ThemeBase,
  claims: { card: { background: '#F1F1F1', footer: '#F1F1F1', footerBorder: '#B2B2B2' }, table: { payer: '#F1F1F1', altPayer: '#CCC' } },
  dashboard: { ...dashboardBase, cards: { background: '#FFF', border: 'var(--color-accent-light-grey)' } },
  emailPasscode: { text: 'var(--color-primary-charcoal)' },
  icons: { arrow: '#858585', support: '#B2B2B2', position: '#5E5E5E' },
  inputs: { border: '#5C5C5C', background: '#FFF', text: '#000' },
  launchPad: { ...launchPadBase, cards: { background: '#FFF', border: '#999' } },
  optionalTitle: { text: 'var(--color-primary-charcoal)' },
  patientInfo: {
    sidebarBG: '#CCC',
    fontColor: { label: '#5C5C5C', p: '#000' },
    initials: { border: `#2A3843`, background: '#5C5C5C' },
    table: { header: '#CCC', rowPrimary: '#295069', rowSecondary: '#CCC', background: '#F1F1F1' },
    cards: { header: '#CCC', content: '#F1F1F1' },
    AR: { items: '#B2B2B2' },
  },
  placeholder: { background: '#fff', accent: '#CCC' },
  restyle: {
    primary: 'var(--color-primary-charcoal)',
    primaryInverse: '#FFF',
    offPrimary: 'var(--color-accent-black)',
    secondary: 'var(--color-primary-charcoal)',
    blueAccent: 'var(--color-primary-blue)',
    darkAccent: 'var(--color-primary-charcoal)',
    purpleAccent: 'var(--color-primary-purple)',
    connectionSwitcher: 'var(--color-primary-charcoal)',
  },
  searchLabel: '#555',
  searchResults: { background: '#F1F1F1' },
  shades: { shadow: '#5C5C5C', dark: '#858585', light: '#B2B2B2', text: '#000', placeholder: '#B2B2B2' },
  viewer: { background: '#FFF' },
  modalBorder: 'var(--color-accent-alsogrey)',
}

const Dark = {
  ...ThemeBase,
  claims: { card: { background: '#858585', footer: '#B2B2B2', footerBorder: '#5C5C5C' }, table: { payer: '#858585', altPayer: '#666' } },
  dashboard: { ...dashboardBase, cards: { background: '#444', border: 'var(--color-accent-light-grey)' } },
  emailPasscode: { text: '#000' },
  icons: { arrow: '#CCC', support: '#B2B2B2', position: '#CCC' },
  inputs: { border: '#5C5C5C', background: '#000', text: '#f1f1f1' },
  launchPad: { ...launchPadBase, cards: { background: '#444', border: 'var(--color-accent-light-grey)' } },
  optionalTitle: { text: '#f1f1f1' },
  patientInfo: {
    sidebarBG: '#858585',
    fontColor: { label: '#F1F1F1', p: '#FFF' },
    initials: { border: `#2A3843`, background: '#5C5C5C' },
    table: { header: '#858585', rowPrimary: '#295069', rowSecondary: '#B2B2B2', background: '#636363' },
    cards: { header: '#858585', content: '#B2B2B2' },
    AR: { items: '#F1F1F1' },
  },
  placeholder: { background: '#CCC', accent: '#B2B2B2' },
  restyle: {
    primary: '#FFF',
    primaryInverse: 'var(--color-primary-charcoal)',
    offPrimary: '#FFF',
    secondary: 'var(--color-primary-lightBlue)',
    blueAccent: 'var(--color-primary-lightBlue)',
    darkAccent: 'var(--color-accent-black)',
    purpleAccent: 'var(--color-accent-purple)',
    connectionSwitcher: 'rgba(255, 255, 255, 0.7)',
  },
  searchLabel: '#f1f1f1',
  searchResults: { background: 'var(--color-primary-charcoal)' },
  shades: { shadow: '#000', dark: '#858585', light: '#B2B2B2', text: '#f1f1f1', placeholder: '#858585' },
  viewer: { background: '#444' },
  modalBorder: '#333',
}

const Theme = ({ children }) => {
  const ThemeName = useAppSelector(state => (state.users ? state.users.ThemeName : 'Light'))
  return <ThemeProvider theme={ThemeName === 'Light' ? Light : Dark}>{children}</ThemeProvider>
}

export default Theme
