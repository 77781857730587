import React, { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { getHistoricNames } from '../searchSlice'
import { buildDate, findPermission } from '../../../../utils/helperMethods'
import { MobileClaimCard } from '../../claims/claimStyles'
import DotLoader from '../../../misc/dotLoader'

const MobileSearchResultCard = React.forwardRef(({ patient }, ref) => {
  const history = useNavigate()

  const dispatch = useAppDispatch()

  const patientPermissions = useAppSelector(state => state.users.UserPermissions.Patients)
  const historicRequest = useAppSelector(state => state.search.historicNameRequests).find(request => request.historicPatientID === patient.id)

  const handleGetHistoricNames = useCallback(
    payload => {
      dispatch(getHistoricNames({ ...payload, controller: new AbortController() }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (patient.patient_history) {
      const payload = {
        PatientID: patient.id,
      }
      handleGetHistoricNames(payload)
    }
  }, [patient.patient_history, patient.id])

  const checkNode = node => {
    if (node === 'svg') {
      return true
    }
    if (node === 'path') {
      return true
    }
    return false
  }

  const handleClick = e => {
    if (!checkNode(e.target.nodeName)) {
      history(`/patientManagement/${patient.id}`)
    }
  }

  const renderHistoricNames = () => {
    return (
      <>
        <label>Historic Names</label>
        <div>
          <DotLoader
            isLoading={historicRequest?.historicStatus === 'pending' && patient.id === historicRequest?.historicPatientID}
            height="50px"
            style={{ marginTop: '-1rem' }}
          />
          {patient.HistoricNames.map((item, i) => {
            return (
              <p key={`${item.LastName}:${i}`}>
                {item.LastName}, {item.FirstName} {item.MiddleName || ''}
              </p>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <MobileClaimCard ref={ref} onClick={e => handleClick(e)}>
      <h5 style={{ gridColumn: '1 / span 2' }} className="header">
        {patient.lastName}, {patient.firstName} {patient.middleName || ''}
      </h5>
      {findPermission('41', patientPermissions) && patient.patient_history ? renderHistoricNames() : null}
      <label>Date of Birth</label>
      <p>{buildDate(patient.DateOfBirth)}</p>
      <label>Patient ID</label>
      <p>{patient.apmId}</p>
    </MobileClaimCard>
  )
})

export default MobileSearchResultCard
