import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import SupportMessage from '../loginPage/supportMessage';
import { LoginFooter } from "../../login/loginStyles";
import { SplashPageDiv, SplashpageWelcome } from "../splashStyle";
import { fetchClientConnection, updateAppRedirecting } from '../userSlice';
import { resetRefresh } from '../../../utils/security';


const RedirectLimbo = () => {
  const preferredName = useAppSelector(state => state.users.PreferredName)
  const firstName = useAppSelector(state => state.users.FirstName)
  const LastAccessedAppID = useAppSelector(state => state.users.Client.LastAccessedAppID)
  const welcomeTimer = useAppSelector(state => state.login.welcomeTimer)

  const dispatch = useAppDispatch()

  // Handling if a user manually redirects to the old launchpad by changing the URL or from a browser bookmark of the old launchPad)
  useEffect(() => {
    if (!welcomeTimer) {
      if (LastAccessedAppID === '1') {
        resetRefresh()
        window.location.assign('/dashboard')
      } else if (LastAccessedAppID === '2') {
        dispatch(updateAppRedirecting(true))
        window.location.assign(`${process.env.REACT_APP_PFR_URL}`);
      } else if (LastAccessedAppID === '3') {
        dispatch(updateAppRedirecting(true))
        window.location.assign(`${process.env.REACT_APP_DR_URL}`);
      } else {
        dispatch(fetchClientConnection({ controller: new AbortController() }))
      }
    }
  }, [welcomeTimer, LastAccessedAppID, dispatch])

  return (
    <SplashPageDiv>
      <SplashpageWelcome>
        Welcome{preferredName ? (' ' + preferredName) : (firstName ? (' ' + firstName) : null)}!
      </SplashpageWelcome>
      <LoginFooter>
        <SupportMessage/>
      </LoginFooter>
    </SplashPageDiv>
  );
};

export default RedirectLimbo;
