import { buildDate, convertNumToCurrency, formatID } from '../../../utils/helperMethods'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { setClaimIndex, setMobileClaimMode, toggleDemographicsExpanded, toggleSelectClaim } from '../patientSlice'
import { MobileClaimCard } from './claimStyles'

const MobilePatientClaimCard = ({ claim, index }) => {
  const dispatch = useAppDispatch()

  const selectClaim = () => {
    dispatch(setClaimIndex(index))
    dispatch(toggleDemographicsExpanded(false))
    dispatch(setMobileClaimMode(true))
  }

  return (
    <MobileClaimCard onClick={selectClaim}>
      <h4 style={{ marginRight: '1rem' }}>Claim Number</h4>
      <p>{formatID(claim.ClaimNumber)}</p>
      <h4>Billing Date</h4>
      <p>{buildDate(claim.BillingDate)}</p>
      <h4>Accession{claim.specimens.length > 1 ? 's' : ''}&nbsp;</h4>
      <div>
        {claim.specimens.map((specimen, i) => (
          <p key={i}>{specimen.SpecimenNumber}</p>
        ))}
      </div>
      {claim.totals.Balance ? (
        <>
          <h4>Pending Balance</h4>
          <p>{convertNumToCurrency(claim.totals.Balance)}</p>
        </>
      ) : null}
      {claim.remarks.length ? (
        <>
          <h4>Claim Remarks</h4>
          <p>{claim.remarks.length}</p>
        </>
      ) : null}
      {claim.denialCount ? (
        <>
          <h4>Reason Codes</h4>
          <p>{claim.denialCount}</p>
        </>
      ) : null}
    </MobileClaimCard>
  )
}

export default MobilePatientClaimCard
