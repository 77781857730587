import { Action } from "redux";
import thunkMiddleware, { ThunkAction, } from "redux-thunk";
import { configureStore, } from "@reduxjs/toolkit";
import users from "../components/login/userSlice";
import reports from "../components/reporting/reportSlice";
import patients from "../components/patientInfo/patientSlice";
import modals from "../components/modals/modalSlice";
import login from "../components/login/loginSlice";
import terms from "../components/termsOfService/termsSlice";
import dashboard from "../components/dashboard/dashboardSlice";
import nav from "../components/nav/navSlice";
import alerts from "../components/alerts/alertSlice";
import search from "../components/patientInfo/search/searchSlice";
import quickSearch from "../components/quickSearch/quickSearchSlice";
import healthCheck from "../components/login/healthCheckSlice";
import passwordReset from "../components/password/passwordResetSlice";
import settings from "../components/settings/settingsSlice";
import mFA from "../components/mfa/mFASlice";
import permissions from "../components/permissions/permissionsSlice";
import payment from "../components/patientInfo/payment/paymentSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

function configurePortalStore(preloadedState?: any) {
  // @ts-ignore

  // const middlewares = [loggerMiddleware, thunkMiddleware];
  // const middlewareEnhancer = applyMiddleware(...middlewares);

  // const enhancers = [middlewareEnhancer];
  // const composedEnhancers = composeWithDevTools(...enhancers);

  const store = configureStore({
    reducer: {
      alerts: alerts,
      dashboard: dashboard,
      healthCheck: healthCheck,
      login: login,
      modals: modals,
      nav: nav,
      passwordReset: passwordReset,
      patients: patients,
      payment: payment,
      permissions: permissions,
      quickSearch: quickSearch,
      reports: reports,
      search: search,
      settings: settings,
      terms: terms,
      mFA: mFA,
      users: users,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["user/fetchRefreshToken/meta"],
          ignoredActionPaths: ["meta.arg", "payload.meta.arg"],
          ignoreState: true,
        },
      }).concat(thunkMiddleware),
    // .concat(loggerMiddleware, thunkMiddleware)
    // the deployed dev website is a production environment so we must distinguish
    // dev and prod via window.location.origin instead of process.env.NODE_ENV
    devTools: (window.location.origin) !== 'https://portal.phytest.com',
  });

  return store;
}
// const store = createStore(rootReducer, preloadedState, composedEnhancers)

export const store = configurePortalStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
