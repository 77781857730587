import { faArrowCircleLeft, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as wjChart from '@grapecity/wijmo.react.chart'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import { useRef } from 'react'
import { UnstyledButton } from '../../../styles/buttonStyles'
import { DashCardHeader } from '../dashboardStyles'
import CustomTooltip from '../../misc/CustomTooltip'

const AgingAnalysis = ({ formatYLabels, formatXLabels, buildSeries, showData, header, bindingX, bucket, selectionChanged, calculateYAxisMax, goToReport, reportName }) => {
  const chartRef = useRef(null)

  const calcMarginBottom = () => {
    if (bindingX === 'PayorDescription' && showData.length > 0 && Object.keys(showData[0]).includes('PayorDescription')) {
      const insuranceLabels = showData.map(data => formatXLabels(null, { text: data?.PayorDescription }).text)
      const longestStr = Math.max(...insuranceLabels.map(label => label.length || 0))
      return 35 + longestStr * 5
    } else if (bindingX === 'AgingBucket' && showData.length > 0 && Object.keys(showData[0]).includes('AgingBucket')) {
      let insurances = []
      showData.forEach(bucket => {
        Object.keys(bucket).forEach(key => {
          if (insurances.includes(key)) return
          insurances.push(key)
        })
      })
      return 40 + insurances.length * 5
    }

    return 100
  }

  return (
    <>
      <DashCardHeader>
        {bindingX === 'AgingBucket' ? null : (
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            style={{ position: 'absolute', left: '10px', cursor: 'pointer' }}
            size="lg"
            onClick={() => selectionChanged(chartRef.current.control)}
          />
        )}
        <h4>{bucket ? `${header}: Days ${bucket}` : `${header} by Days`}</h4>
        {/* <CustomTooltip title='Go to report' enterDelay={500}>
          <UnstyledButton onClick={() => goToReport(reportName)}>
            <FontAwesomeIcon style={{ marginLeft: '15px', cursor: 'pointer' }} icon={faFileAlt} />
          </UnstyledButton>
        </CustomTooltip> */}
      </DashCardHeader>
      <wjChart.FlexChart
        ref={chartRef}
        bindingX={bindingX}
        selectionMode="Point"
        selectionChanged={e => selectionChanged(e)}
        itemsSource={showData}
        stacking="Stacked"
        plotMargin={`20 40 ${calcMarginBottom()} 60`}
      >
        <wjChart.FlexChartLegend position="Bottom" titleAlign="Center"></wjChart.FlexChartLegend>
        {buildSeries()}
        <wjChart.FlexChartAxis wjProperty="axisX" binding="AgingBucket" itemFormatter={(eng, label) => formatXLabels(eng, label)}></wjChart.FlexChartAxis>
        <wjChart.FlexChartAxis
          wjProperty="axisY"
          max={calculateYAxisMax()}
          majorUnit={calculateYAxisMax() / 10}
          itemFormatter={(engine, label) => formatYLabels(engine, label)}
        ></wjChart.FlexChartAxis>
        <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </>
  )
}

export default AgingAnalysis
