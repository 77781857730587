import styled from 'styled-components'

export const UnstyledButton = styled.button`
  font-size: inherit;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: none;
  color: inherit;
  cursor: pointer;
  width: ${props => (props.widthFill ? '100%' : 'fit-content')};
  height: ${props => (props.heightFill ? '100%' : 'fit-content')};
`

export const ButtonIcon = styled.div`
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  font-size: ${props => props.fontSize || '1.25em'};
`

export const FormButton = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  text-align: center;
  cursor: pointer;
`

export const CalloutWrapper = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 100;
  top: 21px;
  right: 0px;
  background-color: gray;
`
export const ToggleSwitch = styled(UnstyledButton)`
  position: relative;
  width: 45px;
  height: 24px;
  margin: 0 5px;
  outline: none;

  :hover,
  :focus {
    outline: none;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + div:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
`

export const Toggle = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.restyle.secondary};
  border-radius: 34px;
  transition: background-color .5s;

  &:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 5px;
    background-color: white;
    border-radius: 50%;
  }
`
