import styled from 'styled-components';

export const ViewerContainer = styled.div`               
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
`;

export const ViewerDiv = styled.div`
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  box-sizing: border-box;
  opacity: 100%;
  display: block;
  background-color: ${props => props.location === '/login' ? 'rgba(255, 255, 255, 0)' : props.theme.viewer.background};
  transition: background-color .5s;
`;

export const AppDiv = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  text-align: center;
  font-family: 'Work Sans', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: ${props => props.login ? 'rgb(255, 255, 255, 0)' : '#f1f1f1'};
  display: flex;
  touch-action: pan-y;
` 

export const FlexWrapper = styled.div`
  display: flex;
  flex-flow: ${props => props.flexFlow};
  justify-content: ${props => props.justify ? props.justify : 'center'};
  align-items: ${props => props.align};
  height: ${props => props.height ? props.height : '100%'};
  width: ${props => props.width ? props.width : '100%'};
`

export const ViewerStatement = styled.h2`
  margin: 20px;
  font-size: 1.2em;
  text-align: center;
  color: ${props => props.theme.inputs.text};
`;