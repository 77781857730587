import { useRef } from 'react'
import { useOutsideClick } from '../../utils/hooks'
import { UnstyledButton } from '../../styles/buttonStyles'
import { ModalBackground, ModalContainer, ModalContent, ModalTitle } from '../modals/modalStyles'
import { Close } from '@mui/icons-material'

const AlertModal = ({ message, toggleModal, readMessage }) => {
  const modalRef = useRef()

  useOutsideClick(modalRef, () => toggleModal())

  return (
    <ModalBackground id="modal-bg">
      <ModalContainer ref={modalRef} style={{ height: '75%' }}>
        <UnstyledButton id="modal-bg" onClick={() => toggleModal()} aria-label="close alert details" style={{ alignSelf: 'flex-end' }}>
          <Close fontSize="large" />
        </UnstyledButton>
        <ModalContent>
          <ModalTitle>{message.MessageSummary}</ModalTitle>
          <p>{message.FullMessage}</p>
        </ModalContent>
      </ModalContainer>
    </ModalBackground>
  )
}

export default AlertModal
