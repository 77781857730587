import { useCallback } from 'react'
import { SearchForm, SearchInputs, SearchText, SearchLabelPM, SearchErrorMessage } from './searchStyles'
import { FormButton } from '../../../styles/buttonStyles'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { updateSearchTerm, submitSearch, resetSearchForm, setErrors } from './searchSlice'
import { trimSearchInput } from '../../../utils/helperMethods'
import { ErrorWrapper } from '../../settings/settingsStyles'
import { Close, Search } from '@mui/icons-material'

const SearchBar = ({ mobile, placeHolder, searchPatientsAllowed, setEnd }) => {
  const dispatch = useAppDispatch()

  const searchTerm = useAppSelector(state => state.search.term)
  const { errors, searchSubmitted, searchResults } = useAppSelector(state => state.search)

  const resetSearch = useCallback(() => {
    dispatch(resetSearchForm())
  }, [dispatch])

  const handleFormClear = () => {
    setEnd(10)
    resetSearch()
  }

  const setSearchTerm = useCallback(
    payload => {
      dispatch(updateSearchTerm(payload))
    },
    [dispatch]
  )

  const submitSearchTerm = useCallback(
    payload => {
      if (payload.searchString.length > 0) {
        dispatch(submitSearch({ ...payload, controller: new AbortController() }))
      } else {
        dispatch(setErrors('Please enter a search query.'))
      }
    },
    [dispatch]
  )

  const handleFormSubmit = e => {
    e.preventDefault()
    e.target.blur()
    submitSearchTerm({ searchString: searchTerm })
  }

  const buildErrors = () => {
    return errors.map((err, i) => {
      return (
        <ErrorWrapper style={{ padding: 0 }}>
          <SearchErrorMessage key={i}>{err}</SearchErrorMessage>
        </ErrorWrapper>
      )
    })
  }

  return (
    <>
      {searchPatientsAllowed ? (
        <SearchForm mobile={mobile} autoComplete="off" onSubmit={e => handleFormSubmit(e)} searchActive={searchSubmitted !== 'idle'}>
          {errors.length > 0 ? buildErrors() : null}
          <SearchInputs>
            <FormButton type="submit" onClick={e => handleFormSubmit(e)} value="Search">
              <Search fontSize='large'/>
            </FormButton>
            <SearchText
              placeholder={placeHolder}
              type="text"
              id="searchBar"
              autoComplete="off"
              value={searchTerm}
              onChange={e => setSearchTerm(trimSearchInput(e.target.value))}
              disabled={searchSubmitted === 'pending' || !searchPatientsAllowed}
            />
            {searchTerm.trim().length > 0 ? (
              <FormButton onClick={() => handleFormClear()}>
                <Close />
              </FormButton>
            ) : null}
          </SearchInputs>
          <SearchLabelPM>Search by Name, Date of Birth or Patient ID</SearchLabelPM>
        </SearchForm>
      ) : null}
    </>
  )
}

export default SearchBar
