import styled from 'styled-components'

export const AlertBannerDiv = styled.div`
    min-height: 50px;
    width: 100%;
    background-color: ${props => props.theme.alerts[props.type]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    margin-top: 0px;
    padding-left: 25px;
    top:${props => props.isLoggedIn ? '80px' : '0px'};
    z-index: 2004;
    font-family: "WorkSans-Regular", "Work Sans", sans-serif;
    color: #fff;
    box-sizing: border-box;
    cursor: pointer;
`

export const AlertIconWrapper = styled.div`
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin: ${props => props.close ? '0 10px 0 20px' : '0 10px 0 0'};
`;

export const AlertText = styled.p`
  cursor: pointer;
  text-align: left;
  font-size: 18px;
  margin: 0 5px;
`;