import React, { useState, useEffect, useCallback, useRef } from 'react'
import { DashCard } from '../dashboardStyles'
import CustomTooltip from '../../misc/CustomTooltip'
import BarChartPlaceholder from '../placeholders/BarChartPlaceholder'
import BrokenChart from '../placeholders/BrokenChart'
import ChargesChart from './ChargesChart'
import { useViewport } from '../../../utils/hooks'
import ChargesChartMobile from './ChargesChartMobile'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { fetchChartData } from '../dashboardSlice'
import DotLoader from '../../misc/dotLoader'
import { FlexWrapper } from '../../../styles/viewerStyles'
import { Info } from '@mui/icons-material'
import { convertNumToCurrency } from '../../../utils/helperMethods'

const ChargesChartContainer = ({ formatYLabels, goToReport, visible }) => {
  const [mobile, setMobile] = useState(false)

  const { width } = useViewport()

  const { reportName, data, error, loaded } = useAppSelector(state => state.dashboard.charges)
  const clientSwitching = useAppSelector(state => state.users.switchingClients)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)

  const dispatch = useAppDispatch()
  const promiseRef = useRef()

  const fetchChargesData = useCallback(() => {
    dispatch(fetchChartData({ controller: new AbortController(), chart: 'charges' }))
  }, [dispatch])

  useEffect(() => {
    if (visible && ClientConnectionID) {
      fetchChargesData()
    }
    return () => {
      if (promiseRef.current) {
        promiseRef.current.abort()
      }
    }
  }, [ClientConnectionID])

  useEffect(() => {
    if (width < 1025) {
      setMobile(true)
    } else {
      setMobile(false)
    }
    return () => {}
  }, [width])

  const handleDataSlicingForExtraneousMonths = data => {
    if (data.length > 6) {
      return data.slice(1)
    } else if (data.length > 0) {
      return data
    }
  }

  const buildTooltip = ht => {
    return `<span class='chart-tooltip'>
      <strong>${ht.item.PeriodAbbreviation || ''}</strong><br/>
      ${convertNumToCurrency(ht.item.Amount)}
      </span>
    `
  }

  const renderChart = () => {
    if (loaded === 'succeeded') {
      if (error) {
        return <BrokenChart refreshFn={fetchChargesData} header={'Collections'} chartType={1} />
      } else {
        if (mobile) {
          return (
            <ChargesChartMobile
              formatYLabels={formatYLabels}
              goToReport={goToReport}
              reportName={reportName}
              charges={handleDataSlicingForExtraneousMonths(data)}
            />
          )
        } else {
          return (
            <ChargesChart
              formatYLabels={formatYLabels}
              goToReport={goToReport}
              reportName={reportName}
              charges={handleDataSlicingForExtraneousMonths(data)}
              buildTooltip={buildTooltip}
            />
          )
        }
      }
    }
  }

  return (
    <>
      {visible ? (
        <DashCard loaded={loaded !== 'succeeded'} cardWidth={width > 1220 ? 2 : 6} error={error}>
          {loaded === 'pending' || clientSwitching === 'pending' ? (
            <BarChartPlaceholder title="Total Charges by Month" />
          ) : data?.length ? (
            <>
              <CustomTooltip title="Including current month, 6 month lookback of charges by month.">
                <Info className="tooltip-icon" />
              </CustomTooltip>
              {renderChart()}
            </>
          ) : (
            <BarChartPlaceholder loaded title="Total Charges by Month" />
          )}
        </DashCard>
      ) : null}
    </>
  )
}

export default ChargesChartContainer
