import { faArrowCircleLeft, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as wjChart from '@grapecity/wijmo.react.chart'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import { convertNumToCurrency } from '../../../utils/helperMethods'
import { UnstyledButton } from '../../../styles/buttonStyles'
import { DashCardHeader } from '../dashboardStyles'
import CustomTooltip from '../../misc/CustomTooltip'

const ARbyPayor = ({
  formatYLabels,
  formatXLabels,
  showData,
  header,
  selectionChanged,
  calculateYAxisMax,
  formatBars,
  goToReport,
  reportName,
  handleBackArrow,
  buildTooltip,
}) => {
  const calcMarginBottom = () => {
    if (header.includes('by Payer')) {
      const insuranceLabels = showData?.map(data => formatXLabels(null, { text: data.name }).text)
      const longestStr = Math.max(...insuranceLabels.map(label => label.length))
      return 35 + longestStr * 5
    }
    return 40
  }

  return (
    <>
      <DashCardHeader>
        {header === 'A/R by Payer' ? null : (
          <FontAwesomeIcon icon={faArrowCircleLeft} style={{ position: 'absolute', left: '10px', cursor: 'pointer' }} size="lg" onClick={() => handleBackArrow()} />
        )}
        <h4>{header}</h4>
        {/* <CustomTooltip title="Go to report" enterDelay={500} enterNextDelay={500}>
          <UnstyledButton onClick={() => goToReport(reportName)}>
            <FontAwesomeIcon style={{ marginLeft: '15px' }} icon={faFileAlt} />
          </UnstyledButton>
        </CustomTooltip> */}
      </DashCardHeader>
      <wjChart.FlexChart
        bindingX="name"
        selectionMode="Point"
        itemsSource={showData}
        selectionChanged={chart => selectionChanged(chart)}
        plotMargin={`20 20 ${calcMarginBottom()} 60`}
      >
        <wjChart.FlexChartSeries
          name="Amount"
          binding="amount"
          cssClass="bar"
          itemFormatter={(eng, ht, defaultRenderer) => formatBars(eng, ht, defaultRenderer)}
          tooltipContent={ht => buildTooltip(ht)}
        ></wjChart.FlexChartSeries>
        <wjChart.FlexChartAxis
          wjProperty="axisY"
          max={calculateYAxisMax()}
          majorUnit={calculateYAxisMax() / 10}
          itemFormatter={(engine, label) => formatYLabels(engine, label)}
        ></wjChart.FlexChartAxis>
        <wjChart.FlexChartAxis
          wjProperty="axisX"
          binding="AgingBucket"
          labelAngle={header.includes('by Payer') ? -45 : 0}
          itemFormatter={(eng, label) => formatXLabels(eng, label)}
        ></wjChart.FlexChartAxis>
        <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </>
  )
}

export default ARbyPayor
