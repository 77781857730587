import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import * as wijmo from '@grapecity/wijmo'
import { ThemeContext } from 'styled-components'
import CustomTooltip from '../../misc/CustomTooltip'
import { useViewport } from '../../../utils/hooks'
import ARbyPayor from './ARbyPayor'
import ARbyPayorMobile from './ARbyPayorMobile'
import BrokenChart from '../placeholders/BrokenChart'
import BarChartPlaceholder from '../placeholders/BarChartPlaceholder'
import { DashCard } from '../dashboardStyles'
import { fetchChartData } from '../dashboardSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { Info } from '@mui/icons-material'
import { convertNumToCurrency } from '../../../utils/helperMethods'

const ArByPayorContainer = ({ formatYLabels, goToReport, visible }) => {
  const dispatch = useAppDispatch()

  const promiseRef = useRef()

  const [showData, setShowData] = useState([])
  const [header, setHeader] = useState('A/R by Payer')
  const theme = useContext(ThemeContext)
  const [mobile, setMobile] = useState(false)

  const arData = useAppSelector(state => state.dashboard.ar.data)
  const clientSwitching = useAppSelector(state => state.users.switchingClients)
  const { reportName, error, loaded } = useAppSelector(state => state.dashboard.ar)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)

  const getData = data => {
    return new wijmo.CollectionView(data, {
      groupDescriptions: ['PayorDescription', 'AgingBucket'],
    })
  }

  const getGroupData = (group, chart) => {
    if (chart) {
      chart.selection = null
    }
    const arr = []
    group.groups.forEach(g => {
      arr.push({
        name: g.name,
        amount: g.items
          .map(el => el.VoucherBalance)
          .reduce((a, b) => {
            return a + b
          }, 0),
        group: g,
      })
    })
    if (arr.length > 0) {
      if (!arr[0].name.match(/\d/g) || arr[0].name.match(/\d/g).length < 3) {
        return arr.sort((a, b) => (a.name > b.name ? 1 : -1))
      } else {
        return arr.sort((a, b) => parseInt(a.name.split('-')[0].match(/\d/g).join('')) - parseInt(b.name.split('-')[0].match(/\d/g).join('')))
      }
    }
  }

  const { width } = useViewport()

  const fetchArData = useCallback(() => {
    dispatch(fetchChartData({ controller: new AbortController(), chart: 'ar' }))
  }, [dispatch])

  useEffect(() => {
    if (visible && ClientConnectionID) {
      fetchArData()
      setHeader('A/R by Payer')
    }
    return () => {
      if (promiseRef.current) {
        promiseRef.current.abort()
      }
    }
  }, [ClientConnectionID])

  useEffect(() => {
    if (width < 1025) {
      setMobile(true)
    } else {
      setMobile(false)
    }
    return () => {}
  }, [width])

  useEffect(() => {
    setShowData(getGroupData(getData(arData)))
    return () => {}
  }, [arData])

  const selectionChanged = chart => {
    if (chart && chart.selection) {
      const point = chart.selection.collectionView.currentItem
      if (point && point.group && !point.group.isBottomLevel) {
        handleDrilldown(chart, point)
      } else {
        setHeader(`A/R by Payer`)
        setShowData(getGroupData(getData(arData), chart))
      }
    }
  }

  const handleBackArrow = () => {
    setHeader(`A/R by Payer`)
    setShowData(getGroupData(getData(arData)))
  }

  const calculateYAxisMax = () => {
    if (showData && showData.length > 0) {
      // get largest VoucherBalance from array of collection objects
      const largestNum = Math.ceil(
        showData
          .map(data => data.amount)
          .sort((a, b) => a - b)
          .pop()
      )
      // get the place value of left most num
      const splitNums = largestNum.toString().split('')
      let newTens = parseInt(
        splitNums
          .map((num, i) => {
            let numVal
            if (i === 0) {
              splitNums[1] > 5 ? (numVal = (parseInt(num) + 1).toString()) : (numVal = num)
            } else if (i === 1) {
              num > 5 ? (numVal = '0') : (numVal = (parseInt(num) + 1).toString())
            } else {
              numVal = '0'
            }
            return numVal
          })
          .join('')
      )
      return newTens
    }
  }

  const handleDrilldown = (chart, point) => {
    setHeader(`${point.name} by Aging Bucket`)
    setShowData(getGroupData(point.group))
    chart.selection = null
  }

  const formatBars = (engine, ht, defaultFormat) => {
    let cssName = ht.item.name

    if (cssName === 'Blue Cross Blue Shield') cssName = 'bcbs'
    if (cssName === 'Cigna Commercial') cssName = 'cignacom'
    if (cssName === 'Cigna Medicare') cssName = 'cignamca'
    if (cssName === 'Commercial Insurance') cssName = 'commercial'
    if (cssName === 'Medicare Advantage') cssName = 'mcareadv'
    if (cssName === 'Patient Responsibility') cssName = 'patient'
    if (cssName === 'Tricare/Champus/VA') cssName = 'tricare'
    if (cssName === 'United Healthcare') cssName = 'uhc'
    if (cssName === 'United Medical Resources') cssName = 'umr'
    if (cssName === 'Veterans') cssName = 'va'

    if (ht.item.name !== null) {
      if (ht.item.name.match(/\d/g)) {
        let payer = ht.item.group.items[0].PayorDescription
        if (payer === 'Blue Cross Blue Shield') payer = 'bcbs'
        if (payer === 'Cigna Commercial') payer = 'cignacom'
        if (payer === 'Cigna Medicare') payer = 'cignamca'
        if (payer === 'Commercial Insurance') payer = 'commercial'
        if (payer === 'Medicare Advantage') payer = 'mcareadv'
        if (payer === 'Patient Responsibility') payer = 'patient'
        if (payer === 'Tricare/Champus/VA') payer = 'tricare'
        if (payer === 'United Healthcare') payer = 'uhc'
        if (payer === 'United Medical Resources') payer = 'umr'
        if (payer === 'Veterans') payer = 'va'

        const time = ht.item.name.match(/\d/g).join('')
        engine.fill = theme.providers?.[payer.toLowerCase()]?.[time] || '#CCC'
      } else {
        engine.fill = cssName !== 'Do Not Bill' && theme.providers[cssName.toLowerCase()]?.main ? theme.providers?.[cssName.toLowerCase()]?.main : '#CCCCCC'
      }
    } else {
      engine.fill = '#CCCCCC'
    }
    engine.stroke = 'none'
    defaultFormat()
  }

  const formatXLabels = (eng, label) => {
    if (label.text === 'Blue Cross Blue Shield') {
      label.text = 'BCBS'
    }
    if (label.text === 'United Healthcare') {
      label.text = 'UHC'
    }
    if (label.text === 'Commercial Insurance') {
      label.text = 'Commercial'
    }
    if (label.text === 'Patient Responsibility') {
      label.text = 'Patient'
    }
    if (label.text === 'United Medical Resources') {
      label.text = 'UMR'
    }
    if (label.text === 'Do Not Bill') {
      label.text = 'DNB'
    }
    if (mobile) {
      if (label.text === 'Cigna Commercial') label.text = 'Cigna Comm'
      if (label.text === 'Medicare Advantage') label.text = 'Medicare Adv'
      if (label.text === 'Tricare/Champus/VA') label.text = 'TRI/CHA/VA'
    }
    return label
  }

  const buildTooltip = ht => {
    return `<span class='chart-tooltip'>
      <strong>${ht.item.name ? ht.item.name : ht.item.PayorDescription}</strong><br/>
      ${convertNumToCurrency(ht.item.amount)}
      </span>
    `
  }

  const renderChart = () => {
    if (error) {
      return <BrokenChart refreshFn={fetchArData} header={'A/R by Payer'} chartType={1} />
    } else {
      if (mobile) {
        return (
          <ARbyPayorMobile
            formatYLabels={formatYLabels}
            formatXLabels={formatXLabels}
            header={header}
            showData={showData}
            selectionChanged={selectionChanged}
            calculateYAxisMax={calculateYAxisMax}
            formatBars={formatBars}
            goToReport={goToReport}
            reportName={reportName}
            handleBackArrow={handleBackArrow}
          />
        )
      } else {
        return (
          <ARbyPayor
            formatYLabels={formatYLabels}
            formatXLabels={formatXLabels}
            header={header}
            showData={showData || []}
            selectionChanged={selectionChanged}
            calculateYAxisMax={calculateYAxisMax}
            formatBars={formatBars}
            goToReport={goToReport}
            reportName={reportName}
            handleBackArrow={handleBackArrow}
            buildTooltip={buildTooltip}
          />
        )
      }
    }
  }

  return (
    <>
      {visible ? (
        <DashCard loaded={loaded !== 'succeeded'} cardWidth={width > 1220 ? 3 : 6} onClick={() => selectionChanged()} error={error}>
          {loaded === 'pending' || clientSwitching === 'pending' ? (
            <BarChartPlaceholder title="A/R by Payer" />
          ) : arData?.length ? (
            <>
              <CustomTooltip title="Snapshot of accounts receivable, broken down by payer. Click on any column to expand by aging bucket.">
                <Info className="tooltip-icon" />
              </CustomTooltip>
              {renderChart()}
            </>
          ) : (
            <BarChartPlaceholder loaded title="A/R by Payer" />
          )}
        </DashCard>
      ) : null}
    </>
  )
}

export default ArByPayorContainer
