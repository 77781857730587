import { useCallback, useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../redux/store'

// styling
import { HelpStatement, LoginButton, LoginField, LoginForm, LoginInput, LoginInputLabel, LoginLink } from "../../login/loginStyles"
import { WizardTip } from '../../../styles/appStyles'
import { ErrorWrapper, SectionTitle } from "../../settings/settingsStyles"
import { ErrorMessage } from '../../modals/modalStyles'

// Components
import LoginTip from './LoginTip'

// Redux
import { getMFAPreferenceTypes, updateMFAPreference } from '../loginSlice'


const UpdateMFAPreference = () => {

  const dispatch = useAppDispatch()

  const [phoneInput, updatePhoneInput] = useState('')
  const [phoneError, togglePhoneError] = useState(false)

  const mobile = useAppSelector(state => state.nav.mobile)
  const getMFAPreferenceStatus = useAppSelector(state => state.login.getMFAPreferenceStatus)
  const errors = useAppSelector(state => state.login.errors)
  const currentPhoneSMSCapable = useAppSelector(state => state.login.SMScapable)
  const currentPhoneNum = useAppSelector(state => (state.login.mfaPhoneNumber) ? state.login.mfaPhoneNumber : '')
  const preferences = useAppSelector(state => state.login.multiFactorPreferences)

  useEffect(() => {
    if (getMFAPreferenceStatus === 'idle') {
      dispatch(getMFAPreferenceTypes({controller: new AbortController()}))
    }
  }, [dispatch])

  useEffect(() => {
    updatePhoneInput(formatTelNum(currentPhoneNum))
  }, [currentPhoneNum])

  const submitMFAPreference = useCallback(
    (payload) => {
      dispatch(updateMFAPreference({...payload, controller: new AbortController()}))
    }, [dispatch]);
  
  const updateSMSPreferenceType = (e) => {
    e.preventDefault()
    togglePhoneError(false)

    if (phoneInput.length >= 5) {
      const payload = {
        MfaPreferenceID: preferences[1].PreferenceTypeID,
        MfaPreferenceTypeDescription: preferences[1].PreferenceTypeDescription,
        PhoneNumber: phoneInput ? phoneInput : currentPhoneNum
      }
      submitMFAPreference(payload)
    } else {
      togglePhoneError(true)
    }
  }

  const updateEmailPreferenceType = (e) => {
    e.preventDefault()
    const payload = {
      MfaPreferenceID: preferences[0].PreferenceTypeID,
      MfaPreferenceTypeDescription: preferences[0].PreferenceTypeDescription,
      PhoneNumber: null
    }
    submitMFAPreference(payload)
  }

  const MFATip = {
    prerequirements: "Authentication Tips:",
    requirements: [
      "Security Codes sent to a mobile device are more secure",
      "Check your phone number to make sure it's accurate",
      "Please ensure the device associated with the phone number can accept text messages"
    ]
  }

  const formatTelNum = (val) => {
    let regex = /[\D\s]/g
    let cleaned = (val).replace(regex, '')
    let match = cleaned.match(/(?<area>\d{1,3})?(?<exchange>\d{1,3})?(?<line>\d{1,4})?/)
    let returnVal

    if (match) {
      if (match.groups.line) {
        returnVal = `(${match.groups.area}) ${match.groups.exchange}-${match.groups.line}`
      } else if (match.groups.exchange) {
        returnVal = `(${match.groups.area}) ${match.groups.exchange}`
      } else if (match.groups.area) {
        returnVal = `${match.groups.area}`
      } else {
        returnVal = ''
      }
    }
    return returnVal
  }

    return (
      <LoginForm>
        <SectionTitle mobile={mobile}>Confirm Your Mobile Number</SectionTitle>
        {/* Error Handling */}
        {!currentPhoneSMSCapable ? (
          <ErrorWrapper style={{ marginBottom: '10px', minWidth: 'auto', width: '50%', maxWidth: '500px' }} data-testid="MFA-preference-smsCapable-error">
            <ErrorMessage>The phone number associated with your account was determined by our system as incapable of receiving text messages.</ErrorMessage>
            <ErrorMessage>To set your preference to mobile authentication, please provide a mobile number.</ErrorMessage>
          </ErrorWrapper>
        ) : null}
        {phoneError || errors.length > 0 ? (
          <ErrorWrapper style={{ marginBottom: '10px', minWidth: 'auto', width: '35%' }} data-testid="MFA-preference-error">
            {errors.length > 0 ? errors.map(error => <ErrorMessage>{error}</ErrorMessage>) : null}
            {phoneError ? <ErrorMessage>Please enter a phone number containing at least five digits.</ErrorMessage> : null}
          </ErrorWrapper>
        ) : null}
        <LoginField mobile={mobile}>
          <LoginInputLabel>Mobile Number:</LoginInputLabel>
          <LoginInput
            autoComplete="off"
            value={phoneInput ? formatTelNum(phoneInput) : ''}
            data-testid="MFA-phone-number"
            style={{ textAlign: 'right', paddingRight: '10px' }} 
            onChange={e => updatePhoneInput(formatTelNum(e.target.value))}
          />
        </LoginField>
        <LoginButton
          style={{
            marginTop: '40px',
            marginBottom: '40px',
            maxWidth: '300px',
          }}
          onClick={(e) => updateSMSPreferenceType(e)}
          mobile={mobile}
          login
        >
            CONFIRM NUMBER
          </LoginButton>
        <WizardTip mobile={mobile} style={{paddingRight: '20px', width: '90%'}}>
          <LoginTip mobile={mobile} tip={MFATip}></LoginTip>
        </WizardTip>
        <HelpStatement style={{ marginTop: '30px',  color: 'var(--color-accent-black)', fontSize: '12px' }}>Don't have a text message capable device?&nbsp;
          <LoginLink onClick={(e) => updateEmailPreferenceType(e)} >Send security codes to my email</LoginLink>
        </HelpStatement>
      </LoginForm>
    )
}

export default UpdateMFAPreference