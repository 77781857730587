import React, { useEffect, useState } from 'react'
import ReportCard from '../reportCard'
import { FavoritesContainer, ReportCarouselContainer } from '../reportStyles'
import FavoriteReportPlaceholder from './FavoriteReportPlaceholder'
import CarouselContainer from '../../misc/carousel/CarouselContainer'
import { useAppSelector } from '../../../redux/store'

const ReportFavorites = ({ reports, goToReport, toggleFavorite }) => {
  const width = useAppSelector(state => state.nav.width)

  const [pages, updatePages] = useState(0)
  const breakpoint = width > 1650 ? 6 : 4

  useEffect(() => {
    // number of pages in the carousel (divides number of favorites by the breakpoint and then rounds up)
    updatePages(Math.ceil(reports.length / breakpoint))
  }, [reports])

  const buildCarouselFavorites = () => {
    let favoriteCards = []
    for (let i = 0; i < pages; i++) {
      const startIndex = i * breakpoint
      const endIndex = startIndex + breakpoint

      favoriteCards.push(<ReportCarouselContainer extraWide={width > 1650}>{buildFavorites().slice(startIndex, endIndex)}</ReportCarouselContainer>)
    }
    return favoriteCards
  }

  const buildFavorites = () => {
    if (reports.length > 0) {
      let sortedReports = [...reports]

      sortedReports.sort((a, b) => {
        let fa = a.ReportName.toLowerCase(),
          fb = b.ReportName.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })

      return sortedReports.map(report => {
        return (
          <ReportCard goToReport={goToReport} toggleFavorite={toggleFavorite} report={report} favorites favoriteCount={reports.length} breakpoint={breakpoint} />
        )
      })
    } else {
      return <FavoriteReportPlaceholder />
    }
  }

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      {buildFavorites().length > breakpoint ? <CarouselContainer>{buildCarouselFavorites()}</CarouselContainer> : <FavoritesContainer>{buildFavorites()}</FavoritesContainer>}
    </div>
  )
}

export default ReportFavorites
