import { KPIAmount, KPIDiv, KPIDivHeader, KPIPending, KPIPosted } from './kpiStyles'
import { convertNumToCurrency } from '../../../utils/helperMethods'
import BrokenChart from '../placeholders/BrokenChart'
import CustomTooltip from '../../misc/CustomTooltip'
import { useAppSelector } from '../../../redux/store'
import { Info } from '@mui/icons-material'
import { FlexWrapper } from '../../../styles/viewerStyles'

const KPI = ({ posted, pending, header, mobile, tip, loaded, refreshKPI }) => {
  const error = useAppSelector(state => state.dashboard.kpis.error)

  const printAmount = amount => {
    if (amount === null) {
      return '--'
    }
    if (!header.includes('Specimen')) {
      return convertNumToCurrency(amount)
    } else {
      return amount
    }
  }

  const calculateTodayBg = () => {
    const total = posted + pending
    if (total === 0) {
      return ['100%', '0%']
    } else {
      return [`${Math.floor((posted / total) * 100)}%`, `${Math.floor((pending / total) * 100)}%`]
    }
  }

  const buildPostedLabel = () => {
    if (pending === 0 && posted > 0) {
      return <label>100% Posted</label>
    } else if (pending > 0 && posted > 0) {
      return <label>{Math.floor((posted / (pending + posted)) * 100)}% Posted</label>
    } else {
      return <label>Posted</label>
    }
  }

  const buildKPI = () => {
    return (
      <KPIDiv mobile={mobile}>
        <FlexWrapper align="center">
          <div style={{ width: '100%' }} />
          <KPIDivHeader>{header}</KPIDivHeader>
          <div className="flexRow" style={{ width: '100%', float: 'right', justifyContent: 'flex-end', paddingRight: '.5rem' }}>
            <CustomTooltip title={tip || ''}>
              <Info sx={{ color: 'var(--color-accent-alsogrey)', fontSize: '1.2rem' }} />
            </CustomTooltip>
          </div>
        </FlexWrapper>
        <KPIAmount bgGradient={calculateTodayBg()}>
          <KPIPosted>
            {buildPostedLabel()}
            {printAmount(posted)}
          </KPIPosted>
          {pending > 0 ? (
            <KPIPending>
              <label>Pending</label>
              <em>{printAmount(pending)}</em>
            </KPIPending>
          ) : null}
        </KPIAmount>
      </KPIDiv>
    )
  }

  const renderKPI = () => {
    if (error) {
      return <BrokenChart refreshFn={refreshKPI} header={header} chartType={2} error={error} />
    } else {
      return buildKPI()
    }
  }

  return renderKPI()
}

export default KPI
