import { useEffect } from 'react'
import * as wjViewer from '@grapecity/wijmo.react.viewer';
import './wijmoViewer.css'
import { getCookie } from '../../utils/security'
import { FlexWrapper } from '../../styles/viewerStyles'
import { useAppDispatch, useAppSelector } from '../../redux/store';
import DotLoader from '../misc/dotLoader';
import { setTab } from '../nav/navSlice';
import ConnectionSwitcher from '../nav/connectionSwitcher/ConnectionSwitcher';
import { useNavigate } from 'react-router-dom';
import { fetchReports } from './reportSlice';


const ReportViewer = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const theme = useAppSelector(state => state.users.ThemeName)
    const UserEmail = useAppSelector(state => state.users.UserEmail)
    const clientID = useAppSelector(state => state.users.Client.ClientID)
    const report = useAppSelector(state => state.reports.toView)
    const reports = useAppSelector(state => state.reports.reports)
    const settingReport = useAppSelector(state => state.reports.settingReport)
    const reportsLoading = useAppSelector(state => state.reports.reportsLoading)
    const showConnectionSwitcher = useAppSelector(state => state.users.connectionSwitcher.showConnectionSwitcher)
    const mobile = useAppSelector(state => state.nav.mobile)
    const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)

  useEffect(() => {
    // Added this useEffect to redirect the user to /reporting upon manual page refresh
    // Previously, if page was refreshed, "report" is false and the page rendered a loader indefinitely
    if ((settingReport === 'idle' && !report) || mobile) {
      navigate('/reporting')
    }
  }, [])

  useEffect(() => {
    dispatch(setTab('reporting'))
  },[dispatch])

  /* RE-CHECKING REPORT PERMISSIONS UPON CLIENT CHANGE */
  useEffect(() => {
    // Monitoring for permissions changes when a user switches clients
    // Recalls /getReportList to view the updated reports a user has permissions to
    if (reportsLoading !== 'pending') {
      dispatch(fetchReports({ controller: new AbortController() }))
    }
  }, [ClientConnectionID])

  useEffect(() => {
    // If the currently-viewed report does not appear in the /getReportList response,
    // the user does not have permissions to that report and must be redirected

    if (report && reportsLoading === 'succeeded') {
      const reportPermissions = reports.find(reportObj => reportObj.ReportName === report)
      if (!reportPermissions) {
        navigate('/reporting')
      }
    }
  }, [report, reportsLoading])

    // useEffect(() => {
    //     if(report === '' && settingReport === 'idle'){
    //         const path = window.location.pathname.split('/')
    //         addReportToView(decodeURI(path[path.length-1]))
    //     }
    //     return () => { }
    // }, [addReportToView, report])

    const setTheme = (e) => {
        e.hostElement.classList.add(theme.toLowerCase())
    }

    const handlePageLoad = (viewer) =>{
        const closeButton = document.querySelector('.wj-viewer-splitter')
        closeButton.click()
    }
        return (
        <>
            <ConnectionSwitcher/>
            <FlexWrapper
                flexFlow='column nowrap'
                height='100%'
                style={{
                  background: '#434343',
                  color: 'white',
                  fontSize: '1.2em',
                  display: showConnectionSwitcher ? 'none' : 'initial'
                }}
            >
            { report && settingReport === 'succeeded' ?
                <>
                <p style={{margin: '0',padding: '5px', height:'35px', textAlign: 'left', paddingLeft: '10px', display: 'flex', alignItems: 'center', background: '#434343'}}> <span style={{fontWeight: 'bold'}}>{report}</span></p>
                <wjViewer.ReportViewer
                    initialized={e=> setTheme(e)}
                    pageLoaded={(e)=>handlePageLoad(e)}
                    zoomFactor={1}
                    zoomMode={0}
                    parameters={{UserName: UserEmail, ClientID: clientID }}
                    requestHeaders={{Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`}}
                    filePath={`${process.env.REACT_APP_REPORTING_PATH}${report}`}
                    serviceUrl={`${process.env.REACT_APP_API_URL}report`}
                    >
                </wjViewer.ReportViewer> 
                </>
                :
                (
                  <FlexWrapper>
                    <DotLoader isLoading={true} width={mobile ? "80%" : "40%"} height="100%" dotSize="35px" />
                  </FlexWrapper>
              )}
            </FlexWrapper>
      </>
        )
}

export default ReportViewer
