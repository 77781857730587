import React, { useRef } from 'react'
import { MobileSortOption, MobileSearchControlWrapper, PhySelectSort, PhyInputsSort, MobileSortArrow } from '../../../patientInfo/search/searchStyles'
import { useAppSelector } from '../../../../redux/store'
import { ArrowDownward } from '@mui/icons-material'

const MobileSearchResultControls = ({ setSortUp, handleSort }) => {
  const selectEl = useRef(null)

  const { searchResults, sortUp, sortKey } = useAppSelector(state => state.search)

  const renderSortIcon = () => {
    return (
      <MobileSortArrow onClick={() => setSortUp(!sortUp)} onKeyDown={() => setSortUp(!sortUp)}>
        <ArrowDownward />
        <div>
          <span>{sortUp ? 'A' : 'Z'}</span>
          <span>{sortUp ? 'Z' : 'A'}</span>
        </div>
      </MobileSortArrow>
    )
  }

  return (
    <MobileSearchControlWrapper>
      <p>
        {searchResults.length} result{searchResults.length === 1 ? '' : 's'}
      </p>
      <div>
        <label style={{ whiteSpace: 'nowrap' }}>Sort by:</label>
        <PhyInputsSort>
          <PhySelectSort ref={selectEl} onChange={e => handleSort(e)} defaultValue={sortKey} style={{ fontSize: '1rem' }}>
            <MobileSortOption value="firstName">First Name</MobileSortOption>
            <MobileSortOption value="lastName">Last Name</MobileSortOption>
            <MobileSortOption value="DateOfBirth">Date of Birth</MobileSortOption>
            <MobileSortOption value="apmId">Patient ID</MobileSortOption>
          </PhySelectSort>
        </PhyInputsSort>
        {sortKey ? renderSortIcon() : null}
      </div>
    </MobileSearchControlWrapper>
  )
}

export default MobileSearchResultControls
