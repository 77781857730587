import { floor } from "lodash"
import { useEffect, useState } from "react"


const Countdown = ({initialSeconds}) => {
    const [secondsRemaining, setSecondsRemaining] = useState(initialSeconds)

    useEffect(() => {
        const start = new Date()
        const countdownTimer = setInterval(() => {
            const now = new Date()
            const elapsed = floor((now - start)/1000)
            const remaining = initialSeconds - elapsed
            setSecondsRemaining(remaining)
            if (remaining <= 0) {
                clearInterval(countdownTimer)
            }
        }, 1000);
        return () => {
            clearInterval(countdownTimer)
        }
    }, [initialSeconds])

    return `(${secondsRemaining}s)`
}

export default Countdown