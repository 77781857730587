import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store'
import { getHistoricNames } from '../searchSlice'
import { HistoricNameUl, ResultData } from '../searchStyles'
import DotLoader from '../../../misc/dotLoader'

type Props = {
  patientID: string | number
}

const HistoricNamesRow = (props: Props) => {
  const dispatch = useAppDispatch()

  const patientData = useAppSelector(state => state.search.sortedResults.find(patient => patient.id === props.patientID))
  const historicRequest = useAppSelector(state => state.search.historicNameRequests).find(request => request.historicPatientID === patientData?.id)

  useEffect(() => {
    dispatch(getHistoricNames({ PatientID: props.patientID, controller: new AbortController() }))
  }, [dispatch])

  return (
    <ResultData id="historicData">
      <p style={{ width: '100%', textAlign: 'left', margin: 0, fontWeight: 500 }}>Historic Names:</p>
      {historicRequest?.historicPatientID === patientData!.id && historicRequest?.historicStatus === 'pending' ? (
        <DotLoader isLoading height="55px" />
      ) : (
        <HistoricNameUl>
          {patientData?.HistoricNames.map((item, i) => {
            return <li key={`${item.LastName + i}`}>{`${item.LastName}, ${item.FirstName} ${item.MiddleName ? item.MiddleName : ''}`}</li>
          })}
        </HistoricNameUl>
      )}
    </ResultData>
  )
}

export default HistoricNamesRow
