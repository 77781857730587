import styled, { css } from 'styled-components'
import { keyframes } from 'styled-components'

const purple = '#692c87'
const lightBlue = '#7ed1e7'
const darkBlue = '#1a5192'
const mint = '#86ccab'

export const LoginBackground = styled.div`
  position: absolute;
  z-index: -100;
  height: 400%;
  width: 300%;
  background: linear-gradient(76deg, ${purple}, ${lightBlue}, ${darkBlue}, ${mint}) repeat;
  background: -webkit-linear-gradient(76deg, ${purple}, ${lightBlue}, ${darkBlue}, ${mint}) repeat;
  background: -moz-linear-gradient(76deg, ${purple}, ${lightBlue}, ${darkBlue}, ${mint}) repeat;
  background: -ms-linear-gradient(76deg, ${purple}, ${lightBlue}, ${darkBlue}, ${mint}) repeat;
  animation: Gradient 60s linear infinite alternate;
  animation-delay: 0.5s;

  @keyframes Gradient {
    0% {
      transform: translate(0%, 0%);
    }
    50% {
      transform: translate(-65%, -70%);
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
`

export const LoginContentsWrapper = styled.div`
  height: 100%;
  width: 100%;
  height: -webkit-fill-available; // needed for Safari
  width: -webkit-fill-available; // needed for Safari
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
`

export const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const LoginLogo = styled.div`
  display: flex;
  height: 50px;
  margin-top: 75px;
  margin-bottom: ${props => (props.mobile ? '15%' : props.loginHome ? '150px' : '50px')};
  justify-content: center;
  align-items: center;
  align-content: center;

  /* Safari only */
  @supports (-webkit-appearance: none) {
    .logo {
      height: 100%;
    }
  }
`

export const StatementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`
export const LoginStatement = styled.p`
  max-width: ${props => (props.mobile ? '90%' : '600px')};
  padding: ${props => (props.mobile ? 0 : '0 15px')};
  font-size: ${props => (props.mobile ? '1.1em' : '1.2em')};
  font-family: 'WorkSans-Regular', 'Work Sans', sans-serif;
  line-height: 24px;
  margin-top: 1em;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
`

export const LoginCardHeader = styled.div`
  width: 100%;
  margin: 0;
  color: #ffffff;
  height: 80px;
  align-self: flex-start;
`

export const WelcomeMessage = styled.div`
  width: 45vmin;
  min-width: 300px;
  margin: 0;
`

export const LoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  box-sizing: border-box;
`

export const LoginButton = styled.button`
  height: 40px;
  min-width: 175px;
  width: fit-content;
  margin: 10px;
  margin-top: 20px;
  margin-bottom: ${props => (props.mobile ? '50px' : '0px')};
  padding: 20px 20px 20px ${props => (props.icon ? '10px' : '20px')};
  box-sizing: border-box;
  border-radius: 30px;
  border: none;
  background-color: ${props => (props.login ? 'var(--color-primary-charcoal)' : props.inverse ? props.theme.restyle.primary : props.theme.restyle.darkAccent)};
  font-size: 14px;
  font-family: 'WorkSansRoman-Medium', 'Work Sans Medium', 'Work Sans', sans-serif;
  font-weight: 600;
  color: ${props => (props.inverse ? props.theme.restyle.primaryInverse : '#ffffff')};
  text-align: center;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.75s, color .75s;

  ${props =>
    props.inverse &&
    css`
      :hover {
        background-color: ${props => props.theme.restyle.primaryInverse};
        color: ${props => props.theme.restyle.primary};
      }
    `}
`

export const LoginField = styled.div`
  width: ${props => (props.mobile ? '90%' : props.settings ? '50%' : '40%')};
  max-width: 500px;
  min-width: 200px;
  height: 50px;
  margin: 10px;
  padding: 10px;
  border-radius: 30px;
  background-color: ${props => (props.disabled ? '#738693' : 'rgba(255, 255, 255)')};
  border: ${props => (props.disabled ? 'none' : '1px solid rgba(191, 205, 216, 0.5)')};
  box-shadow: ${props => (props.disabled ? 'none' : 'inset 0 0 0 100px rgba(232, 240, 254, 0.6)')};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  align-content: center;

  @media screen and (max-width: 1025px) and (max-height: 610px) {
    height: 40px;
  }
`

export const LoginLink = styled.a`
  cursor: pointer;
  color: ${props => props.theme.emailPasscode.text};
  font-weight: 600;
  font-size: 1.1em;
`

export const DatePickerField = styled.div`
  height: 50px;
  width: ${props => (props.mobile ? '90%' : '40%')};
  max-width: 500px;
  margin: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255);
  box-shadow: inset 0 0 0 100px rgba(232, 240, 254, 0.6);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-sizing: border-box;

  @media screen and (max-width: 1025px) and (max-height: 610px) {
    height: 40px;
  }
`

export const DatePickerInput = styled.input`
  border: none;
  border-radius: 30px;
  color: #03263c;
  letter-spacing: 1.3px;
  font-family: sans-serif;
  font-size: 10pt;
  text-align: right;
  box-shadow: inset 0 0 0 100px rgba(232, 240, 254, 0.6);

  ::placeholder {
    color: ${props => (props.settings ? '#03263c' : '#738693')};
  }
  :focus {
    outline: none;
  }

  /* Safari only */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @supports (-webkit-appearance: none) {
      background-color: rgba(232, 240, 254, 0.4);
    }
  }

  @supports (not (-webkit-hyphens: none)) and (not (-moz-appearance: none)) {
    background-color: rgba(255, 255, 255, 0.4);
  }
`

export const LoginInput = styled.input`
  height: 100%;
  width: 100%;
  margin-right: ${props => (props.optionalData ? '20px' : 0)};
  border: none;
  border-radius: 10px;
  color: #03263c;
  letter-spacing: 1.3px;
  background-color: ${props => (props.disabled ? '#738693' : props.optionalData ? 'rgba(255, 255, 255, 0)' : 'rgba(232, 240, 254, 0.6)')};

  /* Safari only */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      background-color: rgba(232, 240, 254, 0.4);
    }
  }

  /* Chrome only */
  @supports (-webkit-text-security: circle) {
    box-shadow: ${props => (props.disabled || props.optionalData ? 'none' : 'inset 0 0 0 100px rgba(255, 255, 255, 0.4)')};
  }

  /* Firefox only */
  @-moz-document url-prefix() {
    &:-webkit-autofill {
      background: rgba(255, 255, 255, 0);
    }
  }

  font-family: sans-serif;
  font-size: 10pt;
  text-align: ${props => (props.optionalData ? 'right' : 'left')};
  ::placeholder {
    color: #738693;
  }
  :focus {
    outline-style: none;
  }
`

export const HelpStatement = styled.p`
  font-size: 0.8em;
  margin: 0;
  margin-top: ${props => (props.extraSpace ? '5px' : null)};
  &:first-child {
    margin-top: 10px;
  }

  a,
  svg {
    color: #fff;
    text-decoration: none;
  }

  @media screen and (min-width: 1200px) {
    font-size: 14px;
  }

  @media screen and (max-width: 700px) {
    padding: 2em;
  }
`

export const LoginViewerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: sticky;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 5em;
  min-width: -webkit-fill-available;
  min-height: 100%;
  min-height: -webkit-fill-available;
  background: ${props => props.theme.viewer.background};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`

export const LoginTitle = styled.h3`
  text-align: center;
  margin-top: 0;
`

export const LoginTipH3 = styled.h3`
  font-size: 1em;
  padding: 0.5em;
`
export const LoginTipH4 = styled.h3`
  font-size: 1.1em;
  padding: 0.5em;
`

export const LoginTipP = styled.p`
  font-size: 0.9em;
  text-align: center;
`

export const LoginTipLI = styled.li`
  margin: 10px 0;
  font-size: 0.9em;
  line-height: normal;
  display: list-item;
  list-style-type: square;
`

export const LoginFooter = styled.div`
  width: 100%;
  height: 40px;
  background-color: #373d4d;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: #fff;
`

export const LoginSelect = styled.div`
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 10px;
  color: ${props => (!props.placeholder && !props.settings ? '#738693' : '#03263c')};
  letter-spacing: 1.3px;
  font-family: sans-serif;
  font-size: 10pt;
  ::placeholder {
    color: #738693;
  }
  :focus {
    outline-style: none;
  }
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  cursor: pointer;
`

const appear = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`

const disappear = keyframes`
  from {opacity: 1;}
  to {opacity: 0;}
`

export const LoginOptionContainer = styled.div`
  height: 100%;
  margin: ${props => (props.settings ? '20px' : 'auto')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  opacity: ${props => (props.appear ? 1 : 0)};
  animation: ${props => (props.appear ? appear : disappear)} 0.25s linear;
`

export const LoginOption = styled.div`
  min-width: ${props => (props.settings ? '80px' : '50px')};
  box-sizing: ${props => (props.settings ? 'border-box' : 'content-box')};
  margin: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255);
  border-radius: 30px;
  border: 1px solid rgba(191, 205, 216, 0.5);
  color: #03263c;
  letter-spacing: 1.3px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background-color: #373d4d;
    border: 1px solid #373d4d;
    color: #fff;
  }
`

export const LoginInputLabel = styled.div`
  width: 200px;
  margin-left: 10px;
  color: ${props => (props.settings ? '#03263c' : '#738693')};
  font-family: sans-serif;
  font-size: 10pt;
  text-align: left;
  letter-spacing: 1.3px;
  cursor: default;
`

export const AccountIssueText = styled.p`
  font-size: 36px;
  text-align: center;
`
