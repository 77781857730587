import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ClientPermission, RequestStatus, UserFeaturePermission, UserPermission } from "../../../type"
import { AppDispatch, RootState } from "../../redux/store"
import { protectedApi } from "../../utils/api"
import { getCookie } from "../../utils/security"
import { logout, fetchRefreshToken, findEditPermissionClients, updateUserPermission } from "../login/userSlice"

interface PermissionState {
  userPermissions: UserPermission[]
  fetchingUserPermissions: RequestStatus
  settingPermissions: RequestStatus
  touchedPermissions: Partial<UpdatePermission>
  error: string
}

export interface UpdatePermission {
  AffectedUserID: string,
  ClientID: string,
  ClientAbbreviation?: string,
  PermissionID: string,
  Permitted: boolean,
  PermissionIDs?: { PermissionID: string, Permitted: boolean }[]
  category: string,
  addToTouched?: boolean,
  removeFromTouched?: boolean,
  updateState?: boolean,
  controller?: AbortController
}


const initialPermissionsState = {
  userPermissions: [],
  fetchingUserPermissions: 'idle',
  touchedPermissions: {
    AffectedUserID: '',
    ClientID: '',
    ClientAbbreviation: '',
    PermissionIDs: []
  },
  settingPermissions: 'idle',
  error: '',
}

const initialState = initialPermissionsState as PermissionState

export const getPermissions = createAsyncThunk<any, {AppID?: number, controller: AbortController}, { dispatch: AppDispatch, state: RootState }>(
  'permissions/getPermissions',
  async (payload, thunkAPI) => {
    if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
      return protectedApi(
        payload.controller,
        getCookie(process.env.REACT_APP_ACCESS_TOKEN)
      ).post('/users/settings/getPermissions', payload)
        .then(res => res.data)
        .then(data => {
          if (data.success) {
            thunkAPI.dispatch(findEditPermissionClients(data.results))
            return { ...data, currentClient: thunkAPI.getState().users.Client.ClientID }
          } else {
            throw new Error()
          }
        }
        )
        .catch((error) => {
          if (error.response.data.tokenExpired) {
            thunkAPI.dispatch(
              fetchRefreshToken(() => {
                thunkAPI.dispatch(
                  getPermissions({ AppID: payload.AppID, controller: payload.controller })
                )
              })
            );
          }
          return error.response.data
        })
    } else {
      thunkAPI.dispatch(logout({controller: new AbortController()}));
    }
  }
)

export const setPermissions = createAsyncThunk<any, Partial<UpdatePermission>, { dispatch: AppDispatch }>(
  'permissions/updatePermissions',
  async (payload, thunkAPI) => {
    if (getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
      return protectedApi(
        payload.controller,
        getCookie(process.env.REACT_APP_ACCESS_TOKEN)
      ).post('/users/settings/updatePermissions', payload)
        .then(res => res.data)
        .then(data => {
          if (data.success) {
            delete payload.controller
            thunkAPI.dispatch(updateUserPermission({ ...payload, ...data }))
            return { ...payload, ...data }
          } else {
            throw new Error()
          }
        })
        .catch((error) => {
          if (error.response.data.tokenExpired) {
            thunkAPI.dispatch(
              fetchRefreshToken(() => {
                thunkAPI.dispatch(setPermissions(payload))
              })
            )
          }
          return error.response.data
        });
    } else {
      thunkAPI.dispatch(logout({controller: new AbortController()}));
    }
  }
)

const buildPermissions = (actionObject: any) => {
  const userArray: any = []
  actionObject.results.reduce((a: any, b: any) => {
    let currentUser = a.length > 0 && a.find((user: any) => user.UserID === b.UserID)
    if (currentUser) {
      currentUser.Clients = [
        {
          ClientAbbreviation: b.ClientAbbreviation,
          ClientID: b.ClientID,
          Features: b.Features,
          selected: false
        },
        ...currentUser.Clients
      ]
    } else {
      const newUser: any = {}
      newUser.UserID = b.UserID
      newUser.UserName = b.UserName
      newUser.UserInitials = b.UserInitials
      newUser.Clients = [{
        ClientAbbreviation: b.ClientAbbreviation,
        ClientID: b.ClientID,
        Features: b.Features,
        selected: false
      }]
      a.push(newUser)
    }
    return a
  }, userArray)
  let processedUserArray = userArray.map((user: any) => {
    const foundClient = user.Clients.find((client: any) => {
      return client.ClientID === actionObject.currentClient
    })
    if (foundClient) {
      foundClient.selected = true
    } else {
      user.Clients[0].selected = true
    }
    return user
  })
  return processedUserArray
}

export const permissionsSlice = createSlice({
  name: 'permission',
  initialState: initialState,
  reducers: {
    handlePermissionChange(state, action: PayloadAction<UpdatePermission>) {
      const userToUpdate = state.userPermissions.find(user => user.UserID === action.payload.AffectedUserID)
      if (userToUpdate) {
        const clientToUpdate = userToUpdate.Clients.find((client: any) => client.selected)
        if (clientToUpdate) {
          const permissionToChange = [...clientToUpdate.Features[action.payload.category]].find((permission: UserFeaturePermission) => {
            return permission.PermissionID === action.payload.PermissionID
          })
          if (permissionToChange) {
            permissionToChange.UserHasPermission = !permissionToChange.UserHasPermission
          }
        }
      }
      if (action.payload.addToTouched) {
        if (state.touchedPermissions.AffectedUserID === action.payload.AffectedUserID) {
          const existsInArray = state.touchedPermissions.PermissionIDs?.find(permission => permission.PermissionID === action.payload.PermissionID)
          if (!existsInArray) {
            state.touchedPermissions.PermissionIDs && state.touchedPermissions.PermissionIDs.push({ PermissionID: action.payload.PermissionID, Permitted: action.payload.Permitted })
          } else if (existsInArray) {
            state.touchedPermissions.PermissionIDs = state.touchedPermissions.PermissionIDs?.filter(permission => permission.PermissionID !== action.payload.PermissionID)
          }
        } else {
          state.touchedPermissions.AffectedUserID = action.payload.AffectedUserID
          state.touchedPermissions.ClientID = action.payload.ClientID
          state.touchedPermissions.ClientAbbreviation = action.payload.ClientAbbreviation
          state.touchedPermissions.PermissionIDs = [{ PermissionID: action.payload.PermissionID, Permitted: action.payload.Permitted }]
        }
      }
    },
    updateUsersActiveClient(state, action) {
      const foundUser = state.userPermissions.find((user: UserPermission) => {
        return user.UserID === action.payload.userID
      }
      )
      if (foundUser) {
        const foundClient = foundUser.Clients.find((client: any) => {
          return client.ClientID === action.payload.clientID
        })
        if (foundClient) {
          const prevClient = foundUser.Clients.find((client: any) => client.selected)
          if (prevClient) {
            prevClient.selected = false
          }
          foundClient.selected = true
        }
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getPermissions.pending, (state) => {
      state.fetchingUserPermissions = 'pending'
      state.error = ''
    })
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      if (action.payload && action.payload.success) {
        state.userPermissions = buildPermissions({results: action.payload.results, currentClient: action.payload.currentClient})
        state.fetchingUserPermissions = 'succeeded'
      } else {
        state.fetchingUserPermissions = 'rejected'
        state.error = action.payload.message
      }
    })
    builder.addCase(getPermissions.rejected, (state, action) => {
      state.fetchingUserPermissions = 'rejected'
      //@ts-ignore
      state.error = action.payload.message
    })
    builder.addCase(setPermissions.pending, (state) => {
      state.settingPermissions = 'pending'
      state.error = ''
    })
    builder.addCase(setPermissions.fulfilled, (state, action) => {
      if (action.payload.success) {
        const foundUser = state.userPermissions.find((userPermission: UserPermission) => {
          return userPermission.UserID === action.payload.AffectedUserID
        })
        if (foundUser) {
          const clientToUpdate = foundUser.Clients.find((client: ClientPermission) => {
            return client.ClientID === action.payload.ClientID
          })
          if (clientToUpdate) {
            [...action.payload.permitted, ...action.payload.unpermitted].forEach((permissionToUpdate: any) => {

              Object.entries(clientToUpdate?.Features).forEach(value => {
                [...value[1]].forEach(permission => {
                  if (permission.PermissionID === permissionToUpdate.PermissionID) {
                    permission.UserHasPermission = permissionToUpdate.Permitted
                  }
                })
              })
            })
          }
        }
      } else {
        state.settingPermissions = 'rejected'
        state.error = action.payload.message
      }
    })
    builder.addCase(setPermissions.rejected, (state, action) => {
      state.settingPermissions = 'rejected'
      //@ts-ignore
      state.error = action.payload.message
    })
  }
})
export const { handlePermissionChange, updateUsersActiveClient } = permissionsSlice.actions
export default permissionsSlice.reducer