import * as wjChart from '@grapecity/wijmo.react.chart'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import { DashCardHeader } from '../dashboardStyles'

const GCRChart = ({ gcr, formatYLabels, buildDataLabels, buildTooltip }) => {
  return (
    <>
      <DashCardHeader>
        <h4>Total GCR By Month</h4>
      </DashCardHeader>
      <wjChart.FlexChart itemsSource={gcr} bindingX="PeriodAbbreviation" rendered={e => e.hostElement.classList.add('gcr-chart')} plotMargin="20 40 70 60">
        <wjChart.FlexChartAxis itemFormatter={(engine, label) => formatYLabels(engine, label)} binding="GCR" wjProperty="axisY" />
        <wjChart.FlexChartAxis labelAngle={-45} wjProperty="axisX"></wjChart.FlexChartAxis>
        <wjChart.FlexChartSeries style={{ fill: 'var(--color-primary-purple)' }} binding="GCR" cssClass="bar percentage" tooltipContent={ht => buildTooltip(ht)} />
        <wjChart.FlexChartDataLabel position={4} cssClass="chart-label" content={ht => buildDataLabels(ht)} />
        <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </>
  )
}

export default GCRChart
