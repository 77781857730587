import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RequestStatus, SearchResult } from '../../../../type'
import { AppDispatch } from '../../../redux/store'
import { protectedApi } from '../../../utils/api'
import { mergeSort } from '../../../utils/helperMethods'
import { getCookie } from '../../../utils/security'
import { fetchRefreshToken, logout } from '../../login/userSlice'

type HistoricNameRequestRedux = {
  historicStatus: RequestStatus
  historicPatientID: string
}

interface SearchState {
  term: string
  searchCancelled: boolean
  searchSlice: number
  searchResults: SearchResult[]
  sortedResults: SearchResult[]
  searchSubmitted: RequestStatus
  resultsPerPage: number
  errors: string[]
  sortKey: 'lastName' | 'firstName' | 'middleName' | 'DateOfBirth' | 'apmId'
  sortUp: boolean
  page: number
  maxPage: number
  historicNameRequests: HistoricNameRequestRedux[]
}

const initialSearchState = {
  term: '',
  searchCancelled: false,
  searchSlice: 0,
  searchResults: [],
  sortedResults: [],
  searchSubmitted: 'idle',
  resultsPerPage: 10,
  errors: [],
  sortKey: 'lastName',
  sortUp: false,
  page: 0,
  maxPage: 1,
  historicNameRequests: [],
}

const initialState = initialSearchState as SearchState

export const submitSearch = createAsyncThunk<{ results: SearchResult[]; success: boolean }, { searchString: string; controller: AbortController }, { dispatch: AppDispatch }>(
  'search/submitSearch',
  async (payload, thunkAPI) => {
    const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN)
    if (token) {
      return protectedApi(payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        .post(`/rcm/patients/search`, { SearchString: payload.searchString })
        .then(resp => resp.data)
        .then(data => {
          if (data.success) {
            return data
          } else {
            throw new Error()
          }
        })
        .catch(error => {
          if (error.response.data.tokenExpired) {
            thunkAPI.dispatch(fetchRefreshToken(() => thunkAPI.dispatch(submitSearch(payload))))
          }
          return error.response.data
        })
    } else {
      thunkAPI.dispatch(
        logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' })
      )
    }
  }
)

export const getHistoricNames = createAsyncThunk<any, any, { dispatch: AppDispatch }>('search/getHistoricNames', async (payload, thunkAPI) => {
  const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN)
  if (token) {
    return protectedApi(payload.controller, getCookie(process.env.REACT_APP_ACCESS_TOKEN))
      .post('/rcm/patients/getHistoricNames', { PatientID: payload.PatientID })
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          return { ...data, PatientID: payload.PatientID }
        } else {
          throw new Error()
        }
      })
      .catch(error => {
        if (error.response.data.tokenExpired) {
          thunkAPI.dispatch(fetchRefreshToken(() => thunkAPI.dispatch(getHistoricNames(payload))))
        }
        return { ...error.response.data, PatientID: payload.PatientID }
      })
  } else {
    thunkAPI.dispatch(
      logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' })
    )
  }
})

const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    updateSearchTerm(state, action) {
      state.term = action.payload
    },
    resetSearchForm(state) {
      state.term = ''
      state.searchSubmitted = 'idle'
      state.historicNameRequests = []
    },
    setResultsPerPage(state, action) {
      state.page = 0
      state.searchSlice = 0
      state.resultsPerPage = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
      state.searchSlice = state.resultsPerPage * action.payload
    },
    setSortKey(state, action) {
      state.sortUp = false
      state.sortKey = action.payload
      const sorted = mergeSort(state.searchResults, state.sortKey, state.sortUp)
      state.sortedResults = sorted
    },
    setSortDirection(state, action) {
      state.sortUp = action.payload
      const sorted = mergeSort(state.searchResults, state.sortKey, state.sortUp)
      state.sortedResults = sorted
    },
    sortResults(state) {
      state.sortUp = false
      const sorted = mergeSort(state.searchResults, state.sortKey, state.sortUp)
      state.sortedResults = sorted
    },
    setErrors(state, action) {
      state.searchSubmitted = 'idle'
      state.errors.push(action.payload)
    },
    resetSearch(state) {
      Object.assign(state, initialSearchState)
    },
  },
  extraReducers: builder => {
    builder.addCase(submitSearch.pending, state => {
      state.searchSubmitted = 'pending'
      state.errors = []
    })
    builder.addCase(submitSearch.fulfilled, (state, action) => {
      if (action.payload.success) {
        if (!action.payload.results) {
          state.searchSubmitted = 'succeeded'
          state.searchResults = []
          state.sortedResults = []
          state.errors.push('Your search returned 0 results')
        } else {
          state.page = 0
          state.searchSlice = 0
          state.searchResults = action.payload.results.map((result: SearchResult) => {
            result.HistoricNames = []
            return result
          })
          state.sortedResults = action.payload.results.map((result: SearchResult) => {
            result.HistoricNames = []
            return result
          })
          state.searchSubmitted = 'succeeded'
        }
      } else {
        state.searchSubmitted = 'rejected'
        // @ts-ignore
        state.errors.push(action.payload.message)
      }
    })
    builder.addCase(submitSearch.rejected, (state, action) => {
      state.searchSubmitted = 'rejected'
      //@ts-ignore
      state.errors.push(action.payload.message)
    })
    builder.addCase(getHistoricNames.pending, (state, action) => {
      state.errors = []
      const found = state.historicNameRequests.find(request => request.historicPatientID === action.meta.arg.PatientID)
      if (found) {
        found.historicStatus = 'pending'
      } else {
        state.historicNameRequests = [
          ...state.historicNameRequests,
          {
            historicStatus: 'pending',
            historicPatientID: action.meta.arg.PatientID,
          },
        ]
      }
    })
    builder.addCase(getHistoricNames.fulfilled, (state, action) => {
      if (action.payload.success) {
        const foundPatient = state.sortedResults.findIndex((patient: SearchResult) => patient.id === action.payload.PatientID)
        state.sortedResults[foundPatient].HistoricNames = action.payload.results
        const found = state.historicNameRequests.find(request => request.historicPatientID === action.meta.arg.PatientID)
        if (found) {
          found.historicStatus = 'succeeded'
        }
      } else {
        const foundPatient = state.sortedResults.findIndex((patient: SearchResult) => patient.id === action.payload.PatientID)
        state.sortedResults[foundPatient].HistoricNames = [{ LastName: 'ERROR', FirstName: 'ERROR' }]
        state.errors.push(action.payload.message)
        const found = state.historicNameRequests.find(request => request.historicPatientID === action.meta.arg.PatientID)
        if (found) {
          found.historicStatus = 'rejected'
        }
      }
    })
  },
})

export const { resetSearch, updateSearchTerm, resetSearchForm, setResultsPerPage, setPage, setSortKey, setSortDirection, sortResults, setErrors } = searchSlice.actions
export default searchSlice.reducer
