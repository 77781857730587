import { useState, useEffect, useCallback, useRef } from "react";
import LoginTip from "../login/firstTimeLogin/LoginTip";

// Styles
import { ErrorMessage } from "../../components/modals/modalStyles";
import { ErrorWrapper, SectionTitle } from "../settings/settingsStyles";
import { SuccessMessage } from "./passwordStyles";
import { LoginButton, LoginField, LoginForm, LoginInput, LoginTipH3 } from "../../components/login/loginStyles";
import { WizardTip } from "../../styles/appStyles";
import KeyIcon from '@mui/icons-material/Key';

// Redux
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  resetState,
  submitNewPassword,
  resetPasswordChanges,
  fpSubmitPassword,
  submitSettingsFormPassword
} from "./passwordResetSlice";
import RequestStatusLoader from "../misc/RequestStatusLoader";

const ResetPassword = ({process}) => {
  
  const dispatch = useAppDispatch();
  const focusRef = useRef();
  const settings = process === 'settings'

  const [passwordIsValid, validatePassword] = useState(null);
  const [passwordIsMatch, checkPasswords] = useState(null);
  const [passwordIsNotUsername, checkPasswordForUsername] = useState(null);
  const [passwordTrimCheck, checkPasswordForSpaces] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const mobile = useAppSelector(state => state.nav.mobile)
  const criticalError = useAppSelector(state => state.passwordReset.criticalError)
  const error = useAppSelector(state => state.passwordReset.error); 
  const mFAModalVisible = useAppSelector(state => state.mFA.modalVisible)
  const passwordChangesLoaded = useAppSelector(state => state.passwordReset.passwordChangesLoaded)
  const forgotPassword = useAppSelector(state => state.nav.forgotPassword)
  const daysToPasswordExpiration = useAppSelector(state => window.location.pathname.includes('login') ? state.login.daysToPasswordExpiration : state.users.DaysToPasswordExpiration)
  const fpUsername = useAppSelector(state => state.passwordReset.username) // username for forgot password flow
  const loginUsername = useAppSelector(state => state.login.username)// username for prelogin flow
  const settingsUsername = useAppSelector(state => state.users.UserEmail)// username for settings menu

  useEffect(() => {
    if (!mFAModalVisible) {
      focusRef.current.focus()
    }
  }, [])

  /* HANDLING INPUT CHANGE*/
  const handlePasswordChange = (e) => {
    // let entry = e.target.value.trim()
    let entry = e.target.value;
    if (e.target.name === "password") {
      setPassword(entry);
    } else {
      setPasswordRepeat(entry);
    }
  };

  /* VALIDATING PASSWORD*/
  const checkAgainstUsername = () => {
    let username;

    if (process === 'forgotPW') { // 
      username = fpUsername;
    } if (process === 'login') {
      username = loginUsername;
    } else if (settings) {
      username = settingsUsername
    }

    return password !== username;
  }

  const validatePasswordEntered = () => {
    const passesValidatePassword = (
      password.length >= 14 && password.length < 151 &&
      passwordRepeat.length >= 14 && passwordRepeat.length < 151
    )
    const passesCheckPassword = (password === passwordRepeat)

    const checkForUsername = checkAgainstUsername()

    const passwordHasSpaces = (password.trim() !== password)
    const passwordRepeatHasSpaces = (passwordRepeat.trim() !== passwordRepeat)
    const checkForSpaces = (!passwordHasSpaces && !passwordRepeatHasSpaces)

    validatePassword(passesValidatePassword)
    checkPasswords(passesCheckPassword)
    checkPasswordForUsername(checkForUsername)
    checkPasswordForSpaces(checkForSpaces)

    return passesValidatePassword && passesCheckPassword && checkForUsername && checkForSpaces;
  };

  useEffect(() => {
    if (error) {
      setPassword('')
      setPasswordRepeat('')
    }
  }, [error])

 /* SUBMITTING PASSWORD */
  const sendSubmitPassword = useCallback(
    (payload) => {
      dispatch(
        submitNewPassword({ ...payload, controller: new AbortController() })
      );
    },
    [dispatch]
  );

  const callSubmitPassword = (e) => {
    e.preventDefault();
    e.target.blur();

    if (validatePasswordEntered()) {
      if (forgotPassword) {
        dispatch(fpSubmitPassword({ Password: password, controller: new AbortController() }));
      } else if (settings) {
        dispatch(submitSettingsFormPassword({ Password: password, controller: new AbortController() }))
      } else {
        sendSubmitPassword({ Password: password});
      }
    } else {
      setPassword('')
      setPasswordRepeat('')
    }
  };

  // For settingsMenu as component will still be mounted
  useEffect(() => {
    if (passwordChangesLoaded === 'succeeded') {
      setPassword('')
      setPasswordRepeat('')
    }
  }, [passwordChangesLoaded])

  /* HANDLING UNMOUNT */
    // Resetting redux on unmount
    const callResetState = useCallback(() => {
      dispatch(resetState());
    }, [dispatch]);
  
    useEffect(() => {
      return () => {
        callResetState();
      };
    }, []); //eslint-disable-line
  
  /* RENDER FUNCTIONS */
  const passwordCriteriaTip = {
    prerequirements: "Your password should be:",
    requirements: [
      "At least 14 characters long",
      "Not a password you've used before",
      "Cannot be your username"
    ]
  }

  return (
    <LoginForm
      autoComplete="off"
      type="password"
      id="reset-password-form"
      name="password"
      onSubmit={(e) => callSubmitPassword(e)}
      style={{overflowY: 'auto'}}
    >
      {/* SUCCESS MESSAGE */}
      {settings || passwordChangesLoaded !== 'succeeded' ? null : (
        <SuccessMessage style={{minHeight: '60px'}} className="reset-password">
          Password update complete! {forgotPassword ? 'You will be redirected to login...' : 'Logging in...'}
        </SuccessMessage>
      )}
      {/* SECTION TITLE */}
      <SectionTitle mobile={mobile} settings={settings}>
        {forgotPassword || settings? "Update Password" : "Your password must be updated. Please enter a new password below."}
      </SectionTitle>
      {/* DAYS TO PASSWORD EXPIRATION */}
      {settings && daysToPasswordExpiration && daysToPasswordExpiration <= 10 ? (
        <WizardTip settings={settings} style={{ padding: 0, margin: '10px 0' }}>
          <LoginTipH3 style={{margin: '5px'}}>Your password expires in {daysToPasswordExpiration} day{daysToPasswordExpiration === 1 ? '' : 's'}</LoginTipH3>
        </WizardTip>
      ) : null}
      {/* ERROR HANDLING */}
      {(!(passwordIsValid === null || passwordIsValid) ||
        !(passwordIsMatch === null || passwordIsMatch) ||
        !(passwordIsNotUsername === null || passwordIsNotUsername) ||
        !(passwordTrimCheck === null || passwordTrimCheck) ||
        error
      ) ? (
        <ErrorWrapper style={{ marginBottom: '10px' }} data-testid="password-error">
          {passwordIsValid === null || passwordIsValid ? null : (
            <ErrorMessage>
              Password must be at least 14 characters long.
            </ErrorMessage>
          )}
          {passwordIsMatch === null || passwordIsMatch ? null : (
            <ErrorMessage>Passwords must match.</ErrorMessage>
          )}
          {passwordIsNotUsername === null || passwordIsNotUsername ? null : (
            <ErrorMessage>Password cannot be your username.</ErrorMessage>
          )}
          {passwordTrimCheck === null || passwordTrimCheck ? null : (
            <ErrorMessage>Password cannot start or end with spaces.</ErrorMessage>
          )}
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        </ErrorWrapper>
      ) : null}
      {/* LOGIN FIELDS AND INPUTS */}
      <LoginField mobile={mobile} settings={settings}>
        {settings ? null : <KeyIcon sx={{ color: '#373d4d', margin: '0 10px' }} />}
        <LoginInput
          autoComplete="off"
          type="password"
          id="reset-password"
          name="password"
          placeholder="New password:"
          value={password}
          onChange={handlePasswordChange}
          ref={focusRef}
          data-testid="password"
        />
      </LoginField>
      <LoginField mobile={mobile} settings={settings}>
        {settings ? null : <KeyIcon sx={{ color: '#373d4d', margin: '0 10px' }} />}
        <LoginInput
          autoComplete="off"
          type="password"
          id="reset-password-repeat"
          name="passwordRepeat"
          placeholder="Confirm new password:"
          value={passwordRepeat}
          onChange={handlePasswordChange}
          data-testid="repeat-password"
        />
      </LoginField>
      {/* WIZARD TIP */}
      <WizardTip mobile={mobile} settings={settings}>
        <LoginTip tip={passwordCriteriaTip} />
      </WizardTip>
      {/* REQUEST STATUS LOADER AND SUBMIT BUTTON */}
      {criticalError ? null : (
        passwordChangesLoaded === 'pending' || passwordChangesLoaded === 'succeeded' ? 
          <RequestStatusLoader
            requestStatus={passwordChangesLoaded}
            resetFunction={() => dispatch(resetPasswordChanges({controller: new AbortController()}))}
          />
          :
          <LoginButton mobile={mobile} type="submit" data-testid="password-submit" login={!settings}>SUBMIT</LoginButton>
      )}
    </LoginForm>
  )
};

export default ResetPassword;