import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DotLoader from '../misc/dotLoader'
import { setTab } from '../nav/navSlice'
import { getPatientClaims, getPatientDemographics, getPatientFinances } from './patientSlice'
import { FlexWrapper } from '../../styles/viewerStyles'
import { useAppDispatch, useAppSelector } from '../../redux/store'

type Props = {
  patientDemographicsAllowed: boolean
  patientClaimsAllowed: boolean
  patientARAllowed: boolean
}

const PatientInfoLoader = ({ patientDemographicsAllowed, patientClaimsAllowed, patientARAllowed }: Props) => {
  const dispatch = useAppDispatch()

  const fetchingUser = useAppSelector(state => state.users.fetchingUser)
  const mobile = useAppSelector(state => state.nav.mobile)

  const { patientId } = useParams()

  useEffect(() => {
    dispatch(setTab('patientManagement'))
    return () => {}
  }, [dispatch])

  const getPatientInfo = (payload: { PatientID: number }) => {
    dispatch(getPatientDemographics({ PatientID: payload.PatientID, controller: new AbortController() }))
  }

  const getAccountsReceivable = (payload: { PatientID: number }) => {
    dispatch(getPatientFinances({ ...payload, controller: new AbortController() }))
  }

  const getClaims = (payload: { PatientID: number }) => {
    dispatch(getPatientClaims({ ...payload, controller: new AbortController() }))
  }

  useEffect(() => {
    if (!!patientId && fetchingUser === 'succeeded') {
      if (patientDemographicsAllowed) {
        getPatientInfo({ PatientID: parseInt(patientId) })
      }
      if (patientClaimsAllowed) {
        getClaims({ PatientID: parseInt(patientId) })
      }
      if (patientARAllowed) {
        getAccountsReceivable({ PatientID: parseInt(patientId) })
      }
    }
    return () => {}
  }, [!!patientId, fetchingUser, dispatch])

  return (
    <FlexWrapper>
      <DotLoader isLoading={true} width={mobile ? '50%' : '20%'} height="100%" dotSize="30px" />
    </FlexWrapper>
  )
}

export default PatientInfoLoader
