import React, { useState, useEffect, useRef, useCallback } from 'react'
import DotLoader from '../misc/dotLoader'
import { findPermission } from '../../utils/helperMethods'
import { useOutsideClick } from '../../utils/hooks'

// Styles
import { ModalContainer, ModalForm, ModalFormLabel, ModalActionBar, ModalBackground, ModalTitle, ErrorMessage } from '../../components/modals/modalStyles'
import { Statement, SupportArea, SupportDiv, SupportInput, SupportModalContent, SupportOption, SupportSelect } from './supportStyles'
import { PhyInputText, PhyInputTextArea } from '../../styles/formStyles'
import { UnstyledButton } from '../../styles/buttonStyles'
import { FlexWrapper } from '../../styles/viewerStyles'

// Redux
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { getCategories, resetSupport, sendSupportRequest, setModal } from '../../components/modals/modalSlice'
import { ErrorWrapper } from '../settings/settingsStyles'
import { Close } from '@mui/icons-material'
import { LoginButton } from '../login/loginStyles'

const SupportModal = () => {
  const [summary, updateSummary] = useState('')
  const [feature, updateFeature] = useState('0')
  const [description, updateDescription] = useState('')
  const [validated, toggleValidation] = useState(false)
  const [hasPermission, updateHasPermission] = useState(false)

  const modalRef = useRef()

  const dispatch = useAppDispatch()

  const submitted = useAppSelector(state => state.modals.ticketSubmitting)
  const loggedIn = useAppSelector(state => state.login.isLoggedIn)
  const supportModal = useAppSelector(state => state.modals.support)
  const supportTicketCategories = useAppSelector(state => state.modals.supportTicketCategories)
  const categoriesLoading = useAppSelector(state => state.modals.categoriesLoading)
  const errors = useAppSelector(state => state.modals.supportErrors)
  const HelpdeskPermissions = useAppSelector(state => state.users.UserPermissions.Helpdesk)
  const mobile = useAppSelector(state => state.nav.mobile)

  useOutsideClick(modalRef, () => toggleModal(false))

  // Redux callbacks
  const toggleModal = useCallback(
    payload => {
      dispatch(setModal({ key: 'support', data: payload }))
    },
    [dispatch]
  )

  const submitSupport = useCallback(
    payload => {
      dispatch(sendSupportRequest({ ...payload, controller: new AbortController() }))
    },
    [dispatch]
  )

  const reset = useCallback(() => {
    dispatch(resetSupport())
  }, [dispatch])

  // useEffects
  useEffect(() => {
    updateHasPermission(findPermission('5', HelpdeskPermissions))
  }, [HelpdeskPermissions])

  useEffect(() => {
    if (loggedIn && supportModal && hasPermission && supportTicketCategories.length === 0 && categoriesLoading === 'idle') {
      dispatch(getCategories({ controller: new AbortController() }))
    }
  }, [loggedIn, supportModal, dispatch])

  useEffect(() => {
    let valid = true
    if (!summary) valid = false
    if (feature === '0') valid = false
    if (!description) valid = false
    toggleValidation(valid)
  }, [summary, feature, description])

  useEffect(() => {
    if (!supportModal) {
      updateSummary('')
      updateFeature('0')
      updateDescription('')
      toggleValidation(false)
      reset()
    }
  }, [supportModal]) //eslint-disable-line

  // Main functions
  // TODO: loading state, success and failure messages
  const handleSummaryChange = e => {
    updateSummary(e.target.value)
  }

  const handleDescriptionChange = e => {
    updateDescription(e.target.value)
  }

  const handleFeatureChange = e => {
    updateFeature(e.target.value)
  }

  const handleSubmitSupport = e => {
    e.preventDefault()
    e.target.blur()
    const payload = {
      TicketCategoryID: feature,
      TicketSummary: summary,
      TicketDescription: description,
      URL: window.location.href,
      BrowserName: sessionStorage.getItem('userBrowser'),
      BrowserVersion: sessionStorage.getItem('browserVersion'),
      AttachmentName: '', // (not being utilized presently)
      AttachmentData: null, // (not being utilized presently)
    }
    if (validated) {
      submitSupport(payload)
    }
  }

  const renderErrors = () => {
    return (
      <ErrorWrapper>
        <ErrorMessage>
          An error has occurred. Please retry submitting your ticket or call our help desk at{' '}
          <a type="tel" href="tel:+18778462953">
            (877) 846 - 2953
          </a>
          .
        </ErrorMessage>
      </ErrorWrapper>
    )
  }

  const buildForm = () => {
    return (
      <ModalForm autoComplete="off" onSubmit={e => handleSubmitSupport(e)}>
        <ModalFormLabel htmlFor="summary">
          Summary <span style={{ color: '#999' }}>*</span>
        </ModalFormLabel>
        <SupportInput style={{ width: '100%' }}>
          <PhyInputText type="text" id="summary" name="summary" value={summary} onChange={handleSummaryChange} />
        </SupportInput>
        <ModalFormLabel htmlFor="feature">
          Feature <span style={{ color: '#999' }}>*</span>
        </ModalFormLabel>
        <SupportInput style={{ width: '100%' }}>
          <SupportSelect value={feature} type="select" id="feature" name="feature" onChange={handleFeatureChange}>
            <SupportOption value="0">Select a Feature...</SupportOption>
            {supportTicketCategories.map(category => {
              return <SupportOption value={`${category.CategoryID}`} key={`${category.CategoryID}`}>{`${category.CategoryName}`}</SupportOption>
            })}
          </SupportSelect>
        </SupportInput>
        <ModalFormLabel htmlFor="description">
          Description <span style={{ color: '#999' }}>*</span>
        </ModalFormLabel>
        <SupportArea>
          <PhyInputTextArea type="textarea" id="description" name="description" value={description} onChange={e => handleDescriptionChange(e)} />
        </SupportArea>
        <ModalActionBar>
          <LoginButton type="submit" style={{ textAlign: 'center', marginBottom: '10px' }} disabled={!validated}>
            Submit
          </LoginButton>
        </ModalActionBar>
      </ModalForm>
    )
  }

  const renderContent = () => {
    if (submitted === 'pending') {
      return (
        <>
          {errors ? renderErrors() : null}
          <ModalTitle>Submitting your help desk ticket...</ModalTitle>
          <FlexWrapper style={{ minHeight: '300px', justifyContent: 'center' }}>
            <DotLoader isLoading={true} width={mobile ? '80%' : '50%'} height="100%" dotSize="35px" />
          </FlexWrapper>
        </>
      )
    } else if (submitted === 'succeeded') {
      return errors ? (
        renderErrors()
      ) : (
        <SupportDiv>
          <ModalTitle>Your help desk ticket has been submitted. Please check your email for updates.</ModalTitle>
          <LoginButton onClick={() => toggleModal(false)} style={{ textAlign: 'center', marginBottom: '10px' }}>
            OK
          </LoginButton>
        </SupportDiv>
      )
    } else {
      return (
        <SupportDiv>
          {errors ? renderErrors() : null}
          <ModalTitle>We're here to help. Let us know what's going on!</ModalTitle>
          {hasPermission ? <Statement>Please fill out the form below to submit a helpdesk ticket.</Statement> : null}
          <Statement>
            {hasPermission ? ' For urgent issues, please ' : 'Please '}
            call the Support Desk at{' '}
            <a type="tel" href="tel:+18778462953">
              (877) 846 - 2953
            </a>
            .
          </Statement>
          {hasPermission ? buildForm() : null}
        </SupportDiv>
      )
    }
  }

  return (
    <>
      {supportModal ? (
        <ModalBackground>
          <ModalContainer ref={modalRef}>
            <SupportModalContent>
              <UnstyledButton
                onClick={() => toggleModal(false)}
                style={{
                  margin: '15px 0px',
                  cursor: 'pointer',
                  alignSelf: 'flex-end',
                }}
              >
                <Close fontSize="large" onClick={() => toggleModal(!supportModal)} />
              </UnstyledButton>
              {renderContent()}
            </SupportModalContent>
          </ModalContainer>
        </ModalBackground>
      ) : null}
    </>
  )
}

export default SupportModal
