import React, { useCallback, useState, useRef, useEffect } from 'react'

// Styling
import { LoginForm, LoginButton, LoginField, LoginInput, LoginOptionContainer, LoginOption, LoginSelect, LoginInputLabel } from '../../login/loginStyles'
import { ErrorWrapper, SectionTitle } from '../settingsStyles'
import { ErrorMessage } from '../../modals/modalStyles'
import { FlexWrapper } from '../../../styles/viewerStyles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Redux
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { getMultiFactorPreferenceTypes, updateMultiFactorPreference, updatePhoneNumber, updateSettingsStatus } from '../settingsSlice'
import RequestStatusLoader from '../../misc/RequestStatusLoader'

const UpdateMFAForm = () => {

  const [phoneInput, updatePhoneInput] = useState('')
  const [phoneError, togglePhoneError] = useState(false)
  const [emailClicked, toggleEmailClicked] = useState(false)
  const [showOptions, toggleOptions] = useState(false)
  const [preferredOptionName, updatePreferredName] = useState(null)

  const mFAModalVisible = useAppSelector(state => state.mFA.modalVisible)
  const mobile = useAppSelector(state => state.nav.mobile)
  const getMFAPreferenceStatus = useAppSelector(state => state.settings.getMultiFactorPreferenceStatus)
  const multiFactorPreferences = useAppSelector(state => state.settings.multiFactorPreferences)
  const updatePhoneNumStatus = useAppSelector(state => state.settings.checkPhoneNumberStatus)
  const updateMFAPreferenceStatus = useAppSelector(state => state.settings.updateMultiFactorPreference)
  const errors = useAppSelector(state => state.settings.errors)
  const currentPhoneNum = useAppSelector(state => (state.settings.phoneNumber) ? state.settings.phoneNumber : '')
  const currentPhoneSMSCapable = useAppSelector(state => state.settings.SMScapable)
  const email = useAppSelector(state => state.settings.email)

  const dispatch = useAppDispatch()

  /* INITIAL REQUESTS */
  useEffect(() => {
    if (getMFAPreferenceStatus === 'idle') {
      dispatch(getMultiFactorPreferenceTypes({controller: new AbortController()}))
    }
  }, [])

  useEffect(() => {
    if (multiFactorPreferences.length > 0) {
      let preferred = multiFactorPreferences.find(pref => pref.isSelected)
      updatePreferredName(preferred.PreferenceTypeDescription)
      updatePhoneInput(currentPhoneNum)
      dispatch(updateSettingsStatus({request: 'getMFAPref', status: 'idle'}))
    }
  }, [multiFactorPreferences])

  /* PHONE NUMBER FUNCTIONS */
  const handleChange = (e) => {
    let regex = /[\D\s]/g
    let cleaned = (e.target.value).replace(regex, '')
    updatePhoneInput(cleaned)
  }
  
  const formatTelNum = (val) => {
    let regex = /[\D\s]/g
    let cleaned = (val).replace(regex, '')
    let match = cleaned.match(/(?<area>\d{1,3})?(?<exchange>\d{1,3})?(?<line>\d{1,4})?/)
    let returnVal

    if (match) {
      if (match.groups.line) {
        returnVal = `(${match.groups.area}) ${match.groups.exchange}-${match.groups.line}`
      } else if (match.groups.exchange) {
        returnVal = `(${match.groups.area}) ${match.groups.exchange}`
      } else if (match.groups.area) {
        returnVal = `${match.groups.area}`
      } else {
        returnVal = ''
      }
    }
    return returnVal
  }

  const submitPhoneNumber = useCallback(
    (payload) => {
      dispatch(updatePhoneNumber({ PhoneNumber: payload, controller: new AbortController() })) 
    }
  )
  const handleUpdatePhoneNum = (e) => {
    e.preventDefault()
    togglePhoneError(false)
    if (phoneInput.length >= 5) {
      submitPhoneNumber(phoneInput)
    } else {
      togglePhoneError(true)
    }
  }

  /* MFA PREFERENCE FUNCTIONS */
  const handleMFAMethodChange = (e, newID, newMethodName) => {
    e.preventDefault()
    toggleOptions(false)
    updatePreferredName(newMethodName)
    submitMFAPreference(newID)
  }

  const submitMFAPreference = useCallback(
    (payload) => {
      dispatch(updateMultiFactorPreference({MfaPreferenceID: payload, controller: new AbortController()}))
    }, [dispatch]);

  useEffect(() => {
    if (updateMFAPreferenceStatus === 'succeeded' && getMFAPreferenceStatus === 'idle') {
      dispatch(getMultiFactorPreferenceTypes({controller: new AbortController()}))
    }
  }, [updateMFAPreferenceStatus])

  /* RENDERING FUNCTIONS */
  const renderUpdatePhoneStatus = () => {
    if (updatePhoneNumStatus === 'pending' || updatePhoneNumStatus === 'succeeded') {
      return (
        <RequestStatusLoader
          requestStatus={updatePhoneNumStatus}
          resetFunction={dispatch(updateSettingsStatus({ request: 'updatePhone', status: 'idle' }))}
        />
      )
    } else {
      if (phoneInput === currentPhoneNum) {
        return null
      } else {
        return (
          <LoginButton
            style={{ marginTop: '10px' }}
            onClick={e => handleUpdatePhoneNum(e)}
            data-testid="save-new-phone-button"
            mobile={mobile}
          >
            SAVE
          </LoginButton>
        )
      }
    }
  }

  const renderMFAPrefStatus = () => {
    if (updateMFAPreferenceStatus === 'pending' || updateMFAPreferenceStatus === 'succeeded') {
      return (
        <RequestStatusLoader
          requestStatus={updateMFAPreferenceStatus}
          resetFunction={() => dispatch(updateSettingsStatus({ request: 'updateMFAPref', status: 'idle' }))}
        />
      )
    } else {
      return null
    }
  }

  const focusRef = useRef()

  useEffect(() => {
    if (!mFAModalVisible) {
      focusRef.current.focus()
    }
  }, [])

  return (
    <LoginForm style={{height: '70%', justifyContent: 'flex-start'}}>
      <SectionTitle settings style={{marginBottom: '40px'}}>Multifactor Authentication Settings</SectionTitle>
      <FlexWrapper height="80%" flexFlow="column" align="center">
        {/* Error Handling */}
        {!currentPhoneSMSCapable ? (
          <ErrorWrapper style={{ marginBottom: '10px', minWidth: 'auto', width: '50%', maxWidth: '500px' }} data-testid="MFA-preference-smsCapable-error">
            <ErrorMessage>The phone number associated with your account was determined by our system as incapable of receiving text messages.</ErrorMessage>
            <ErrorMessage>To set your preference to mobile authentication, please provide a mobile number.</ErrorMessage>
          </ErrorWrapper>
        ) : null}
        {phoneError || errors.length > 0 ? (
          <ErrorWrapper style={{ marginBottom: '10px', minWidth: 'auto', width: '50%', maxWidth: '500px' }} data-testid="MFA-preference-validation-error">
            {errors.length > 0 ? errors.map((error, i) => <ErrorMessage key={i}>{error}</ErrorMessage>) : null}
            {phoneError ? <ErrorMessage>Please enter a phone number containing at least five digits.</ErrorMessage> : null}
          </ErrorWrapper>
        ) : null}
        {/* Phone Number */}
        <FlexWrapper flexFlow="column" align="center" justify="flex-start">
          <LoginField mobile={mobile}>
            <LoginInputLabel settings>Phone Number:</LoginInputLabel>
            <LoginInput
              autoComplete="off"
              value={phoneInput ? formatTelNum(phoneInput) : ''}
              onChange={e => handleChange(e)}
              placeholder={formatTelNum(currentPhoneNum)}
              optionalData
              ref={focusRef}
              data-testid="MFA-phone-number"
            />
          </LoginField>
          {renderUpdatePhoneStatus()}
        </FlexWrapper>
        {/* Email Address */}
        <FlexWrapper flexFlow="column" align="center" justify="flex-start" data-testid="email-address-container">
          <LoginField
            mobile={mobile}
            onClick={e => toggleEmailClicked(!emailClicked)}
            data-testid="email-address-field"
          >
            <LoginInputLabel settings>Email Address:</LoginInputLabel>  
            <LoginInput
              autoComplete="off"
              disabled
              value={email}
              optionalData
            />
          </LoginField>
          {emailClicked ? <ErrorMessage style={{width: '40%'}} data-testid="email-error">This cannot be updated. Please contact support.</ErrorMessage> : null}
        </FlexWrapper>
        {/* Preferred Method */}
        <FlexWrapper flexFlow="column" align="center" justify="flex-start">
          <LoginField
            mobile={mobile}
            onClick={e => toggleOptions(!showOptions)}
            data-testid="preferred-method-select"
          >
            <LoginInputLabel settings>Preferred Method:</LoginInputLabel>  
            <LoginSelect settings>
              {preferredOptionName ? preferredOptionName : 'Please Select'}
              <ExpandMoreIcon style={{marginRight: '15px'}} />
            </LoginSelect>
          </LoginField>
          {showOptions && multiFactorPreferences.length > 0 ? (
            <LoginOptionContainer appear={showOptions} settings data-testid="mfa-option-container">
              {multiFactorPreferences.map(pref =>
                <LoginOption
                  settings
                  isSelected={pref.isSelected}
                  onClick={e => handleMFAMethodChange(e, pref.PreferenceTypeID, pref.PreferenceTypeDescription)}
                  data-testid={pref.PreferenceTypeDescription + '-option'}
                  key={pref.PreferenceTypeID}
                >
                  {pref.PreferenceTypeDescription}
                </LoginOption>)}
            </LoginOptionContainer>
          ) : null}
          {renderMFAPrefStatus()}
        </FlexWrapper>
      </FlexWrapper>
    </LoginForm>
  )
}

export default UpdateMFAForm
