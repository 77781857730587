import { useState, useEffect, useCallback, useRef } from "react";
import DotLoader from "../../misc/dotLoader";
import { useOutsideClick } from '../../../utils/hooks'

// Date Picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs'

// Styles
import { ErrorMessage } from "../../modals/modalStyles";
import { ErrorWrapper, SectionTitle } from "../settingsStyles";
import {
  LoginButton,
  LoginField,
  LoginForm,
  LoginInput,
  LoginSelect,
  LoginOption,
  LoginOptionContainer,
  DatePickerField,
  DatePickerInput,
  LoginInputLabel
} from "../../login/loginStyles";

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Redux
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { updateProfileData, getHonorifics, getOptionalData } from "../../login/loginSlice";
import { getSettingsHonorifics, getSettingsOptionalData, resetRequestStatus, saveSettingsOptionalData } from "../settingsSlice"
import { FlexWrapper } from "../../../styles/viewerStyles";
import { createTheme, ThemeProvider } from "@mui/material";
import { useTheme } from "styled-components";


const OptionalProfile = ({ settings }) => {

  dayjs().format()

  const dispatch = useAppDispatch()

  const InputsRef = useRef([])
  const honorificRef = useRef()

  const updateProfileStatus = useAppSelector(state => state.login.updateProfileStatus)
  const mobile = useAppSelector(state => state.nav.mobile)
  const criticalError = useAppSelector(state => state.login.criticalError)
  const loginErrors = useAppSelector(state => state.login.errors)
  const settingsErrors = useAppSelector(state => state.settings.errors)

  const honorifics = useAppSelector(state => state.login.honorifics)
  const getHonorificsStatus = useAppSelector(state => state.login.getHonorificsStatus)
  const optionalData = useAppSelector(state => state.login.optionalData)
  const getOptionalDataStatus = useAppSelector(state => state.login.getOptionalDataStatus)
  const loginTask = useAppSelector(state => state.login.loginTask)

  const settingsHonorifics = useAppSelector(state => state.settings.settingsHonorifics)
  const getSettingsHonorificsStatus = useAppSelector(state => state.settings.getSettingsHonorificsStatus)
  const optionalUserData = useAppSelector(state => state.settings.optionalUserData)
  const getSettingsOptionalDataStatus = useAppSelector(state => state.settings.getSettingsOptionalDataStatus)
  const fetchUserInfoStatus = useAppSelector(state => state.users.requests.fetchUserInfo)

  const [dob, updateDOB] = useState(settings ? optionalUserData.DateOfBirth : optionalData.DateOfBirth)
  const [name, updateName] = useState(settings ? optionalUserData.PreferredName : optionalData.PreferredName)
  const [pronouns, updatePronouns] = useState(settings ? optionalUserData.Pronoun : optionalData.Pronoun)
  const [selectedHonorificID, updateSelectedHonorificID] = useState(settings ? optionalUserData.HonorificID : optionalData.HonorificID)
  const [jobTitle, updateJobTitle] = useState(settings ? optionalUserData.JobTitle : optionalData.JobTitle)

  const [showHonorifics, toggleShowHonorifics] = useState(false)
  const [showSettingsHonorifics, toggleShowSettingsHonorifics] = useState(false)
  const [dateError, toggleDateError] = useState(false)
  const [nameError, toggleNameError] = useState(false)

  // Initial fetch requests
  const fetchHonorifics = useCallback(
    () => {
      dispatch(getHonorifics({controller: new AbortController()})) // add redux function here
    }
  )

  const fetchSettingsHonorifics = useCallback(
    () => {
      dispatch(getSettingsHonorifics({ controller: new AbortController() })) // add redux function here
    }
  )

  const fetchOptionalData = useCallback(
    () => {
      dispatch(getOptionalData({controller: new AbortController()})) // add redux function here
    }
  )

  const fetchSettingsOptionalData = useCallback(
    () => {
      dispatch(getSettingsOptionalData({controller: new AbortController()})) // add redux function here
    }
  )

  useEffect(() => {
    if (settings) {
      if (getSettingsHonorificsStatus === 'idle') { fetchSettingsHonorifics() }
      if (getSettingsOptionalDataStatus === 'idle') { fetchSettingsOptionalData() }
    } else {
      if (getHonorificsStatus === 'idle') { fetchHonorifics() }
      if (getOptionalDataStatus === 'idle') { fetchOptionalData() }
    }
  }, [])

  // Updating state with returned info
  useEffect(() => {
    updateDOB(optionalData.DateOfBirth)
    updateName(optionalData.PreferredName)
    updatePronouns(optionalData.Pronoun)
    updateSelectedHonorificID(optionalData.HonorificID ? optionalData.HonorificID : 'Please Select')
    updateJobTitle(optionalData.JobTitle)
    dispatch(resetRequestStatus('getOptionalData'))
  }, [optionalData, dispatch])

  // Updating state with returned info for the settings menu
  useEffect(() => {
    updateDOB(optionalUserData.DateOfBirth)
    updateName(optionalUserData.PreferredName)
    updatePronouns(optionalUserData.Pronoun)
    updateSelectedHonorificID(optionalUserData.HonorificID ? optionalUserData.HonorificID : 'Please Select')
    updateJobTitle(optionalUserData.JobTitle)
  }, [optionalUserData])

  // Outside click for honorifics
  useOutsideClick(honorificRef, () => {
    if (honorifics || settingsHonorifics) {
      toggleShowHonorifics(false)
      toggleShowSettingsHonorifics(false)
    }
  })

  //Handling submit
  const updateProfile = useCallback(
    (payload) => {
      if (!settings && loginTask !== null) {
        dispatch(updateProfileData({ ...payload, controller: new AbortController() }))
      } else {
        dispatch(saveSettingsOptionalData({ ...payload, controller: new AbortController() }))
      }
    },
    [dispatch],
  )

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.blur();
    toggleDateError(false)
    toggleNameError(false)

    // Date validation
    const minDate = dayjs().subtract(100, 'year')
    const maxDate = dayjs().subtract(18, 'year')

    let dobValid = dob ?
      dob.isAfter(minDate) && dob.isBefore(maxDate)
      :
      true; // defaulting to true in case user leaves input blank

    // Preferred name validation
    let nameValid = true; // defaulting to true in case user leaves input blank
    if (name && name.trim()) {
      const regex = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ\s\-']+$/) // allows letters (incl. accents), spaces, dashes, and apostrophes
      const regexName = name.trim().match(regex)
  
      if (regexName) {
        // If the user inputted a prohibited character, nameValid will be set to false
        nameValid = name.trim() === regexName[0]
      } else {
        toggleNameError(true)
        return
      }
    }

    if (dobValid && nameValid) {
      updateProfile({
        DateOfBirth: dob === null ? null : dob.format('YYYY/MM/DD'),
        PreferredName: name && name.trim() ? name.trim() : null,
        Pronoun: pronouns && pronouns.trim() ? pronouns.trim() : null,
        HonorificID: selectedHonorificID === "None Selected" ? null : selectedHonorificID,
        JobTitle: jobTitle && jobTitle.trim() ? jobTitle.trim() : null
      })
    } else {
      toggleDateError(!dobValid)
      toggleNameError(!nameValid)
    }
  };

  const theme = createTheme({
    zIndex: {
      modal: 3001
    }
  })

  return (
    (getHonorificsStatus === "pending" || updateProfileStatus === "pending" ||
      getSettingsOptionalDataStatus === "pending" || getSettingsHonorificsStatus === "pending" || fetchUserInfoStatus === 'pending') ? (
      <DotLoader width="250px" height="200px" dotSize="30px"
        isLoading={getHonorificsStatus === "pending" || updateProfileStatus === "pending" ||
          getSettingsOptionalDataStatus === "pending" || getSettingsHonorificsStatus === "pending" || fetchUserInfoStatus === 'pending'} />
    ) : (
      <LoginForm
        autoComplete="off"
        type="password"
        id="optional-form"
        name="optional"
        onSubmit={(e) => handleSubmit(e)}
      >
        <SectionTitle mobile={mobile} style={{ marginTop: '40px'}} settings={settings}>
          Please update your profile. All fields are optional.
        </SectionTitle>
        {loginErrors.length > 0 || settingsErrors.length > 0 || dateError || nameError ? (
          <ErrorWrapper style={{ marginBottom: '10px' }}>
            {loginErrors.length > 0 ? loginErrors.map(error => <ErrorMessage>{error}</ErrorMessage>) : null}
            {settingsErrors.length > 0 ? settingsErrors.map(error => <ErrorMessage>{error}</ErrorMessage>) : null}
            {dateError ? <ErrorMessage>Please enter a valid date of birth.</ErrorMessage> : null}
            {nameError ? <ErrorMessage>Please enter a name with no special characters.</ErrorMessage> : null}
          </ErrorWrapper>
        ) : null}
          <DatePickerField style={{ border: "1px solid rgba(191, 205, 216, 0.5)" }} mobile={mobile}> {/* Date of birth */}
            <LoginInputLabel settings={settings} >Date of Birth:</LoginInputLabel>
            <FlexWrapper justify="flex-end" align="center" style={{ marginRight: '20px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>   
                <ThemeProvider theme={theme}>
                  <DatePicker
                    autoComplete="off"
                    name="dateOfbirth"
                    placeholder="Date of Birth:"
                    value={dob}
                    onChange={date => updateDOB(date === null ? null : dayjs(date))}
                    renderInput={({inputRef, inputProps, InputProps }) => (
                    <>
                    <DatePickerInput
                      settings={settings}
                      ref={inputRef}
                      {...inputProps}
                    />
                    {InputProps?.endAdornment}
                    </>
                    )}
                  />
                </ThemeProvider>
              </LocalizationProvider>
            </FlexWrapper>
          </DatePickerField>
        <LoginField mobile={mobile} onClick={e => InputsRef.current.PreferredName.focus()}> {/* Preferred name */}
          <LoginInputLabel settings={settings} >Preferred Name:</LoginInputLabel>  
          <LoginInput
            autoComplete="off"
            type="text"
            name="preferredName"
            value={name}
            onChange={e => updateName(e.target.value)}
            ref={input => InputsRef.current.PreferredName = input}
            optionalData
          />
        </LoginField>
        <LoginField mobile={mobile} onClick={e => InputsRef.current.Pronouns.focus()}> {/* Pronouns */}
          <LoginInputLabel settings={settings}>Pronouns:</LoginInputLabel>  
          <LoginInput
            autoComplete="off"
            type="text"
            name="pronouns"
            value={pronouns}
            onChange={e => updatePronouns(e.target.value)}
            ref={input => InputsRef.current.Pronouns = input}
            optionalData
            maxLength="15"
          />
        </LoginField>
        {settings ? 
        <LoginField mobile={mobile} ref={honorificRef} onClick={e => toggleShowSettingsHonorifics(!showSettingsHonorifics)}> {/* Honorific */}
        {/* <LoginInput placeholder="Honorific:" disabled />
        <LoginSelect disabled> */}
          <LoginInputLabel settings={settings} >Honorific:</LoginInputLabel>
          <LoginSelect
            placeholder={settingsHonorifics.length > 1 && Number.isInteger(selectedHonorificID)}
            settings={settings}
          >
            {settingsHonorifics.length > 1 && Number.isInteger(selectedHonorificID) ?
              settingsHonorifics[selectedHonorificID - 1].Abbreviation
              : 'Please Select'
            }
            <ExpandMoreIcon style={{ marginRight: '15px' }} />
          </LoginSelect>
        </LoginField>
        :
        <LoginField mobile={mobile} ref={honorificRef} onClick={e => toggleShowHonorifics(!showHonorifics)}> {/* Honorific */}
          {/* <LoginInput placeholder="Honorific:" disabled />
          <LoginSelect disabled> */}
          <LoginInputLabel settings={settings} >Honorific:</LoginInputLabel>  
          <LoginSelect
              placeholder={honorifics.length > 1 && Number.isInteger(selectedHonorificID)}
              settings={settings}
          >
            {honorifics.length > 1 && Number.isInteger(selectedHonorificID) ?
              honorifics[selectedHonorificID - 1].Abbreviation
              : 'Please Select'
              } 
            <ExpandMoreIcon style={{marginRight: '15px'}} />
          </LoginSelect>
        </LoginField>
        }
        {showHonorifics ? (
            <LoginOptionContainer appear={showHonorifics}>
            {honorifics.map((honorific, i) => 
              <LoginOption
                key={i}
                id={honorific.HonorificID}
                onClick={e => updateSelectedHonorificID(parseInt(e.target.id))}
                settings={settings}
              >
                {honorific.Abbreviation}
              </LoginOption>)
            }
          </LoginOptionContainer>
          ) : null}
        {showSettingsHonorifics ? (
            <LoginOptionContainer appear={showSettingsHonorifics}>
            {settingsHonorifics.map((honorific, i) => 
              <LoginOption
                key={i}
                id={honorific.HonorificID}
                onClick={e => updateSelectedHonorificID(parseInt(e.target.id))}
                settings={settings}
              >
                {honorific.Abbreviation}
              </LoginOption>)
            }
          </LoginOptionContainer>
          ) : null}
        <LoginField mobile={mobile} onClick={e => InputsRef.current.JobTitle.focus()}> {/* Job title */}
          <LoginInputLabel settings={settings} >Job Title:</LoginInputLabel>  
          <LoginInput
            autoComplete="off"
            type="text"
            name="jobtitle"
            value={jobTitle}
            onChange={e => updateJobTitle(e.target.value)}
            ref={input => InputsRef.current.JobTitle = input}
            optionalData
          />
        </LoginField>
        {criticalError ? null : (
          <LoginButton mobile={mobile} type="submit" login={!settings}>SUBMIT</LoginButton>
        )}
      </LoginForm>
    )
  )
};

export default OptionalProfile;