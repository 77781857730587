import './styles/stylesheets/phytest-atoms.css'
import './styles/stylesheets/App.css'
import './styles/stylesheets/phytest-atoms.css'
import React, { useCallback, useEffect, useRef } from 'react'
import './components/dashboard/wijmo.css'

import AppSidebar from './components/nav/AppSidebar'
import Viewer from './viewer'
import Theme from './styles/theme'
import SupportModal from './components/support/supportModal'
import TimeoutMonitor from './components/modals/TimeoutMonitor'
import TermsOfService from './components/termsOfService/TermsOfService'
import { checkBrowser, checkOS, findPermission } from './utils/helperMethods'
import AlertBanner from './components/alerts/AlertBanner'
import { useViewport } from './utils/hooks'
import { AppDiv } from './styles/viewerStyles'
import { useLocation } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import { useAppDispatch, useAppSelector } from './redux/store'
import { callHealthCheck } from './components/login/healthCheckSlice'
import { fetchClientConnection, fetchInitialState, logout, switchConnections, updateAppRedirecting, updateClientFromStorage } from './components/login/userSlice'
import { fetchProtectedBannerMessages, fetchUnprotectedBannerMessages } from './components/alerts/alertSlice'
import SettingsModal from './components/settings/SettingsModal'

import { updateViewport } from './components/nav/navSlice'
import MobileSiteHeader from './components/nav/siteHeader/mobileSiteHeader'
import SiteHeader from './components/nav/siteHeader/SiteHeader'
import UserMenu from './components/settings/userMenu/userMenu'
import MobileConnectionSwitcher from './components/nav/connectionSwitcher/MobileConnectionSwitcher'
import { setWelcomeTimer } from './components/login/loginSlice'
import AccountIssuePage from './components/nav/AccountIssuePage'
import { getCookie } from './utils/security'

const App = () => {
  const menuRef = useRef(null)

  const { width, height } = useViewport()

  const location = useLocation()
  const login = window.location.pathname.includes('login')

  const dispatch = useAppDispatch()

  const mobile = useAppSelector(state => state.nav.mobile)
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn)
  const userReceived = useAppSelector(state => state.users.fetchingUser)
  const settingsActive = useAppSelector(state => state.modals.settings)
  const clientsFetched = useAppSelector(state => state.users.requests.fetchAccessibleClients)
  const termsModal = useAppSelector(state => state.modals.terms)
  const mobileSidebar = useAppSelector(state => state.nav.mobileSidebar)
  const protectedFetchBannerMessagesStatus = useAppSelector(state => state.alerts.protectedFetchBannerMessagesStatus)
  const unprotectedFetchBannerMessagesStatus = useAppSelector(state => state.alerts.unprotectedFetchBannerMessagesStatus)
  // Permissions
  const GlobalPermissions = useAppSelector(state => state.users.UserPermissions.Global)
  const OtherPermissions = useAppSelector(state => state.users.UserPermissions.Other)
  const dashboardPermissions = useAppSelector(state => state.users.UserPermissions.Dashboard)
  const reportingPermissions = useAppSelector(state => state.users.UserPermissions.Reporting)
  const patientPermissions = useAppSelector(state => state.users.UserPermissions.Patients)
  // For auto forwarding
  const appRedirecting = useAppSelector(state => state.users.appRedirecting)
  const switchingClients = useAppSelector(state => state.users.switchingClients)
  const LastAccessedAppID = useAppSelector(state => state.users.Client.LastAccessedAppID)
  const LastAccessedClient = useAppSelector(state => state.users.Client.ClientID)
  const accessibleClients = useAppSelector(state => state.users.connectionSwitcher.clients)
  const accessibleApps = useAppSelector(state => state.users.Client.ClientApps)
  const ClientConnectionID = useAppSelector(state => state.users.Client.ClientConnectionID)
  const welcomeTimer = useAppSelector(state => state.login.welcomeTimer)

  //----------MONITORING AND RESETTING LOGIN WELCOME TIMER----------
  useEffect(() => {
    if (welcomeTimer) {
      setTimeout(() => {
        if (LastAccessedClient && LastAccessedAppID && LastAccessedAppID !== '1') {
          dispatch(updateAppRedirecting(true))
        }
        dispatch(setWelcomeTimer(false))
      }, 2 * 1000)
    }
  }, [welcomeTimer, dispatch])

  //----------INITIAL API CALLS----------

  // Fetching user health status upon first render
  const sendHealthCheck = useCallback(() => {
    dispatch(callHealthCheck({ controller: new AbortController() }))
  }, [dispatch])

  const callFetchUnprotectedBannerMessages = useCallback(() => {
    dispatch(fetchUnprotectedBannerMessages({ controller: new AbortController() }))
  }, [dispatch])

  const callFetchProtectedBannerMessages = useCallback(() => {
    dispatch(fetchProtectedBannerMessages({ controller: new AbortController() }))
  }, [dispatch])

  useEffect(() => {
    checkBrowser()
    checkOS()
    sendHealthCheck()
    return () => {}
  }, []) // eslint-disable-line

  useEffect(() => {
    if (protectedFetchBannerMessagesStatus === 'idle' && isLoggedIn && !!ClientConnectionID) {
      callFetchProtectedBannerMessages()
    } else if (unprotectedFetchBannerMessagesStatus === 'idle') {
      callFetchUnprotectedBannerMessages()
    }
  }, [protectedFetchBannerMessagesStatus, unprotectedFetchBannerMessagesStatus, isLoggedIn, ClientConnectionID])

  // Fetching initial state if ever idle
  const getInitialState = useCallback(
    payload => {
      dispatch(fetchInitialState(payload))
    },
    [dispatch]
  )

  useEffect(() => {
    if (isLoggedIn && userReceived === 'idle') {
      getInitialState({ controller: new AbortController() })
    }

    return () => {}
  }, [isLoggedIn])

  //----------UPDATING REDUX----------

  // Updating the viewport and mobile
  const callupdateViewport = useCallback(
    payload => {
      dispatch(updateViewport(payload))
    },
    [dispatch]
  )

  useEffect(() => {
    callupdateViewport({
      width: width,
      height: height,
      mobile: width < 700,
      tablet: height < 700 || (width >= 700 && width < 1090),
    })
  }, [width, height])

  //----------MOBILE SWIPE FUNCTIONS----------

  const swipeConfig = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  }

  const handleSwipeRefresh = e => {
    if (e.initial[1] < 50) {
      window.location.reload()
    }
  }

  const handlers = useSwipeable({
    onSwipedDown: e => handleSwipeRefresh(e),
    ...swipeConfig,
  })

  //----------MONITORING AND RESETTING LOGOUT MESSAGE----------
  useEffect(() => {
    const logoutMessage = sessionStorage.getItem('LogoutMessage')
    const logoutMessageExpires = new Date(sessionStorage.getItem('LogoutMessageExpires'))
    const currentDate = new Date()

    if (logoutMessage && logoutMessageExpires && currentDate > logoutMessageExpires) {
      sessionStorage.removeItem('LogoutMessage')
      sessionStorage.getItem('LogoutMessageExpires')
    }
  })

  //----------SETTING CLIENT AND APP IF NULL----------
  useEffect(() => {
    // Checking if a user has a "null" accessed clientID or appID (usually new users)
    // If null, set to first available Client or App in array
    // Redux default value is '', so null must have been returned from the server as a ClientID or LastAccessedAppID has not been determined

    if ((LastAccessedClient === null || LastAccessedAppID === null) & (switchingClients !== 'pending')) {
      let determinedClient = LastAccessedClient
      let determinedApp = LastAccessedAppID

      if (LastAccessedClient === null) {
        if (accessibleClients.length > 0) {
          determinedClient = accessibleClients[0].ClientID
        } else {
          dispatch(
            logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' })
          )
        }
      }

      if (LastAccessedAppID === null) {
        if (accessibleApps.length > 0) {
          determinedApp = accessibleApps[0].AppID
        } else {
          dispatch(
            logout({ controller: new AbortController(), message: 'An error has occurred. Please try again. If issues persist, please contact support at (877) 846-2953.' })
          )
        }
      }

      dispatch(
        switchConnections({
          AppID: determinedApp,
          ClientID: determinedClient,
          controller: new AbortController(),
        })
      )
    }
  }, [LastAccessedClient, LastAccessedAppID])

  //----------HANDLING ACTIVE APP----------

  // HANDLING APP FORWARDING
  // If appRedirecting is true, then either the user is being autoforwarded from login or they selected a different app in the ConnectionSwitcher
  // If appRedirecting is false, the user must have manually changed the URL and, if they have permission, their ClientConnection needs to be updated

  useEffect(() => {
    if (userReceived === 'succeeded' && switchingClients !== 'pending' && !welcomeTimer && LastAccessedAppID && LastAccessedAppID !== '1') {
      if (appRedirecting) {
        if (LastAccessedAppID === '3') {
          window.location.assign(process.env.REACT_APP_DR_URL)
        } else if (LastAccessedAppID === '2') {
          window.location.assign(process.env.REACT_APP_PFR_URL)
        }
      } else {
        if (findPermission('2', OtherPermissions)) {
          dispatch(
            switchConnections({
              AppID: '1',
              ClientID: LastAccessedClient,
              controller: new AbortController(),
            })
          )
        } else {
          window.location.assign(process.env.REACT_APP_PORTAL_URL)
        }
      }
    }
  }, [userReceived, switchingClients, welcomeTimer, LastAccessedAppID, appRedirecting, OtherPermissions])

  //----------HANDLING ACTIVE CLIENT----------

  // Initializing client
  useEffect(() => {
    if (isLoggedIn) {
      // Setting client details in redux
      // First check sessionStorage
      // Then check localStorage
      // Then call fetchClientConnection

      if (sessionStorage.getItem('ActiveClientDetails')) {
        dispatch(updateClientFromStorage('session'))
      } else if (localStorage.getItem('ActiveClientDetails')) {
        dispatch(updateClientFromStorage('local'))
      } else {
        dispatch(fetchClientConnection({ controller: new AbortController() }))
      }
    }
  }, [isLoggedIn])

  // In case of multiple tabs, updating client and/or app when the window is re-focused
  const evaluateMultipleTabs = () => {
    if (isLoggedIn) {
      // Checking if the user has logged out in another tab
      if (!getCookie(process.env.REACT_APP_ACCESS_TOKEN) || !getCookie(process.env.REACT_APP_REFRESH_TOKEN)) {
        dispatch(logout({ controller: new AbortController() }))
      }

      // Updating connection, if necessary
      const parsedLocalStorage = JSON.parse(localStorage.getItem('ActiveClientDetails'))

      if (
        localStorage.getItem('User') === sessionStorage.getItem('User') && // this check is necessary in case a user has multiple tabs with different accounts
        LastAccessedClient &&
        LastAccessedAppID &&
        switchingClients !== 'pending' &&
        (parsedLocalStorage.ClientID !== LastAccessedClient || parsedLocalStorage.LastAccessedAppID !== LastAccessedAppID)
      ) {
        dispatch(
          switchConnections({
            AppID: LastAccessedAppID,
            ClientID: LastAccessedClient,
            controller: new AbortController(),
            backgroundUpdate: true,
          })
        )
      }
    }
  }

  useEffect(() => {
    window.addEventListener('focus', evaluateMultipleTabs)
    return () => {
      window.removeEventListener('focus', evaluateMultipleTabs)
    }
  })

  //----------RENDER FUNCTIONS----------

  const showSiteHeader = () => {
    if (isLoggedIn && userReceived && !login && !window.location.pathname.includes('launchPad')) {
      return mobile ? (
        <MobileSiteHeader quickSearchAllowed={findPermission('40', patientPermissions)} />
      ) : (
        <SiteHeader quickSearchAllowed={findPermission('40', patientPermissions)} />
      )
    }
  }

  const buildAppSidebar = () => {
    if (isLoggedIn && !mobile && !login && !window.location.pathname.includes('launchPad') && window.location.pathname !== '/') {
      return <AppSidebar />
    }
  }

  const buildApp = () => {
    return (
      <Theme>
        {showSiteHeader()}
        {isLoggedIn && mobile && !mobileSidebar ? <MobileConnectionSwitcher /> : null}
        {isLoggedIn ? <TimeoutMonitor /> : null}
        <AlertBanner />
        <SupportModal />
        {termsModal ? <TermsOfService /> : null}
        {settingsActive ? <SettingsModal /> : null}
        {isLoggedIn ? <UserMenu menuRef={menuRef} /> : null}
        {buildAppSidebar()}
        <Viewer />
      </Theme>
    )
  }

  // Show an error page under the following circumstances
  if (
    userReceived === 'succeeded' &&
    clientsFetched === 'succeeded' &&
    (!findPermission('1', GlobalPermissions) || // If no global permissions
      accessibleClients.length === 0 || // If no accessible clients
      (accessibleClients.length === 1 && // If only one accessible client but with no app permissions
        !findPermission('14', reportingPermissions) &&
        !findPermission('15', dashboardPermissions) &&
        !findPermission('16', patientPermissions)) ||
      ClientConnectionID === null) // If the database returns no ClientConnectionID
  ) {
    return <AccountIssuePage />
  } else {
    return (
      <AppDiv {...handlers} mobile={width > 1024} location={location.pathname} login={login} loggedIn={userReceived && isLoggedIn && !login}>
        {buildApp()}
      </AppDiv>
    )
  }
}

export default App
