import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoginResponse, RequestStatus } from "../../../type";
import { AppDispatch } from "../../redux/store";
import { api, protectedApi } from "../../utils/api";
import { getCookie, deleteCookie, endSession } from "../../utils/security";
import { engageMultiFactor, resetmFA } from "../mfa/mFASlice";
import { setCookie } from "./userSlice";
import { fetchInitialState } from "./userSlice";

// dayJS imports
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

interface LoginState {
  loginTask: null | string,
  preloginCheckStatus: RequestStatus;
  mfaPhoneNumber: string;
  SMScapable: boolean | null;
  mfaEmail: string;
  checkPhoneNumberStatus: RequestStatus;
  getMFAPreferenceStatus: RequestStatus;
  updateMFAPreference: RequestStatus;
  multiFactorPreferences: {};
  getHonorificsStatus: RequestStatus;
  honorifics: object[];
  daysToPasswordExpiration: null | number;
  errors: string[];
  criticalError: boolean;
  isLoggedIn: boolean;
  updateProfileStatus: RequestStatus;
  getOptionalDataStatus: RequestStatus;
  optionalData: any;
  loginPending: RequestStatus;
  username: string;
  welcomeTimer: boolean;
}

// interface FormInput {
//   key: keyof typeof initialLoginState_TEST;
//   data: unknown;
// }

// const initialLoginState_TEST = {
//   email: "",
//   errors: [],
//   isLoggedIn: false,
//   loginPending: "idle",
// };

const initialLoginState = {
  loginTask: null,
  preloginCheckStatus: 'idle',
  mfaPhoneNumber: '',
  SMScapable: true,
  mfaEmail: '',
  checkPhoneNumberStatus: 'idle',
  getMFAPreferenceStatus: 'idle',
  updateMFAPreference: 'idle',
  multiFactorPreferences: {},
  daysToPasswordExpiration: null,
  getHonorificsStatus: 'idle',
  honorifics: [],
  errors: [],
  criticalError: false,
  isLoggedIn:
    !!getCookie(process.env.REACT_APP_ACCESS_TOKEN) &&
    !!getCookie(process.env.REACT_APP_REFRESH_TOKEN),
  updateProfileStatus: 'idle',
  getOptionalDataStatus: 'idle',
  optionalData: {
    FirstName: '',
    LastName: '',
    DateOfBirth: null,
    JobTitle: '',
    PreferredName: '',
    HonorificID: 1,
    Honorific: '',
    Pronoun: '',
  },
  username: "",
  loginPending: "idle",
  welcomeTimer: false,
};

const initialState = initialLoginState as LoginState;
// const initialState = initialLoginState_TEST as LoginState;

const abortController = new AbortController()

export const callLogin = createAsyncThunk<
  LoginResponse,
  any,
  { dispatch: AppDispatch }
>("login/callLogin", async (payload, thunkAPI) => {
  return api
    .post("/login", payload)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        //setting cookie
        thunkAPI.dispatch(
          setCookie({
            name: process.env.REACT_APP_LOGIN_TOKEN,
            val: data.token,
          })
        )

        // clearing sessionStorage logoutMessage if necessary
        if (sessionStorage.getItem('LogoutMessage') || sessionStorage.getItem('LogoutMessageExpires')) {
          sessionStorage.removeItem('LogoutMessage')
          sessionStorage.removeItem('LogoutMessageExpires')
        }

        //engaging multifactor
        thunkAPI.dispatch(engageMultiFactor({controller: abortController }))
        return data
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      thunkAPI.dispatch(setLoginError(error.response.data.message))
      setTimeout(() => {
        endSession('', true)
      }, 1000 * 5)
      return error.response.data
    });
});

export const preloginCheck = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("login/preloginCheck", async (payload, thunkAPI) => {

  const token = process.env.REACT_APP_PRELOGIN_TOKEN

  return protectedApi(payload.controller, getCookie(token))
    .get("/preLogin/check")
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        if (data.taskExists) {
          return data
        } else {
          // Checking sessionStorage and localStorage
          //@ts-ignore
          const userEmail: string = thunkAPI.getState().login.username
          const storedEmail: string | null = sessionStorage.getItem('User') ? sessionStorage.getItem('User') : localStorage.getItem('User')

          // This check is required in case a user has multiple accounts. If the account email has changed, clear old ClientConnectionID info to prevent errors
          if (userEmail !== storedEmail) {
            sessionStorage.clear()
            localStorage.clear()
          }

          // Handling cookies
          deleteCookie(token)
          thunkAPI.dispatch(
            setCookie({
              name: process.env.REACT_APP_ACCESS_TOKEN,
              val: data.accessToken
            })
          );
          thunkAPI.dispatch(
            setCookie({
              name: process.env.REACT_APP_REFRESH_TOKEN,
              val: data.refreshToken,
            })
          );

          // API calls and updating redux
          thunkAPI.dispatch(fetchInitialState({ controller: new AbortController() }));
          thunkAPI.dispatch(setWelcomeTimer(true)) // Must be dispatched here rather than in the addCase or value will still be false when <RedirectLimbo/> renders
          thunkAPI.dispatch(completeLogin());
          thunkAPI.dispatch(resetmFA({saveSecurityAuth: false}));
          thunkAPI.dispatch(resetLoginTask());
          return data
        }
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      thunkAPI.dispatch(
        setLoginError(error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : error.response.data.message)
      )
      setTimeout(() => {
        endSession()
      }, 1000 * 5)
      return error.response.data
    });
});

export const getMFAPreferenceTypes = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("login/getMFAPreferenceType", async (payload, thunkAPI) => {

  const token = process.env.REACT_APP_PRELOGIN_TOKEN

  return protectedApi(payload.controller, getCookie(token))
    .get("/preLogin/getMultiFactorPreferenceTypes")
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return data            
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      thunkAPI.dispatch(
        setLoginError(error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : error.response.data.message))
      setTimeout(() => {
        endSession()
      }, 1000 * 5)
      return error.response.data
    });
});

export const updateMFAPreference = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("login/updateMFAPreference", async (payload, thunkAPI) => {

  const token = process.env.REACT_APP_PRELOGIN_TOKEN;

  return protectedApi(payload.controller, getCookie(token))
    .post("/preLogin/updateMultiFactorPreference", payload)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        thunkAPI.dispatch(preloginCheck({controller: new AbortController()}))
        return data                       
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      if (error.response.status === 400) { // Phone number does not meet character-limit, contains non-numeric characters, or is not a mobile number
        thunkAPI.dispatch(setLoginError(error.response.data.message))
      } else {
        thunkAPI.dispatch(
          setLoginError(error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : error.response.data.message)
        )
        setTimeout(() => {
          endSession()
        }, 1000 * 5)
      }
      return error.response.data
    });
});

export const getOptionalData = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("login/getOptionalData", async (payload, thunkAPI) => {

  const token = process.env.REACT_APP_PRELOGIN_TOKEN

  return protectedApi(payload.controller, getCookie(token))
    .get("/preLogin/getOptionalUserData")
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return data            
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      thunkAPI.dispatch(
        setLoginError(error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : error.response.data.message)
      )
      setTimeout(() => {
        endSession()
      }, 1000 * 5)
      return error.response.data
    });
});

export const getHonorifics = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("login/getHonorifics", async (payload, thunkAPI) => {

  const token = process.env.REACT_APP_PRELOGIN_TOKEN

  return protectedApi(payload.controller, getCookie(token))
    .get("/preLogin/getHonorifics")
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return data            
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      thunkAPI.dispatch(
        setLoginError(error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : error.response.data.message)
      )
      setTimeout(() => {
        endSession()
      }, 1000 * 5)
      return error.response.data
    });
});

export const updateProfileData = createAsyncThunk<
  any,
  any,
  { dispatch: AppDispatch }
>("login/updateProfileData", async (payload, thunkAPI) => {

  const token = process.env.REACT_APP_PRELOGIN_TOKEN

  return protectedApi(payload.controller, getCookie(token))
    .post("/preLogin/saveOptionalUserData", payload)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        thunkAPI.dispatch(preloginCheck({controller: new AbortController()}))
        return data            
      } else {
        throw new Error()
      }
    })
    .catch((error) => {
      thunkAPI.dispatch(
        setLoginError(error.response.data.tokenExpired ? 'Your login session has timed out. Please log in again.' : error.response.data.message)
      )
      setTimeout(() => {
        endSession()
      }, 1000 * 5)
      return error.response.data
    });
});

const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    completeLogin(state) {
      state.isLoggedIn =
        !!getCookie(process.env.REACT_APP_ACCESS_TOKEN) &&
        !!getCookie(process.env.REACT_APP_REFRESH_TOKEN);
    },
    setLoginTask(state, action) {
      state.loginTask = action.payload
    },
    resetLoginTask(state) {
      state.loginTask = null
    },
    setLoginError(state, action) {
      state.errors.push(action.payload)
    },
    setWelcomeTimer(state, action) {
      state.welcomeTimer = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(callLogin.pending, (state, action) => {
      state.loginPending = "pending";
      state.errors = [];
    });
    builder.addCase(callLogin.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.username = action.payload.username;
        state.loginPending = "succeeded";
      } else if (typeof action.payload.message === "string") {
        state.loginPending = "rejected"
      }
    });
    builder.addCase(callLogin.rejected, (state, action) => {
      state.loginPending = "rejected"
    })
    builder.addCase(preloginCheck.pending, (state, action) => {
      state.preloginCheckStatus = "pending"
      state.errors = []
    });
    builder.addCase(preloginCheck.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.preloginCheckStatus = "succeeded"
        state.daysToPasswordExpiration = action.payload.daysToPasswordExpiration
        if (action.payload.taskExists) {
          state.loginTask = action.payload.task
        }
      } else {
        state.preloginCheckStatus = "rejected"
      }
    });
    builder.addCase(preloginCheck.rejected, (state, action) => {
      state.preloginCheckStatus = "rejected"
    });
    builder.addCase(getMFAPreferenceTypes.pending, (state, action) => {
      state.getMFAPreferenceStatus = "pending"
      state.errors = []
    });
    builder.addCase(getMFAPreferenceTypes.fulfilled, (state, action) => {
      if (action.payload.success) {
        const emailData = action.payload.MfaPreferenceTypes.find((pref: any) => pref.PreferenceTypeID === 1)
        const phoneData = action.payload.MfaPreferenceTypes.find((pref: any) => pref.PreferenceTypeID === 2)
        
        state.getMFAPreferenceStatus = "succeeded"
        state.multiFactorPreferences = action.payload.MfaPreferenceTypes
        state.mfaPhoneNumber = phoneData.destination
        state.SMScapable = phoneData.phoneSMSCapable
        state.mfaEmail = emailData.destination
      } else {
        state.getMFAPreferenceStatus = "rejected"
      }
    });
    builder.addCase(getMFAPreferenceTypes.rejected, (state, action) => {
      state.getMFAPreferenceStatus = "rejected"
    });
    builder.addCase(updateMFAPreference.pending, (state, action) => {
      state.updateMFAPreference = "pending"
    });
    builder.addCase(updateMFAPreference.fulfilled, (state, action) => {
      state.updateMFAPreference = (action.payload.success ? "succeeded" : "rejected")
    });
    builder.addCase(updateMFAPreference.rejected, (state, action) => {
      state.updateMFAPreference = "rejected"
    });
    builder.addCase(getOptionalData.pending, (state, action) => {
      state.getOptionalDataStatus = "pending"
    });
    builder.addCase(getOptionalData.fulfilled, (state, action) => {
      if (action.payload.success) {
        dayjs.extend(utc)
        dayjs.extend(timezone)
        dayjs.tz.setDefault("America/New_York")

        state.getOptionalDataStatus = "succeeded"
        state.optionalData.PreferredName = action.payload.PreferredName
        state.optionalData.FirstName = action.payload.FirstName
        state.optionalData.LastName = action.payload.LastName
        state.optionalData.DateOfBirth = action.payload.DateOfBirth === null ? null : dayjs.tz(action.payload.DateOfBirth)
        state.optionalData.JobTitle = action.payload.JobTitle
        state.optionalData.HonorificID = action.payload.HonorificID
        state.optionalData.Honorific = action.payload.Honorific
        state.optionalData.Pronoun = action.payload.Pronoun
      } else {
        state.getOptionalDataStatus = "rejected"
      }
    });
    builder.addCase(getOptionalData.rejected, (state, action) => {
      state.getOptionalDataStatus = "rejected"
    });
    builder.addCase(getHonorifics.pending, (state, action) => {
      state.getHonorificsStatus = "pending"
    });
    builder.addCase(getHonorifics.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.getHonorificsStatus = "succeeded"
        state.honorifics = action.payload.honorifics
      } else {
        state.getHonorificsStatus = "rejected"
      }
    });
    builder.addCase(getHonorifics.rejected, (state, action) => {
      state.getHonorificsStatus = "rejected"
    });
    builder.addCase(updateProfileData.pending, (state, action) => {
      state.updateProfileStatus = "pending"
    });
    builder.addCase(updateProfileData.fulfilled, (state, action) => {
      state.updateProfileStatus = (action.payload.success ? "succeeded" : "rejected")
    });
    builder.addCase(updateProfileData.rejected, (state, action) => {
      state.updateProfileStatus = "rejected"
    });
  },
});

export const {
  completeLogin,
  setLoginTask,
  resetLoginTask,
  setLoginError,
  setWelcomeTimer,
} = loginSlice.actions;

export default loginSlice.reducer;
