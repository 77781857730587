import styled, { css } from 'styled-components'
import { UnstyledButton } from '../../../styles/buttonStyles'
import { ModalContainer, ModalContent } from '../../../components/modals/modalStyles'

export const BoldSpan = styled.span`
  font-size: ${props => (props.tablet ? '.9rem' : '1rem')};
  font-weight: 700;
`
export const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: ${props => props.size || '1rem'};
  width: ${props => props.size || '1rem'};
  min-height: ${props => props.size || '1rem'};
  min-width: ${props => props.size || '1rem'};
  margin: 0 0.5rem 0 0;
  outline: 0;
  border: none;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid ${props => props.theme.restyle.primary};

  &:checked {
    background-color: ${props => props.theme.restyle.darkAccent};
    border-color: ${props => props.theme.restyle.darkAccent};
  }

  &:after {
    content: ' ';
    position: relative;
    left: 35%;
    top: 13%;
    width: 20%;
    height: 50%;
    transform: rotate(40deg);
    display: none;
    border: solid #fff;
    border-width: 0 2px 2px 0;
  }

  &:checked:after {
    display: block;
  }
  transition: 0.2s background-color, 0.2s border-color;
`

// #region ClaimCardContainer.js

// Holds both ClaimSidebar and ClaimToolbar
export const PatientClaimsSection = styled.div`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: ${props => (props.mobile ? 'center' : 'flex-start')};
  padding-top: ${props => (props.mobile ? 0 : '1rem')};
  margin-bottom: ${props => (props.tablet ? 0 : '1rem')};
  overflow: ${props => (props.mobile ? 'visible' : 'hidden')};
  box-sizing: border-box;
  border-top: ${props => (props.mobile && props.mobileClaimMode ? 'none' : `3px solid ${props.theme.restyle.darkAccent}`)};
`

export const ClaimsSideContent = styled.div`
  height: 100%;
  width: ${props => (props.demographicsExpanded ? 'auto' : 'calc(20% - .5rem)')};
  min-width: ${props => (props.demographicsExpanded ? 'auto' : 'calc(20% - .5rem)')};
  max-width: ${props => (props.demographicsExpanded ? 'auto' : 'calc(20% - .5rem)')};
  padding: ${props => (props.mobile ? 0 : '0 .5rem 0 1rem')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid ${props => props.theme.restyle.darkAccent};
  transition: width 1s, min-width 1s, max-width 1s, border-color 1s, border-width 1s;
`

// Container for claim section
export const ClaimContentWrapper = styled.div`
  width: ${props => (props.demographicsExpanded || props.mobile ? '100%' : '80%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 1s;
  padding-right: ${props => (props.mobile ? 0 : '1rem')};
`

export const CardContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-left: 0.5rem;
  box-sizing: border-box;
  // overflow: ${props => (props.mobile ? 'visible' : 'hidden auto')};
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar {
    opacity: 1;
    width: 15px;
  }
`

export const NoClaimsStatement = styled.h3`
  font-weight: 400;
`
// #endregion

// #region ClaimSidebar.js
export const ClaimSidebar = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  white-space: nowrap;

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar {
    opacity: 1;
    width: 15px;
  }
`

export const ClaimSidebarItem = styled.div`
  width: 100%;
  display: grid;
  column-width: 1rem;
  grid-template-columns: ${props => (props.exportMode ? '3% 80% 17%' : '80% 20%')};
  text-align: left;
  padding: 0.25rem 0.5rem;
  color: ${props => (props.selected ? '#FFF' : 'inherit')};
  background-color: ${props => (props.selected ? props.theme.restyle.darkAccent : props.even ? 'transparent' : 'rgba(0, 0, 0, ' + (props.darkMode ? '0.2' : '0.1') + ')')};
  font-weight: ${props => (props.selected ? 700 : 'inherit')};
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.5s, color 0.5s;

  span,
  p {
    margin: 0.25rem 0;
  }

  span {
    display: block;
    font-weight: ${props => (props.selected ? 700 : 500)};
  }

  p {
    font-size: ${props => (props.tablet ? '.8rem' : '.9rem')};
  }
`

export const ClaimSidebarIcons = styled.div`
  display: flex;
  flex-wrap: ${props => (props.demographicsExpanded ? 'nowrap' : 'wrap')};
  justify-content: flex-end;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    cursor: initial;
  }

  svg:nth-child(n + 1) {
    margin: 0.2rem;
  }
`

// #endregion

// #region ClaimsToolbar.js
export const ClaimsToolbarContainer = styled.div`
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1rem;
  padding-left: ${props => (props.mobile ? '.5rem' : 0)};
  font-size: 1rem;
  border-bottom: ${props => (props.mobile ? null : '1px solid var(--color-accent-alsogrey)')};

  & > *:nth-child(n + 2) {
    margin-top: 0.5rem;
  }
`

export const ClaimsToolbarButton = styled(UnstyledButton)`
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.mobile ? 'center' : 'flex-start')};
  white-space: nowrap;
  margin: 0.5rem 0;
  font-size: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  p {
    margin: 0;
    color: ${props => (props.disabled ? 'var(--color-accent-alsogrey)' : props.theme.restyle.primary)};
  }
  svg {
    margin-right: 1rem;
    fill: ${props => (props.disabled ? 'var(--color-accent-alsogrey)' : props.theme.restyle.primary)};
  }
`

export const ClaimsFilterLabel = styled.label`
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
`

// #endregion

// #region Claim.js

export const MobileClaimHeader = styled.div`
  height: 100%;
  display: flex;
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.viewer.background};
  transition: background-color 0.75s;
`

// Claim header
export const MobileClaimInfoContainer = styled.div`
  width: 75%;
  margin: 1rem 0 2rem 0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;

  > p {
    margin: 0;
  }
`

export const ClaimKPISContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: ${props => (props.portableDevice ? 'grid' : 'flex')};
  justify-content: space-between;
  background: var(--color-primary-charcoal);
  color: #fff;
  padding: 0.5rem;
  margin-bottom: ${props => (props.mobile ? 0 : '.1rem')};

  // For mobile only
  grid-template-columns: 50% 50%;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
`
export const ClaimKPI = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 0.5rem;
  border-left: 6px solid ${props => props.theme.restyle.darkAccent};

  &:first-child {
    border-left-color: var(--color-accent-green);
  }

  &:nth-child(2) {
    border-left-color: var(--color-accent-purple);
  }

  &:nth-child(3) {
    border-left-color: var(--color-primary-lightBlue);
  }

  &:nth-child(4) {
    width: 75%;
    border-left-color: var(--color-accent-yellow);
  }

  span {
    font-size: ${props => (props.tablet ? '1.4rem' : '1.6rem')};
    font-weight: 500;
  }

  p {
    font-size: ${props => (props.tablet ? '0.7rem' : '0.9rem')};
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
`
export const ClaimDetails = styled.div`
  width: 100%;
  font-size: ${props => (props.tablet ? '.9rem' : '1rem')};
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  div {
    flex: 1;
    justify-content: space-between;
    display: flex;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--color-accent-grey);
  }

  p {
    margin: 0;
    font-size: ${props => (props.tablet ? '.9rem' : '1rem')};
  }
`
// Specimen

export const SpecimenContainer = styled.div`
  text-align: left;
  padding: 1rem 0;

  span {
    font-weight: 600;
  }

  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 3px solid ${props => props.theme.restyle.darkAccent};
  }
`

export const AccessionHeader = styled.div`
  box-sizing: border-box;

  h2 {
    margin: 0;
    font-size: ${props => (props.tablet ? '1.2rem' : '1.3rem')};
    font-weight: 600;
  }
`

export const ServiceContainer = styled.div`
  padding: 0.2rem 0;
  border-top: ${props => (props.index ? '1px solid var(--color-accent-grey)' : 'none')};

  span,
  p {
    font-size: ${props => (props.tablet ? '.9rem' : '1rem')};
  }
`

export const SpecimenMainRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-weight: 600;

  span,
  p {
    width: ${props => (props.payor ? '100%' : 'auto')};
    font-size: ${props => (props.tablet ? '1rem' : '1.2rem')};
    margin: 0.3rem 0;
  }
`

export const SpecimenGrid = styled.div`
  display: grid;
  grid-template-areas: ${props =>
    props.portableDevice ? `'CPTDesc CPTCode DOS' 'denialCount . .' 'DXCodes . .' 'Modifiers . .'` : `'CPTDesc CPTCode DOS denialCount' 'DXCodes . . .' 'Modifiers . . .'`};
  grid-template-columns: ${props => (props.portableDevice ? '33% 33% 34%' : '33% 33% 20% 14%')};
  margin-bottom: 1rem;

  span,
  p {
    margin: 0.5rem 0;
  }
`

export const SpecimenPayorGrid = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  span,
  p {
    margin: 0.5rem 0;
  }
`

// #endregion

// #region ClaimCard.js (mobile)
export const MobileClaimCard = styled(UnstyledButton)`
  width: 100%;
  margin: 0;
  padding: 1rem;
  text-align: left;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${props => props.theme.restyle.primary};
  transition: border-color 0.5s;

  h4,
  h5,
  p,
  ul {
    margin: 0;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  .header {
    margin-top: -1rem;
    margin-left: -1rem;
    padding: 0.2rem 0.4rem;
    font-size: 1.2rem;
    background-color: ${props => props.theme.restyle.darkAccent};
    color: #fff;
    transition: background-color 0.5s, color 0.5s;
  }
`

// #endregion

// #region Claim denials

export const DenialCount = styled.button.attrs(props => ({ className: 'invisibleButton' }))`
  align-self: center;
  font-weight: 600;
  color: inherit;
  transition: border-color 0.5s;
  border-bottom: 1px solid ${props => props.theme.restyle.primary};
  grid-area: ${props => (props.reasonCode ? 'denialCount' : 'auto / auto / auto / auto')};

  ${props =>
    props.reasonCode && props.portableDevice
      ? css`
          margin: 1rem 0;
          grid-column: 1 / span 3;
        `
      : css`
          margin-left: auto;
        `};
`

// Modal container
export const DenialsModalContainer = styled(ModalContainer)`
  min-height: 33%;
  max-height: 75%;
  height: 100%;
  width: ${props => (props.mobile ? '100%' : '60%')};
  padding: 1rem;
  box-sizing: border-box;
  color: ${props => props.theme.restyle.primary};

  svg {
    fill: ${props => props.theme.restyle.primary};
  }

  h3,
  h4 {
    font-weight: 500;
  }

  h3 {
    font-size: 1.3rem;
    background-color: ${props => props.theme.restyle.darkAccent};
    color: #fff;
    padding: 0.5rem 0.4rem;
  }

  h4 {
    font-size: 1.15rem;
  }
`

export const DenialsModalContent = styled(ModalContent)`
  width: 100%;
  margin: 0;
  justify-content: flex-start;
  text-align: left;
`

export const ModalCloseIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    cursor: pointer;
  }
`

export const DenialsModalTitle = styled.h2`
  width: 100%;
  margin: 0;
  font-weight: 500;
  text-align: left;
`

// Modal content
export const DenialsModalHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.2rem;
`

export const ModalBlock = styled.div``

export const ServiceBlock = styled.div`
  :nth-of-type(n + 2) {
    border-top: 2px solid ${props => props.theme.restyle.darkAccent};
  }
`

export const DenialsGrid = styled.div`
  display: ${props => (props.mobile ? 'block' : 'grid')};
  width: 100%;
  grid-template-columns: ${props => (props.mobile ? '67% 28%' : '34% 8% 54%')}; //grid is missing 4% to account for column-gap
  grid-row-gap: 2rem;
  grid-column-gap: 1rem;
  margin: 2rem 0;
  align-items: center;
  text-align: left;
  word-break: ${props => (props.mobile ? 'break-word' : 'normal')};

  p {
    height: 100%;
    margin: 0;
  }

  // On mobile, apply a border-bottom on the child description as long as it's not the last child
  // On tablet/desktop, a border-top to all <p/> tags after the first three
  ${props =>
    props.mobile
      ? css`
          p {
            font-size: 0.9rem;
            margin-bottom: 1rem;
          }
          p:nth-child(3n):not(:last-child) {
            padding-bottom: 1rem;
            border-bottom: ${props => (!props.noBorder ? '0.1rem solid var(--color-accent-alsogrey)' : 'none')};
          }
        `
      : css`
          p:nth-child(n + 4) {
            padding-top: 1.2rem;
          }
        `}
`

// #endregion
