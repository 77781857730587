import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle, faEllipsisH, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { FeaturePermission, UserFeaturePermission } from '../../../type'
import { useOutsideClick } from '../../utils/hooks'
import { setPermissions } from './permissionsSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { UnstyledButton } from '../../styles/buttonStyles'
import { PermissionBadge, PermissionBadgeWrapper, PermissionCheckbox, PermissionMenu } from './permissionStyles'

interface Props {
    add?: boolean,
    permissions?: UserFeaturePermission[] | FeaturePermission,
    permissionCategory?: string,
    subPermission?: any
    userID: string
    setExpandedForm?:any
    expandedForm?: boolean,
    canEdit?: boolean,
    Client: {
        ClientID: string, 
        ClientAbbreviation: string, 
        ClientName: string, 
        Features: FeaturePermission,
        selected: boolean
    } | null
}

const PermissionBadgeComponent = (props: Props) => {

    const [showMenu, setShowMenu] = useState(false)

    const dispatch = useAppDispatch()

  const menuRef: any = useRef()
  const theme = useAppSelector(state => state.users.ThemeName)
    
    const handleMenuClick = (e:any=null) => {
        e && e.stopPropagation()
        setShowMenu(!showMenu)
    }
// @ts-ignore
    const handleCheckboxClick = (e, payload) => {
      e.stopPropagation()
      const controller = new AbortController()
            const updateObj = {
                  AffectedUserID: payload.AffectedUserID,
                  category: payload.category,
                  PermissionIDs: [{PermissionID: payload.permission.PermissionID, Permitted: !payload.permission.UserHasPermission}], 
                  ClientID: props.Client?.ClientID, 
                  ClientAbbreviation: props.Client?.ClientAbbreviation, 
              updateState: payload.updateState,
              controller: controller
              }
            dispatch(setPermissions(updateObj))
        }
    
    useOutsideClick(menuRef, ()=>setShowMenu(false))
   
    const buildCheckboxes = (permissions: UserFeaturePermission[] | undefined, payload:{AffectedUserID:String, category: string, updateState:boolean}) => {
        return permissions && Array.isArray(permissions) && permissions.map((permission:UserFeaturePermission) => {
            return(
            <PermissionCheckbox
                style={props.canEdit ? {} : {cursor: 'auto', opacity: 0.75}}
                key={`${props.userID}:${props.permissionCategory}:${permission.PermissionID}`}
                onClick={(e)=> props.Client && props.canEdit ? 
                    handleCheckboxClick(e, {...payload, permission: permission} ) 
                    : 
                        null
                    }
            >
                <UnstyledButton
                style={props.canEdit ? {} : {cursor: 'auto'}}
                >
                    { permission.UserHasPermission ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faCircle}/>}
                    <label 
                    style={props.canEdit ? {} : {cursor: 'auto'}}
                    htmlFor={permission.PermissionDescription}>{permission.PermissionDescription}
                    </label>
                </UnstyledButton>
            </PermissionCheckbox>
            ) 
        })
    }

    const buildFullMenu = () => {
        if(typeof props.permissions === 'object' && !Array.isArray(props.permissions)){
            return (<PermissionMenu 
                        key={`${props.userID}:AllPermissions`}
                        style={props.canEdit ? {} : {cursor: 'auto'}}
                    >
                {Object.entries(props.permissions).map((key)=>{
                    return (    
                        <>
                            {key[0]}
                            <div>
                                {buildCheckboxes(key[1], {AffectedUserID: props.userID, category: key[0], updateState:false} )}
                            </div>
                        </>
                    )
                })}
                </PermissionMenu>
            )
        }
    }

    if(props.add){
        return(
            <div style={{position: 'relative', background: 'inherit', margin: '10px'}}>
                <PermissionBadge
                    ref={menuRef} 
                    onClick={()=> props.setExpandedForm(!props.expandedForm)}
                    className='addPermission'
              permissionCategory={props.permissionCategory}
              style={
                  theme.toLowerCase() === 'light' ? {color: 'black'} : {color: 'white'}
              }
                >
                <FontAwesomeIcon icon={faPlus} />
                </PermissionBadge>
                    { showMenu && typeof props.permissions === 'object' && !Array.isArray(props.permissions) ?
                        buildFullMenu()
                        :
                        null
                    }
            </div>
        )
    }else{
        return (
            <PermissionBadgeWrapper>
            <PermissionBadge
                key={`${props.userID}:`}
                ref={menuRef} 
                onClick={(e)=> handleMenuClick(e)}
                permissionCategory={props.permissionCategory}
            >
                <div>
                    <FontAwesomeIcon icon={faEllipsisH} />
                    <span>{props.permissions && props.permissionCategory}</span>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </PermissionBadge>
                {showMenu && Array.isArray(props.permissions) && props.permissionCategory ?
                    <PermissionMenu
                        permissionCategory={props.permissionCategory}
                    >
                        <div>
                            {buildCheckboxes(props.permissions, {AffectedUserID:props.userID, category: props.permissionCategory, updateState: true})}
                        </div>
                    </PermissionMenu>
                    :
                    null
                }
            </PermissionBadgeWrapper>
    )
}
}

export default PermissionBadgeComponent
