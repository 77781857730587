
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/stylesheets/index.css'
import App from './App';
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import {store} from './redux/store.ts'
import * as wijmo from '@grapecity/wijmo'

if (window.Cypress) {
  window.store = store
}

wijmo.setLicenseKey("portal.phytest.com,834458256892378#B08IMpjIs9WQisnOiQkIsISP3cXSVRmRzk5UUt4RX9UWOFkTD3USYNXepd4dZZ4M4UzbMN5Koh7b5pkUplTW52WUwAlbx8WaIBXUVdlUSNkavk4ZrUXW88UOJl5SupmeQRXSp3WSOdHULNEZGpnTrpHUEFnaKNTOvBTe7EUavQVTaxUWzQVWXJlN92GeSNHU9UzYIVVenJDcHl5L8MWa6UnRmVWMkpERyVmVShzd6ZTdK5kYlZ5Rxl7ayV7R4JzYp3iMz2CUrEVV5lFOX9UdpJXdSZleBJnYVNFMadkNKRWcNNETEFDdR5WR7kVeVZGWyhzUwpkW7oGMtRnehRES9pkaQRnU546b6kEUoFmdzEFRTN4dthES4YDSwFHc7lWRU3iVOpEU9MWdz5kTsZEVUR4M4VXUQRHa456dwU7MjRnUF94TwcnM4QzbpZzVilVTkFGcXlTevJjRW9WNxpWRLVjehdHMtVTNhJiOiMlIsICR9QjNDhjI0ICSiwiNzczNxQTNykTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiMDMzEDOwAiMxMDMxIDMyIiOiQncDJCLi46bj9CdzVGd9hGcuwWY4J7bwJiOiMXbEJCLi8yYulEIsQ7clRXeoBlI0ISYONkIsICO7MjM9gjN5IDO5QDNzgjI0ICZJJCL3JyM6BjMwIjI0IiclZnIsU6csFmZ0IZcIR")
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App id="main-app" key="main-app"/>
    </Router>
  </Provider>,
  document.getElementById('root')
);

