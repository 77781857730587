import * as wjChart from '@grapecity/wijmo.react.chart'
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation'
import { convertNumToCurrency } from '../../../utils/helperMethods'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { DashCardHeader } from '../dashboardStyles'
import { UnstyledButton } from '../../../styles/buttonStyles'
import CustomTooltip from '../../misc/CustomTooltip'

const CollectionsChart = ({ collections, formatYLabels, goToReport, reportName, theme, buildTooltip }) => {
  // round largest amount to nearest place value
  const calculateYAxisMax = () => {
    // get largest VoucherBalance from array of collection objects
    const largestNum = Math.ceil(
      collections
        .map(data => data.PaymentAmount)
        .sort((a, b) => a - b)
        .pop()
    )
    // get the place value of left most num
    const splitNums = largestNum.toString().split('')
    let newTens = parseInt(
      splitNums
        .map((num, i) => {
          let numVal
          if (i === 0) {
            splitNums[1] > 5 ? (numVal = (parseInt(num) + 1).toString()) : (numVal = num)
          } else if (i === 1) {
            num > 5 ? (numVal = '0') : (numVal = (parseInt(num) + 1).toString())
          } else {
            numVal = '0'
          }
          return numVal
        })
        .join('')
    )
    return newTens
  }

  return (
    <>
      <DashCardHeader>
        <h4>Total Collections by Month</h4>
        {/* <CustomTooltip title="Go to report" enterDelay={500} enterNextDelay={500}>
          <UnstyledButton
            onClick={() => {
              goToReport(reportName)
            }}
          >
            <FontAwesomeIcon style={{ marginLeft: '15px' }} icon={faFileAlt} />
          </UnstyledButton>
        </CustomTooltip> */}
      </DashCardHeader>
      <wjChart.FlexChart itemsSource={collections} bindingX="PeriodAbbreviation" cssClass="dark" plotMargin="20 40 70 60">
        <wjChart.FlexChartAxis itemFormatter={(engine, label) => formatYLabels(engine, label)} binding="PaymentAmount" wjProperty="axisY" max={calculateYAxisMax()} />
        <wjChart.FlexChartAxis labelAngle={-45} wjProperty="axisX"></wjChart.FlexChartAxis>
        <wjChart.FlexChartSeries style={{ fill: 'var(--color-primary-blue' }} binding="PaymentAmount" cssClass="bar" tooltipContent={ht => buildTooltip(ht)} />
        <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </>
  )
}

export default CollectionsChart
