import React, { useCallback } from 'react'
import { AlertBannerDiv, AlertIconWrapper, AlertText } from './alertStyles'
import AlertModal from './AlertInfoModal'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import {
  clearBannerMessage,
  toggleAlertModal,
} from './alertSlice'
import ErrorIcon from '@mui/icons-material/Error';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CloseIcon from '@mui/icons-material/Close';
import { FlexWrapper } from '../../styles/viewerStyles'

const AlertBanner = ({ isLoggedIn, termsAccepted }) => {
  const dispatch = useAppDispatch()

  const alert = useAppSelector((state) => state.alerts.allAlerts.find((msg) => !msg.read))
  const allAlerts = useAppSelector((state) => state.alerts.allAlerts)
  const readAlerts = useAppSelector((state) => state.alerts.allAlerts.filter((alert) => alert.read))
  const modalVisible = useAppSelector((state) => state.alerts.modalVisible)

  const toggleModal = useCallback(() => {
    dispatch(toggleAlertModal())
  }, [dispatch])

  const readMessage = useCallback(
    (payload) => {
      dispatch(clearBannerMessage(payload))
    },
    [dispatch]
  )

  const handleClick = () => {
    toggleModal()
    readMessage(alert.MessageID)
  }

  const renderIcon = () => {
    if (alert.MessageTypeDescription === 'Alert') {
      return (
        <AlertIconWrapper>
          <ErrorIcon/>
        </AlertIconWrapper>
      )
    } else if (alert.MessageTypeDescription === 'Warning') {
      return (
        <AlertIconWrapper>
          <NotificationsActiveIcon/>
        </AlertIconWrapper>
      )
    }
  }

  return (
    <>
      {!!alert ? (
        <AlertBannerDiv
          isLoggedIn={isLoggedIn && termsAccepted}
          type={alert.MessageTypeDescription}
        >
          <FlexWrapper justify="flex-start" align="center" onClick={() => toggleModal()}>
          {alert.MessageTypeDescription === 'Notification' ? null : renderIcon()}
          <AlertText
            aria-label={`${alert.MessageSummary}. Open details about this alert`}
          >
            {alert.MessageSummary + ' • ' + (readAlerts.length + 1) + '/' + allAlerts.length}
          </AlertText>
          </FlexWrapper>
          <AlertIconWrapper
            aria-label="close alert banner"
            onClick={() => readMessage(alert.MessageID)}
            close
          >
            <CloseIcon/>
          </AlertIconWrapper>
        </AlertBannerDiv>
      ) : null}
      {modalVisible ? (
        <AlertModal
          toggleModal={handleClick}
          message={alert}
          readMessage={readMessage}
        />
      ) : null}
    </>
  )
}

export default AlertBanner
