import React from 'react'
import { SubMenuUnorderedList } from './settingsStyles'

interface Props {
  name: string
  items: any[]
  setSelected: any
  setSelectedSubitem: any
  selected: string
  closeMobileMenu: any
}

const SubMenu = (props: Props) => {
  const handleClick = (e: any, item: any) => {
    e.stopPropagation()
    props.setSelected(props.name)
    props.setSelectedSubitem(item)
    props.closeMobileMenu(false)
  }

  const buildListItems = () => {
    return props.items.map((item: any, i: number) => {
      return (
        <li
          key={i}
          onClick={(e) => handleClick(e, item)}
          style={{color: props.selected === item ? '#7ED1E7' : '#FFF'}}
          data-testid={item + 'Page'}
        >
          {item}
        </li>
      )
    })
  }

  return <SubMenuUnorderedList>{buildListItems()}</SubMenuUnorderedList>
}

export default SubMenu
