import styled from 'styled-components';

export const MFAInstructions = styled.p`
  font-size: ${props => props.mobile ? '1.1em': '1.5em'};
  width: 80%;
  max-width: 600px;
  box-sizing: border-box;
  font-family: "WorkSans-Regular", "Work Sans", sans-serif;
  color: ${props => props.elevated && props.lightTheme? '#000' : '#FFF'};
  text-align: center;
  line-height: 30px;
`

export const MFAHelpStatement = styled.p`
  font-size: ${props => props.mobile ? '1.1em' : '1.2em'};
  font-family: "WorkSans-Regular", "Work Sans", sans-serif;
  width: 80%;
  margin-bottom: 10px;
  box-sizing: border-box;
  color: ${props => props.elevated && props.lightTheme? '#000' : '#FFF'};
  text-align: center;
  cursor: pointer;
`

export const MFALink = styled.a`
    cursor: pointer;
    color: ${props => props.elevated ? props.theme.shades.text : props.theme.emailPasscode.text};
    font-family: "WorkSans-Regular", "Work Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: none;
`

export const MFActorOptionsContainer = styled.div`
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    min-height: ${props => props.minHeight ? props.minHeight : 'initial'};
    box-sizing: border-box;
`

export const InputWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const PasscodeInput = styled.input`
  height: ${props => props.mobile ? '40px': '60px'};
  width: ${props => props.mobile ? '40px': '60px'};
  margin: ${props => props.mobile ? '6px': '15px'};
  background-color: ${props => props.elevated && props.lightTheme ? '#f1f1f1' : 'rgba(255, 255, 255, 0.7)'};
  color: ${props => props.elevated && props.lightTheme ? '#000000' : '#03263c'};
  font-family: "WorkSans-Regular", "Work Sans", sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  border-radius: 80%;
  border: ${props => props.elevated ?
    props.lightTheme ? 'solid 2px #555' : 'none'
    :
    'solid 2px #FFF'
  };
  :focus {
    outline: none;
  }
`;

export const ResendIcon = styled.div`
  width: 50px;
  height: 50px;
  margin: 10px;
  padding: 2px 2px 2px 2px;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 100%;
  background-color: #373d4d;
  color: #FFF;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ResendButton = styled.p`
  margin: 5px 15px;
  padding: 10px 15px;
  color: ${props => props.elevated && props.lightTheme ? '#555' : '#FFF'};
  font-family: "WorkSans-Regular", "Work Sans", sans-serif;
  font-size: 1em;
  text-align: center;
  cursor: pointer;
`

export const OptionsButton = styled.button`
  height: ${props => props.mobile ? '50px' : '60px'};
  width: ${props => props.mobile ? '50px' : '60px'};
  margin: ${props => props.mobile ? '8px' : '15px'};
  background-color: ${props => props.elevated && props.theme === 'Light' ? '#f1f1f1' : 'rgba(255, 255, 255, 0.7)'};
  color: #1a5192;
  font-family: "WorkSans-Regular", "Work Sans", sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  border-radius: 80%;
  cursor: pointer;
  border: ${props => props.elevated && props.theme === 'Light' ? 'solid 2px #555' : 'none'};
  :focus {
    outline: none;
  }
`;